import React from "react";
import InformationContainer from "pages/TransactionPage/InformationContainer";
import s from "./SwapFee.module.scss";

type SwapFeeProps = {
  value: string;
  className?: string;
  isLoading?: boolean;
};

function SwapFee({ value, className = "", isLoading = false }: SwapFeeProps) {
  return (
    <div className={`${className} ${s.container}`}>
      <InformationContainer
        tKey="transaction.swapFee"
        value={value}
        isLoading={isLoading}
      />
    </div>
  );
}

export default SwapFee;
