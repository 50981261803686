import BackButton from "components/common/BackButton";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ListingItemNew from "components/Listing/ListingItemNew";
import ListingItemSkeleton from "components/Listing/ListingItemSkeleton";
import ProjectPath from "constants/paths";
import getCoinsToChoose from "helpers/GetCoinsToChoose";
import useCreateListing from "hooks/requests/useCreateListing";
import useGetBalance from "hooks/requests/useGetBalance";
import { useAppDispatch } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import { regexJustNumbersAndDot } from "hooks/useHandleInputChange";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { openBalanceModal } from "store/modalsSlice";
import { Coins, CoinType, JustCoinType } from "types/coins";
import { SimpleListing, TokenAddress } from "types/listing";
import { getBalanceItem } from "utils/getSMTFromResponse";
import CoinSelect from "../CoinSelect";
import CommissionContainer from "../CommissionContainer";
import GasContainer from "../GasContainer";
import LabelContainer from "../LabelContainer";
import PolygonContainer from "../PolygonContainer";
import TransactionCounter from "../TransactionCounter";

import s from "../TransactionPage.module.scss";
import sellStyle from "./SellContent.module.scss";

interface BalanceModalResponse {
  coin: `${CoinType}`;
  chosen?: number;
  tokenAddress?: TokenAddress;
}

function SellContent() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [NFTQuantity, setNFTQuantity] = useState(1);
  const entity = useMemo<any>(() => searchParams.get("entity"), [searchParams]);
  const tokenAddress = useMemo<any>(
    () => searchParams.get("token_address"),
    [searchParams],
  );

  const itemId = useMemo(
    () => Number(searchParams.get("itemId")),
    [searchParams],
  );

  const coins: JustCoinType[] = useMemo(
    () => getCoinsToChoose(tokenAddress),
    [tokenAddress],
  );

  const dispatch = useAppDispatch();
  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();

  const [transferEntity, setTransferEntity] = useState<`${CoinType}`>(entity);
  const [NFTItemId, setNFTItemId] = useState<number | undefined>(
    itemId || undefined,
  );
  const [NFTItem, setNFTItem] = useState<SimpleListing | undefined>(undefined);
  const [coinIndex, setCoinIndex] = useState<number>(0);
  const [price, setPrice] = useState<string>("");

  const isFormValid = useMemo(() => {
    if (!price || !NFTItem) return false;
    return true;
  }, [price, NFTItem]);

  const { mutate: createListing, isLoading: isCreatingListing } =
    useCreateListing({ tokenAddress });

  useEffect(() => {
    if (balance) {
      setNFTItem(getBalanceItem(balance, itemId, tokenAddress));
    }
  }, [balance, itemId, tokenAddress]);

  const openModal = useAwaitModalResponse<BalanceModalResponse>({
    openModal: () => {
      dispatch(
        openBalanceModal({
          stage: "NFTSelector",
          withNFT: {
            car: true,
            part: true,
            pass: true,
            pet: true,
            town: true,
          },
        }),
      );
    },
    onResolve: (res) => {
      if (balance && res?.coin === "nft" && res.chosen) {
        setTransferEntity("nft");
        setNFTItemId(res.chosen);
        setNFTItem(getBalanceItem(balance, res.chosen, tokenAddress));
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.set("token_address", res.tokenAddress || "");
          prev.set("itemId", `${res.chosen}`);
          return prev;
        });
      }
      if (res?.coin !== "nft" && res?.coin) {
        setTransferEntity(res.coin);
        setNFTItemId(undefined);
        setNFTItem(undefined);
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.delete("token_address");
          prev.delete("itemId");
          return prev;
        });
      }
    },
  });

  const onCoinClick = useCallback(
    (coin: `${CoinType}`) => {
      setCoinIndex(coins.indexOf(coin as JustCoinType));
    },
    [coins],
  );

  const onPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (regexJustNumbersAndDot.test(e.target.value)) {
        setPrice(e.target.value);
      }
    },
    [],
  );

  const onConfirmClick = useCallback(() => {
    if (!NFTItemId) return;
    createListing({
      price,
      price_type: coins[coinIndex] as Coins,
      token_address: tokenAddress,
      tokenId: `${NFTItemId}`,
      amount: tokenAddress === "town" ? NFTQuantity : undefined,
    });
  }, [
    NFTItemId,
    createListing,
    price,
    coinIndex,
    tokenAddress,
    NFTQuantity,
    coins,
  ]);

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <BackButton
          className={s.backButton}
          route={`/${ProjectPath.WALLET_NFT}/${tokenAddress}/${NFTItemId}`}
        />
        <div className={s.title}>
          <span>{t("common.pages.sell")}</span>
          <Icon
            variant="sell"
            wrapperClassName={s.iconWrapper}
            className={s.icon}
            withWrapper
          />
        </div>
      </div>
      <div className={s.chosenNFT}>
        {isBalanceLoading || !NFTItem ? (
          <ListingItemSkeleton />
        ) : (
          <ListingItemNew
            key={NFTItem.token_id}
            id={NFTItem.token_id}
            attributes={NFTItem.metadata.attributesObj}
            image={NFTItem.metadata.image}
            name={NFTItem.metadata.name}
            onClick={openModal}
            tokenAddress={tokenAddress}
            quantity={NFTItem.metadata.balance}
          />
        )}
      </div>
      <div className={s.inputs}>
        {tokenAddress === "town" && NFTItem?.metadata && (
          <LabelContainer
            label="Quantity"
            className={s.quantityLabel}
            htmlFor="quantity-input"
          >
            <TransactionCounter
              quantity={NFTQuantity}
              setQuantity={setNFTQuantity}
              max={NFTItem.metadata.balance || 0}
              id="quantity-input"
            />
          </LabelContainer>
        )}
        <LabelContainer label={`${t("common.price")}:`}>
          <div className={sellStyle.sellWrapper}>
            <div className={sellStyle.inputWrapper}>
              <CoinSelect
                coin={coins[coinIndex]}
                handleClick={onCoinClick}
                coinsToChoose={coins}
              />
              <input
                placeholder={`${t("transaction.pricePlaceholder")} ${
                  CoinType[coins[coinIndex]]
                }`}
                className={sellStyle.input}
                onChange={onPriceChange}
                value={price}
              />
            </div>
          </div>
        </LabelContainer>
      </div>
      <div className={s.bottomPart}>
        <Button
          className={`${s.submit} ${s.mobile}`}
          disabled={!isFormValid}
          onClick={onConfirmClick}
          isLoading={isCreatingListing}
        >
          {t("common.pages.sell")}
        </Button>
        <div className={s.containers}>
          {transferEntity === "nft" && (
            <>
              <CommissionContainer />
              <div className={s.separator} />
            </>
          )}
          <PolygonContainer />
          <div className={s.separator} />
          <GasContainer />
        </div>
        <Button
          className={`${s.submit} ${s.desktop}`}
          disabled={!isFormValid}
          onClick={onConfirmClick}
          isLoading={isCreatingListing}
        >
          {t("common.pages.sell")}
        </Button>
      </div>
    </div>
  );
}

export default SellContent;
