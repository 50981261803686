import React, { useCallback } from "react";
import { changeAuthEmail, switchToVerifyModalStage } from "store/modalsSlice";
import { setEmail } from "store/userProfileSlice";

import { useAppDispatch } from "hooks/store";
import { setAuthCaptchaSource } from "store/appStateSlice";
import { AuthResponse, GetAuthTgLinkResponse } from "types/responses";
import useReCAPTCHAv3 from "./useReCAPTCHAv3";
import useRequestAuth from "./requests/useRequestAuth";

export interface ValuesType {
  email: string;
}

function isGetAuthResponse(
  resp: GetAuthTgLinkResponse | AuthResponse,
): resp is AuthResponse {
  return (resp as AuthResponse).ok !== undefined;
}

const useSubmitAuthForm = () => {
  const dispatch = useAppDispatch();
  const { recaptchaToken } = useReCAPTCHAv3("login");
  const { mutateAsync: auth } = useRequestAuth();

  const onSubmit = useCallback(
    async ({ email }: ValuesType) => {
      const data = await auth({
        email,
        token: recaptchaToken,
        source: "login/registration",
      });
      if (isGetAuthResponse(data) && data.ok) {
        dispatch(switchToVerifyModalStage());
        dispatch(setAuthCaptchaSource("login/registration"));
        dispatch(changeAuthEmail(email));
        dispatch(setEmail(email));
      } else {
        throw new Error("Verification failed");
      }
    },
    [recaptchaToken, auth, dispatch],
  );

  return { onSubmit };
};

export default useSubmitAuthForm;
