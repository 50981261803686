import { configureStore } from "@reduxjs/toolkit";

import { modalsReducer } from "./modalsSlice";
import { menusReducer } from "./menusSlice";
import { appStateReducer } from "./appStateSlice";
import { userProfileReducer } from "./userProfileSlice";
import { marketItemReducer } from "./marketItemSlice";
import { filtersReducer } from "./filtersSlice";
import { marketCollectionTabReducer } from "./marketCollectionTabSlice";

const store = configureStore({
  reducer: {
    appState: appStateReducer,
    modals: modalsReducer,
    menus: menusReducer,
    filters: filtersReducer,
    userProfile: userProfileReducer,
    marketItem: marketItemReducer,
    marketCollectionTab: marketCollectionTabReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
