import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useBuyListing from "hooks/requests/useBuyListing";
import useCancelListing from "hooks/requests/useCancelListing";
import { useAppDispatch } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import useCreateListing from "hooks/requests/useCreateListing";
import { setIsCaptchaOpen } from "store/appStateSlice";
import { Listing, TokenAddress } from "types/listing";
import { IconsVariant } from "types/icons";
import { Coins } from "types/coins";
import {
  openActionModal,
  openAuthModal,
  openConfirmationModal,
} from "store/modalsSlice";

import Button from "components/common/Button";
import ButtonRoundIcon from "components/common/ButtonRoundIcon";

import s from "./ListingControls.module.scss";

type ListingControlsProps = Pick<
  Listing,
  "id" | "price_type" | "priceConverted"
> & {
  isLoading: boolean;
  isMyToken: boolean;
  className?: string;
  tokenAddress: TokenAddress;
  tokenId: string;
  setIsEditingPrice: (value: boolean) => void;
};

function ListingControls({
  id,
  price_type: priceType,
  priceConverted,
  isLoading,
  isMyToken,
  tokenAddress,
  tokenId,
  className = "",
  setIsEditingPrice,
}: ListingControlsProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cookie = useGetAuthCookie();

  const { mutate: buyListing, isLoading: isBaying } = useBuyListing({
    listing_id: id,
    tokenAddress,
  });
  const { mutate: reCreateListing, isLoading: isUpdating } = useCreateListing({
    tokenAddress,
  });
  const { mutate: stopListing, isLoading: isClosing } = useCancelListing({
    listing_id: id,
    tokenAddress,
  });

  const handleEditPrice = useCallback(() => {
    setIsEditingPrice(true);
  }, [setIsEditingPrice]);

  const openLoginModal = useCallback(() => {
    dispatch(openAuthModal());
    dispatch(setIsCaptchaOpen(true));
  }, [dispatch]);

  const openBuyModal = useAwaitModalResponse({
    openModal: () => {
      dispatch(
        openConfirmationModal({
          title: t("modals.buyItem.title"),
          text: t("modals.buyItem.text"),
          buttonText: t("modals.buyItem.button"),
        }),
      );
    },
    onResolve: () => {
      buyListing();
    },
  });

  const openStopModal = useAwaitModalResponse({
    openModal: () => {
      dispatch(
        openConfirmationModal({
          title: t("modals.stopSell.title"),
          text: t("modals.stopSell.text"),
          buttonText: t("modals.stopSell.button"),
        }),
      );
    },
    onResolve: () => {
      stopListing();
    },
  });

  type PriceChangeModalType = {
    price: string;
    actualPriceType: Coins;
  };

  const openPriceChangeModal = useAwaitModalResponse<PriceChangeModalType>({
    openModal: () => {
      dispatch(
        openActionModal({
          title: t("modals.changePrice.title"),
          buttonText: t("modals.changePrice.button"),
          input: {
            coin: priceType || "matic",
            initialValue: priceConverted || 0,
            placeHolder: t("modals.changePrice.pricePlaceholder"),
          },
        }),
      );
    },
    onResolve: (args: PriceChangeModalType | undefined) => {
      if (args) {
        const { price, actualPriceType } = args;
        reCreateListing({
          price,
          price_type: actualPriceType,
          token_address: tokenAddress,
          tokenId,
          amount: tokenAddress === "town" ? 1 : undefined,
        });
      }
    },
  });

  const onBuyItem = useCallback(() => {
    openBuyModal();
  }, [openBuyModal]);

  const onStopItem = useCallback(() => {
    openStopModal();
  }, [openStopModal]);

  const onChangePriceItem = useCallback(() => {
    openPriceChangeModal();
  }, [openPriceChangeModal]);

  const controls = useMemo(() => {
    if (!cookie) {
      return (
        <Button className={s.button} onClick={openLoginModal} isLoading={false}>
          {t("common.logIn")}
        </Button>
      );
    }
    if (isLoading) return null;
    if (isMyToken) {
      return (
        <>
          <ButtonRoundIcon
            iconVariant={IconsVariant.pensil}
            onClick={handleEditPrice}
            isLoading={isClosing || isUpdating}
            containerClassName={s.roundButton}
          >
            {t("actions.change")}
          </ButtonRoundIcon>
          <ButtonRoundIcon
            iconVariant={IconsVariant.locker}
            onClick={onStopItem}
            isLoading={isClosing || isUpdating}
          >
            {t("actions.stop")}
          </ButtonRoundIcon>
        </>
      );
    }
    return (
      <Button className={s.button} onClick={onBuyItem} isLoading={isBaying}>
        {t("actions.buy")}
      </Button>
    );
  }, [
    cookie,
    isBaying,
    isClosing,
    isLoading,
    isMyToken,
    isUpdating,
    onBuyItem,
    onChangePriceItem,
    onStopItem,
    openLoginModal,
    t,
  ]);
  return <div className={`${className} ${s.controls}`}>{controls}</div>;
}

export default ListingControls;
