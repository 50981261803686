import React from "react";
import { useTranslation } from "react-i18next";

import { Stat as StatType } from "types/stat";

import StatIcon from "./StatIcon/StatIcon";

import s from "./Stat.module.scss";

interface Props {
  variant: StatType;
  value: string | number;
  className?: string;
}

function Stat({ variant, value, className = "" }: Props) {
  const { t } = useTranslation();

  const title = (() => {
    switch (variant) {
      case "tier":
        return t("item.stat.tier");
      case "lvl":
        return t("item.stat.lvl");
      case "energy":
        return "";
      case "luck":
        return "";

      default:
        return "";
    }
  })();

  const valueComputed = (() => {
    switch (variant) {
      case "tier":
        return value;
      case "lvl":
        return value;
      case "energy":
        return `+${value}`;
      case "luck":
        return `+${value}`;
      case "attack":
        return value;
      case "defense":
        return value;

      default:
        return value;
    }
  })();

  const iconVariantComputed = (() => {
    switch (variant) {
      case "tier":
        return "star";
      case "lvl":
        return "lvlUp";
      case "energy":
        return "energy";
      case "luck":
        return "luck";
      case "attack":
        return "attackSvg";
      case "defense":
        return "defenseSvg";

      default:
        return "star";
    }
  })();

  return (
    <div className={`${s.stat} ${className}`}>
      <StatIcon variant={iconVariantComputed} />
      <span className={s.value}>{valueComputed}</span>
      {title && <span className={s.title}>{title}</span>}
    </div>
  );
}

export default Stat;
