import { useMutation } from "@tanstack/react-query";
import { requestAuth, requestTgAuth } from "api/auth";
import ApiError from "classes/ApiError";
import { useTranslation } from "react-i18next";
import { AuthResponse, GetAuthTgLinkResponse } from "types/responses";
import { showErrors } from "utils/showErrors";

export function isGetAuthTgLinkResponse(
  resp: GetAuthTgLinkResponse | AuthResponse,
): resp is GetAuthTgLinkResponse {
  return (resp as GetAuthTgLinkResponse).link !== undefined;
}

export function isGetAuthResponse(
  resp: GetAuthTgLinkResponse | AuthResponse,
): resp is AuthResponse {
  return (resp as AuthResponse).ok !== undefined;
}

interface AuthRequestArgs {
  email: string;
  token: string;
  source: "login/registration";
}
interface TelegramAuthRequestArgs {
  token: string;
  source: "loginViaTg";
}

const useRequestAuth = () => {
  const { t } = useTranslation();
  return useMutation<
    AuthResponse | GetAuthTgLinkResponse,
    ApiError,
    AuthRequestArgs | TelegramAuthRequestArgs
  >(
    ["auth-user"],
    async (args) => {
      const { source, token } = args;
      if (source === "login/registration") {
        if (args.email) {
          return requestAuth({ email: args.email, token });
        }
        return Promise.reject(new Error(t("notifications.invalidEmail.title")));
      }

      if (source === "loginViaTg") {
        return requestTgAuth({ token });
      }

      return Promise.reject(
        new Error(t("notifications.somethingWentWrong.title")),
      );
    },
    {
      onError: showErrors,
    },
  );
};

export default useRequestAuth;
