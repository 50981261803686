import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { TFuncKey, useTranslation } from "react-i18next";
import Icon from "../Icon";
import s from "./Collapse.module.scss";

interface IProps {
  open?: boolean;
  title: string;
  tKey?: TFuncKey;
  className?: string;
}

function Collapse({
  open = false,
  title,
  tKey = undefined,
  className = "",
  children = <div />,
}: React.PropsWithChildren<IProps>) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(open);

  const variants = {
    closed: {
      height: 0,
      transition: {
        duration: 0.5,
      },
    },
    opened: {
      height: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const toggleOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <button type="button" onClick={toggleOpening}>
      <div
        className={`${s.collapse} ${
          isOpen ? s.collapseOpened : s.collapseClosed
        }  ${className}`}
      >
        <div className={s.collapseHeader}>
          <div className={s.title}>{tKey ? t(tKey).toString() : title}</div>
          <div>
            {isOpen ? (
              <Icon variant="arrowDown" className={s.arrowUp} />
            ) : (
              <Icon variant="arrowDown" className={s.arrowDown} />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={variants}
            initial="closed"
            animate="opened"
            exit="closed"
            style={{ overflow: "hidden" }}
          >
            <div className={s.children}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}

export default Collapse;
