import React, { useMemo } from "react";

import { StatIcons } from "types/stat";
import Icon from "components/common/Icon";

import s from "./StatIcon.module.scss";

interface IconProps {
  className?: string;
}

interface Props extends IconProps {
  variant: StatIcons;
}

function StatIcon({ variant, className = "" }: Props) {
  const iconClassName = useMemo(
    () => `${s.icon} ${s[variant]} ${className}`,
    [className, variant],
  );
  return <Icon variant={variant} className={iconClassName} />;
}

export default StatIcon;
