import React from "react";

import ShopCompleteModal from "components/modals/ShopCompleteModal";
import BenefitsPart from "./BenefitsPart";
import ControlPart from "./ControlPart";

import s from "./GameShopPage.module.scss";

function GameShopPage() {
  return (
    <>
      <div className={s.gameShop}>
        <div className={s.container}>
          <h1 className={s.title}>Game Shop</h1>
          <ControlPart />
          <BenefitsPart />
        </div>
      </div>
      <ShopCompleteModal />
    </>
  );
}

export default GameShopPage;
