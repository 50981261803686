import React from "react";
import { IconMapKey, IconMapValue, IconsVariant } from "types/icons";
import { ReactComponent as SwapIcon } from "assets/images/swap.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock.svg";
import { ReactComponent as MoonPayIcon } from "assets/images/moonPay.svg";
import { ReactComponent as ArrowThickIcon } from "assets/images/arrowThick.svg";
import { ReactComponent as PensilIcon } from "assets/images/pensil.svg";
import { ReactComponent as LockerIcon } from "assets/images/locker.svg";
import { ReactComponent as MoneyBagIcon } from "assets/images/moneyBag.svg";
import { ReactComponent as TrendyCrownIcon } from "assets/images/trendyCrown.svg";
import { ReactComponent as SettingsIcon } from "assets/images/settings.svg";
import { ReactComponent as WalletIcon } from "assets/images/wallet.svg";
import { ReactComponent as ArrowDownRoundedIcon } from "assets/images/arrowDownRounded.svg";
import { ReactComponent as AttackIcon } from "assets/images/attack.svg";
import { ReactComponent as DefenseIcon } from "assets/images/defense.svg";
import { ReactComponent as RarityIcon } from "assets/images/rarity.svg";
import { ReactComponent as ElementIcon } from "assets/images/element.svg";
import { ReactComponent as AdsIcon } from "assets/images/ads.svg";
import { ReactComponent as ClockAlarmIcon } from "assets/images/clockAlarm.svg";
import { ReactComponent as LoginIcon } from "assets/images/login.svg";
import { ReactComponent as LogoutIcon } from "assets/images/logout.svg";
import { ReactComponent as SendIcon } from "assets/images/send.svg";
import { ReactComponent as ExchangeIcon } from "assets/images/exchange.svg";
import { ReactComponent as IncomeIcon } from "assets/images/income.svg";
import { ReactComponent as ProfileIcon } from "assets/images/profile.svg";
import { ReactComponent as SuccessIcon } from "assets/images/success.svg";
import { ReactComponent as ErrorIcon } from "assets/images/error.svg";
import { ReactComponent as TelegramIcon } from "assets/images/telegram.svg";
import { ReactComponent as WarningExclamationIcon } from "assets/images/warningExclamation.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/arrowRight.svg";

/** use svgIconMap to add svg icons outside icomoon font */
const svgIconMap: Record<IconMapKey, IconMapValue> = {
  [IconsVariant[IconsVariant.arrowThick]]: (className: string) => (
    <ArrowThickIcon className={className} />
  ),
  [IconsVariant[IconsVariant.send]]: (className: string) => (
    <SendIcon className={className} />
  ),
  [IconsVariant[IconsVariant.income]]: (className: string) => (
    <IncomeIcon className={className} />
  ),
  [IconsVariant[IconsVariant.profile]]: (className: string) => (
    <ProfileIcon className={className} />
  ),
  [IconsVariant[IconsVariant.exchange]]: (className: string) => (
    <ExchangeIcon className={className} />
  ),
  [IconsVariant[IconsVariant.pensil]]: (className: string) => (
    <PensilIcon className={className} />
  ),
  [IconsVariant[IconsVariant.moneyBag]]: (className: string) => (
    <MoneyBagIcon className={className} />
  ),
  [IconsVariant[IconsVariant.locker]]: (className: string) => (
    <LockerIcon className={className} />
  ),
  [IconsVariant[IconsVariant.moonPay]]: (className: string) => (
    <MoonPayIcon className={className} />
  ),
  [IconsVariant[IconsVariant.swap]]: (className: string) => (
    <SwapIcon className={className} />
  ),
  [IconsVariant[IconsVariant.clock]]: (className: string) => (
    <ClockIcon className={className} />
  ),
  [IconsVariant[IconsVariant.trendyCrown]]: (className: string) => (
    <TrendyCrownIcon className={className} />
  ),
  [IconsVariant[IconsVariant.settingsSvg]]: (className: string) => (
    <SettingsIcon className={className} />
  ),
  [IconsVariant[IconsVariant.arrowDownRoundedSvg]]: (className: string) => (
    <ArrowDownRoundedIcon className={className} />
  ),
  [IconsVariant[IconsVariant.walletSvg]]: (className: string) => (
    <WalletIcon className={className} />
  ),
  [IconsVariant[IconsVariant.attackSvg]]: (className: string) => (
    <AttackIcon className={className} />
  ),
  [IconsVariant[IconsVariant.defenseSvg]]: (className: string) => (
    <DefenseIcon className={className} />
  ),
  [IconsVariant[IconsVariant.raritySvg]]: (className: string) => (
    <RarityIcon className={className} />
  ),
  [IconsVariant[IconsVariant.elementSvg]]: (className: string) => (
    <ElementIcon className={className} />
  ),
  [IconsVariant[IconsVariant.adsSvg]]: (className: string) => (
    <AdsIcon className={className} />
  ),
  [IconsVariant[IconsVariant.clockAlarmSvg]]: (className: string) => (
    <ClockAlarmIcon className={className} />
  ),
  [IconsVariant[IconsVariant.logInSvg]]: (className: string) => (
    <LoginIcon className={className} />
  ),
  [IconsVariant[IconsVariant.logOutSvg]]: (className: string) => (
    <LogoutIcon className={className} />
  ),
  [IconsVariant[IconsVariant.success]]: (className: string) => (
    <SuccessIcon className={className} />
  ),
  [IconsVariant[IconsVariant.error]]: (className: string) => (
    <ErrorIcon className={className} />
  ),
  [IconsVariant[IconsVariant.telegramSvg]]: (className: string) => (
    <TelegramIcon className={className} />
  ),
  [IconsVariant[IconsVariant.warningExclamationSvg]]: (className: string) => (
    <WarningExclamationIcon className={className} />
  ),
  [IconsVariant[IconsVariant.arrowRight]]: (className: string) => (
    <ArrowRightIcon className={className} />
  ),
};

export default svgIconMap;
