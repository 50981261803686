import React, { useEffect, useRef } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { FiltersSliceType } from "types/filters";
import { ListingTabTypeMap, TokenAddress } from "types/listing";
import { useAppSelector } from "hooks/store";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

import CookiePanel from "./components/CookiePanel";

import s from "./NavigationLayout.module.scss";
import BottomPanel from "./Sidebar/BottomPanel";
import NavigationMenu from "./Menu/NavigationMenu";

function NavigationLayout() {
  const { isTgWebApp } = useAppSelector((state) => state.appState);
  const { pathname } = useLocation();
  const { collectionType } = useParams<{ collectionType: TokenAddress }>();
  const filterSliceType: FiltersSliceType = `${collectionType ?? "car"}Filters${
    pathname?.endsWith("my") ? ListingTabTypeMap.my : ListingTabTypeMap.all
  }`;
  const { appliedFilter } = useAppSelector(
    (state) => state.filters[filterSliceType],
  );
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (appliedFilter) {
      pageRef.current?.scrollTo({ top: 0 });
    }
  }, [appliedFilter]);

  const noHeader =
    ((pathname === "/wallet" || pathname === "/wallet/nft") &&
      window.innerWidth <= 415) ||
    isTgWebApp;

  return (
    <div className={`${s.layout} ${noHeader && s.noHeader}`}>
      <div className={s.sidebar}>
        <Sidebar />
      </div>
      {noHeader || (
        <div className={s.header}>
          <Header />
        </div>
      )}
      <div className={s.contentWrapper} ref={pageRef}>
        <div className={s.content}>
          <Outlet />
        </div>
        <div className={s.footer}>
          <Footer />
        </div>
        <CookiePanel />
      </div>
      <div className={s.bottomPanel}>
        <BottomPanel />
      </div>
      <NavigationMenu />
    </div>
  );
}

export default NavigationLayout;
