import { useQuery, useQueryClient } from "@tanstack/react-query";
import { verifyAuthTgData } from "api/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "store/appStateSlice";
import getAuthCookie from "utils/getAuthCookie";

interface UseRequestAuthVerifyTgData {
  WebApp: any;
}

const useRequestAuthVerifyTgData = (params: UseRequestAuthVerifyTgData) => {
  const dispatch = useDispatch();

  const queryInfo = useQuery({
    queryKey: ["verifyAuthTgData"],
    queryFn: async () =>
      verifyAuthTgData({ tgData: params.WebApp.initData ?? "" }),
    enabled: false,
    onError: () => {
      params.WebApp.showAlert(
        "To log in, link your email in the MetaTrace app.",
        () => {
          params.WebApp.close();
        },
      );
    },
    retry: 0,
  });

  useEffect(() => {
    if (queryInfo.data) {
      const cookie = getAuthCookie();
      if (cookie) dispatch(setAuthToken(cookie));
    }
  }, [queryInfo, dispatch]);

  return queryInfo;
};

export default useRequestAuthVerifyTgData;
