import React from "react";

import {
  tgInputOnFocus,
  tgInputOnKeyDown,
} from "helpers/TgWebAppInputHandlers";

import { useAppSelector } from "hooks/store";

import divideStringIntoParts from "utils/divideStringIntoParts";

import s from "./TransactionInput.module.scss";

interface TransactionInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  input?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
  variant?: "default" | "gigantic";
  disabled?: boolean;
  hasError?: boolean;
  focused?: boolean;
  className?: string;
  placeholder?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  unTouchable?: boolean;
}

function TransactionInput({
  input = true,
  before = undefined,
  after = undefined,
  variant = "default",
  disabled = false,
  hasError = false,
  focused = false,
  className = "",
  placeholder = "",
  type = "text",
  unTouchable = false,
  value,
  ...props
}: TransactionInputProps) {
  const mainClassName = `${s.inputWrapper} ${s[variant]} ${
    disabled ? s.disabled : ""
  } ${className} ${hasError ? s.error : ""} ${
    unTouchable ? s.unTouchable : ""
  } ${focused ? s.focused : ""}`;

  const { isTgWebApp } = useAppSelector((state) => state.appState);

  const onFocus = (event: React.FocusEvent<HTMLElement>) => {
    tgInputOnFocus(event, !!isTgWebApp);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    tgInputOnKeyDown(event, !!isTgWebApp);
  };

  return (
    <div className={s.container}>
      <div className={mainClassName}>
        {before && before}
        {input && (
          <input
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            {...props}
            type={type}
            className={s.input}
            placeholder={placeholder}
          />
        )}
        {after && after}
      </div>
      {hasError && (
        <p className={s.error}>
          Please check if the address is entered correctly
        </p>
      )}
    </div>
  );
}

export default TransactionInput;
