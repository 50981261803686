import React, { useContext } from "react";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import { useDispatch } from "react-redux";
import { changeAuthModalStage } from "store/modalsSlice";
import TelegramTabContext from "helpers/TelegramTabContextProvider";
import useGetAuthTgLink from "hooks/requests/useGetAuthTgLink";
import { isGetAuthTgLinkResponse } from "hooks/requests/useRequestAuth";

import s from "./AuthTelegram.module.scss";

const AuthTelegram = React.memo(function AuthTelegram() {
  const dispatch = useDispatch();
  const tgAuthTab = useContext(TelegramTabContext);
  const { data, isLoading, isError } = useGetAuthTgLink();

  const handleClick = () => {
    if (data && isGetAuthTgLinkResponse(data)) {
      const newTgTab = window.open(data.link ?? "", "_blank");
      tgAuthTab.current = newTgTab;
      dispatch(changeAuthModalStage("verifyTelegramCode"));
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.lineContainer}>
        <hr className={s.line} />
        <div className={s.lineContainerText}>or via</div>
        <hr className={s.line} />
      </div>
      <Button
        className={s.telegramButton}
        variant="filledBlue"
        classNameChildren={s.telegramButtonContent}
        onClick={handleClick}
        isLoading={isLoading}
        disabled={isError}
      >
        <Icon variant="telegramSvg" className={s.icon} />
        <span className={s.buttonText}>Telegram</span>
      </Button>
    </div>
  );
});

export default AuthTelegram;
