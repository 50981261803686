import {
  FilterRestructured,
  IntegerFiltersRestructured,
  ListingFilter,
  StringFiltersRestructured,
} from "types/filters";
import { RequestListingFilter } from "types/requests";

export const sortArray = (arr: ListingFilter[]): ListingFilter[] => {
  const arrCopy = [...arr];
  if (arrCopy?.length > 0) {
    return arrCopy?.sort((a, b) => {
      if (a.trait_type === "Type") {
        return 1;
      }
      if (b.trait_type === "Type") {
        return 1;
      }
      if (a.trait_type < b.trait_type) {
        return -1;
      }
      if (a.trait_type > b.trait_type) {
        return 1;
      }
      return 1;
    });
  }
  return [];
};

const restructureFilters = (
  filters: ListingFilter[],
  appliedFilter: RequestListingFilter,
): FilterRestructured => {
  const restructuredData: FilterRestructured = {};
  if (filters?.length > 0) {
    filters?.forEach((filter) => {
      const appliedFilters = appliedFilter[filter.trait_type];
      if ("values_string" in filter) {
        const restructuredFilter: StringFiltersRestructured = {
          ...filter,
          type: "selector",
          isActive: !!appliedFilters,
          value:
            appliedFilters &&
            Array.isArray(appliedFilters) &&
            appliedFilters.length
              ? (appliedFilter[filter.trait_type] as string[])
              : [],
        };
        restructuredData[filter.trait_type] = restructuredFilter;
        return;
      }

      const restructuredFilter: IntegerFiltersRestructured = {
        ...filter,
        type: "range",
        isActive: !!appliedFilters,
        value:
          appliedFilters &&
          Array.isArray(appliedFilters) &&
          appliedFilters.length
            ? (appliedFilter[filter.trait_type] as number[])
            : [filter.value_int_min, filter.value_int_max],
      };
      restructuredData[filter.trait_type] = restructuredFilter;
    });
  }
  return restructuredData;
};

export default restructureFilters;
