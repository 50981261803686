import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { createListing } from "api/listing";
import { convertDecimalTokenBackward } from "utils/convertDecimalToken";
import { showErrors } from "utils/showErrors";

import ProjectPath from "constants/paths";
import ApiError from "classes/ApiError";
import { CreateListingRequest } from "types/requests";
import { CreateListingResponse } from "types/responses";
import { TokenAddress } from "types/listing";
import { useState } from "react";
import { updateMarketCollectionTab } from "store/marketCollectionTabSlice";
import { useDispatch } from "react-redux";

interface UseCreateListingParams {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  tokenAddress: TokenAddress;
}

const useCreateListing = (params?: UseCreateListingParams) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { onSuccess = undefined, onError = undefined } = params || {};
  const queryInfo = useMutation<
    CreateListingResponse,
    ApiError,
    CreateListingRequest
  >(
    ["create-listing"],
    async ({
      price,
      price_type,
      tokenId,
      token_address,
      amount,
      listing_id,
    }) => {
      if (price && price_type && tokenId && token_address) {
        setIsLoading(true);
        const requestBody: CreateListingRequest = {
          price: `${convertDecimalTokenBackward(price, price_type)}`,
          price_type,
          tokenId,
          token_address,
          listing_id,
        };
        if (amount) {
          requestBody.amount = amount;
        }
        return createListing(requestBody);
      }
      return Promise.reject(new Error("One of values is invalid"));
    },
    {
      onSuccess:
        onSuccess ||
        ((_, functionParams) =>
          setTimeout(() => {
            setIsLoading(false);
            if (
              params?.tokenAddress === "town" &&
              functionParams.amount &&
              functionParams.amount > 4
            ) {
              navigate(`/${ProjectPath.WALLET}`);
              enqueueSnackbar({
                message:
                  "Item has been put up for sale, but it requires some time to update",
                variant: "success",
              });
              return;
            }
            enqueueSnackbar({
              message: t("notifications.itemOnSale.title"),
              variant: "success",
            });
            dispatch(updateMarketCollectionTab({ tab: "my" }));
            navigate(`/${ProjectPath.MARKET}/${params?.tokenAddress}`);
          }, 1000)),
      onError: (error) => {
        setIsLoading(false);
        if (onError && typeof onError === "function") {
          onError(error);
        } else {
          showErrors(error);
        }
      },
    },
  );
  return { ...queryInfo, isLoading };
};

export default useCreateListing;
