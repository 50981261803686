import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import ApiError from "classes/ApiError";

import { showErrors } from "utils/showErrors";

import { getBTCandETHPrice } from "api/wallet";
import { BTCandETHPrice } from "types/requests";

const useGetBTCandETHprices = () => {
  const currencies = useQuery<BTCandETHPrice, Error>(
    ["BTCandETHPrice"],
    async () => {
      return getBTCandETHPrice();
    },
  );

  useEffect(() => {
    if (currencies.isError && currencies.error instanceof ApiError) {
      showErrors(currencies.error);
    }
  }, [currencies.isError, currencies.error]);

  return currencies;
};

export default useGetBTCandETHprices;
