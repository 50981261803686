import useGetFilters from "hooks/requests/useGetFilters";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { resetFilters } from "store/filtersSlice";
import { closeFilterMenu } from "store/menusSlice";
import { FiltersSliceType } from "types/filters";
import { ListingTabType, TokenAddress } from "types/listing";
import FilterButton from "../FilterButton";
import FilterElement from "../FilterElement";

import s from "./WithResetContainer.module.scss";

type WithResetContainerProps = {
  collectionType: TokenAddress;
  listingType: ListingTabType;
  filterSliceType: FiltersSliceType;
  isAllFiltersCollapsed: boolean;
  setIsAllFiltersCollapsed: Dispatch<SetStateAction<boolean>>;
};
function WithResetContainer({
  collectionType,
  listingType,
  filterSliceType,
  isAllFiltersCollapsed,
  setIsAllFiltersCollapsed,
}: WithResetContainerProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useGetFilters({
    listingType,
    typeNft: collectionType ?? "car",
  });
  const filterState = useAppSelector((state) => state.filters);
  const { filtersData } = filterState[filterSliceType];

  const handleResetClick = useCallback(() => {
    dispatch(resetFilters(filterSliceType));
    dispatch(closeFilterMenu());
    setIsAllFiltersCollapsed(true);
  }, [dispatch, filterSliceType]);

  return (
    <>
      {!!filtersData?.length && (
        <div className={s.filters}>
          {filtersData.map((elem) => (
            <FilterElement
              filtersSliceType={filterSliceType}
              title={elem.trait_type}
              key={elem.trait_type}
              filter={elem}
              isAllFiltersCollapsed={isAllFiltersCollapsed}
              collapseAllFilters={setIsAllFiltersCollapsed}
            />
          ))}
        </div>
      )}
      <FilterButton
        title={t("menus.filter.reset")}
        className={s.reset}
        onClick={handleResetClick}
        iconVariant="restart"
      />
    </>
  );
}

export default WithResetContainer;
