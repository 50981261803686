import PopUpTooltip from "components/PopUpTooltip";
import { Field, FormikErrors, useField } from "formik";
import {
  tgInputOnBlur,
  tgInputOnFocus,
  tgInputOnKeyDown,
} from "helpers/TgWebAppInputHandlers";
import { useAppSelector } from "hooks/store";
import CoinSelect from "pages/TransactionPage/CoinSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { CoinType, ESwapCoins } from "types/coins";
import filterCurrencyInput from "utils/filterCurrencyInput";
import { getCurrencyNumberLengthAfterDot } from "../../utils";

import s from "../../SwapContent.module.scss";

type FromTokenProps = {
  balance: string;
  balanceFull: string;
  fromCurrency: string;
  setFromAmountRequest: React.Dispatch<React.SetStateAction<string | null>>;
  errors: FormikErrors<any>;
  setFromCurrency: (coin: ESwapCoins) => void;
};

function FromToken({
  balance,
  balanceFull,
  fromCurrency,
  setFromAmountRequest,
  errors,
  setFromCurrency,
}: FromTokenProps) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({
    type: "text",
    name: "fromAmount",
  });

  const { setValue } = helpers;
  const { isTgWebApp } = useAppSelector((state) => state.appState);

  const handleFromCoinClick = (coin: `${CoinType}`): void => {
    setFromCurrency(coin as ESwapCoins);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currency = fromCurrency.toLowerCase() as ESwapCoins;

    const newValue = filterCurrencyInput(
      e.target.value,
      getCurrencyNumberLengthAfterDot(currency),
    );
    setValue(newValue);

    const parsedNewValue = parseFloat(newValue);
    const checkedValue = Number.isNaN(parsedNewValue) ? 0 : parsedNewValue;
    setFromAmountRequest(`${checkedValue}`);
  };

  const handleMaxClick = () => {
    setValue(balanceFull.toString());
    setFromAmountRequest(balanceFull);
  };

  const onFocus = (event: React.FocusEvent<HTMLElement>) => {
    tgInputOnFocus(event, !!isTgWebApp);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    tgInputOnKeyDown(event, !!isTgWebApp);
  };

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    tgInputOnBlur(event, !!isTgWebApp);
  };

  return (
    <div
      className={`${s.tokenWrapper} ${errors.fromAmount ? s.errorWrapper : ""}`}
    >
      <div className={s.inputWrapper}>
        <label htmlFor="fromCurrency" className={s.label}>
          {t("common.balance")}: {balance}
        </label>
        <div>
          <CoinSelect
            coin={
              CoinType[
                fromCurrency.toLowerCase() as unknown as keyof typeof CoinType
              ]
            }
            handleClick={handleFromCoinClick}
            coinsToChoose={["trc", "matic", "usdt", "ace", "wbtc", "weth"]}
          />
          <Field
            className={s.input}
            name="fromCurrency"
            value={fromCurrency}
            readOnly
            type="hidden"
          />
        </div>
      </div>
      <div className={s.inputWrapper}>
        <label htmlFor="fromAmount" className={s.label}>
          {t("swap.fromLabel")}:
        </label>
        <div className={s.fromAmountContainer}>
          <input
            {...field}
            placeholder="0"
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            className={s.input}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <PopUpTooltip
            text="All in"
            buttonStyles={s.maxButton}
            buttonText="Max"
            onClick={handleMaxClick}
          />
        </div>
      </div>
    </div>
  );
}

export default FromToken;
