import React from "react";

import realixLogo from "assets/images/REALIX.svg";

import s from "./Footer.module.scss";

function Footer() {
  return (
    <div className={s.footer}>
      <div className={s.docs}>
        <a
          href="https://wiki.metatrace.me/legal-information/privacy-policy"
          target="__blank"
          className={s.doc}
        >
          Privacy Policy
        </a>
        <div className={s.separator} />
        <a
          href="https://wiki.metatrace.me/legal-information/terms-of-service"
          target="__blank"
          className={s.doc}
        >
          Terms of Use
        </a>
      </div>
      <div className={s.copyright}>
        2022-2024 Copyright by MetaTrace. All rights reserved.
      </div>
      <div className={s.company}>
        <div className={s.title}>Powered by</div>
        <div className={s.logo}>
          <img src={realixLogo} alt="logo" className="fill-contain" />
          <a
            href="https://realix.tech/"
            target="_blank"
            className="fill-link"
            rel="noreferrer"
          >
            logo
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
