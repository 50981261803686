import telegram from "assets/images/dashboard/telegram.svg";
import twitter from "assets/images/dashboard/twitter.svg";
import discord from "assets/images/dashboard/discord.svg";
import instagram from "assets/images/dashboard/instagram.svg";

const socialMediaList = [
  {
    id: "twitter",
    name: "Twitter",
    source: twitter,
    rating: undefined,
    link: "https://twitter.com/trace_meta",
  },
  {
    id: "discord",
    name: "Discord",
    source: discord,
    rating: undefined,
    link: "https://discord.com/invite/metatrace",
  },
  {
    id: "telegram",
    name: "Telegram",
    source: telegram,
    rating: undefined,
    link: "https://t.me/s/MetaTrace",
  },
  {
    id: "instagram",
    name: "Instagram",
    source: instagram,
    rating: 31200,
    link: "https://www.instagram.com/meta.trace/",
  },
];

export default socialMediaList;
