import { useMemo } from "react";
import getAuthCookie from "utils/getAuthCookie";
import { useAppSelector } from "./store";

const useGetAuthCookie = () => {
  const { authToken } = useAppSelector((state) => state.appState);

  return useMemo(() => getAuthCookie(), [document.cookie, authToken]);
};

export default useGetAuthCookie;
