import AppReleaseBanner from "components/AppReleaseBanner";
import BackButton from "components/common/BackButton";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ListingItemNew from "components/Listing/ListingItemNew";
import ListingItemSkeleton from "components/Listing/ListingItemSkeleton";
import PopUpTooltip from "components/PopUpTooltip";
import ProjectPath from "constants/paths";
import { Formik, FormikHelpers, FormikProps } from "formik";
import useGetBalance from "hooks/requests/useGetBalance";
import useMakeTransaction, {
  MakeTransactionRequestHookObject,
} from "hooks/requests/useMakeTransaction";
import { useAppDispatch } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import useHandleInputChange from "hooks/useHandleInputChange";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openBalanceModal } from "store/modalsSlice";
import { CoinType } from "types/coins";
import { SimpleListing, TokenAddress } from "types/listing";
import { getBalanceItem } from "utils/getSMTFromResponse";
import regexWalletAddress from "utils/regexWalletAddress";
import * as Yup from "yup";
import CoinSelect from "../CoinSelect";
import GasContainer from "../GasContainer";
import LabelContainer from "../LabelContainer";
import PolygonContainer from "../PolygonContainer";
import TransactionCounter from "../TransactionCounter";
import TransactionInput from "../TransactionInput";
import ts from "./SendContent.module.scss";

import s from "../TransactionPage.module.scss";

interface BalanceModalResponse {
  coin: `${CoinType}`;
  chosen: number;
  tokenAddress: TokenAddress;
}

interface ValuesType {
  quantity?: number | string;
  to: string;
}

const initialValues: ValuesType = { quantity: "", to: "" };

const QUANTITY = "quantity";

function SendContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [NFTQuantity, setNFTQuantity] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const entity = useMemo<any>(() => searchParams.get("entity"), [searchParams]);
  const back = useMemo<any>(() => searchParams.get("back"), [searchParams]);
  const tokenAddress = useMemo<any>(
    () => searchParams.get("token_address"),
    [searchParams],
  );
  const itemId = useMemo(
    () => Number(searchParams.get("itemId")),
    [searchParams],
  );
  const formikRef = useRef<FormikProps<ValuesType>>(null);
  const dispatch = useAppDispatch();
  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedTo, setIsFocusedTo] = useState(false);
  const [transferEntity, setTransferEntity] = useState<`${CoinType}`>(entity);
  const [NFTItemId, setNFTItemId] = useState<number | undefined>(
    itemId || undefined,
  );
  const [NFTItem, setNFTItem] = useState<SimpleListing | undefined>(undefined);

  const { mutate: makeTransaction, isLoading: isMakingTransaction } =
    useMakeTransaction({ transferEntity, transfer_type: "external" });

  useEffect(() => {
    if (balance) {
      setNFTItem(getBalanceItem(balance, itemId, tokenAddress));
    }
  }, [balance, itemId, tokenAddress, searchParams]);

  const openModalForNft = useAwaitModalResponse<BalanceModalResponse>({
    openModal: () => {
      dispatch(
        openBalanceModal({
          stage: "NFTSelector",
          withNFT: {
            car: true,
            part: true,
            pass: true,
            pet: true,
            town: true,
          },
        }),
      );
    },
    onResolve: (res) => {
      if (balance && res?.coin === "nft" && res.chosen) {
        setTransferEntity("nft");
        setNFTItemId(res.chosen);
        setNFTItem(getBalanceItem(balance, res.chosen, tokenAddress));
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.set("token_address", res.tokenAddress);
          prev.set("itemId", `${res.chosen}`);
          prev.set("back", "send");
          return prev;
        });
      }
    },
  });

  const openModal = useAwaitModalResponse<BalanceModalResponse>({
    openModal: () => {
      dispatch(
        openBalanceModal({
          withACE: true,
          withMATIC: true,
          withNFT: {
            car: true,
            part: true,
            pass: true,
            pet: true,
            town: true,
          },
          withTRC: true,
          withUSDT: true,
          withWBTC: true,
          withWETH: true,
        }),
      );
    },
    onResolve: (res) => {
      if (balance && res?.coin === "nft" && res.chosen) {
        setTransferEntity("nft");
        setNFTItemId(res.chosen);
        setNFTItem(getBalanceItem(balance, res.chosen, tokenAddress));
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.set("token_address", res.tokenAddress);
          prev.set("itemId", `${res.chosen}`);
          return prev;
        });
      }
      if (res?.coin !== "nft" && res?.coin) {
        setTransferEntity(res.coin);
        setNFTItemId(undefined);
        setNFTItem(undefined);
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.delete("token_address");
          prev.delete("itemId");
          prev.delete("back");
          return prev;
        });
      }
    },
  });

  const handleChoose = useCallback(
    (coin: `${CoinType}`) => {
      if (coin === "nft") {
        openModalForNft();
      }
      if (coin !== "nft" && coin) {
        setTransferEntity(coin);
        setNFTItemId(undefined);
        setNFTItem(undefined);
        setSearchParams((prev) => {
          prev.set("entity", coin);
          prev.delete("token_address");
          prev.delete("itemId");
          return prev;
        });
      }
    },
    [openModalForNft, setSearchParams],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleUnFocus = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocusTo = useCallback(() => {
    setIsFocusedTo(true);
  }, []);

  const handleUnFocusTo = useCallback(() => {
    setIsFocusedTo(false);
  }, []);

  const {
    maticConverted: matic,
    trcConverted: trc,
    aceConverted: ace,
    usdtConverted: usdt,
    wbtcConverted: wbtc,
    wethConverted: weth,
    maticConvertedFull: maticFull,
    trcConvertedFull: trcFull,
    aceConvertedFull: aceFull,
    usdtConvertedFull: usdtFull,
    wbtcConvertedFull: wbtcFull,
    wethConvertedFull: wethFull,
  } = balance || {};

  const getTransferEntityValue = useCallback((): string => {
    switch (transferEntity) {
      case CoinType.ace:
        return ace || "0";
      case CoinType.trc:
        return trc || "0";
      case CoinType.matic:
        return matic || "0";
      case CoinType.usdt:
        return usdt || "0";
      case CoinType.wbtc:
        return wbtc || "0";
      case CoinType.weth:
        return weth || "0";

      default:
        return "0";
    }
  }, [transferEntity, ace, trc, matic, usdt, wbtc, weth]);

  const onMaxClick = useCallback(
    (setFieldValue: FormikHelpers<ValuesType>["setFieldValue"]) => () => {
      switch (transferEntity) {
        case CoinType.ace:
          setFieldValue(QUANTITY, aceFull || "0");
          break;
        case CoinType.trc:
          setFieldValue(QUANTITY, trcFull || "0");
          break;
        case CoinType.matic:
          setFieldValue(QUANTITY, maticFull || "0");
          break;
        case CoinType.usdt:
          setFieldValue(QUANTITY, usdtFull || "0");
          break;
        case CoinType.wbtc:
          setFieldValue(QUANTITY, wbtcFull || "0");
          break;
        case CoinType.weth:
          setFieldValue(QUANTITY, wethFull || "0");
          break;

        default:
          setFieldValue(QUANTITY, "0");
          break;
      }
    },
    [aceFull, maticFull, transferEntity, trcFull, usdtFull, wbtcFull, wethFull],
  );

  const onSubmit = useCallback(
    async ({ quantity, to }: ValuesType) => {
      if (transferEntity === "nft") {
        if (NFTItemId) {
          const requestObject: MakeTransactionRequestHookObject = {
            token_type: tokenAddress,
            value_or_id: NFTItemId,
            to,
          };
          if (tokenAddress === "town") {
            requestObject.value = NFTQuantity;
          }
          makeTransaction(requestObject);
        }
        return;
      }
      makeTransaction({
        token_type: transferEntity,
        value_or_id: quantity || "0",
        to,
      });
      formikRef?.current?.resetForm();
      formikRef?.current?.setFieldValue("to", to);
    },
    [transferEntity, makeTransaction, NFTItemId, tokenAddress, NFTQuantity],
  );

  const formDataSchema: Yup.ObjectSchema<ValuesType> = Yup.object().shape({
    to: Yup.string().required().matches(regexWalletAddress),
    quantity:
      transferEntity === "nft"
        ? Yup.number().positive()
        : Yup.number().positive().required(),
  });

  if (!CoinType[entity as CoinType]) {
    navigate(`/${ProjectPath.WALLET}`);
  }

  const lackOfBalance = (quantity: string | number | undefined): boolean => {
    if (transferEntity === "nft" || !balance) return false;
    return (
      Number(quantity || 0) > Number(balance[`${transferEntity}ConvertedFull`])
    );
  };

  const route = useMemo(() => {
    if (back === "send") {
      return `/${ProjectPath.WALLET_SEND}?entity=usdt`;
    }
    if (back === "item") {
      return `/${ProjectPath.WALLET_NFT}/${tokenAddress}/${NFTItemId}`;
    }
    return `/${ProjectPath.WALLET}`;
  }, [back, NFTItemId, tokenAddress]);

  const handleAmountChange = useHandleInputChange();

  return (
    <>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <BackButton className={s.backButton} route={route} />
          <div className={s.title}>
            <span>{t("common.pages.send")}</span>
            <Icon
              variant="up"
              wrapperClassName={s.iconWrapper}
              className={s.icon}
              withWrapper
            />
          </div>
        </div>
        {transferEntity === "nft" && (
          <div className={s.chosenNFT}>
            {isBalanceLoading || !NFTItem ? (
              <ListingItemSkeleton />
            ) : (
              <ListingItemNew
                key={NFTItem.token_id}
                id={NFTItem.token_id}
                attributes={NFTItem.metadata.attributesObj}
                image={NFTItem.metadata.image}
                name={NFTItem.metadata.name}
                onClick={openModal}
                tokenAddress={tokenAddress}
                quantity={NFTItem.metadata.balance}
              />
            )}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={formDataSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }: FormikProps<ValuesType>) => {
            const isButtonDisabled = (() => {
              if (transferEntity === "nft") {
                return !NFTItemId || !touched.to || Boolean(errors.to);
              }
              return (
                !dirty ||
                Boolean(errors.quantity) ||
                Boolean(errors.to) ||
                lackOfBalance(values.quantity)
              );
            })();
            return (
              <form onSubmit={handleSubmit} className={s.form}>
                <div className={s.inputs}>
                  {tokenAddress === "town" && NFTItem?.metadata && (
                    <LabelContainer label="Quantity" htmlFor="quantity-input">
                      <TransactionCounter
                        quantity={NFTQuantity}
                        setQuantity={setNFTQuantity}
                        max={NFTItem.metadata.balance || 0}
                        id="quantity-input"
                      />
                    </LabelContainer>
                  )}
                  {transferEntity !== "nft" && (
                    <div className={ts.selectCurrencyWrapper}>
                      <div
                        className={`${ts.selectCurrency} ${
                          ((touched.quantity && Boolean(errors.quantity)) ||
                            lackOfBalance(values.quantity)) &&
                          ts.inputError
                        }
                      ${isFocused && ts.inputFocused}
                  `}
                      >
                        <div className={ts.leftPartSelectCoin}>
                          <p className={ts.balance}>
                            Balance: {getTransferEntityValue()}
                          </p>
                          <CoinSelect
                            coin={transferEntity}
                            handleClick={handleChoose}
                            coinsToChoose={[
                              "trc",
                              "ace",
                              "matic",
                              "usdt",
                              "nft",
                              "wbtc",
                              "weth",
                            ]}
                          />
                        </div>
                        <div className={ts.rightPartSelectMax}>
                          <p className={ts.enterAmount}>Enter an amount:</p>
                          <div className={ts.balancePart}>
                            <input
                              // type="number"
                              placeholder="0"
                              className={ts.input}
                              onChange={(e) =>
                                handleAmountChange(e, setFieldValue)
                              }
                              onFocus={handleFocus}
                              onBlur={(e) => {
                                handleBlur(e);
                                handleUnFocus();
                              }}
                              value={values.quantity}
                              name={QUANTITY}
                              // eslint-disable-next-line jsx-a11y/no-autofocus
                              autoFocus
                            />
                            <PopUpTooltip
                              buttonText="Max"
                              text="All in"
                              onClick={onMaxClick(setFieldValue)}
                              buttonStyles={ts.maxButton}
                            />
                          </div>
                        </div>
                      </div>
                      {lackOfBalance(values.quantity) && (
                        <p className={ts.error}>
                          Insufficient funds on balance
                        </p>
                      )}
                    </div>
                  )}
                  <LabelContainer label={t("transaction.addressLabel")}>
                    <TransactionInput
                      placeholder={t("transaction.addressPlaceHolder")}
                      onChange={handleChange}
                      // value={values.to}
                      value="0xe56A1545BB2657Ce6B1E7fe21fd138D00371C25d"
                      hasError={touched.to && Boolean(errors.to)}
                      name="to"
                      onFocus={handleFocusTo}
                      focused={isFocusedTo}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleUnFocusTo();
                      }}
                      after={
                        touched.to &&
                        Boolean(!errors.to) && (
                          <Icon
                            variant="success"
                            className={s.successIcon}
                            wrapperClassName={s.successWrapper}
                            withWrapper
                          />
                        )
                      }
                    />
                  </LabelContainer>
                </div>
                <div className={s.bottomPart}>
                  <div className={s.containers}>
                    <PolygonContainer />
                    <div className={s.separator} />
                    <GasContainer />
                  </div>
                  <Button
                    type="submit"
                    className={s.submit}
                    isLoading={isMakingTransaction}
                    disabled={isButtonDisabled}
                  >
                    {t("common.pages.send")}
                  </Button>
                </div>
                <AppReleaseBanner wrapperClassName={s.mobileSideBanner} />
              </form>
            );
          }}
        </Formik>
      </div>
      <AppReleaseBanner wrapperClassName={s.sideBanner} />
    </>
  );
}

export default SendContent;
