import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import cn from "classnames";

import { useAppDispatch } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import useGetProfile from "hooks/requests/useGetProfile";
import useMakeSubscribe from "hooks/requests/subscription/useMakeSubscribe";
import { closeCommonModal, openCommonModal } from "store/modalsSlice";
import formatDate from "utils/formatDate";

import gemImage from "assets/images/shop/gem.webp";
import proTicketImage from "assets/images/shop/pro-ticket.webp";
import randomShardImage from "assets/images/shop/random-shard.webp";
import decorativeBoxImage from "assets/images/shop/decorative-box.webp";
import dotsImage from "assets/images/shop/dots.webp";

import Button from "components/common/Button";
import Show from "components/common/Show";
import Icon from "components/common/Icon";
import Coin from "components/Coin";

import s from "./ControlPart.module.scss";

function ControlPart() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: profile, isLoading: isProfileLoading } = useGetProfile();

  const { mutateAsync: buySubscription } = useMakeSubscribe();

  const openHowToBuyModal = useCallback(() => {
    dispatch(
      openCommonModal({
        title: t("gameShop.howToBuyModal.title"),
        text: t("gameShop.howToBuyModal.text"),
        isResolveButton: true,
        resolveButtonText: t("gameShop.howToBuyModal.button"),
      }),
    );
  }, [dispatch, t]);

  const openBuyModal = useAwaitModalResponse({
    openModal: () => {
      dispatch(
        openCommonModal({
          title: t("gameShop.buyModal.title"),
          text: t("gameShop.buyModal.text"),
          isResolveButton: true,
          resolveButtonText: t("gameShop.buyModal.buttonBuy"),
          isRejectButton: true,
          rejectButtonText: t("gameShop.buyModal.buttonCancel"),
          closeAfterResolveReject: false,
        }),
      );
    },
    onResolve: async () => {
      await buySubscription();
      dispatch(closeCommonModal());
    },
    onReject: () => {
      dispatch(closeCommonModal());
    },
  });

  const expiresIn = useMemo(() => {
    return formatDate(Number(profile?.expire_at));
  }, [profile?.expire_at]);

  return (
    <section className={s.controlPart}>
      <div className={s.content}>
        <h3 className={s.title}>
          <span
            dangerouslySetInnerHTML={{ __html: t("gameShop.control.title1") }}
          />{" "}
          <Icon variant="crown" className={s.crownIcon} />
          <span
            dangerouslySetInnerHTML={{ __html: t("gameShop.control.title2") }}
          />
        </h3>
        <div className={s.controls}>
          <div className={s.pricePart}>
            <Coin
              currency="usdt"
              variant="outerRing"
              wrapperClassName={s.coinWrapper}
            />
            <span className={s.price}>9.99 USDT</span>
            <s className={s.sale}>12.99 USDT</s>
          </div>
          <Show>
            <Show.When
              isTrue={!profile?.have_subscription && !isProfileLoading}
            >
              <div className={s.buttons}>
                <Button className={s.button} onClick={openBuyModal}>
                  {t("gameShop.control.buttonBuy")}
                </Button>
                <Button
                  className={s.button}
                  variant="filledDepthPurple"
                  onClick={openHowToBuyModal}
                >
                  {t("gameShop.control.buttonHow")}
                </Button>
              </div>
            </Show.When>
            <Show.When
              isTrue={!!profile?.have_subscription && !isProfileLoading}
            >
              <div className={s.status}>
                <span className={s.statusText}>
                  {t("gameShop.control.status")}
                </span>
                <Icon variant="check" className={s.checkIcon} />
              </div>
            </Show.When>
            <Show.Else>
              <div className={s.loadingFiller}>
                <div className={s.buttons}>
                  <Button className={s.button} onClick={openBuyModal}>
                    {t("gameShop.control.buttonBuy")}
                  </Button>
                  <Button
                    className={s.button}
                    variant="filledDepthPurple"
                    onClick={openHowToBuyModal}
                  >
                    {t("gameShop.control.buttonHow")}
                  </Button>
                </div>
              </div>
            </Show.Else>
          </Show>
        </div>
        <div className={s.includes}>
          <div className={s.includesTitle}>{t("gameShop.includes.title")}</div>
          <div className={s.positions}>
            <div className={s.position}>
              <Icon variant="check" className={s.positionIcon} />
              <span className={s.positionName}>
                <b>600 Gems</b>
              </span>
              <img src={gemImage} alt="" className={s.positionImage} />
            </div>
            <div className={s.position}>
              <Icon variant="check" className={s.positionIcon} />
              <span
                className={s.positionName}
                dangerouslySetInnerHTML={{
                  __html: t("gameShop.includes.position2"),
                }}
              />
              <img src={proTicketImage} alt="" className={s.positionImage} />
            </div>
            <div className={s.position}>
              <Icon variant="check" className={s.positionIcon} />
              <span
                className={s.positionName}
                dangerouslySetInnerHTML={{
                  __html: t("gameShop.includes.position3"),
                }}
              />
              <img src={randomShardImage} alt="" className={s.positionImage} />
            </div>
          </div>
        </div>
      </div>
      <div className={s.decorativeBoxWrapper}>
        <img src={decorativeBoxImage} alt="" className="fill-cover" />
      </div>
      <div className={s.dotsWrapper}>
        <img src={dotsImage} alt="" className="fill-cover" />
      </div>
    </section>
  );
}

export default ControlPart;
