import cn from "classnames";
import AppReleaseBanner from "components/AppReleaseBanner";
import Attributes from "components/Attributes";
import Characteristic from "components/Characteristic";
import CharacteristicSkeleton from "components/Characteristic/CharacteristicSkeleton";
import BackButton from "components/common/BackButton";
import Collapse from "components/common/Collapse";
import Icon from "components/common/Icon";
import ProjectPath from "constants/paths";
import useGetBalanceItem from "hooks/requests/useGetBalanceItem";
import useGetEmptyArray from "hooks/useGetEmptyArray";
import React, { useEffect } from "react";
import { TFuncKey, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TokenAddress } from "types/listing";
import isListedListing from "utils/isListedListing";
import ListingStatus from "../ListingStatus";
import ListingTable from "../ListingTable";
import ItemControls from "./parts/ItemControls";

import s from "./ItemContent.module.scss";

function ItemContent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tokenAddress, tokenId } = useParams();

  const { data: balanceItem, isLoading: isBalanceItemLoading } =
    useGetBalanceItem({
      token_id: tokenId,
      type: tokenAddress as TokenAddress,
    });

  useEffect(() => {
    if (!balanceItem && !isBalanceItemLoading) {
      navigate(`/${ProjectPath.WALLET}`);
    }
  }, [balanceItem, isBalanceItemLoading, navigate]);

  const metadata = balanceItem?.metadata;
  const attributes = balanceItem?.metadata.attributes;
  const attributesObj = balanceItem?.metadata.attributesObj;

  const loadingArr = useGetEmptyArray({ desktopLength: 8, mobileLength: 6 });

  const shouldShowCharacteristics = attributes && !isBalanceItemLoading;
  const shouldShowAttributes =
    !!attributes?.length &&
    tokenAddress !== "pet" &&
    tokenAddress !== "town" &&
    tokenAddress !== "part";
  const shouldShowBalance = metadata?.name.includes("Town") && metadata;
  const shouldShowListed = metadata?.listings && metadata.listings.length > 0;
  const shouldShowTable =
    tokenAddress === "town" &&
    metadata?.listings &&
    metadata.listings.length > 0;

  const itemTitle = (() => {
    switch (tokenAddress) {
      case "car":
        return attributesObj?.Type?.value ?? "Car";
      case "pet":
        return attributesObj?.Name?.value || "Pet";
      case "pass":
        return (
          attributesObj?.Type?.value ??
          `${tokenAddress?.charAt(0).toUpperCase()}${tokenAddress?.slice(1)}`
        );
      case "part":
        return attributesObj?.Name?.value || "Part";
      case "town":
        return attributesObj?.Name?.value || "Town";

      default:
        return "";
    }
  })();

  return (
    <div className={s.container}>
      <div className={s.itemPart}>
        <AppReleaseBanner wrapperClassName={s.sideBanner} />
        <BackButton
          className={s.backButton}
          iconClassName={s.icon}
          route={`/${ProjectPath.WALLET_NFT}`}
        >
          {t("common.back")}
        </BackButton>
        {shouldShowListed && (
          <ListingStatus status="onMarket" wrapperClassName={s.listed} />
        )}
        <div className={s.information}>
          <div className={s.titlePart}>
            <BackButton
              className={s.mobileBackButton}
              iconClassName={s.mobileIcon}
              route={`/${ProjectPath.WALLET_NFT}`}
            />
            <div className={s.titleWrapper}>
              {itemTitle && <div className={s.title}>{itemTitle}</div>}
              {shouldShowListed && (
                <ListingStatus
                  status="onMarket"
                  wrapperClassName={cn(s.listed, s.listedMobile)}
                />
              )}
              <div className={s.hash}>{metadata?.name}</div>
            </div>
          </div>
        </div>
        <div className={s.image}>
          {metadata && (
            <img src={metadata?.image} alt="item" className="fill-cover" />
          )}
          {shouldShowBalance && (
            <div className={s.amount}>x {metadata.balance}</div>
          )}
        </div>
        <ItemControls
          className={s.controls}
          tokenId={balanceItem?.token_id ?? 0}
        />
      </div>
      <div className={s.characteristics}>
        {shouldShowCharacteristics &&
          attributes.map((attribute) =>
            "max_value" in attribute ? (
              <Characteristic
                value={attribute.value}
                max={attribute.max_value}
                variant={attribute.trait_type}
                key={attribute.trait_type}
              />
            ) : (
              <Characteristic
                value={attribute.value}
                variant={attribute.trait_type}
                key={attribute.trait_type}
              />
            ),
          )}
        {isBalanceItemLoading &&
          loadingArr.map((elem) => <CharacteristicSkeleton key={elem} />)}
      </div>
      {shouldShowAttributes && (
        <Collapse tKey={"item.attributes.title" as TFuncKey} title="Attributes">
          <Attributes attributes={attributes || []} />
        </Collapse>
      )}
      {shouldShowTable && (
        <ListingTable
          listings={
            metadata.listings && isListedListing(metadata.listings)
              ? metadata?.listings
              : []
          }
          collectionId={tokenId || ""}
          usageFrom="wallet"
          amountNFT={0}
          setAmountNFT={() => {}}
        />
      )}
      <AppReleaseBanner wrapperClassName={s.mobileSideBanner} />
    </div>
  );
}

export default ItemContent;
