import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import { openSortModal } from "store/modalsSlice";
import { setSort } from "store/filtersSlice";
import { getSortTitle } from "utils/getSortTitle";
import { FiltersSliceType, SortVariant } from "types/filters";
import FilterButton from "../FilterButton";

import s from "./SortButton.module.scss";

interface SortResolvePayload {
  variant: SortVariant;
}

interface SortButtonProps {
  filtersSliceType: FiltersSliceType;
}

function SortButton({ filtersSliceType }: SortButtonProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { sort } = useAppSelector((state) => state.filters[filtersSliceType]);

  const onSortClick = useAwaitModalResponse<SortResolvePayload>({
    openModal: () => {
      dispatch(openSortModal());
    },
    onResolve: (payload) => {
      if (payload) {
        dispatch(setSort({ sort: payload.variant, filtersSliceType }));
      }
    },
  });

  const description = useMemo(() => {
    if (sort) return getSortTitle(sort);
    return "";
  }, [sort]);

  return (
    <FilterButton
      title={t("menus.filter.sort")}
      description={description}
      iconVariant="sort"
      onClick={onSortClick}
      className={s.sortButton}
    />
  );
}

export default SortButton;
