import ApiError from "classes/ApiError";
import { PriceType, TokenAddress } from "types/listing";
import {
  BTCandETHPrice,
  GetBalanceItemRequest,
  GetSwapCostRequest,
  MakeSwapRequest,
  MakeTransactionRequest,
} from "types/requests";
import {
  ApiErrorResponse,
  GetBalanceResponse,
  GetSwapCostResponse,
  MakeTransactionResponse,
  RestructuredBalanceItemResponse,
  RestructuredGetBalanceResponse,
} from "types/responses";
import {
  convertFieldsToNumber,
  convertFieldsToString,
} from "utils/convertJSONFields";
import restructureBalanceItemResponse from "utils/restructureBalanceItemResponse";
import restructureBalanceListingResponse from "utils/restructureBalanceListingResponse";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getBalance = async (
  type?: TokenAddress | PriceType,
): Promise<RestructuredGetBalanceResponse> => {
  const response = await fetch(`${endpoint}/balance/get`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(type ? { type } : {}),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }
  const stringData = await response.text();

  const convertedData = convertFieldsToString(stringData, [
    "matic",
    "ace",
    "trc",
    "usdt",
    "wbtc",
    "weth",
  ]);

  const dataRaw = JSON.parse(convertedData);
  const { data }: { data: GetBalanceResponse } = dataRaw;
  const newData = restructureBalanceListingResponse(data);
  return newData;
};

export const getBalanceItem = async ({
  token_id,
  type,
}: GetBalanceItemRequest): Promise<RestructuredBalanceItemResponse> => {
  const response = await fetch(`${endpoint}/balance/get`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type,
      token_id,
    }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }
  const dataRaw = await response.json();
  const { data }: { data: GetBalanceResponse } = dataRaw;
  const newData = restructureBalanceItemResponse(data, type);
  return newData;
};

export const makeTransaction = async (
  body: MakeTransactionRequest,
): Promise<MakeTransactionResponse> => {
  const restructuredBody = convertFieldsToNumber(JSON.stringify(body), [
    "value_or_id",
  ]);

  const response = await fetch(`${endpoint}/transfer/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: restructuredBody,
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    if (errorData.errors) {
      throw new ApiError(errorData.errors, response.status);
    } else if (errorData.error) {
      throw new ApiError([], response.status, errorData.error);
    }
  }
  return response.json();
};

export const getSwapCost = async (
  body: GetSwapCostRequest,
): Promise<GetSwapCostResponse> => {
  const restructuredBody = convertFieldsToNumber(JSON.stringify(body), [
    "matic",
    "ace",
    "trc",
    "usdt",
    "wbtc",
    "weth",
  ]);

  const response = await fetch(`${endpoint}/swap/price`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: restructuredBody,
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const stringData = await response.text();
  const convertedData = convertFieldsToString(stringData, [
    "trc",
    "matic",
    "usdt",
    "ace",
    "min_fee_trc",
    "fee",
    "total_output",
    "price",
  ]);
  const data = JSON.parse(convertedData);
  return data;
};

export const makeSwap = async (body: MakeSwapRequest) => {
  const restructuredBody = convertFieldsToNumber(JSON.stringify(body), [
    "trc",
    "matic",
    "usdt",
    "ace",
    "wbtc",
    "weth",
  ]);

  const response = await fetch(`${endpoint}/swap/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: restructuredBody,
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }
  return response.json();
};

const dashboardEndpoint = process.env.REACT_APP_DASHBOARD_ENDPOINT || "";
export const getCurrenciesUSDPrices = async (
  currencies: string[],
): Promise<any> => {
  const response = await fetch(
    `${dashboardEndpoint}/coin-market-cap-prices/${currencies.join(",")}/usd`,
    {
      method: "GET",
    },
  );

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const getBTCandETHPrice = async (): Promise<BTCandETHPrice> => {
  const response = await fetch(`${dashboardEndpoint}/prices`, {
    method: "GET",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};
