import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import useGetWindowWidth from "hooks/useGetWindowWidth";

import s from "./Tabs.module.scss";

export interface ITab {
  title: string;
  link?: string;
  handleTabClick?: (...args: any[]) => void;
}

interface ITabsProps {
  tabs: ITab[];
  currentTabIdx: number;
}

function Tabs({ tabs, currentTabIdx }: ITabsProps) {
  const windowWidth = useGetWindowWidth();
  const menuRef = useRef<HTMLUListElement>(null);
  const activeTabRef = useRef<HTMLLIElement>(null);
  const [highlighterStyle, setHighlighterStyle] = useState<React.CSSProperties>(
    { width: 0 },
  );
  const [isHighlighterRendered, setIsHighlighterRendered] =
    useState<boolean>(false);

  useEffect(() => {
    if (!menuRef.current || !activeTabRef.current) return;

    const menuBCRect = menuRef.current.getBoundingClientRect();
    const activeTabBCRect = activeTabRef.current.getBoundingClientRect();

    setHighlighterStyle({
      width: activeTabBCRect.width,
      left: activeTabBCRect.left - menuBCRect.left,
    });
  }, [currentTabIdx, windowWidth]);

  useEffect(() => {
    setTimeout(() => {
      setIsHighlighterRendered(true);
    }, 500);
  }, []);

  return (
    <ul className={s.root} ref={menuRef}>
      <div
        className={cn(
          s.highlighter,
          isHighlighterRendered && s.highlighter_withTransition,
        )}
        style={highlighterStyle}
      />

      {tabs.map((tab: ITab, i: number) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={cn(s.tab, i === currentTabIdx && s.tab_active)}
          ref={i === currentTabIdx ? activeTabRef : undefined}
        >
          <button
            className={s.button}
            type="button"
            onClick={tab.handleTabClick}
          >
            {tab.title}

            {tab.link && (
              <Link className={cn(s.link)} to={tab.link}>
                {tab.title}
              </Link>
            )}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default Tabs;
