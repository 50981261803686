const getAuthCookie = () => {
  const cookieRow = document.cookie
    .split("; ")
    .find((row) => row.startsWith("metatrace="));
  if (!cookieRow) {
    return null;
  }
  return cookieRow.split("=")[1];
};

export default getAuthCookie;
