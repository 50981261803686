import {
  GetBalanceResponse,
  RestructuredGetBalanceResponse,
} from "types/responses";
import restructureAttributes from "./restructureAttributes";
import {
  convertDecimalToken,
  convertDecimalTokenShort,
} from "./convertDecimalToken";

const restructureBalanceListingResponse = (
  data: GetBalanceResponse,
): RestructuredGetBalanceResponse => {
  const newData = {
    ...data,
    maticConverted: convertDecimalTokenShort(data.matic, "matic") || "0",
    trcConverted: convertDecimalTokenShort(data.trc, "trc") || "0",
    aceConverted: convertDecimalTokenShort(data.ace, "ace") || "0",
    usdtConverted: convertDecimalTokenShort(data.usdt, "usdt") || "0",
    wbtcConverted: convertDecimalTokenShort(data.wbtc, "wbtc") || "0",
    wethConverted: convertDecimalTokenShort(data.weth, "weth") || "0",
    maticConvertedFull: convertDecimalToken(data.matic, "matic") || "0",
    trcConvertedFull: convertDecimalToken(data.trc, "trc") || "0",
    aceConvertedFull: convertDecimalToken(data.ace, "ace") || "0",
    usdtConvertedFull: convertDecimalToken(data.usdt, "usdt") || "0",
    wbtcConvertedFull: convertDecimalToken(data.wbtc, "wbtc") || "0",
    wethConvertedFull: convertDecimalToken(data.weth, "weth") || "0",
    car: {
      ...data.car,
      token_metadata: data.car?.token_metadata?.map((elem) => ({
        ...elem,
        metadata: {
          ...elem.metadata,
          attributesObj: restructureAttributes(elem.metadata.attributes),
          listings: elem.metadata.listings,
        },
      })),
    },
    pass: {
      ...data.pass,
      token_metadata: data.pass?.token_metadata?.map((elem) => ({
        ...elem,
        metadata: {
          ...elem.metadata,
          attributesObj: restructureAttributes(elem.metadata.attributes),
          listings: elem.metadata.listings,
        },
      })),
    },
    pet: {
      ...data.pet,
      token_metadata: data.pet?.token_metadata?.map((elem) => ({
        ...elem,
        metadata: {
          ...elem.metadata,
          attributesObj: restructureAttributes(elem.metadata.attributes),
          listings: elem.metadata.listings,
        },
      })),
    },
    part: {
      ...data.part,
      token_metadata: data.part?.token_metadata?.map((elem) => ({
        ...elem,
        metadata: {
          ...elem.metadata,
          attributesObj: restructureAttributes(elem.metadata.attributes),
          listings: elem.metadata.listings,
        },
      })),
    },
    town: {
      ...data.town,
      token_metadata: data.town?.token_metadata?.map((elem) => ({
        ...elem,
        metadata: {
          ...elem.metadata,
          attributesObj: restructureAttributes(elem.metadata.attributes),
          listings: elem.metadata.listings,
        },
      })),
    },
  };

  return newData;
};

export default restructureBalanceListingResponse;
