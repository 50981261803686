import React, { useCallback, useState } from "react";

import { JustCoinType } from "types/coins";

import Coin from "components/Coin";

import { AnimatePresence, motion } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { selectorSlide } from "utils/motion";

import s from "./CoinSelectNoNFT.module.scss";

interface ICoinSelectProps {
  coin: `${JustCoinType}`;
  handleClick: (coin: `${JustCoinType}`) => void;
  coinsToChoose?: `${JustCoinType}`[];
}

interface ICurrencyProps {
  coin: `${JustCoinType}`;
  handleClick: (coin: `${JustCoinType}`) => void;
  open: () => void;
  close: () => void;
  isSelected: boolean;
  isOpen?: boolean;
}

function Currency({
  coin,
  handleClick,
  open,
  isSelected,
  isOpen = false,
  close,
}: ICurrencyProps) {
  const choose = useCallback(() => {
    handleClick(coin);
    close();
  }, [close, coin, handleClick]);

  return (
    <div className={`${s.root} ${isSelected && s.isSelected}`}>
      <Coin currency={JustCoinType[coin]} variant="outerRing" />

      <span className={isOpen ? s.labelForSelect : s.label}>{coin}</span>

      {isOpen || <i className={`icon-arrowDown ${s.icon}`} />}

      <button
        className={s.button}
        type="button"
        onClick={isOpen ? choose : open}
      >
        {coin}
      </button>
    </div>
  );
}

function CoinSelectNoNFT({
  coin,
  handleClick,
  coinsToChoose = [],
}: ICoinSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className={s.coinSelect}>
      <Currency
        coin={coin}
        handleClick={handleClick}
        open={open}
        close={close}
        isSelected={false}
      />
      {coinsToChoose.length > 0 && (
        <AnimatePresence>
          {isOpen && (
            <ClickAwayListener onClickAway={close}>
              <motion.div
                variants={selectorSlide()}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={s.menu}
              >
                {coinsToChoose.map((c) => (
                  <Currency
                    coin={c}
                    handleClick={handleClick}
                    isSelected={c === coin}
                    isOpen
                    close={close}
                    open={open}
                    key={c}
                  />
                ))}
              </motion.div>
            </ClickAwayListener>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}
export default CoinSelectNoNFT;
