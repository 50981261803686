import React from "react";
import cn from "classnames";

import { TokenType } from "pages/TokensPage/TokensPage";

import trcImage from "assets/images/tokensPage/trcToken.webp";
import aceImage from "assets/images/tokensPage/aceToken.webp";

import TokensChart from "./parts/TokensChart";
import TokensChartAce from "./parts/TokensChartAce";
import TokensBanner from "./parts/TokensBanner";
import TokensStats from "./parts/TokensStats";
import TokensPlatforms from "./parts/TokensPlatforms";

import s from "./TokensSection.module.scss";

interface Props {
  tokenType: TokenType;
  changeTokenType: (type: TokenType) => void;
}

function TokensSection({ tokenType, changeTokenType }: Props) {
  return (
    <section className={s.tokensSection}>
      <div className={s.titleBar}>
        <div className={s.switcherAndTitle}>
          <div className={s.title}>Tokens</div>
          <div className={s.switcher}>
            <div
              className={cn(
                s.switch,
                s.switchTRC,
                tokenType === "trc" && s.active,
              )}
            >
              <div className={s.gradient} />
              <div className={s.coin}>
                <img src={trcImage} alt="trc token" className="fill-cover" />
              </div>
              <div className={s.coinTitle}>TRC</div>
              <button
                type="button"
                className={cn(s.button, "fill-link")}
                onClick={() => changeTokenType("trc")}
              >
                Change Token Type
              </button>
            </div>
            <div
              className={cn(
                s.switch,
                s.switchACE,
                tokenType === "ace" && s.active,
              )}
            >
              <div className={s.gradient} />
              <div className={s.coin}>
                <img src={aceImage} alt="ace token" className="fill-cover" />
              </div>
              <div className={s.coinTitle}>ACE</div>
              <button
                type="button"
                className={cn(s.button, "fill-link")}
                onClick={() => changeTokenType("ace")}
              >
                Change Token Type
              </button>
            </div>
          </div>
        </div>
        <div className={s.lastUpdate}>Last update 24h ago</div>
      </div>
      <div className={s.topRow}>
        <TokensChart tokenType={tokenType} />
        <TokensBanner />
      </div>
      <div className={s.stats}>
        <TokensStats tokenType={tokenType} />
      </div>
      <div className={s.platforms}>
        <TokensPlatforms tokenType={tokenType} />
      </div>
    </section>
  );
}

export default TokensSection;
