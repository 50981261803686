export type PriceType = "matic" | "trc" | "ace";
export type TokenAddress = "car" | "pass" | "part" | "town" | "pet";
export const NftMap: Record<TokenAddress, TokenAddress> = {
  car: "car",
  pass: "pass",
  part: "part",
  town: "town",
  pet: "pet",
};

export interface RawListing {
  id: number;
  user_id: string;
  price_type: PriceType;
  token_address: TokenAddress;
  metadata: RawMetaData;
  tokenId: string;
  price: string;
  amount?: number;
}

export interface RawCollection {
  amount_listings: number;
  amount_nft: number;
  price: string;
  price_type: PriceType;
  tokenId: string;
  token_address: TokenAddress;
  metadata: RawMetaData;
  listings?: RawListing[];
}

export interface Listing extends RawListing {
  metadata: MetaData;
  priceConverted: string | undefined;
}

export interface Collection extends RawCollection {
  metadata: MetaData;
  priceConverted: string | undefined;
  listings?: Listing[];
}

export interface RawMetaData {
  attributes: Attribute[];
  description: string;
  external_url: string;
  image: string;
  name: string;
}

export interface MetaData extends RawMetaData {
  attributesObj: AttributesObject;
}

export type TraitType =
  | "Currency"
  | "Tier"
  | "Type"
  | "Power"
  | "Aerodynamics"
  | "Weight"
  | "Strength"
  | "Luck"
  | "Level"
  | "Mints"
  | "Durability"
  | "Earning"
  | "Breeding"
  | "Energy"
  | "Attack"
  | "Defense"
  | "Rarity"
  | "Element"
  | "Category"
  | "Lat"
  | "Lon"
  | "Name";

interface AttributeBase {
  trait_type: TraitType;
}

export interface AttributeString extends AttributeBase {
  value: string;
}
export interface AttributeNumber extends AttributeBase {
  trait_type: TraitType;
  value: number;
}
export interface AttributeRange extends AttributeBase {
  trait_type: TraitType;
  value: number;
  max_value: number;
}
export type Attribute = AttributeString | AttributeNumber | AttributeRange;

export type AttributesObject = {
  [K in TraitType]?: Attribute;
};

export interface RawListedListing {
  id: number;
  amount?: number;
  price: string;
  price_type: PriceType;
}

export interface ListedListing extends RawListedListing {
  priceConverted: string;
  user_id: string;
  token_address: TokenAddress;
}

export interface RawSimpleListing {
  token_id: number;
  metadata: {
    name: string;
    description: string;
    image: string;
    attributes: Attribute[];
    balance?: number;
    listings?: RawListedListing[];
  };
}

export interface SimpleListing extends RawSimpleListing {
  metadata: {
    name: string;
    description: string;
    image: string;
    attributes: Attribute[];
    attributesObj: AttributesObject;
    balance?: number;
    listings?: ListedListing[] | RawListedListing[];
  };
}

export interface RawNftCollection {
  total: number;
  contractAddress: string;
  token_metadata: RawSimpleListing[];
}

export interface NftCollection {
  total: number;
  contractAddress: string;
  token_metadata: SimpleListing[];
}

export type ListingTabType = "all" | "my";
export type ListingTabTypeCapitalized = "All" | "My";
export const ListingTabTypeMap: Record<
  ListingTabType,
  ListingTabTypeCapitalized
> = {
  all: "All",
  my: "My",
};
export const ListingTabTypeMapOriginal: Record<ListingTabType, ListingTabType> =
  {
    all: "all",
    my: "my",
  };

export interface CollectionListingElement {
  id: number;
  balance: number;
  user_id: string;
  price_type: RawListing["price_type"];
  price: RawListing["price"];
}
