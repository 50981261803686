import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { updateMarketCollectionTab } from "store/marketCollectionTabSlice";
import { ListingTabType } from "types/listing";
import { useDispatch } from "react-redux";

import s from "./Tabs.module.scss";

interface Tab {
  id: number;
  path: string;
  title: string;
  amount?: string;
  className?: string;
}

interface TabsProps {
  tabs: Tab[];
  currentTabPath: string;
  className?: string;
  isLink?: boolean;
}

function Tabs({
  tabs,
  currentTabPath,
  isLink = true,
  className = "",
}: TabsProps) {
  const dispatch = useDispatch();

  return (
    <div className={`${s.component} ${className}`}>
      <div className={s.topPart}>
        <div className={s.tabs}>
          {tabs.map((tab) => (
            <div className={s.tabContainer} key={tab.id}>
              {isLink ? (
                <Link
                  to={tab.path}
                  className={cn(
                    s.tab,
                    {
                      [s.current]: currentTabPath === tab.path,
                    },
                    tab.className,
                  )}
                >
                  {tab.title}
                </Link>
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    dispatch(
                      updateMarketCollectionTab({
                        tab: tab.path as ListingTabType,
                      }),
                    )
                  }
                  className={cn(
                    s.tab,
                    {
                      [s.current]: currentTabPath === tab.path,
                    },
                    tab.className,
                  )}
                >
                  {tab.title}
                </button>
              )}
              {!!tab?.amount?.length && (
                <div
                  className={cn(
                    { [s.tabAmountCurrent]: currentTabPath === tab.path },
                    s.tabAmount,
                  )}
                >
                  {tab?.amount}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={s.underline} />
    </div>
  );
}

export default Tabs;
