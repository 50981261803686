import React, { useCallback, useState } from "react";

import { CoinType } from "types/coins";

import Coin from "components/Coin";

import { AnimatePresence, motion } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { selectorSlide } from "utils/motion";

import s from "./CoinSelect.module.scss";

interface ICoinSelectProps {
  coin: `${CoinType}`;
  handleClick: (coin: `${CoinType}`) => void;
  coinsToChoose?: `${CoinType}`[];
}

interface ICurrencyProps {
  coin: `${CoinType}`;
  isSelected: boolean;
  isOpen?: boolean;
  open: () => void;
  close: () => void;
  handleClick?: (coin: `${CoinType}`) => void;
}

function Currency({
  coin,
  isSelected,
  isOpen = false,
  close,
  open,
  handleClick = undefined,
}: ICurrencyProps) {
  const choose = useCallback(() => {
    if (handleClick) handleClick(coin);
    close();
  }, [close, coin, handleClick]);

  return (
    <div className={`${s.root} ${isSelected && s.isSelected}`}>
      <Coin currency={CoinType[coin]} variant="outerRing" />

      <span className={isOpen ? s.labelForSelect : s.label}>{coin}</span>

      {isOpen || <i className={`icon-arrowDown ${s.icon}`} />}

      <button
        className={s.button}
        type="button"
        onClick={isOpen ? choose : open}
      >
        {coin}
      </button>
    </div>
  );
}

function CoinSelect({
  coin,
  handleClick,
  coinsToChoose = [],
}: ICoinSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className={s.coinSelect}>
      <Currency coin={coin} open={open} close={close} isSelected={false} />
      {coinsToChoose.length > 0 && (
        <AnimatePresence>
          {isOpen && (
            <ClickAwayListener onClickAway={close}>
              <motion.div
                variants={selectorSlide()}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={s.menu}
              >
                {coinsToChoose.map((c) => (
                  <Currency
                    coin={c}
                    handleClick={() => handleClick(c)}
                    isSelected={c === coin}
                    isOpen
                    close={close}
                    open={open}
                    key={c}
                  />
                ))}
              </motion.div>
            </ClickAwayListener>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}
export default CoinSelect;
