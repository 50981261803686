const tooltipData: Record<string, any> = {
  /** key is year in chart */
  "1": {
    marketing: 0.298,
    publicSale: 0.006,
    preSale: 0.026,
    ambassador: 0.02,
    strategic: 0.096,
    seed: 0.023,
    advisors: 0.036,
    team: 0.0,
    ecosystem: 0.218,
    rewards: 0.541,
    total: 1.264,
  },
  "2": {
    marketing: 0.597,
    publicSale: 0.01,
    preSale: 0.043,
    ambassador: 0.033,
    strategic: 0.188,
    seed: 0.051,
    advisors: 0.09,
    team: 0.24,
    ecosystem: 0.449,
    rewards: 1.081,
    total: 2.782,
  },
  "3": {
    marketing: 0.622,
    publicSale: 0.01,
    preSale: 0.042,
    ambassador: 0.033,
    strategic: 0.22,
    seed: 0.078,
    advisors: 0.144,
    team: 0.48,
    ecosystem: 0.681,
    rewards: 1.622,
    total: 3.932,
  },
  "4": {
    marketing: 0.622,
    publicSale: 0.01,
    preSale: 0.042,
    ambassador: 0.033,
    strategic: 0.22,
    seed: 0.106,
    advisors: 0.15,
    team: 0.5,
    ecosystem: 0.913,
    rewards: 2.162,
    total: 4.758,
  },
  "5": {
    marketing: 0.622,
    publicSale: 0.01,
    preSale: 0.042,
    ambassador: 0.033,
    strategic: 0.22,
    seed: 0.115,
    advisors: 0.15,
    team: 0.5,
    ecosystem: 0.965,
    rewards: 2.343,
    total: 5.0,
  },
};

export default tooltipData;
