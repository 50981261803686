import React from "react";
import Skeleton from "react-loading-skeleton";
import useGetEmptyArray from "hooks/useGetEmptyArray";

import s from "./Listing.module.scss";

interface ListingProps {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  isFetchingNext?: boolean;
}

function Listing({
  children,
  className = "",
  loading = false,
  isFetchingNext = false,
}: ListingProps) {
  const loadingArr = useGetEmptyArray({ desktopLength: 6, mobileLength: 6 });

  if (loading) {
    return (
      <div className={`${s.listing} ${className}`}>
        {loadingArr.map((elem) => (
          <div className={s.loadingItem} key={elem}>
            <Skeleton height="100%" containerClassName="skeleton" />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={`${s.listing} ${className}`}>
      {children}
      {isFetchingNext &&
        loadingArr.map((elem) => (
          <div className={s.loadingItem} key={elem}>
            <Skeleton height="100%" containerClassName="skeleton" />
          </div>
        ))}
    </div>
  );
}

export default Listing;
