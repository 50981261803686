import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { buyListing } from "api/listing";
import { showErrors } from "utils/showErrors";

import ProjectPath from "constants/paths";
import ApiError from "classes/ApiError";
import { BuyListingRequest } from "types/requests";
import { BuyListingResponse } from "types/responses";
import { openWalletSuccessfulPurchaseModal } from "store/modalsSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { TokenAddress } from "types/listing";

const useBuyListing = ({
  listing_id,
  tokenAddress,
  amount,
}: BuyListingRequest & { tokenAddress: TokenAddress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const queryInfo = useMutation<BuyListingResponse, ApiError>(
    ["buy-listing"],
    async () => {
      if (listing_id) {
        const body: BuyListingRequest = { listing_id };
        if (amount) {
          body.amount = amount;
        }
        return buyListing(body);
      }
      return Promise.reject(new Error(t("notifications.noListingId.title")));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["balance"] });
        dispatch(openWalletSuccessfulPurchaseModal());
        navigate(`/${ProjectPath.MARKET}/${tokenAddress}`);
      },
    },
  );

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      if (
        queryInfo.error.status === 500 &&
        queryInfo.error.errors &&
        queryInfo.error.errors[0].meta.error.includes(
          "transfer amount exceeds balance",
        )
      ) {
        queryInfo.error.errors[0].meta.error = t(
          "notifications.insufficientFundsForSelling.title",
        );
        showErrors(queryInfo.error);
        return;
      }
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error, t]);
  return queryInfo;
};

export default useBuyListing;
