import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { requestVerify } from "api/auth";
import { setAuthToken } from "store/appStateSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { showErrors } from "utils/showErrors";

import ApiError from "classes/ApiError";
import { VerifyCodeResponse } from "types/responses";
import getAuthCookie from "utils/getAuthCookie";

interface RequestParams {
  code: string;
}

const useRequestVerifyCode = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.modals.logInModal);
  const queryClient = useQueryClient();

  return useMutation<VerifyCodeResponse, ApiError, RequestParams>(
    ["verify-code"],
    async ({ code }) => {
      if (code) {
        return requestVerify({ code, email });
      }
      return Promise.reject(
        new Error(t("notifications.invalidFirebaseToken.title")),
      );
    },
    {
      onSuccess: () => {
        const cookie = getAuthCookie();
        if (cookie) dispatch(setAuthToken(cookie));
        return queryClient.invalidateQueries({ queryKey: ["profile"] });
      },
      onError: showErrors,
    },
  );
};

export default useRequestVerifyCode;
