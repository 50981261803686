import useGetWindowWidth from "hooks/useGetWindowWidth";
import React from "react";

export interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: number;
  };
}

function CustomTickYAxis({
  x = undefined,
  y = undefined,
  payload = undefined,
}: CustomTickProps) {
  const windowWidth = useGetWindowWidth();
  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx={8} cy={0} r={2.5} fill="#6B7A99" />
      <text
        x={-7}
        y={0}
        dy={5}
        fontSize={windowWidth <= 1280 ? "1.45rem" : "1rem"}
        textAnchor="end"
        fill="#605D62"
      >
        {payload?.value}B
      </text>
    </g>
  );
}

export default CustomTickYAxis;
