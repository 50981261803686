import cn from "classnames";
import Avatar from "components/Avatar";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Image from "components/common/Image";
import ProjectPath from "constants/paths";
import { AnimatePresence, motion } from "framer-motion";
import useGetProfile from "hooks/requests/useGetProfile";
import React from "react";
import ClickAwayListener from "react-click-away-listener";
import { selectorSlide } from "utils/motion";
import ConnectTelegramButton from "../ConnectTelegramButton";
import LogOutButton from "../LogOutButton";

import s from "./ProfileCard.module.scss";

interface IProps {
  isOpen: boolean;
  close: () => void;
}

function ProfileCard({ isOpen, close }: IProps) {
  const { data: profile } = useGetProfile();

  return (
    <AnimatePresence>
      {isOpen && (
        <ClickAwayListener onClickAway={close}>
          <motion.div
            variants={selectorSlide()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={cn(s.profile, profile?.is_genesis && s.genesisWrapper)}
          >
            <div className={s.topPart}>
              <Avatar
                gender={profile?.character}
                subscribed={profile?.have_subscription}
                isGenesis={profile?.is_genesis}
              />
              <div className={s.profileInfo}>
                <div className={s.mainInfo}>
                  <p className={s.nickname}>@{profile?.nick}</p>
                </div>
              </div>
            </div>
            {profile?.is_genesis && (
              <div className={s.genesis}>
                <span className={s.genesisTitle}>Genesis Tracer:</span>
                <b className={s.genesisValue}>{profile?.genesis_token_id}</b>
                <Icon className={s.genesisIcon} variant="star_empty_inside" />
              </div>
            )}
            <div className={s.middleButtons}>
              <Button
                link={`/${ProjectPath.BURN_PASS}`}
                isLocal
                className={cn(s.button, s.burnPass)}
                classNameChildren={s.buttonContent}
              >
                <Icon variant="star_empty_inside" className={s.buttonIcon} />
                <span className={s.buttonText}>Genesis Tracer</span>
              </Button>
              {!profile?.telegram_id && (
                <ConnectTelegramButton
                  variant="filledBlue"
                  className={cn(s.button)}
                  classNameChildren={s.buttonContent}
                >
                  <Icon
                    variant="telegramSvg"
                    className={cn(s.buttonIcon, s.telegramIcon)}
                  />
                  <span className={s.buttonText}>Add Telegram</span>
                </ConnectTelegramButton>
              )}
            </div>
            <div className={s.bottomButtons}>
              <LogOutButton
                variant={profile?.is_genesis ? "filledDepthPurple" : "default"}
              />
            </div>
            {profile?.is_genesis && (
              <Image
                wrapperClassName={s.genesisBackground}
                image="genesis_decoration"
              />
            )}
          </motion.div>
        </ClickAwayListener>
      )}
    </AnimatePresence>
  );
}

export default ProfileCard;
