import { useQuery } from "@tanstack/react-query";
import { DashboardResponse } from "types/dashboard";
import { getDashboardData } from "api/dashboard";

interface UseGetDashboardParams {
  enabled?: boolean;
}

const useGetDashboard = (params?: UseGetDashboardParams) => {
  const { enabled = true } = params || {};
  return useQuery<DashboardResponse, Error>(
    ["dashboard"],
    async () => {
      return getDashboardData();
    },
    { enabled },
  );
};

export default useGetDashboard;
