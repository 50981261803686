import React from "react";

import {
  tokensTRCExchangePlatforms,
  tokensTRCRatingsPlatforms,
  tokensACEExchangePlatforms,
  tokensACERatingsPlatforms,
} from "constants/tokens";
import { TokenType } from "pages/TokensPage/TokensPage";

import s from "./TokensPlatforms.module.scss";

interface Props {
  tokenType: TokenType;
}

function TokensPlatforms({ tokenType }: Props) {
  const platforms =
    tokenType === "trc"
      ? tokensTRCExchangePlatforms
      : tokensACEExchangePlatforms;
  const ratings =
    tokenType === "trc" ? tokensTRCRatingsPlatforms : tokensACERatingsPlatforms;

  return (
    <div className={s.tokensPlatforms}>
      <div className={s.row}>
        <div className={s.titlePart}>
          <p className={s.title}>Exchanges</p>
          <p className={s.description}>
            Choose the exchange you trade, log in and start trading{" "}
            {tokenType === "trc" ? "TRC" : "ACE"} on spot.
          </p>
        </div>
        <div className={s.grid}>
          {platforms.map((elem) => (
            <div className={s.platform} key={elem.id}>
              <img
                src={elem.source}
                alt={elem.title}
                className={s.platformImage}
              />
              <div className={s.platformTitle}>{elem.title}</div>
              <a href={elem.link} className="fill-link">
                {elem.title}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={s.line} />
      <div className={s.row}>
        <div className={s.titlePart}>
          <p className={s.title}>Rating</p>
        </div>
        <div className={s.grid}>
          {ratings.map((elem) => (
            <div className={s.platform} key={elem.id}>
              <img
                src={elem.source}
                alt={elem.title}
                className={s.platformImage}
              />
              <div className={s.platformTitle}>{elem.title}</div>
              <a href={elem.link} className="fill-link">
                {elem.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TokensPlatforms;
