import useImage from "hooks/useImage";
import React from "react";
import { useTranslation } from "react-i18next";
import Benefits from "./parts/Benefits";
import PassList from "./parts/PassList";

import s from "./BurnPassPage.module.scss";

function BurnPassPage() {
  const { image: topBanner } = useImage("images/burnPass/TopBanner.webp");
  const { t } = useTranslation();

  return (
    <div className={s.burnPassPage}>
      <div className={s.container}>
        <div className={s.titleMobile}>{t("burnPass.title")}</div>
        <div className={s.topBlockWrapper}>
          <div className={s.topBlockContent}>
            <div className={s.title}>{t("burnPass.title")}</div>
            <div className={s.benefitsTitle}>
              {t("burnPass.benefits.title")}
            </div>
            <Benefits />
          </div>
          <div className={s.topBlockImage}>
            {topBanner && <img src={topBanner} alt="top banner" />}
          </div>
        </div>
        <div className={s.mainBlockWrapper}>
          <div className={s.subtitle}>{t("burnPass.subtitle")} 🔥</div>
          <PassList />
        </div>
      </div>
    </div>
  );
}

export default BurnPassPage;
