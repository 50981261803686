import React, { useState } from "react";
import car from "assets/images/nftCategories/cars.webp";
import pass from "assets/images/nftCategories/pass.webp";
import part from "assets/images/nftCategories/parts.webp";
import pet from "assets/images/nftCategories/cypet.webp";
import town from "assets/images/nftCategories/town.webp";
import { TokenAddress } from "types/listing";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Icon from "components/common/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { NFT_TRENDY_MAP } from "constants/nftConstants";
import { useParams } from "react-router-dom";

import s from "./CollectionBanner.module.scss";

const imageMap: Record<TokenAddress, any> = {
  car,
  pass,
  pet,
  town,
  part,
};

type CollectionBannerTProps = {
  totalSupply: string;
  className?: string;
};

interface ICollectionBanner {
  image: string;
  title: string;
  description: string;
  isTrendy: boolean;
}

function CollectionBanner({
  totalSupply,
  className = "",
}: CollectionBannerTProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { collectionType = "car" } = useParams<{
    collectionType: TokenAddress;
  }>();

  const info: ICollectionBanner = {
    image: imageMap[collectionType],
    title: t(`marketcollections.${collectionType ?? "car"}.title`),
    description: t(`marketcollections.${collectionType ?? "car"}.description`),
    isTrendy: NFT_TRENDY_MAP[collectionType],
  };

  const variants = {
    closed: {
      height: 0,
      transition: {
        duration: 0.5,
      },
    },
    opened: {
      height: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const toggleOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={cn(s.wrapper, className)}>
      <div className={s.imageBlock}>
        <img className="fill-cover" src={info.image} alt="cars collection" />
        <div className={s.border} />
      </div>
      <div className={s.header}>
        <div className={cn(s.headerItem, s.title)}>{info.title}</div>
        <div className={cn(s.headerItem, s.headerTag, s.supply)}>
          {totalSupply.length ? totalSupply : "..."} {t("common.items")}
        </div>
        {info.isTrendy && (
          <div className={cn(s.headerItem, s.headerTag, s.trendy)}>
            <Icon variant="trendyCrown" className={s.icon} />
            <div>{t("common.trendy")}</div>
          </div>
        )}
      </div>
      <button
        className={s.descriptionContainerBtn}
        type="button"
        onClick={toggleOpening}
      >
        {!isOpen && (
          <div className={s.descriptionContainer}>
            <div className={cn(s.description, { [s.cutDescription]: !isOpen })}>
              {info.description}
            </div>
            <Icon variant="arrowDown" className={s.arrowDown} />
          </div>
        )}
        <AnimatePresence>
          <motion.div
            variants={variants}
            initial="closed"
            animate="opened"
            exit="closed"
            style={{ overflow: "hidden" }}
            className={s.descriptionContainer}
          >
            {isOpen && (
              <div className={s.descriptionContainer}>
                <div className={s.description}>{info.description}</div>
                <Icon variant="arrowDown" className={s.arrowUp} />
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </button>
    </div>
  );
}

export default CollectionBanner;
