import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ESwapCoins } from "types/coins";

import { closeWalletSwapCoinChooseModal } from "store/modalsSlice";

import { useAppDispatch, useAppSelector } from "hooks/store";

import ModalContainer from "components/modals/ModalContainer";
import Coin from "components/Coin";

import s from "./WalletSwapCoinChooseModal.module.scss";

const coinsMap: ESwapCoins[] = [
  ESwapCoins.ace,
  ESwapCoins.trc,
  ESwapCoins.matic,
  ESwapCoins.usdt,
];

interface IWalletSwapCoinChooseModalProps {
  excludedCoins?: ESwapCoins[];
  disabledCoins?: ESwapCoins[];
  setCoin: (...args: any[]) => void;
}

function WalletSwapCoinChooseModal({
  excludedCoins = undefined,
  disabledCoins = undefined,
  setCoin,
}: IWalletSwapCoinChooseModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    walletSwapCoinChooseModal: { isOpen },
  } = useAppSelector((state) => state.modals);

  const close = useCallback(() => {
    dispatch(closeWalletSwapCoinChooseModal());
  }, [dispatch]);

  const handleClick = (coin: ESwapCoins) => (): void => {
    setCoin(coin);
    close();
  };

  return (
    <ModalContainer
      open={isOpen}
      close={close}
      theme="white"
      withButton={false}
      modalClassName={s.modalContainer}
    >
      <div className={s.modalContent}>
        <div className={s.header}>
          <div className={s.title}>
            {t("modals.walletSwapCoinChoose.title")}
          </div>

          <button className={s.closer} type="button" onClick={close}>
            <i className="icon-close" />
          </button>
        </div>

        <ul className={s.items}>
          {coinsMap.map(
            (coin: ESwapCoins) =>
              !excludedCoins?.includes(coin) && (
                <li
                  key={coin}
                  className={`${s.item} ${
                    disabledCoins?.includes(coin) ? s.disabled : ""
                  }`}
                >
                  {coin}

                  <Coin
                    currency={coin}
                    variant="outerRing"
                    wrapperClassName={s.coinIconWrapper}
                    className={s.coinIcon}
                  />

                  <button
                    className={s.itemButton}
                    type="button"
                    onClick={
                      !disabledCoins?.includes(coin)
                        ? handleClick(coin)
                        : undefined
                    }
                  >
                    {coin}
                  </button>
                </li>
              ),
          )}
        </ul>
      </div>
    </ModalContainer>
  );
}

export default WalletSwapCoinChooseModal;
