import React from "react";

import s from "./Burger.module.scss";

interface BurgerProps {
  active: boolean;
  onClick: () => void;
  id?: string;
}

function Burger({ active, onClick, id = "burgerButton" }: BurgerProps) {
  return (
    <button
      id={id}
      type="button"
      className={`${s.burger} ${active ? s.active : ""}`}
      onClick={onClick}
    >
      <span className={s.line} id="burgerButton" />
      <span className={s.line} id="burgerButton" />
      <span className={s.line} id="burgerButton" />
    </button>
  );
}

export default Burger;
