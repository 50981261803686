import { TokenAddress } from "types/listing";

export const TOKEN_ADDRESS_MAP: Record<TokenAddress, TokenAddress> = {
  car: "car",
  pass: "pass",
  pet: "pet",
  part: "part",
  town: "town",
};

export const NFT_TRENDY_MAP: Record<TokenAddress, boolean> = {
  car: true,
  pass: false,
  part: false,
  town: false,
  pet: false,
};
