import React from "react";
import ClickAwayListener from "react-click-away-listener";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "hooks/store";
import { menuSlide } from "utils/motion";
import cn from "classnames";

import s from "./MenuContainer.module.scss";

interface MenuContainerProps {
  classNameHeaderContainer?: string;
  classNameHeader?: string;
  classNameHeaderTitle?: string;
  classNameHeaderIcon?: string;
  children: React.ReactNode;
  open: boolean;
  headerTitle?: string;
  alignment?: "left" | "right";
  onBackgroundClose?: boolean;
  headerWithCloseButton?: boolean;
  close: () => void;
}

function MenuContainer({
  children,
  open,
  headerTitle = "",
  alignment = "right",
  onBackgroundClose = true,
  close,
  classNameHeaderContainer = "",
  classNameHeader = "",
  classNameHeaderTitle = "",
  classNameHeaderIcon = "",
  headerWithCloseButton = false,
}: MenuContainerProps) {
  const { isOpen: isSortModalOpen } = useAppSelector(
    (state) => state.modals.sortModal,
  );
  const onClickAway = (event: FocusEvent | MouseEvent | TouchEvent) => {
    const targetId = ((event as MouseEvent).target as HTMLElement).id;
    if (
      targetId === "profileMenu" ||
      targetId === "languageSelector" ||
      targetId === "burgerButton" ||
      targetId === "logout"
    )
      return;
    if (isSortModalOpen) return;
    close();
  };

  if (onBackgroundClose) {
    return (
      <AnimatePresence>
        {open && (
          <ClickAwayListener onClickAway={onClickAway} mouseEvent="mouseup">
            <motion.div
              variants={menuSlide({ alignment })}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={`${s.menu} ${s[alignment]}`}
            >
              <div className={classNameHeaderContainer}>
                <div className={cn(s.headerPart, classNameHeader)}>
                  <div className={cn(s.title, classNameHeaderTitle)}>
                    {headerTitle}
                  </div>
                  {headerWithCloseButton && (
                    <button type="button" className={s.close} onClick={close}>
                      <span className={cn(s.line, classNameHeaderIcon)} />
                      <span className={cn(s.line, classNameHeaderIcon)} />
                    </button>
                  )}
                </div>
              </div>
              <div className={s.content}>{children}</div>
            </motion.div>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={menuSlide({ alignment })}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={`${s.menu} ${s[alignment]}`}
        >
          <div className={s.headerPart}>
            <div className={s.title}>{headerTitle}</div>
            <button type="button" className={s.close} onClick={close}>
              <span className={s.line} />
              <span className={s.line} />
            </button>
          </div>
          <div className={s.content}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default MenuContainer;
