import React, { useRef } from "react";

import TextField from "components/common/TextField";
import { Formik, FormikProps } from "formik";
import useRequestAuth from "hooks/requests/useRequestAuth";
import useSubmitAuthForm from "hooks/useSubmitAuthForm";
import { useTranslation } from "react-i18next";
import AuthTelegram from "../AuthTelegram";
import ModalButton from "../ModalButton";
import NewAuthSwitcher from "../NewAuthSwitcher";
import createFormDataSchema from "../utils";

import s from "./LogInContent.module.scss";

interface ValuesType {
  email: string;
}

const initialValues = { email: "" };

function LogInContent() {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<ValuesType>>(null);
  const { onSubmit } = useSubmitAuthForm();
  const { isLoading } = useRequestAuth();
  const formDataSchema = createFormDataSchema();

  return (
    <div className={s.modal}>
      <NewAuthSwitcher className={s.switcher} />
      <Formik
        initialValues={initialValues}
        validationSchema={formDataSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
        }: FormikProps<ValuesType>) => {
          return (
            <form className={s.form} onSubmit={handleSubmit}>
              <div className={s.formContainer}>
                <div className={s.enterEmail}>
                  {t("modals.logIn.emailLabel")}
                </div>
                <TextField
                  placeholder="satoshi@gmail.com"
                  hasError={touched.email && Boolean(errors.email)}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <div className={s.buttonContainer}>
                <ModalButton
                  className={s.submit}
                  type="submit"
                  isLoading={isSubmitting || isLoading}
                  disabled={!dirty || Boolean(errors.email)}
                >
                  {t("common.logIn")}
                </ModalButton>
                <div className={s.info}>{t("modals.logIn.text")}</div>
              </div>
            </form>
          );
        }}
      </Formik>
      <AuthTelegram />
    </div>
  );
}

export default LogInContent;
