import React from "react";
import partners from "./partners";

import s from "./PartnerSection.module.scss";

function PartnerSection() {
  return (
    <div>
      <div className={s.title}>MetaTrace Partners</div>
      <div className={s.content}>
        {partners.map((partner) => (
          <div className={s.card} key={partner.id}>
            {!!partner.image && <img src={partner.image} alt={partner.id} />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartnerSection;
