/* eslint-disable react/function-component-definition */
import React, { Children, ReactElement, ReactNode } from "react";

interface ShowProps {
  children: ReactNode;
}

interface WhenProps {
  isTrue: boolean;
  children: ReactNode;
}

interface ElseProps {
  render?: ReactNode;
  children: ReactNode;
}

const Show = ({ children }: ShowProps): ReactElement | null => {
  let when: ReactElement | null = null;
  let otherwise: ReactElement | null = null;

  Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.isTrue === undefined) {
        otherwise = child;
      } else if (!when && child.props.isTrue === true) {
        when = child;
      }
    }
  });

  return when || otherwise;
};

Show.When = ({ isTrue, children }: WhenProps): ReactElement | null =>
  isTrue ? (children as ReactElement) : null;

Show.Else = ({ render = null, children }: ElseProps): ReactElement | null =>
  ((render || children) as ReactElement) || null;

export default Show;
