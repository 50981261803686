import ButtonRoundIcon from "components/common/ButtonRoundIcon";
import ProjectPath from "constants/paths";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IconsVariant } from "types/icons";
import { NftMap, TokenAddress } from "types/listing";

import s from "./ItemControls.module.scss";

type ItemControlsProps = {
  tokenId: number;
  className?: string;
};
function ItemControls({ tokenId, className = "" }: ItemControlsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tokenAddress } = useParams();

  const onSellItem = useCallback(() => {
    navigate(
      `/${ProjectPath.WALLET_SELL}?entity=nft&token_address=${tokenAddress}&itemId=${tokenId}`,
    );
  }, [navigate, tokenAddress, tokenId]);
  const onSendItem = useCallback(() => {
    navigate(
      `/${ProjectPath.WALLET_SEND}?entity=nft&token_address=${tokenAddress}&itemId=${tokenId}&back=item`,
    );
  }, [navigate, tokenAddress, tokenId]);
  const onTransferItem = useCallback(() => {
    navigate(
      `/${ProjectPath.WALLET_TRANSFER}?entity=nft&token_address=${tokenAddress}&itemId=${tokenId}&back=item`,
    );
  }, [navigate, tokenAddress, tokenId]);

  return (
    <div className={`${className} ${s.controls}`}>
      <ButtonRoundIcon
        containerClassName={s.roundButton}
        iconVariant={IconsVariant.moneyBag}
        onClick={onSellItem}
        disabled={!tokenId}
      >
        {t("actions.sell")}
      </ButtonRoundIcon>
      <ButtonRoundIcon
        containerClassName={s.roundButton}
        iconClassName={s.icon}
        iconVariant={IconsVariant.arrowPointing}
        onClick={onSendItem}
        disabled={!tokenId}
      >
        {t("actions.send")}
      </ButtonRoundIcon>
      {NftMap[tokenAddress as TokenAddress] &&
        tokenAddress !== "pass" &&
        tokenAddress !== "part" &&
        tokenAddress !== "town" &&
        tokenAddress !== "pet" && (
          <ButtonRoundIcon
            iconClassName={`${s.iconArrows} ${s.iconPart1}`}
            iconVariant={IconsVariant.arrowsIn}
            onClick={onTransferItem}
            disabled={!tokenId}
          >
            {t("actions.transfer")}
          </ButtonRoundIcon>
        )}
    </div>
  );
}

export default ItemControls;
