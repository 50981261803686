import cn from "classnames";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import TextField from "components/common/TextField";
import { CommunityLinks } from "constants/paths";
import { Formik, FormikHelpers, FormikProps } from "formik";
import useLogOut from "hooks/requests/useLogOut";
import useRequestSetReferralCode from "hooks/requests/useRequestSetReferralCode";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { changeAuthModalStage, closeAuthModal } from "store/modalsSlice";
import { setReferralCode } from "store/userProfileSlice";
import * as Yup from "yup";
import ModalButton from "../ModalButton";
import NewAuthSwitcher from "../NewAuthSwitcher";

import s from "./ReferralCodeContent.module.scss";

interface ValuesType {
  code?: number | string;
}

const initialValues: ValuesType = {
  code: "Tracer",
};

function ReferralCodeContent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mutateAsync: logOut } = useLogOut();
  const { logInModal } = useAppSelector((state) => state.modals);
  const { mutateAsync: sendRequest } = useRequestSetReferralCode();

  const formDataSchema: Yup.ObjectSchema<ValuesType> = Yup.object().shape({
    code: Yup.string().required(),
  });

  const onSubmit = useCallback(
    async ({ code = "" }: ValuesType, actions: FormikHelpers<ValuesType>) => {
      actions.setSubmitting(true);
      console.log("code = ", code);
      if (logInModal.stage === "referralCodeRegister") {
        dispatch(setReferralCode(code?.toString() ?? ""));
      } else if (logInModal.stage === "referralCode") {
        const data = await sendRequest({
          referralCode: `${code}`,
        });
        if (data?.ok) {
          dispatch(changeAuthModalStage("logIn"));
        }
      }
      dispatch(closeAuthModal());
      actions.setSubmitting(false);
    },
    [logInModal.stage, dispatch, sendRequest],
  );

  return (
    <div className={s.modal}>
      <NewAuthSwitcher
        isRegistrationLocked
        isRegistration={logInModal.stage === "referralCodeRegister"}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={formDataSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }: FormikProps<ValuesType>) => (
          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.formContainer}>
              <div className={s.enterCode}>
                {t("modals.referralCodeVerify.title")}
              </div>
              <div className={s.warningContainer}>
                <div>
                  <div className={cn(s.text, s.warning)}>
                    <Trans
                      i18nKey="modals.referralCodeVerify.warning"
                      components={{ 1: <strong /> }}
                    />
                  </div>
                </div>
              </div>
              <TextField
                // label={t("modals.verify.codeLabel")}
                placeholder={t("modals.referralCodeVerify.placeHolder")}
                name="code"
                hasError={touched.code && Boolean(errors.code)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                classNames={{ wrapper: s.input }}
              />
              <div className={s.text}>
                {t("modals.referralCodeVerify.text")}
              </div>
              <div className={s.linkContainer}>
                <Button
                  link={CommunityLinks.TWITTER}
                  className={cn(s.link, s.colored)}
                  classNameChildren={s.linkContent}
                >
                  <Icon variant="X" className={s.icon} /> <span>Twitter</span>
                </Button>
                <Button
                  link={CommunityLinks.DISCORD}
                  className={cn(s.link, s.colored)}
                  classNameChildren={s.linkContent}
                >
                  <Icon
                    variant="discord"
                    className={cn(s.icon, s.discordIcon)}
                  />
                  <span>Discord</span>
                </Button>
                <Button
                  link={CommunityLinks.TELEGRAM}
                  className={cn(s.link, s.colored)}
                  classNameChildren={s.linkContent}
                >
                  <Icon
                    variant="telegramSvg"
                    className={cn(s.icon, s.telegramIcon)}
                  />
                  <span>Telegram</span>
                </Button>
              </div>
            </div>
            <div className={s.formContainer}>
              <div className={s.buttons}>
                <ModalButton
                  type="button"
                  onClick={() => logOut()}
                  className={s.back}
                  variant="cancel"
                >
                  {t("common.goBack")}
                </ModalButton>
                <ModalButton
                  className={s.submit}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!values.code && (!dirty || Boolean(errors.code))}
                >
                  {t("common.next")}
                </ModalButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ReferralCodeContent;
