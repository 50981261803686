import AppReleaseBanner from "components/AppReleaseBanner";
import ButtonRoundIcon from "components/common/ButtonRoundIcon";
import Tabs from "components/common/Tabs";
import Listing from "components/Listing/Listing";
import ListingItem from "components/Listing/ListingItem";
import { FilterMenu } from "components/menus";
import SortModal from "components/modals/SortModal";
import useGetTotalSupplyByNftType from "hooks/requests/polygon/useGetTotalSupplyByNftType";
import useGetListing from "hooks/requests/useGetListing";
import useGetListingTotalAmount from "hooks/requests/useGetListingTotalAmount";
import { useAppSelector } from "hooks/store";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateMarketCollectionTab } from "store/marketCollectionTabSlice";
import { openFilterMenu } from "store/menusSlice";
import { FiltersSliceType } from "types/filters";
import { IconsVariant } from "types/icons";
import {
  Collection as ICollection,
  Listing as IListing,
  ListingTabTypeMap,
  ListingTabTypeMapOriginal,
  TokenAddress,
} from "types/listing";
import CollectionBanner from "./parts/CollectionBanner";
import createListingParams from "./utils";

import s from "./MarketCollectionPage.module.scss";
import NoListing from "./parts/CollectionBanner/NoListing";

function MarketCollectionPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookie = useGetAuthCookie();
  const { collectionType } = useParams<{ collectionType: TokenAddress }>();
  const { tab } = useAppSelector((state) => state.marketCollectionTab);
  const capTab = ListingTabTypeMap[tab];
  const filterSliceType: FiltersSliceType = `${
    collectionType || "car"
  }Filters${capTab}`;
  const { appliedFilter } = useAppSelector(
    (state) => state.filters[filterSliceType],
  );
  const { data: totalSupplyData } = useGetTotalSupplyByNftType(
    collectionType ?? "car",
  );

  useEffect(() => {
    return () => {
      dispatch(updateMarketCollectionTab({ tab: "all" }));
    };
  }, [dispatch]);
  const getListingParams = createListingParams(
    appliedFilter,
    tab,
    collectionType,
  );

  const {
    data,
    isLoading: isListingLoading,
    isFetchingNextPage: isListingFetchingNext,
    hasNextPage: isListingHasNext,
    fetchNextPage,
  } = useGetListing(getListingParams);

  const [{ data: allTotalListings }, { data: myTotalListings }] =
    useGetListingTotalAmount("both", appliedFilter, collectionType);

  const listings = useMemo(
    () =>
      data?.pages.reduce((accumulator, page) => {
        if (page && "collections" in page) {
          const pageListings = page?.collections || [];
          pageListings.forEach((collection) => {
            const existingIndex = accumulator.findIndex(
              (item) =>
                "tokenId" in item && item.tokenId === collection.tokenId,
            );
            if (existingIndex === -1) {
              accumulator.push(collection);
            }
          });
          return accumulator;
        }
        const pageListings = page?.listings || [];
        pageListings.forEach((listing) => {
          const existingIndex = accumulator.findIndex(
            (item) => "id" in item && item.id === listing.id,
          );
          if (existingIndex === -1) {
            accumulator.push(listing);
          }
        });
        return accumulator;
      }, [] as (IListing | ICollection)[]),
    [data?.pages],
  );

  const tabsAuthorized = useMemo(
    () => [
      {
        id: 0,
        path: ListingTabTypeMapOriginal.all,
        title: t("market.tags.allListings"),
        amount: allTotalListings?.total_listings?.toString() ?? "0",
        className: s.tab,
      },
      {
        id: 1,
        path: ListingTabTypeMapOriginal.my,
        title: t("market.tags.myListings"),
        amount: myTotalListings?.total_listings?.toString() ?? "0",
        className: s.tab,
      },
    ],
    [t, allTotalListings?.total_listings, myTotalListings?.total_listings],
  );

  const tabsProps = useMemo(() => {
    if (cookie) {
      return {
        tabs: tabsAuthorized,
        withSettings: true,
      };
    }
    return {
      tabs: [
        {
          id: 0,
          path: ListingTabTypeMapOriginal.all,
          title: t("market.tags.allListings"),
          amount: allTotalListings?.total_listings?.toString() ?? "0",
          className: s.tab,
        },
      ],
      withSettings: true,
    };
  }, [allTotalListings?.total_listings, cookie, t, tabsAuthorized]);

  const onViewTrigger = (inView: boolean) => {
    if (inView) {
      fetchNextPage();
    }
  };

  const onOpenFilter = () => {
    dispatch(openFilterMenu());
  };

  const isViewableItemActive =
    !isListingLoading && !isListingFetchingNext && isListingHasNext;

  if (!collectionType) return null;

  return (
    <div className={s.marketPage}>
      <div className={s.container}>
        <AppReleaseBanner wrapperClassName={s.sideBanner} />
        <CollectionBanner
          totalSupply={totalSupplyData?.totalSupply ?? "..."}
          className={s.banner}
          key={collectionType}
        />
        <div className={s.content}>
          <Tabs
            tabs={tabsProps.tabs}
            currentTabPath={
              tab === "all"
                ? ListingTabTypeMapOriginal.all
                : ListingTabTypeMapOriginal.my
            }
            isLink={false}
          />
          <Listing
            loading={isListingLoading}
            isFetchingNext={isListingFetchingNext}
            className={s.listing}
          >
            {listings?.length ? (
              <>
                {listings?.map(
                  (item, index) =>
                    item && (
                      <ListingItem
                        key={item.metadata.name || index}
                        id={"id" in item ? item.id : item.tokenId}
                        image={item.metadata.image}
                        tokenId={item.tokenId}
                        attributes={item.metadata.attributesObj}
                        name={item.metadata.name}
                        priceConverted={item.priceConverted}
                        price_type={item.price_type}
                        tokenAddress={collectionType!}
                        quantity={
                          "amount_nft" in item ? item.amount_nft : undefined
                        }
                      />
                    ),
                )}
                {isViewableItemActive && (
                  <InView
                    className={s.viewableItem}
                    as="div"
                    onChange={onViewTrigger}
                  />
                )}
              </>
            ) : (
              <NoListing />
            )}
          </Listing>
        </div>
        <div className={s.filterMenu}>
          <FilterMenu listingType={tab} />
        </div>
      </div>
      <SortModal />
      {((!isListingLoading && !!listings?.length && listings?.[0]) ||
        data?.pages[0]?.filters?.length) && (
        <div className={s.settingsButtonWrapper}>
          <ButtonRoundIcon
            type="button"
            containerClassName={s.settingsButtonContainer}
            onClick={onOpenFilter}
            iconVariant={IconsVariant.settingsSvg}
            iconClassName={s.iconSettings}
            buttonClassName={s.settingsButton}
          />
        </div>
      )}
    </div>
  );
}

export default MarketCollectionPage;
