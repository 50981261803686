import React from "react";
import useGetGasPrice from "hooks/requests/useGetGasPrice";
import InformationContainer from "../InformationContainer";
import s from "./GasContainer.module.scss";

type GasContainerProps = {
  className?: string;
};

function GasContainer({ className = "" }: GasContainerProps) {
  const { data: gas, isLoading } = useGetGasPrice();

  return (
    <div className={`${className} ${s.gasContainer}`}>
      <InformationContainer
        tKey="transaction.gas"
        value={`≈ ${gas?.suggested} MATIC`}
        isLoading={isLoading}
      />
    </div>
  );
}

export default React.memo(GasContainer);
