import { useQuery } from "@tanstack/react-query";
import { requestTgAuth } from "api/auth";
import TG_CODE from "constants/telegram";
import { showErrors } from "utils/showErrors";

const useRequestTgConnectLink = () => {
  const queryInfo = useQuery({
    queryKey: ["telegram-connect"],
    queryFn: () => requestTgAuth({}),
    onSuccess: (data) => {
      if (data?.code) {
        sessionStorage.setItem(TG_CODE, data.code);
      }
    },
    onError: showErrors,
    staleTime: 120000,
  });

  return queryInfo;
};

export default useRequestTgConnectLink;
