import { FilterRestructured } from "types/filters";
import { TraitType } from "types/listing";
import { RequestListingFilter } from "types/requests";

const transformToAppliedFilters = (
  filters: FilterRestructured,
): RequestListingFilter => {
  const result: RequestListingFilter = {};

  Object.entries(filters).forEach(([traitType, filter]) => {
    if (!filter.isActive) return;
    result[traitType as TraitType] = filter.value;
  });

  return result as RequestListingFilter;
};

export default transformToAppliedFilters;
