import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JustCoinType } from "types/coins";
import { TokenAddress } from "types/listing";
import { ILootboxItem } from "types/lootboxes";
import {
  ActionModalFields,
  AuthModalStage,
  BalanceModalFields,
  BalanceModalStage,
  CommonModalOpenPayload,
  ConfirmationModalFields,
  EWalletSwapCoinChooseModalType,
  InitialState,
  ModalTaskCompletePayload,
  ModalTaskRequestStage,
} from "types/modals";

const initialState: InitialState = {
  commonModal: {
    isOpen: false,
    title: "",
    text: "",
    isResolveButton: false,
    resolveButtonText: "",
    isRejectButton: false,
    rejectButtonText: "",
    closeAfterResolveReject: true,
  },
  logInModal: {
    isOpen: false,
    stage: "logIn",
    email: "",
    mnemonic: [],
    mnemonicString: "",
  },
  walletActionModal: {
    isOpen: false,
    entity: "trc",
  },
  walletSelectTransferTypeModal: {
    isOpen: false,
  },
  walletSuccessfulPurchaseModal: {
    isOpen: false,
  },
  walletSuccessfulSellingModal: {
    isOpen: false,
  },
  walletWarningListingModal: {
    isOpen: false,
    forbiddenCoinTypeListings: [],
  },
  confirmationModal: {
    isOpen: false,
    title: "",
    buttonText: "",
    text: "",
  },
  actionModal: {
    isOpen: false,
    title: "",
    buttonText: "",
  },
  balanceModal: {
    isOpen: false,
    withNFT: undefined,
    withACE: false,
    withTRC: false,
    withMATIC: false,
    stage: "main",
  },
  welcomeBuyModal: {
    isOpen: false,
  },
  welcomeWideModal: {
    isOpen: false,
  },
  sortModal: {
    isOpen: false,
  },
  authConfirmationModal: {
    isOpen: false,
  },
  modalTaskRequest: {
    stage: "idle",
    task: undefined,
    data: undefined,
  },
  moonPayModal: {
    isOpen: false,
  },
  fillUpModal: {
    isOpen: false,
  },
  walletSwapModal: {
    isOpen: false,
  },
  walletSwapCoinChooseModal: {
    isOpen: false,
    type: EWalletSwapCoinChooseModalType.from,
  },
  lootBoxWinningModal: {
    isOpen: false,
    gift: undefined,
  },
  buyTraceProModal: {
    isOpen: false,
  },
  shopCompleteModal: {
    isOpen: false,
  },
  tokensModal: {
    isOpen: false,
  },
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    // CommonModal
    openCommonModal(state, action: PayloadAction<CommonModalOpenPayload>) {
      state.commonModal.isOpen = true;
      state.commonModal.title = action.payload.title;
      state.commonModal.text = action.payload.text;
      state.commonModal.isResolveButton = action.payload.isResolveButton;
      state.commonModal.resolveButtonText = action.payload.resolveButtonText;
      state.commonModal.isRejectButton = action.payload.isRejectButton;
      state.commonModal.rejectButtonText = action.payload.rejectButtonText;
      if (action.payload.closeAfterResolveReject === false)
        state.commonModal.closeAfterResolveReject = false;
      else {
        state.commonModal.closeAfterResolveReject = true;
      }
    },
    closeCommonModal(state) {
      state.commonModal.isOpen = false;
    },
    // AuthModal
    openAuthModal: {
      reducer: (state, action: PayloadAction<AuthModalStage | undefined>) => {
        state.logInModal.isOpen = true;
        if (action?.payload) {
          state.logInModal.stage = action?.payload;
        } else {
          state.logInModal.stage = "logIn";
        }
      },
      prepare: (stage?: AuthModalStage) => ({ payload: stage }),
    },
    closeAuthModal(state, action: PayloadAction<AuthModalStage | undefined>) {
      if (action.payload) state.logInModal.stage = action.payload;
      state.logInModal.isOpen = false;
      state.logInModal.mnemonic = [];
      state.logInModal.mnemonicString = "";
      state.logInModal.email = "";
    },
    endRegistration(state) {
      state.logInModal.isOpen = false;
      state.logInModal.stage = "logIn";
      state.logInModal.mnemonic = [];
      state.logInModal.mnemonicString = "";
      state.logInModal.email = "";
    },
    switchToVerifyModalStage(state) {
      state.logInModal.stage = "verifyCode";
    },
    changeAuthModalStage(state, action: PayloadAction<AuthModalStage>) {
      state.logInModal.stage = action.payload;
    },
    changeAuthEmail(state, action: PayloadAction<string>) {
      state.logInModal.email = action.payload;
    },
    changeAuthMnemonic(state, action: PayloadAction<string>) {
      state.logInModal.mnemonicString = action.payload;
      state.logInModal.mnemonic = action.payload.split(" ");
    },
    // WalletActionModal
    openWalletActionModal(state, action: PayloadAction<`${JustCoinType}`>) {
      state.walletActionModal.isOpen = true;
      state.walletActionModal.entity = action.payload;
    },
    closeWalletActionModal(state) {
      state.walletActionModal.isOpen = false;
    },
    // WalletSelectTransferTypeModal
    openWalletSelectTransferTypeModal(state) {
      state.walletSelectTransferTypeModal.isOpen = true;
    },
    closeWalletSelectTransferTypeModal(state) {
      state.walletSelectTransferTypeModal.isOpen = false;
    },
    // WalletSwapModal
    openWalletSwapModal(state) {
      state.walletSwapModal.isOpen = true;
    },
    closeWalletSwapModal(state) {
      state.walletSwapModal.isOpen = false;
    },
    // WalletSwapCoinChooseModal
    openWalletSwapCoinChooseModal(state) {
      state.walletSwapCoinChooseModal.isOpen = true;
    },
    closeWalletSwapCoinChooseModal(state) {
      state.walletSwapCoinChooseModal.isOpen = false;
    },
    setWalletSwapCoinChooseModalType(
      state,
      action: PayloadAction<EWalletSwapCoinChooseModalType>,
    ) {
      state.walletSwapCoinChooseModal = {
        ...state.walletSwapCoinChooseModal,
        type: action.payload,
      };
    },
    // WalletSuccessfulPurchaseModal
    openWalletSuccessfulPurchaseModal(state) {
      state.walletSuccessfulPurchaseModal.isOpen = true;
    },
    closeWalletSuccessfulPurchaseModal(state) {
      state.walletSuccessfulPurchaseModal.isOpen = false;
    },
    // WalletSuccessfulSellingModal
    openWalletSuccessfulSellingModal(state) {
      state.walletSuccessfulPurchaseModal.isOpen = true;
    },
    closeWalletSuccessfulSellingModal(state) {
      state.walletSuccessfulPurchaseModal.isOpen = false;
    },

    // WalletWarningListingModal
    openWalletWarningListingModal(
      state,
      action: PayloadAction<Array<TokenAddress>>,
    ) {
      state.walletWarningListingModal.isOpen = true;
      state.walletWarningListingModal.forbiddenCoinTypeListings =
        action.payload;
    },
    closeWalletWarningListingModal(state) {
      state.walletWarningListingModal.isOpen = false;
      state.walletWarningListingModal.forbiddenCoinTypeListings = [];
    },
    // ConfirmationModal
    openConfirmationModal(
      state,
      action: PayloadAction<ConfirmationModalFields>,
    ) {
      state.confirmationModal = { isOpen: true, ...action.payload };
    },
    closeConfirmationModal(state) {
      state.confirmationModal = {
        isOpen: false,
        title: "",
        buttonText: "",
        text: "",
      };
    },
    // ActionModal
    openActionModal(state, action: PayloadAction<ActionModalFields>) {
      state.actionModal = { isOpen: true, ...action.payload };
    },
    closeActionModal(state) {
      state.actionModal = {
        isOpen: false,
        title: "",
        buttonText: "",
        input: undefined,
      };
    },
    // BalanceModal
    openBalanceModal(state, action: PayloadAction<BalanceModalFields>) {
      state.balanceModal = {
        isOpen: true,
        stage: action.payload.stage || "main",
        withNFT: undefined,
        withACE: false,
        withTRC: false,
        withMATIC: false,
        withUSDT: false,
        ...action.payload,
      };
    },
    changeBalanceModalStage(state, action: PayloadAction<BalanceModalStage>) {
      state.balanceModal.stage = action.payload;
    },
    closeBalanceModal(state) {
      state.balanceModal = {
        ...state.balanceModal,
        isOpen: false,
        stage: "main",
      };
    },
    // SortModal
    openSortModal(state) {
      state.sortModal.isOpen = true;
    },
    closeSortModal(state) {
      state.sortModal.isOpen = false;
    },
    // Fill Up Modal
    openFillUpModal(state) {
      state.fillUpModal.isOpen = true;
    },
    closeFillUpModal(state) {
      state.fillUpModal.isOpen = false;
    },
    // Welcome Buy Modal
    openWelcomeBuyModal(state) {
      state.welcomeBuyModal.isOpen = true;
    },
    closeWelcomeBuyModal(state) {
      state.welcomeBuyModal.isOpen = false;
    },
    // Welcome Wide Modal
    openWelcomeWideModal(state) {
      state.welcomeWideModal.isOpen = true;
    },
    closeWelcomeWideModal(state) {
      state.welcomeWideModal.isOpen = false;
    },
    // AuthConfirmationModal
    openAuthConfirmationModal(state) {
      state.authConfirmationModal.isOpen = true;
    },
    closeAuthConfirmationModal(state) {
      state.authConfirmationModal.isOpen = false;
    },
    // winnableLootBoxModal
    openLootBoxWinningModal(
      state,
      action: PayloadAction<{ gift: ILootboxItem }>,
    ) {
      state.lootBoxWinningModal.isOpen = true;
      state.lootBoxWinningModal.gift = action.payload.gift;
    },
    closeLootBoxWinningModal(state) {
      state.lootBoxWinningModal.isOpen = false;
      state.lootBoxWinningModal.gift = undefined;
    },
    // ModalTaskRequest
    setTaskRequest(state, action: PayloadAction<{ taskId: string }>) {
      state.modalTaskRequest = {
        task: action.payload.taskId,
        stage: "pending",
        data: undefined,
      };
    },
    changeTaskRequestStage(
      state,
      action: PayloadAction<`${ModalTaskRequestStage}`>,
    ) {
      state.modalTaskRequest.stage = action.payload;
    },
    setResultTaskRequest(
      state,
      action: PayloadAction<ModalTaskCompletePayload>,
    ) {
      state.modalTaskRequest.stage = action.payload.result;
      state.modalTaskRequest.data = action.payload.data;
    },
    finishTaskRequest(state) {
      state.modalTaskRequest = {
        task: undefined,
        stage: "idle",
        data: undefined,
      };
    },
    openMoonPayModal(state) {
      state.moonPayModal.isOpen = true;
    },
    closeMoonPayModal(state) {
      state.moonPayModal.isOpen = false;
    },
    // buyTraceProModal
    openBuyTraceProModal(state) {
      state.buyTraceProModal.isOpen = true;
    },
    closeBuyTraceProModal(state) {
      state.buyTraceProModal.isOpen = false;
    },
    // ShopCompleteModal
    openShopCompleteModal(state) {
      state.shopCompleteModal.isOpen = true;
    },
    closeShopCompleteModal(state) {
      state.shopCompleteModal.isOpen = false;
    },
    // TokensModalState
    openTokensModal(state) {
      state.tokensModal.isOpen = true;
    },
    closeTokensModal(state) {
      state.tokensModal.isOpen = false;
    },
  },
});

export const {
  // AuthModal
  openCommonModal,
  closeCommonModal,
  // AuthModal
  openAuthModal,
  closeAuthModal,
  switchToVerifyModalStage,
  changeAuthModalStage,
  changeAuthEmail,
  endRegistration,
  changeAuthMnemonic,
  // WalletActionModal
  openWalletActionModal,
  closeWalletActionModal,
  // WalletSelectTransferTypeModal
  openWalletSelectTransferTypeModal,
  closeWalletSelectTransferTypeModal,
  // WalletSuccessfulPurchaseModal
  openWalletSuccessfulPurchaseModal,
  closeWalletSuccessfulPurchaseModal,
  // WalletSuccessfulSellingModal
  openWalletSuccessfulSellingModal,
  closeWalletSuccessfulSellingModal,
  // WalletWarningListingModal
  openWalletWarningListingModal,
  closeWalletWarningListingModal,
  // WalletSwapModal
  openWalletSwapModal,
  closeWalletSwapModal,
  // WalletSwapCoinChooseModal
  openWalletSwapCoinChooseModal,
  closeWalletSwapCoinChooseModal,
  setWalletSwapCoinChooseModalType,
  // ConfirmationModal
  openConfirmationModal,
  closeConfirmationModal,
  // ActionModal
  openActionModal,
  closeActionModal,
  // BalanceModal
  openBalanceModal,
  changeBalanceModalStage,
  closeBalanceModal,
  // SortModal
  openSortModal,
  closeSortModal,
  // AuthConfirmationModal
  openAuthConfirmationModal,
  closeAuthConfirmationModal,
  // winnableLootBoxModal
  openLootBoxWinningModal,
  closeLootBoxWinningModal,
  // ModalTaskRequest
  setTaskRequest,
  changeTaskRequestStage,
  setResultTaskRequest,
  finishTaskRequest,
  // moonPayModal
  openMoonPayModal,
  closeMoonPayModal,
  // Fill Up Modal
  openFillUpModal,
  closeFillUpModal,
  // Welcome Buy Modal
  closeWelcomeBuyModal,
  openWelcomeBuyModal,
  // Welcome Modal
  closeWelcomeWideModal,
  openWelcomeWideModal,
  // buyTraceProModal
  openBuyTraceProModal,
  closeBuyTraceProModal,
  // ShopCompleteModal
  openShopCompleteModal,
  closeShopCompleteModal,
  // TokensModal
  openTokensModal,
  closeTokensModal,
} = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
