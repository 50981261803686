import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  walletAddress: string;
  email: string;
  referralCode: string;
  hasGenesis?: boolean;
  hasSubscription?: boolean;
}
const initialState: InitialState = {
  walletAddress: "",
  email: "",
  referralCode: "",
  hasSubscription: undefined,
  hasGenesis: undefined,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setWalletAddress(state, action: PayloadAction<string>) {
      state.walletAddress = action.payload;
    },
    dropWalletAddress(state) {
      state.walletAddress = "";
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    dropEmail(state) {
      state.email = "";
    },
    setReferralCode(state, action: PayloadAction<string>) {
      state.referralCode = action.payload;
    },
    dropReferralCode(state) {
      state.referralCode = "";
    },
    setHasSubscription(state, action: PayloadAction<boolean | undefined>) {
      state.hasSubscription = action.payload;
    },
    setHasGenesis(state, action: PayloadAction<boolean | undefined>) {
      state.hasGenesis = action.payload;
    },
    dropHasSubscription(state) {
      state.hasSubscription = undefined;
    },
  },
});

export const {
  setWalletAddress,
  setReferralCode,
  dropWalletAddress,
  setEmail,
  dropEmail,
  dropReferralCode,
  setHasSubscription,
  dropHasSubscription,
  setHasGenesis,
} = userProfileSlice.actions;

export const userProfileReducer = userProfileSlice.reducer;
