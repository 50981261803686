import React from "react";

import s from "../NavigationElement.module.scss";

interface Props {
  className?: string;
}

function NavigationBackground({ className = "" }: Props) {
  return (
    <div className={`${s.navigationBackground} ${className}`}>
      <div className={s.backgroundBack} />
      <div className={s.backgroundFront} />
    </div>
  );
}

export default NavigationBackground;
