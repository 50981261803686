import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ApiError from "classes/ApiError";
import { signUp } from "api/auth";
import { showErrors } from "utils/showErrors";
import { SignUpResponse } from "types/responses";
import { setReferralCode } from "store/userProfileSlice";
import { openAuthModal } from "store/modalsSlice";
import { SignUpRequest } from "types/requests";

const useSignUp = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const queryInfo = useMutation<SignUpResponse, ApiError, SignUpRequest>(
    ["signUp"],
    async (args) => {
      return signUp(args);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        dispatch(setReferralCode(""));
      },
      onError: showErrors,
    },
  );

  useEffect(() => {
    if (
      queryInfo.isError &&
      queryInfo.error instanceof ApiError &&
      queryInfo.error.status === 406
    ) {
      dispatch(setReferralCode(""));
      dispatch(openAuthModal("referralCodeRegister"));
    }
  }, [queryInfo.isError, queryInfo.error, dispatch]);

  return queryInfo;
};

export default useSignUp;
