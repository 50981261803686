import React, { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  closeCommonModal,
  finishTaskRequest,
  setResultTaskRequest,
} from "store/modalsSlice";

import Button from "components/common/Button";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./CommonModal.module.scss";

function CommonModal() {
  const dispatch = useAppDispatch();
  const {
    isOpen,
    title,
    text,
    isResolveButton,
    resolveButtonText,
    isRejectButton,
    rejectButtonText,
    closeAfterResolveReject,
  } = useAppSelector((state) => state.modals.commonModal);

  const { stage } = useAppSelector((state) => state.modals.modalTaskRequest);

  const close = useCallback(() => {
    dispatch(closeCommonModal());
    dispatch(finishTaskRequest());
  }, [dispatch]);

  const resolve = useCallback(() => {
    if (closeAfterResolveReject) dispatch(closeCommonModal());
    dispatch(
      setResultTaskRequest({
        result: "resolved",
      }),
    );
  }, [dispatch, closeAfterResolveReject]);

  const reject = useCallback(() => {
    if (closeAfterResolveReject) dispatch(closeCommonModal());
    dispatch(
      setResultTaskRequest({
        result: "rejected",
      }),
    );
  }, [dispatch, closeAfterResolveReject]);

  return (
    <ModalContainerWithActions open={isOpen} close={close} title={title}>
      <div className={s.content}>
        <div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={s.actions}>
          {isRejectButton && (
            <Button
              className={s.button}
              onClick={reject}
              variant="filledGray"
              disabled={stage === "resolving"}
            >
              {rejectButtonText}
            </Button>
          )}
          {isResolveButton && (
            <Button
              className={s.button}
              onClick={resolve}
              isLoading={stage === "resolving"}
            >
              {resolveButtonText}
            </Button>
          )}
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default CommonModal;
