import { useQuery } from "@tanstack/react-query";
import { showErrors } from "utils/showErrors";
import { GetSwapCostResponse } from "types/responses";
import { GetSwapCostRequest } from "types/requests";
import { ESwapCoins } from "types/coins";
import { getSwapCost } from "api/wallet";
import { useEffect } from "react";
import ApiError from "classes/ApiError";
import { useTranslation } from "react-i18next";

interface UseGetSwapCostParams extends GetSwapCostRequest {
  from: string;
  fromCurrency: ESwapCoins;
  toCurrency: ESwapCoins;
  enabled?: boolean;
}

interface UseGetSwapCostParams extends GetSwapCostRequest {
  from: string;
  fromCurrency: ESwapCoins;
  toCurrency: ESwapCoins;
  enabled?: boolean;
}

const useGetSwapCost = ({
  from,
  fromCurrency,
  toCurrency,
  type,
  enabled = true,
}: UseGetSwapCostParams) => {
  const { t } = useTranslation();
  const deps: any[] = ["swap_cost", from, fromCurrency, toCurrency, type];

  const queryInfo = useQuery<GetSwapCostResponse | null, Error>(
    deps,
    async () => {
      return getSwapCost({ [fromCurrency]: from, [toCurrency]: 0, type });
    },
    {
      enabled: fromCurrency !== toCurrency && enabled,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      if (
        (queryInfo.error.status === 500 || queryInfo.error.status === 400) &&
        queryInfo.error.errors
      ) {
        queryInfo.error.errors[0].meta.error = t(
          "notifications.swapCostCommonError.title",
        );
        showErrors(queryInfo.error);
      }
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error, t]);

  return queryInfo;
};

export default useGetSwapCost;
