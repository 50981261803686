import React from "react";

import { CITY_CATEGORY_TO_ICON, TownCategory } from "constants/townConstants";

import s from "./TownCornerIcon.module.scss";

interface Props {
  value: TownCategory;
  className: string;
}

function TownCornerIcon({ value, className }: Props) {
  return (
    <div
      className={`${className} ${s.iconWrapper} ${
        s[CITY_CATEGORY_TO_ICON[value]]
      }`}
    >
      <span className={`icon-${CITY_CATEGORY_TO_ICON[value]}`} />
    </div>
  );
}

export default TownCornerIcon;
