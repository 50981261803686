import ApiError from "classes/ApiError";
import {
  ApiErrorResponse,
  GetProfileResponse,
  GetReferralCodeResponse,
  GetReferralListResponse,
  MakeSubscription,
  RestructuredGetProfileResponse,
} from "types/responses";
import { restructureProfileResponse } from "utils/restructureProfileResponse";

/* eslint-disable import/prefer-default-export */
const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getProfile = async (): Promise<RestructuredGetProfileResponse> => {
  const response = await fetch(`${endpoint}/auth/getProfile/`, {
    credentials: "include",
    method: "GET",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const dataRaw: GetProfileResponse = await response.json();
  const newData = restructureProfileResponse(dataRaw);
  return newData;
};

export const getReferralList = async (): Promise<GetReferralListResponse> => {
  const response = await fetch(`${endpoint}/auth/getProfile/referralList`, {
    credentials: "include",
    method: "GET",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const getReferralCode = async (): Promise<GetReferralCodeResponse> => {
  const response = await fetch(`${endpoint}/auth/getProfile/referralCode`, {
    credentials: "include",
    method: "GET",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const makeSubscription = async (): Promise<MakeSubscription> => {
  const response = await fetch(`${endpoint}/subscription/`, {
    credentials: "include",
    method: "POST",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};
