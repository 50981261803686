import React, { useEffect, useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import useGetAuthCookie from "hooks/useGetAuthCookie";

import AppLayout from "layouts/AppLayout";
import MarketPage from "pages/MarketPage";
import WalletPage from "pages/WalletPage";
import ItemPage from "pages/ItemPage";
import TransactionPage from "pages/TransactionPage";
import MarketCollectionPage from "pages/MarketCollectionPage";
import ReferralPage from "pages/ReferralPage";
import NavigationLayout from "layouts/NavigationLayout";
import ProjectPath from "constants/paths";
import { openBuyTraceProModal, openWelcomeWideModal } from "store/modalsSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks/store";
import GameShopPage from "pages/GameShopPage";
import BurnPassPage from "pages/BurnPassPage";
import TokensPage from "pages/TokensPage";
import ProtectedRoute from "hocs/withProtectedRoute";

function Router() {
  const cookie = useGetAuthCookie();
  const { logInModal } = useAppSelector((state) => state.modals);
  const { hasSubscription } = useAppSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { isTgWebApp } = useAppSelector((state) => state.appState);

  useEffect(() => {
    const isWelcomeWideShown = sessionStorage.getItem("welcomeDownload");
    const isBuyTraceProShown = sessionStorage.getItem("tracePro");

    if (
      !isWelcomeWideShown &&
      cookie &&
      logInModal.stage === "logIn" &&
      isTgWebApp === false
    ) {
      dispatch(openWelcomeWideModal());
      sessionStorage.setItem("welcomeDownload", "true");
    }

    if (
      !isBuyTraceProShown &&
      cookie &&
      logInModal.stage === "logIn" &&
      hasSubscription === false &&
      isTgWebApp === false
    ) {
      dispatch(openBuyTraceProModal());
      sessionStorage.setItem("tracePro", "true");
    }
  }, [cookie, logInModal.stage, hasSubscription, isTgWebApp, dispatch]);

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route path="/*" element={<AppLayout />}>
            <Route path="/*" element={<NavigationLayout />}>
              <Route
                index
                element={<Navigate replace to={ProjectPath.MARKET} />}
              />
              <Route
                index
                element={<Navigate replace to={ProjectPath.MARKET} />}
              />
              <Route path={ProjectPath.MARKET} element={<MarketPage />} />
              <Route
                path={`${ProjectPath.MARKET_ITEM}/:tokenAddress/:tokenId`}
                element={<ItemPage />}
              />
              <Route
                path={`${ProjectPath.MARKET}/:collectionType`}
                element={<MarketCollectionPage />}
              />
              <Route path={`${ProjectPath.TOKENS}`} element={<TokensPage />} />

              <Route
                path={ProjectPath.WALLET}
                element={
                  <ProtectedRoute
                    element={WalletPage}
                    tab={`/${ProjectPath.WALLET}`}
                  />
                }
              />
              <Route
                path={ProjectPath.WALLET_NFT}
                element={
                  <ProtectedRoute
                    element={WalletPage}
                    tab={`/${ProjectPath.WALLET_NFT}`}
                  />
                }
              />
              <Route
                path={ProjectPath.WALLET_SEND}
                element={<ProtectedRoute element={TransactionPage} />}
              />
              <Route
                path={ProjectPath.WALLET_TRANSFER}
                element={<ProtectedRoute element={TransactionPage} />}
              />
              <Route
                path={ProjectPath.WALLET_SELL}
                element={<ProtectedRoute element={TransactionPage} />}
              />
              <Route
                path={`${ProjectPath.WALLET_SWAP}/:currency`}
                element={<ProtectedRoute element={TransactionPage} />}
              />
              <Route
                path={`${ProjectPath.WALLET_NFT}/:tokenAddress/:tokenId`}
                element={<ProtectedRoute element={ItemPage} />}
              />
              <Route
                path={`${ProjectPath.INVITE}`}
                element={<ProtectedRoute element={ReferralPage} />}
              />
              <Route
                path={`${ProjectPath.GAME_SHOP}`}
                element={<ProtectedRoute element={GameShopPage} />}
              />
              <Route
                path={`${ProjectPath.BURN_PASS}`}
                element={<ProtectedRoute element={BurnPassPage} />}
              />

              <Route path="*" element={<MarketPage />} />
            </Route>
          </Route>,
        ),
      ),
    [cookie],
  );

  return <RouterProvider router={router} />;
}

export default Router;
