import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getProfile } from "api/user";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { useAppDispatch } from "hooks/store";
import { dropAuthToken } from "store/appStateSlice";
import { showErrors } from "utils/showErrors";
import deleteCookie from "utils/deleteCookie";

import ApiError from "classes/ApiError";
import { RestructuredGetProfileResponse } from "types/responses";
import {
  setEmail,
  setHasGenesis,
  setHasSubscription,
} from "store/userProfileSlice";

const useGetProfile = () => {
  const dispatch = useAppDispatch();
  const cookie = useGetAuthCookie();

  const queryInfo = useQuery<RestructuredGetProfileResponse, Error>(
    ["profile"],
    async () => {
      return getProfile();
    },
    {
      enabled: Boolean(cookie),
    },
  );

  useEffect(() => {
    dispatch(setEmail(queryInfo?.data?.email ?? ""));
  }, [queryInfo?.data?.email, dispatch]);

  useEffect(() => {
    dispatch(setHasSubscription(queryInfo?.data?.have_subscription));
  }, [queryInfo?.data?.have_subscription, dispatch]);

  useEffect(() => {
    dispatch(setHasGenesis(queryInfo?.data?.is_genesis));
  }, [queryInfo?.data?.is_genesis, dispatch]);

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      if (queryInfo.error.status === 403) {
        if (cookie) {
          deleteCookie();
        }
        dispatch(dropAuthToken());
      }
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error, cookie, dispatch]);

  return queryInfo;
};

export default useGetProfile;
