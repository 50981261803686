/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import ComplexNavigationElement from "./parts/ComplexNavigationElement";
import SimpleNavigationElement from "./parts/SimpleNavigationElement";

import { IComplexNavigationElement, INavigationItem } from "./types";

const isComplexNavigationElement = (
  element: INavigationItem,
): element is IComplexNavigationElement => {
  return (element as IComplexNavigationElement).subItems !== undefined;
};

function NavigationElement(props: INavigationItem) {
  if (isComplexNavigationElement(props)) {
    return <ComplexNavigationElement {...props} />;
  }

  return <SimpleNavigationElement {...props} />;
}

export default NavigationElement;
