import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "components/common/Image";
import { TFuncKey, useTranslation } from "react-i18next";
import cn from "classnames";
import { ImageType } from "../Image/Image";

import s from "./BannerSlider.module.scss";

type Slider = {
  id: number;
  title: TFuncKey | string;
  text: TFuncKey | string;
  image: ImageType;
  contentClassName?: string;
  titleClassName?: string;
  textClassName?: string;
};
type BannerSliderProps = {
  sliders: Slider[];
  className?: string;
};
function BannerSlider({ sliders, className = "" }: BannerSliderProps) {
  const { t } = useTranslation();
  return (
    <Swiper className={cn(s.slider, className)}>
      {!!sliders.length &&
        sliders.map(
          (
            {
              id,
              image,
              title,
              text,
              textClassName = "",
              titleClassName = "",
              contentClassName = "",
            },
            index,
          ) => {
            const translatedTitle =
              typeof title === "string" ? title : t(title);
            const translatedText = typeof text === "string" ? text : t(text);
            return (
              <SwiperSlide className={s.slide} key={id}>
                <div className={cn(s.slideContent, contentClassName)}>
                  <div className={cn(s.title, titleClassName)}>
                    {translatedTitle}
                  </div>
                  <div className={cn(s.description, textClassName)}>
                    {translatedText}
                  </div>
                </div>
                <Image
                  key={image}
                  image={image}
                  wrapperClassName={s.banner}
                  fetchpriority={index === 0 ? "high" : "auto"}
                />
              </SwiperSlide>
            );
          },
        )}
    </Swiper>
  );
}

export default BannerSlider;
