import boy from "assets/images/avatars/boy.webp";
import girl from "assets/images/avatars/girl.webp";
import TextField from "components/common/TextField";
import { Formik, FormikHelpers, FormikProps } from "formik";
import useGetProfile from "hooks/requests/useGetProfile";
import useSignUp from "hooks/requests/useSignUp";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  changeAuthMnemonic,
  changeAuthModalStage,
  endRegistration,
} from "store/modalsSlice";
import * as Yup from "yup";
import ModalButton from "../ModalButton";

import s from "./ProfileInfoContent.module.scss";

interface ValuesType {
  name: string;
  nick: string;
}

const initialValues = {
  name: "",
  nick: "",
};

function ProfileInfoContent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: profile } = useGetProfile();
  const { mutateAsync: signUp } = useSignUp();
  const [gender, setGender] = useState<"boy" | "girl">("boy");
  const referralCode = useAppSelector(
    (state) => state.userProfile.referralCode,
  );
  const MAX_LENGTH = 64;

  const toggleGenderBoy = () => {
    setGender("boy");
  };
  const toggleGenderGirl = () => {
    setGender("girl");
  };

  const onSubmit = useCallback(
    async ({ name, nick }: ValuesType, actions: FormikHelpers<ValuesType>) => {
      actions.setSubmitting(true);
      if (!profile || !name || !nick) {
        actions.setSubmitting(false);
        return;
      }
      const data = await signUp({
        name,
        nick: nick.substring(1),
        characterId: gender === "boy" ? 0 : 1,
        referralCode,
      });
      if (data.mnemonic) {
        dispatch(changeAuthMnemonic(data.mnemonic));
        dispatch(changeAuthModalStage("mnemonic"));
      } else {
        enqueueSnackbar({
          variant: "success",
          message: "Registration successfully completed",
        });
        dispatch(endRegistration());
      }
      window.gtag("event", "signed_up", {
        event_category: "sign_up",
      });
      actions.setSubmitting(false);
    },
    [gender, profile, dispatch, referralCode, signUp],
  );

  const formDataSchema: Yup.ObjectSchema<ValuesType> = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9_ ]+$/,
        t("modals.profile.validationMessages.name.content"),
      )
      .min(3, t("modals.profile.validationMessages.name.min"))
      .max(MAX_LENGTH, t("modals.profile.validationMessages.name.max"))
      .required(t("modals.profile.validationMessages.name.required")),
    nick: Yup.string()
      .min(3, t("modals.profile.validationMessages.nickName.min"))
      .max(MAX_LENGTH, t("modals.profile.validationMessages.nickName.max"))
      .matches(
        /^@?[a-zA-Z0-9_]+$/,
        t("modals.profile.validationMessages.nickName.content"),
      )
      .required(t("modals.profile.validationMessages.nickName.required")),
  });

  return (
    <div className={s.modal}>
      <Formik
        initialValues={initialValues}
        validationSchema={formDataSchema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          dirty,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }: FormikProps<ValuesType>) => (
          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.formContainer}>
              {/* TODO: change translation 
               <div className={s.title}>{t("modals.profile.title")}</div>
              */}

              <div className={s.title}>Choose your avatar</div>
              <div className={s.avatars}>
                <button
                  type="button"
                  className={s.avatar}
                  onClick={toggleGenderBoy}
                >
                  <div
                    className={`${s.imageWrapper} ${
                      gender === "boy" ? s.chose : ""
                    }`}
                  >
                    <img src={boy} alt="boy" className="fill-cover" />
                  </div>
                  <div
                    className={`${s.radio} ${
                      gender === "boy" ? s.checked : ""
                    }`}
                  >
                    <div className={s.radioFiller} />
                  </div>
                </button>
                <button
                  type="button"
                  className={s.avatar}
                  onClick={toggleGenderGirl}
                >
                  <div
                    className={`${s.imageWrapper} ${
                      gender === "girl" ? s.chose : ""
                    }`}
                  >
                    <img src={girl} alt="girl" className="fill-cover" />
                  </div>
                  <div
                    className={`${s.radio} ${
                      gender === "girl" ? s.checked : ""
                    }`}
                  >
                    <div className={s.radioFiller} />
                  </div>
                </button>
              </div>
            </div>

            <div className={s.formContainer}>
              <div className={s.inputs}>
                <div className={s.input}>
                  <TextField
                    label={t("modals.profile.nameLabel")}
                    placeholder="Satoshi Nakamoto"
                    name="name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let modifiedValue = event.target.value;
                      modifiedValue = modifiedValue.trimStart();
                      modifiedValue = modifiedValue.replace(/\s{2,}/g, " ");
                      if (/\s.*\s/g.test(modifiedValue)) {
                        modifiedValue = modifiedValue.trimEnd();
                      }
                      setFieldValue("name", modifiedValue);
                    }}
                    onBlur={handleBlur}
                    value={values.name}
                    hasError={touched.name && Boolean(errors.name)}
                    maxValueLength={MAX_LENGTH}
                    type="text"
                  />
                  {errors.name && (
                    <div className={s.errorContainer}>
                      <div className={s.errorText}>
                        {errors.name.toString()}
                      </div>
                    </div>
                  )}
                </div>
                <div className={s.input}>
                  <TextField
                    label={t("modals.profile.userNameLabel")}
                    placeholder="@tracer"
                    name="nick"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const inputValue = event.target.value.trim();
                      const nickValue = inputValue.startsWith("@")
                        ? inputValue
                        : `@${inputValue}`;
                      setFieldValue("nick", nickValue);
                    }}
                    withStartLetter
                    onBlur={handleBlur}
                    value={values.nick}
                    hasError={touched.nick && Boolean(errors.nick)}
                    maxValueLength={MAX_LENGTH + 1}
                  />
                  {errors.nick && (
                    <div className={s.errorContainer}>
                      <div className={s.errorText}>
                        {errors.nick.toString()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={s.buttons}>
              <ModalButton
                className={s.submit}
                type="submit"
                isLoading={isSubmitting}
                disabled={
                  !dirty ||
                  Boolean(errors.name) ||
                  Boolean(errors.nick) ||
                  isSubmitting
                }
              >
                {t("common.next")}
              </ModalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileInfoContent;
