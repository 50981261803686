import React, { useEffect } from "react";
import { endRegistration, openAuthModal } from "store/modalsSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useGetProfile from "hooks/requests/useGetProfile";
import { dropAuthCaptchaSource } from "store/appStateSlice";
import { useLocation, useSearchParams } from "react-router-dom";

function AuthorizationWatcher() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { data: profile } = useGetProfile();
  const referralCode = useAppSelector(
    (state) => state.userProfile.referralCode,
  );
  const { logInModal } = useAppSelector((state) => state.modals);
  const location = useLocation();

  useEffect(() => {
    if (
      profile &&
      (!profile.name || !profile.nick) &&
      logInModal.stage !== "mnemonic"
    ) {
      if (referralCode) {
        dispatch(openAuthModal("profileInfo"));
      } else {
        dispatch(openAuthModal("referralCodeRegister"));
      }
    }

    if (
      profile &&
      profile.name &&
      profile.nick &&
      !profile.is_referred &&
      logInModal.stage !== "mnemonic"
    ) {
      dispatch(openAuthModal("referralCode"));
    }
    if (
      profile &&
      profile.name &&
      profile.nick &&
      profile.is_referred &&
      !logInModal.mnemonicString &&
      logInModal.isOpen
    ) {
      dispatch(endRegistration());
      dispatch(dropAuthCaptchaSource());
      const from = searchParams.get("from");
      if (location?.state?.from?.pathname) {
        window.location.href = location.state.from.pathname;
      } else if (from) {
        window.location.href = from;
      }
    }
  }, [logInModal, profile, referralCode, dispatch]);
  return null;
}

export default AuthorizationWatcher;
