import React from "react";
import { Link } from "react-router-dom";
import { IconsVariant } from "types/icons";
import Icon from "components/common/Icon";

import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

import s from "./ChainItem.module.scss";

interface ChainItemProps {
  title: string;
  link?: string;
  icon?: `${IconsVariant}`;
}

function ChainItem({
  title,
  link = undefined,
  icon = undefined,
}: ChainItemProps) {
  return (
    <div className={s.chainItem}>
      {icon && <Icon variant={icon} className={s.icon} />}
      <div className={s.chainTitle}>{capitalizeFirstLetter(title)}</div>
      {link && (
        <Link to={link} className="fill-link">
          {title}
        </Link>
      )}
    </div>
  );
}

export default ChainItem;
