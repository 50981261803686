import { Attribute, AttributesObject } from "types/listing";

const restructureAttributes = (attributes: Attribute[]): AttributesObject => {
  const obj: AttributesObject = {};
  attributes?.forEach((elem) => {
    obj[elem.trait_type] = { ...elem };
  });
  return obj;
};

export default restructureAttributes;
