import { FormikHelpers } from "formik";
import { useCallback } from "react";

export const regexJustNumbersAndDot = /^\d*\.?\d*$/;

const useHandleInputChange = () => {
  return useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      handleChange: FormikHelpers<string>["setFieldValue"],
    ) => {
      const { value, name } = e.target;
      if (value === "" || regexJustNumbersAndDot.test(value)) {
        handleChange(name, value);
      }
    },
    [],
  );
};

export default useHandleInputChange;
