import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import Icon from "components/common/Icon";
import { changeAuthModalStage } from "store/modalsSlice";
import { useTranslation } from "react-i18next";

import s from "./NewAuthSwitcher.module.scss";

type NewAuthSwitcherProps = {
  isRegistrationLocked?: boolean;
  isRegistration?: boolean;
  className?: string;
};

function NewAuthSwitcher({
  isRegistration = undefined,
  isRegistrationLocked = false,
  className = "",
}: NewAuthSwitcherProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { stage } = useAppSelector((state) => state.modals.logInModal);

  const switchToRegister = () => {
    dispatch(changeAuthModalStage("register"));
  };

  const switchToLogin = () => {
    dispatch(changeAuthModalStage("logIn"));
  };

  return (
    <>
      {isRegistrationLocked && isRegistration && (
        <div className={`${s.button} ${s.activeRegister} ${className}`}>
          <span className={s.text}>{t("common.registration")}</span>
          <Icon variant="gem" className={s.registerIcon} />
        </div>
      )}
      {isRegistrationLocked && !isRegistration && (
        <div className={`${s.button} ${s.activeLogin} ${className}`}>
          <span className={s.text}>{t("common.logIn")}</span>
          <Icon variant="logInSvg" className={s.loginIcon} />
        </div>
      )}
      {!isRegistrationLocked && (
        <div className={`${s.switcher} ${className}`}>
          <button
            type="button"
            className={`${s.button} ${stage === "logIn" ? s.activeLogin : ""}`}
            onClick={switchToLogin}
          >
            <span className={s.text}>{t("common.logIn")}</span>
            <Icon variant="logInSvg" className={s.loginIcon} />
          </button>
          <button
            type="button"
            className={`${s.button} ${
              stage === "register" ? s.activeRegister : ""
            }`}
            onClick={switchToRegister}
          >
            <span className={s.text}>{t("common.registration")}</span>
            <Icon variant="gem" className={s.registerIcon} />
          </button>
        </div>
      )}
    </>
  );
}

export default NewAuthSwitcher;
