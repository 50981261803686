import React from "react";
import cn from "classnames";

import Icon from "components/common/Icon";

import { useAppDispatch } from "hooks/store";
import useLogOut from "hooks/requests/useLogOut";
import { closeNavigationMenu } from "store/menusSlice";
import { useTranslation } from "react-i18next";

import s from "./LogOutButton.module.scss";

interface IProps {
  variant?: "default" | "filledDepthPurple";
}

function LogOutButton({ variant = "default" }: IProps) {
  const dispatch = useAppDispatch();
  const { mutateAsync: logOut } = useLogOut();
  const { t } = useTranslation();

  const onLogOut = () => {
    logOut();
    dispatch(closeNavigationMenu());
  };

  return (
    <button
      type="button"
      className={cn(s.button, s[variant])}
      onClick={onLogOut}
    >
      <span className={s.title}>{t("common.logOut")}</span>
      <Icon variant="logOut" className={s.icon} />
    </button>
  );
}

export default LogOutButton;
