import Coin from "components/Coin";
import Icon from "components/common/Icon";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { CoinType } from "types/coins";
import { IconsVariant } from "types/icons";
import roundTo from "utils/roundTo";
import digitalizeLongNumber from "../../utils/digitalizeLongNumber";

import s from "./BalanceContainer.module.scss";

enum Variant {
  default = "default",
  wallet = "wallet",
  modal = "modal",
}

interface BalanceContainerProps {
  value: number | string;
  valueUSD?: number;
  priceUSD?: number | string;
  currency: `${CoinType}`;
  variant?: `${Variant}`;
  endIcon?: `${IconsVariant}` | "";
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  coinIconWrapper?: string;
  approximately?: boolean;
  onClick?: () => void;
  numberAfterDot?: number;
  widget?: ReactNode;
}
function BalanceContainer({
  value,
  valueUSD = undefined,
  priceUSD = undefined,
  currency,
  variant = Variant.default,
  endIcon = "",
  loading = false,
  disabled = false,
  approximately = true,
  className = "",
  coinIconWrapper = "",
  onClick = undefined,
  numberAfterDot = 4,
  widget = undefined,
}: BalanceContainerProps) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={s.container}>
        <div className={s.skeleton}>
          <Skeleton height="100%" containerClassName="skeleton" />
        </div>
      </div>
    );
  }

  const content = (() => {
    if (variant === Variant.wallet) {
      return (
        <>
          <div className={s.leftPart}>
            <Coin
              currency={currency}
              variant="outerRing"
              wrapperClassName={coinIconWrapper || s.coinIconWrapper}
              className={s.coinIcon}
            />

            <div className={`${s.currency} uppercase`}>
              <span className={s.name}>{currency}</span>

              {priceUSD && (
                <span className={s.balance}>
                  {approximately && "~"}$
                  {digitalizeLongNumber(
                    roundTo(priceUSD, numberAfterDot, true),
                  )}
                </span>
              )}
            </div>
            {widget && <div>{widget}</div>}
          </div>

          <div className={s.rightPart}>
            <div className={`${s.currency} uppercase`}>
              <span className={s.name}>
                {value} <span className="uppercase">{currency}</span>
              </span>

              {!!valueUSD && valueUSD > 0 && (
                <span className={s.balance}>
                  ~$
                  {digitalizeLongNumber(
                    roundTo(valueUSD, numberAfterDot, true),
                  )}
                </span>
              )}
            </div>

            {endIcon && (
              <div className={s.endIcon}>
                <Icon variant={endIcon} className={s.icon} />
              </div>
            )}
          </div>
        </>
      );
    }

    if (variant === Variant.modal) {
      return (
        <>
          <div className={s.balancePart}>
            <div className={`${s.currency} uppercase`}>
              <span className={s.name}>{currency}</span>
            </div>

            <div className={s.balance}>
              {currency === CoinType.nft ? (
                <b>{value}</b>
              ) : (
                <>
                  {t("common.balance")}: <b>{value}</b>
                </>
              )}
            </div>
          </div>

          <Coin
            currency={currency}
            className={s.balanceCoin}
            variant="outerRing"
          />
        </>
      );
    }

    return (
      <>
        <div className={s.leftPart}>
          <Coin currency={currency} variant="outerRing" />

          <div className={`${s.currency} uppercase`}>
            <span className={s.name}>{currency}</span>
          </div>
        </div>

        <div className={s.rightPart}>
          <div className={s.value}>
            {t("common.balance")}: <b>{value}</b>
          </div>

          {endIcon && (
            <div className={s.endIcon}>
              <Icon variant={endIcon} className={s.icon} />
            </div>
          )}
        </div>
      </>
    );
  })();

  if (onClick) {
    return (
      <button
        type="button"
        className={`${s.container} ${s[variant]} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
    );
  }

  return (
    <div className={`${s.container} ${s[variant]} ${className}`}>{content}</div>
  );
}

export default BalanceContainer;
