import TelegramTabContext from "helpers/TelegramTabContextProvider";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { closeAuthModal } from "store/modalsSlice";
import ModalContainer from "../ModalContainer";
import LogInContent from "./LogInContent";
import MnemonicContent from "./MnemonicContent";
import ProfileInfoContent from "./ProfileInfoContent";
import ReferralCodeContent from "./ReferralCodeContent";
import RegisterContent from "./RegisterContent";
import RegistrationModalContainer from "./RegistrationModalContainer";
import VerifyCodeContent from "./VerifyCodeContent";
import VerifyTelegram from "./VerifyTelegram";

import s from "./AuthModal.module.scss";

function AuthModal() {
  const dispatch = useAppDispatch();
  const tgAuthTab = useRef<null | Window>(null);
  const { logInModal } = useAppSelector((state) => state.modals);
  const [registerState, setRegisterState] = useState<"login" | "registration">(
    "login",
  );

  useEffect(() => {
    if (logInModal.stage === "logIn") {
      setRegisterState("login");
    } else if (logInModal.stage === "register") {
      setRegisterState("registration");
    }
  }, [logInModal.stage]);

  const close = useCallback(() => {
    if (registerState === "registration") dispatch(closeAuthModal("register"));
    if (registerState === "login") dispatch(closeAuthModal("logIn"));
  }, [dispatch, registerState]);

  const withButton = useMemo(() => {
    if (
      logInModal.stage === "profileInfo" ||
      logInModal.stage === "mnemonic" ||
      logInModal.stage === "referralCode" ||
      logInModal.stage === "referralCodeRegister"
    ) {
      return false;
    }
    return true;
  }, [logInModal.stage]);

  const isClosable = useMemo(() => {
    if (
      logInModal.stage === "profileInfo" ||
      logInModal.stage === "mnemonic" ||
      logInModal.stage === "referralCode" ||
      logInModal.stage === "referralCodeRegister"
    ) {
      return false;
    }
    return true;
  }, [logInModal.stage]);

  return (
    <TelegramTabContext.Provider value={tgAuthTab}>
      {logInModal.stage === "profileInfo" || logInModal.stage === "mnemonic" ? (
        <ModalContainer
          open={logInModal.isOpen}
          close={close}
          withButton={withButton}
          disabled={!isClosable}
          modalClassName={s.modal}
        >
          {logInModal.stage === "profileInfo" && <ProfileInfoContent />}
          {logInModal.stage === "mnemonic" && <MnemonicContent />}
        </ModalContainer>
      ) : (
        <RegistrationModalContainer
          open={logInModal.isOpen}
          close={close}
          withButton={withButton}
          disabled={!isClosable}
          modalContentClassName={
            logInModal.stage === "referralCode" ||
            logInModal.stage === "referralCodeRegister"
              ? s.refCodeModal
              : ""
          }
        >
          {logInModal.stage === "logIn" && <LogInContent />}
          {logInModal.stage === "register" && <RegisterContent />}
          {logInModal.stage === "verifyCode" && (
            <VerifyCodeContent
              isRegistration={registerState === "registration"}
            />
          )}
          {logInModal.stage === "verifyTelegramCode" && (
            <VerifyTelegram isRegistration={registerState === "registration"} />
          )}
          {(logInModal.stage === "referralCode" ||
            logInModal.stage === "referralCodeRegister") && (
            <ReferralCodeContent />
          )}
        </RegistrationModalContainer>
      )}
    </TelegramTabContext.Provider>
  );
}

export default AuthModal;
