import React, { useCallback, useEffect, useState } from "react";
import { NewLanguages, NewLanguage, Languages } from "types/languages";
import { useTranslation } from "react-i18next";
import Icon from "components/common/Icon";
import { AnimatePresence, motion } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { selectorSlide } from "utils/motion";

import america from "assets/images/languages/america.svg";
// import china from "assets/images/languages/china.svg";
// import korea from "assets/images/languages/korea.svg";
import indonesia from "assets/images/languages/indonesia.svg";
import spanish from "assets/images/languages/spanish.svg";
import russia from "assets/images/languages/russia.svg";
import ukraine from "assets/images/languages/ukraine.svg";

import poland from "assets/images/languages/poland.svg";
import portugal from "assets/images/languages/portugal.svg";
import turkey from "assets/images/languages/turkey.svg";

import s from "./NewLanguageSelector.module.scss";

type FlagImports = {
  [key: string]: string;
};

const flagImports: FlagImports = {
  america,
  indonesia,
  spanish,
  russia,
  ukraine,
  poland,
  portugal,
  turkey,
};

const languages: NewLanguage[] = [
  {
    icon: "america",
    language: NewLanguages.EN,
    abbreviation: Languages.EN,
  },
  {
    icon: "spanish",
    language: NewLanguages.ES,
    abbreviation: Languages.ES,
  },
  {
    icon: "indonesia",
    language: NewLanguages.ID,
    abbreviation: Languages.ID,
  },
  // {
  //   icon: "korea",
  //   language: NewLanguages.KO,
  //   abbreviation: Languages.KO,
  // },
  // {
  //   icon: "china",
  //   language: NewLanguages.ZH,
  //   abbreviation: Languages.ZH,
  // },
  {
    icon: "russia",
    language: NewLanguages.RU,
    abbreviation: Languages.RU,
  },
  {
    icon: "ukraine",
    language: NewLanguages.UA,
    abbreviation: Languages.UA,
  },
  {
    icon: "portugal",
    language: NewLanguages.PT,
    abbreviation: Languages.PT,
  },
  {
    icon: "poland",
    language: NewLanguages.PL,
    abbreviation: Languages.PL,
  },
  {
    icon: "turkey",
    language: NewLanguages.TR,
    abbreviation: Languages.TR,
  },
];

interface IProps {
  variant?: "default" | "mobile";
}

function NewLanguageSelector({ variant = "default" }: IProps) {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggler = () => {
    setIsOpen((prev) => !prev);
  };

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onChangeLanguage = (lang: string) => () => {
    window.localStorage.setItem("lng", lang);
    i18n.changeLanguage(lang).then().catch();
  };

  const getFlagImport = (icon: string) => {
    return flagImports[icon];
  };

  return (
    <div className={`${s.languageSelector} ${s[variant]}`}>
      <button type="button" onClick={toggler} className={s.selector}>
        <span className={s.value}>{i18n.language}</span>
        <Icon
          variant="arrowDown"
          className={`${s.arrowIcon} ${isOpen ? s.open : ""}`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <ClickAwayListener onClickAway={close}>
            <motion.div
              variants={selectorSlide()}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={s.menu}
            >
              {languages.map((lang) => (
                <button
                  type="button"
                  className={`${s.item} ${
                    lang.abbreviation === i18n.language ? s.active : ""
                  }`}
                  key={lang.language}
                  onClick={onChangeLanguage(lang.abbreviation)}
                >
                  <div className={s.langIconWrapper}>
                    <img src={getFlagImport(lang.icon)} alt={lang.icon} />
                  </div>
                  <p className={s.langTitle}>{lang.language}</p>
                </button>
              ))}
            </motion.div>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </div>
  );
}

export default NewLanguageSelector;
