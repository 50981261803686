import React from "react";
import Skeleton from "react-loading-skeleton";

import Icon from "components/common/Icon";

import { IconsVariant } from "types/icons";

import s from "./FilterButton.module.scss";

interface FilterButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  iconVariant?: `${IconsVariant}`;
  isLoading?: boolean;
  className?: string;
  description?: string;
}

function FilterButton({
  title,
  iconVariant = undefined,
  isLoading = false,
  className = "",
  description = "",
  ...props
}: FilterButtonProps) {
  const filterClassName = `${s.filterElement} ${className || className}`;

  if (isLoading) {
    return (
      <div className={filterClassName}>
        <Skeleton height="100%" containerClassName="skeleton fill" />
      </div>
    );
  }

  return (
    <button type="button" className={filterClassName} {...props}>
      <div className={s.leftPart}>
        <div className={s.title}>{title}</div>
        {description && <div className={s.description}>{description}</div>}
      </div>
      {iconVariant && (
        <Icon
          variant={iconVariant}
          className={s.icon}
          wrapperClassName={s.iconWrapper}
          withWrapper
        />
      )}
    </button>
  );
}

export default FilterButton;
