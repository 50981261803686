import React, { useCallback, useMemo } from "react";

import useGetBalance from "hooks/requests/useGetBalance";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  changeBalanceModalStage,
  closeBalanceModal,
  setResultTaskRequest,
} from "store/modalsSlice";
import { CoinType } from "types/coins";

import BalanceContainer from "components/BalanceContainer";

import s from "./MainContent.module.scss";

function BalanceModal() {
  const dispatch = useAppDispatch();
  const { data: balance, isLoading } = useGetBalance();
  const {
    balanceModal: {
      withACE,
      withMATIC,
      withNFT,
      withTRC,
      withUSDT,
      withWBTC,
      withWETH,
    },
  } = useAppSelector((state) => state.modals);

  const switchToNFTSelector = useCallback(() => {
    dispatch(changeBalanceModalStage("NFTSelector"));
  }, [dispatch]);

  const onBalanceClick = (coin: CoinType) => () => {
    dispatch(closeBalanceModal());
    dispatch(
      setResultTaskRequest({
        result: "resolved",
        data: { coin, chosen: undefined },
      }),
    );
  };

  const matic = balance?.maticConverted;
  const trc = balance?.trcConverted;
  const ace = balance?.aceConverted;
  const usdt = balance?.usdtConverted;
  const wbtc = balance?.wbtcConverted;
  const weth = balance?.wethConverted;

  const isNFTShouldBeShown = useMemo(() => {
    if (!withNFT) return false;
    if (withNFT.car && balance?.car?.total) return true;
    if (withNFT.pet && balance?.pet?.total) return true;
    if (withNFT.town && balance?.town?.total) return true;
    if (withNFT.pass && balance?.pass?.total) return true;
    if (withNFT.part && balance?.part?.total) return true;
    return false;
  }, [withNFT, balance]);

  const NFTAmount = useMemo(() => {
    let result = 0;
    if (!withNFT) return 0;
    if (withNFT.car && balance?.car?.total) result += balance.car.total;
    if (withNFT.pet && balance?.pet?.total) result += balance.pet.total;
    if (withNFT.town && balance?.town?.total) result += balance.town.total;
    if (withNFT.pass && balance?.pass?.total) result += balance.pass.total;
    if (withNFT.part && balance?.part?.total) result += balance.part.total;
    return result;
  }, [balance, withNFT]);

  return (
    <div className={s.modal}>
      <div className={s.content}>
        {isNFTShouldBeShown && (
          <button type="button" onClick={switchToNFTSelector}>
            <BalanceContainer
              currency={CoinType.nft}
              value={NFTAmount}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(ace)) && withACE && (
          <button type="button" onClick={onBalanceClick(CoinType.ace)}>
            <BalanceContainer
              currency={CoinType.ace}
              value={ace || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(trc)) && withTRC && (
          <button type="button" onClick={onBalanceClick(CoinType.trc)}>
            <BalanceContainer
              currency={CoinType.trc}
              value={trc || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(matic)) && withMATIC && (
          <button type="button" onClick={onBalanceClick(CoinType.matic)}>
            <BalanceContainer
              currency={CoinType.matic}
              value={matic || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(usdt)) && withUSDT && (
          <button type="button" onClick={onBalanceClick(CoinType.usdt)}>
            <BalanceContainer
              currency={CoinType.usdt}
              value={usdt || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(wbtc)) && withWBTC && (
          <button type="button" onClick={onBalanceClick(CoinType.wbtc)}>
            <BalanceContainer
              currency={CoinType.wbtc}
              value={wbtc || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
        {(isLoading || Boolean(weth)) && withWETH && (
          <button type="button" onClick={onBalanceClick(CoinType.weth)}>
            <BalanceContainer
              currency={CoinType.weth}
              value={weth || 0}
              variant="modal"
              loading={isLoading}
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default BalanceModal;
