/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import Icon from "components/common/Icon";
import NavigationBackground from "./NavigationBackground";
import NavigationLink from "../../../components/NavigationLink";
import SimpleNavigationElement from "./SimpleNavigationElement";
import { IComplexNavigationElement } from "../types";

import s from "../NavigationElement.module.scss";

function ComplexNavigationElement({
  title,
  icon,
  isResourceLocal,
  link,
  subItems,
  mustBeAuthorized,
}: IComplexNavigationElement) {
  const { pathname } = useLocation();
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const variants = {
    open: { opacity: 1, height },
    closed: { opacity: 1, height: 0 },
  };

  const isActive = useMemo(() => {
    if (pathname === link) return true;
    return false;
  }, [pathname, link]);
  const isOpen = useMemo(() => {
    if (isActive) return true;
    if (subItems.some((elem) => elem.link === pathname)) return true;
    return false;
  }, [pathname, isActive, subItems]);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
    }
  }, [isOpen]);

  return (
    <div className={`${s.navigationElementWrapper} ${isOpen ? s.isOpen : ""}`}>
      <div
        className={`${s.navigationElement} ${isActive ? s.isActive : ""} ${
          isOpen && !isActive ? s.isOpenNotActive : ""
        }`}
      >
        {icon && <Icon variant={icon} className={s.icon} />}
        <div className={s.title}>{title}</div>
        <Icon variant="arrowDown" className={s.iconMore} />
        <NavigationBackground />
        <NavigationLink
          title={title}
          link={link}
          isResourceLocal={isResourceLocal}
          mustBeAuthorized={mustBeAuthorized}
        />
      </div>

      <motion.div
        className={s.contentWrapper}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div className={s.content} ref={contentRef}>
          {subItems.map((elem) => (
            <SimpleNavigationElement {...elem} key={elem.id} />
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default ComplexNavigationElement;
