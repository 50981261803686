import React from "react";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { openAuthModal } from "store/modalsSlice";

import s from "./NavigationLink.module.scss";

interface Props {
  title: string;
  link: string;
  isResourceLocal: boolean;
  mustBeAuthorized: boolean;
  isTargetBlank?: boolean;
}

function NavigationLink({
  title,
  link,
  isResourceLocal,
  mustBeAuthorized,
  isTargetBlank = false,
}: Props) {
  const dispatch = useAppDispatch();
  const { authToken } = useAppSelector((state) => state.appState);

  if (mustBeAuthorized && !authToken) {
    const onClick = () => {
      dispatch(openAuthModal());
    };
    return (
      <button type="button" className={s.link} onClick={onClick}>
        {title}
      </button>
    );
  }

  if (isResourceLocal) {
    return (
      <Link
        className={s.link}
        to={link}
        target={isTargetBlank ? "_blank" : "_self"}
        rel={isTargetBlank ? "noopener noreferrer" : undefined}
      >
        {title}
      </Link>
    );
  }
  return (
    <a
      className={s.link}
      href={link}
      target={isTargetBlank ? "_blank" : "_self"}
      rel={isTargetBlank ? "noopener noreferrer" : undefined}
    >
      {title}
    </a>
  );
}

export default NavigationLink;
