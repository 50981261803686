/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import cn from "classnames";

import s from "./LabelContainer.module.scss";

interface IProps {
  children: React.ReactNode;
  label: string;
  className?: string;
  htmlFor?: string;
}

function LabelContainer({ children, label, className = "", ...props }: IProps) {
  return (
    <label className={cn(s.labelWrapper, className)} {...props}>
      <span className={s.label}>{label}</span>
      {children}
    </label>
  );
}

export default LabelContainer;
