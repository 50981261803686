import { isGetAuthTgLinkResponse } from "hooks/requests/useRequestAuth";
import useRequestTgConnectLink from "hooks/requests/useRequestTgConnectLink";
import useRequestVerifyTgConnection from "hooks/requests/useRequestVerifyTgConnection";
import React, { useContext } from "react";

function withConnectToTelegram<P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> {
  // eslint-disable-next-line react/function-component-definition
  return (props) => {
    const { data, isFetching, isError } = useRequestTgConnectLink();
    const { isChecking, startChecking, telegramId } =
      useRequestVerifyTgConnection();

    const handleClick = () => {
      if (data && isGetAuthTgLinkResponse(data)) {
        window.open(data.link ?? "", "_blank");
        setTimeout(() => startChecking(), 3000);
      }
    };

    if (telegramId) return null;

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(props as P)}
        onClick={handleClick}
        isLoading={isFetching || isChecking}
        disabled={isError}
      />
    );
  };
}

export default withConnectToTelegram;
