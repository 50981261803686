import React from "react";

import s from "./Checkbox.module.scss";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
  className?: string;
  onChange: () => void;
}

function Checkbox({
  label,
  checked,
  className = "",
  onChange,
  ...props
}: CheckboxProps) {
  return (
    <label className={s.checkboxWrapper}>
      <input
        {...props}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={`${s.checkbox} ${className} ${checked ? s.checked : ""}`}
      />
      <span className={s.label}>{label}</span>
    </label>
  );
}

export default Checkbox;
