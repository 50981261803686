import { ListingTabType, TokenAddress } from "types/listing";
import { RequestListingFilter } from "types/requests";

function createListingParams(
  appliedFilter: RequestListingFilter,
  tab: ListingTabType,
  collectionType?: TokenAddress,
) {
  return { listingType: tab, typeNft: collectionType, ...appliedFilter };
}

export default createListingParams;
