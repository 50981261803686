import React from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";

import { modalDrawer, modalFadeIn } from "utils/motion";

import s from "./ModalContainer.module.scss";

interface ModalContainerProps {
  children: React.ReactNode;
  open: boolean;
  theme?: "white" | "gray";
  disabled?: boolean;
  withButton?: boolean;
  modalClassName?: string;
  close: () => void;
}

function ModalContainer({
  children,
  open,
  theme = "gray",
  disabled = false,
  withButton = true,
  modalClassName = "",
  close,
}: ModalContainerProps) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {open && (
        <div
          className={`${s.modalContainer} ${s[theme]} ${
            withButton ? s.withButton : ""
          }`}
        >
          <motion.button
            variants={modalFadeIn()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            type="button"
            onClick={close}
            className={s.background}
            disabled={disabled}
          />
          <motion.div
            variants={window.innerWidth > 520 ? modalFadeIn() : modalDrawer()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`${s.modal} ${modalClassName ?? ""}`}
          >
            <div className={s.content}>{children}</div>
            {withButton && (
              <button
                type="button"
                onClick={close}
                className={s.close}
                disabled={disabled}
              >
                {t("common.close")}
              </button>
            )}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

export default ModalContainer;
