import { ChartLinesType } from "../../types";

const dataPoints: Array<Record<ChartLinesType | "year", number | null>> = [
  {
    year: 0.34,
    rewards: 0.4,
    ecosystem: 0.22,
    marketing: 0.156,
    team: 0.057,
    seed: 0.057,
    strategic: 0.052,
    advisors: 0.019,
    preSale: 0.018,
    ambassador: 0.009,
    publicSale: 0.002,
  },
  {
    year: 0.83,
    rewards: 1.049,
    ecosystem: 0.598,
    marketing: 0.419,
    team: 0.17,
    seed: 0.17,
    strategic: 0.152,
    advisors: 0.072,
    preSale: 0.045,
    ambassador: 0.023,
    publicSale: 0.006,
  },
  {
    year: 1,
    rewards: 1.264,
    ecosystem: 0.723,
    marketing: 0.505,
    team: 0.207,
    seed: 0.207,
    strategic: 0.184,
    advisors: 0.088,
    preSale: 0.052,
    ambassador: 0.026,
    publicSale: 0.006,
  },
  {
    year: 1.25,
    rewards: 1.642,
    ecosystem: 0.966,
    marketing: 0.69,
    team: 0.317,
    seed: 0.257,
    strategic: 0.227,
    advisors: 0.108,
    preSale: 0.059,
    ambassador: 0.0258,
    publicSale: 0.0008,
  },
  {
    year: 1.58,
    rewards: null,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: null,
    ambassador: null,
    publicSale: 0.001,
  },
  {
    year: 1.67,
    rewards: null,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: 0.077,
    ambassador: 0.034,
    publicSale: null,
  },
  {
    year: 2,
    rewards: 2.782,
    ecosystem: 1.701,
    marketing: 1.252,
    team: 0.655,
    seed: 0.415,
    strategic: 0.364,
    advisors: 0.176,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 2.08,
    rewards: 2.907,
    ecosystem: 1.781,
    marketing: 1.312,
    team: 0.69,
    seed: 0.43,
    strategic: 0.377,
    advisors: 0.181,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 2.42,
    rewards: 3.296,
    ecosystem: 1.989,
    marketing: 1.443,
    team: 0.821,
    seed: 0.481,
    strategic: 0.419,
    advisors: 0.199,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 3,
    rewards: 3.932,
    ecosystem: 2.31,
    marketing: 1.629,
    team: 1.007,
    seed: 0.527,
    strategic: 0.449,
    advisors: 0.229,
    preSale: 0.085,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 3.08,
    rewards: 4.026,
    ecosystem: 2.359,
    marketing: 1.658,
    team: 1.036,
    seed: 0.536,
    strategic: 0.455,
    advisors: 0.235,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 3.17,
    rewards: 4.093,
    ecosystem: 2.381,
    marketing: 1.661,
    team: 1.039,
    seed: 0.539,
    strategic: 0.456,
    advisors: 0.236,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  // this point is added for beauty of the chart
  {
    year: 3.8,
    rewards: 4.4,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: null,
    ambassador: null,
    publicSale: null,
  },
  {
    year: 4,
    rewards: 4.758,
    ecosystem: 2.596,
    marketing: 1.683,
    team: 1.061,
    seed: 0.561,
    strategic: 0.455,
    advisors: 0.235,
    preSale: 0.085,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 4.25,
    rewards: 4.954,
    ecosystem: 2.656,
    marketing: 1.691,
    team: 1.069,
    seed: 0.569,
    strategic: 0.456,
    advisors: 0.236,
    preSale: 0.086,
    ambassador: 0.043,
    publicSale: 0.01,
  },
  {
    year: 4.37,
    rewards: null,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: null,
    ambassador: null,
    publicSale: null,
  },
  {
    year: 4.5,
    rewards: null,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: null,
    ambassador: null,
    publicSale: null,
  },
  {
    year: 4.7,
    rewards: null,
    ecosystem: null,
    marketing: null,
    team: null,
    seed: null,
    strategic: null,
    advisors: null,
    preSale: null,
    ambassador: null,
    publicSale: null,
  },
  {
    year: 5,
    rewards: 5,
    ecosystem: 2.657,
    marketing: 1.692,
    team: 1.07,
    seed: 0.57,
    strategic: 0.455,
    advisors: 0.235,
    preSale: 0.085,
    ambassador: 0.043,
    publicSale: 0.01,
  },
];

export default dataPoints;
