import { useQuery } from "@tanstack/react-query";

import { getReferralList } from "api/user";
import { GetReferralListResponse } from "types/responses";

const useGetReferralList = () => {
  return useQuery<GetReferralListResponse, Error>(
    ["referral", "list"],
    async () => {
      return getReferralList();
    },
  );
};

export default useGetReferralList;
