import React from "react";
import { useAppDispatch } from "hooks/store";

import Icon from "components/common/Icon";

import { closeNavigationMenu } from "store/menusSlice";
import ProjectPath from "constants/paths";

import logo from "assets/images/logo.svg";

import s from "./TopControlPart.module.scss";

function TopControlPart() {
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(closeNavigationMenu());
  };

  return (
    <div className={s.topControlPart}>
      <div className={s.logoWrapper}>
        <img src={logo} alt="logo" className={s.logo} />
        <a
          href={`${ProjectPath.REAL_WORLD}`}
          className="fill-link"
          target="__blank"
        >
          main site
        </a>
      </div>
      <button type="button" className={s.close} onClick={close}>
        <Icon variant="close" />
      </button>
    </div>
  );
}

export default TopControlPart;
