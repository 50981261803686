export type ChartLinesType =
  | "marketing"
  | "publicSale"
  | "preSale"
  | "ambassador"
  | "strategic"
  | "seed"
  | "advisors"
  | "team"
  | "rewards"
  | "ecosystem";

export const linesMap: Record<ChartLinesType, ChartLinesType> = {
  rewards: "rewards",
  ecosystem: "ecosystem",
  marketing: "marketing",
  team: "team",
  seed: "seed",
  strategic: "strategic",
  advisors: "advisors",
  preSale: "preSale",
  ambassador: "ambassador",
  publicSale: "publicSale",
};

export const AreaStrokeColorMap: Record<ChartLinesType, string> = {
  rewards: "#52A8FE",
  ecosystem: "#7663CC",
  marketing: "#AA47BC",
  team: "#4136C3",
  seed: "#12B0A7",
  strategic: "#FE7009",
  advisors: "#FEB800",
  preSale: "#109D58",
  ambassador: "#E1574A",
  publicSale: "#9E480D",
};

export const linesMapLegend: Record<ChartLinesType, string> = {
  marketing: "Marketing & In-game events",
  publicSale: "Public Sale",
  preSale: "Pre-Sale",
  ambassador: "Ambassador allocation",
  strategic: "Strategic Sale",
  seed: "Seed",
  advisors: "Advisors",
  team: "Team",
  rewards: "Rewards",
  ecosystem: "Ecosystem & Liquidity",
};
