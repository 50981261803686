import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import ProjectPath from "constants/paths";

type PageName =
  | "Market"
  | "MyMarket"
  | "Wallet"
  | "Item"
  | "Transfer"
  | "Send"
  | "Sell"
  | "Swap"
  | "Lootboxes"
  | "Lootbox"
  | "GameShop"
  | "Unknown";

interface PageObject {
  isMarket: boolean;
  isWallet: boolean;
  isItem: boolean;
  isSendAction: boolean;
  isSellAction: boolean;
  isTransferAction: boolean;
  isSwapAction: boolean;
  isLootboxes: boolean;
  isLootbox: boolean;
  isGameShop: boolean;
  pageName: PageName;
}

const getPageObject = (pathname: string): PageObject => {
  const isMarket = pathname === `/${ProjectPath.MARKET}`;
  const isWallet = pathname.startsWith(`/${ProjectPath.WALLET}`);
  const isSendAction = pathname.startsWith(`/${ProjectPath.WALLET_SEND}`);
  const isSellAction = pathname.startsWith(`/${ProjectPath.WALLET_SELL}`);
  const isTransferAction = pathname.startsWith(
    `/${ProjectPath.WALLET_TRANSFER}`,
  );
  const isSwapAction = pathname.startsWith(`/${ProjectPath.WALLET_SWAP}`);
  const isItem = pathname.startsWith(`/${ProjectPath.MARKET_ITEM}`);
  const isLootboxes = pathname.startsWith(`/${ProjectPath.LOOTBOXES}`);
  const isLootbox = pathname.startsWith(`/${ProjectPath.LOOTBOXES}/`);
  const isGameShop = pathname.startsWith(`/${ProjectPath.GAME_SHOP}`);

  let pageName: PageName = "Unknown";
  if (isMarket) {
    pageName = "Market";
  } else if (isWallet) {
    pageName = "Wallet";
  } else if (isSendAction) {
    pageName = "Send";
  } else if (isSwapAction) {
    pageName = "Swap";
  } else if (isTransferAction) {
    pageName = "Transfer";
  } else if (isSellAction) {
    pageName = "Sell";
  } else if (isItem) {
    pageName = "Item";
  } else if (isLootboxes) {
    pageName = "Lootboxes";
  } else if (isGameShop) {
    pageName = "GameShop";
  }

  return {
    isMarket,
    isWallet,
    isItem,
    isSendAction,
    isSellAction,
    isTransferAction,
    isSwapAction,
    isLootboxes,
    isLootbox,
    isGameShop,
    pageName,
  };
};

const useWhichPage = () => {
  const { pathname } = useLocation();

  const pageObject = useMemo(() => getPageObject(pathname), [pathname]);

  return pageObject;
};

export default useWhichPage;
