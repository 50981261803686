import {
  GetBalanceResponse,
  RestructuredBalanceItemResponse,
} from "types/responses";
import { ListedListing, RawListedListing, TokenAddress } from "types/listing";
import restructureAttributes from "./restructureAttributes";
import { convertDecimalTokenShort } from "./convertDecimalToken";

const restructureListing = (
  listing: RawListedListing,
  type: `${TokenAddress}`,
): ListedListing => {
  return {
    ...listing,
    token_address: type,
    user_id: "my",
    priceConverted: convertDecimalTokenShort(listing.price, listing.price_type),
  };
};

const restructureBalanceItemResponse = (
  data: GetBalanceResponse,
  type: `${TokenAddress}`,
): RestructuredBalanceItemResponse => {
  const result = data?.[type].token_metadata?.map((elem) => ({
    ...elem,
    metadata: {
      ...elem.metadata,
      listings: elem.metadata.listings?.map((listing) =>
        restructureListing(listing, type),
      ),
      attributesObj: restructureAttributes(elem.metadata.attributes),
    },
  }))[0];

  return result;
};

export default restructureBalanceItemResponse;
