/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import { IconColor, IconMapKey, IconsVariant } from "types/icons";
import svgIconMap from "./constants";
import s from "./Icon.module.scss";

interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant: `${IconsVariant}`;
  className?: string;
  wrapperClassName?: string;
  withWrapper?: boolean;
  color?: IconColor | "";
}

function Icon({
  variant,
  className = "",
  wrapperClassName = "",
  withWrapper = false,
  color = "",
  ...props
}: IconProps) {
  const icon = useMemo(() => {
    if (variant === IconsVariant.settings) {
      return (
        <span className={`icon-${variant} ${className}`} {...props}>
          <span className="path1" />
          <span className="path2" />
        </span>
      );
    }
    if (variant === IconsVariant.arrowsIn) {
      return (
        <span className={`icon-${variant} ${className}`} {...props}>
          <span className="path1" />
          <span className="path2" />
        </span>
      );
    }
    if (svgIconMap[variant as IconMapKey]) {
      return svgIconMap[variant as IconMapKey](className);
    }

    return <span className={`icon-${variant} ${className}`} {...props} />;
  }, [variant, className, props]);

  if (withWrapper) {
    return (
      <div className={`${s.iconWrapper} ${wrapperClassName} ${s[color]}`}>
        {icon}
      </div>
    );
  }
  return icon;
}

export default Icon;
