import { useQuery, useQueryClient } from "@tanstack/react-query";
import { verifyAuthTg } from "api/auth";
import TG_CODE from "constants/telegram";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "store/appStateSlice";
import getAuthCookie from "utils/getAuthCookie";
import { showErrors } from "utils/showErrors";

const useRequestAuthVerifyTg = () => {
  const dispatch = useDispatch();
  const code = sessionStorage.getItem(TG_CODE);
  const queryClient = useQueryClient();

  const queryInfo = useQuery({
    queryKey: ["verifyAuthTg"],
    queryFn: () => verifyAuthTg({ code: code ?? "" }),
    enabled: false,
    onError: showErrors,
  });

  useEffect(() => {
    if (queryInfo.data) {
      const cookie = getAuthCookie();
      if (cookie) dispatch(setAuthToken(cookie));
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      queryClient.removeQueries(["authTgLink"]);
    }
  }, [queryInfo, queryClient, dispatch]);

  return queryInfo;
};

export default useRequestAuthVerifyTg;
