import Icon from "components/common/Icon";
import { useAppDispatch } from "hooks/store";
import React from "react";
import { useTranslation } from "react-i18next";
import { setIsCaptchaOpen } from "store/appStateSlice";
import { openAuthModal } from "store/modalsSlice";

import s from "./RegistrationButton.module.scss";

function RegistrationButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onAuthModalRegister = () => {
    dispatch(openAuthModal("register"));
    dispatch(setIsCaptchaOpen(true));
  };

  return (
    <button type="button" onClick={onAuthModalRegister} className={s.register}>
      <span className={s.registerText}>
        {t("common.registration").toUpperCase()}
      </span>
      <Icon variant="gem" className={s.registerIcon} />
    </button>
  );
}

export default RegistrationButton;
