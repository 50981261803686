/* eslint-disable import/prefer-default-export */

import {
  GetProfileResponse,
  RestructuredGetProfileResponse,
} from "types/responses";

export const restructureProfileResponse = (
  data: GetProfileResponse,
): RestructuredGetProfileResponse => {
  // Проверяем, есть ли поле character
  if (!data.character) return { ...data, character: "0" };

  // Удаляем лишние слеши
  const cleanedCharacter = data.character.replace(/\\/g, "");

  try {
    // Пытаемся разобрать строку как JSON
    const parsed = JSON.parse(cleanedCharacter);

    // Проверяем, соответствует ли id '0' или '1' и возвращаем соответствующее значение
    if (parsed.id === "0" || parsed.id === "1") {
      return { ...data, character: parsed.id };
    }
    // Если id не '0' и не '1', возвращаем "0" по умолчанию
    return { ...data, character: "0" };
  } catch (e) {
    // В случае ошибки при разборе JSON возвращаем "0" по умолчанию
    console.error("Ошибка при разборе строки character: ", e);
    return { ...data, character: "0" };
  }
};
