import React, { useCallback, useState } from "react";

import useGetProfile from "hooks/requests/useGetProfile";

import Icon from "components/common/Icon";
import Avatar from "components/Avatar";
import ProfileCard from "../ProfileCard";

import s from "./ProfileInformation.module.scss";

function ProfileInformation() {
  const { data: profile } = useGetProfile();
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const close = useCallback(() => {
    setIsOpenProfile(false);
  }, []);

  const handleOpenProfile = useCallback(() => {
    setIsOpenProfile((prev) => !prev);
  }, []);

  return (
    <div className={s.profileSide}>
      <div className={s.controlPanel}>
        {profile && (
          <div className={s.userWrapper}>
            <div className={s.userWrapperOpen}>
              <div className={s.nickname}>@{profile?.nick}</div>
              <button
                type="button"
                onClick={handleOpenProfile}
                className={s.openProfile}
              >
                {!profile?.is_genesis && (
                  <p className={s.openProfileText}>Open profile</p>
                )}
                {profile?.is_genesis && (
                  <p className={s.openProfileText}>
                    Genesis: <b>{profile?.genesis_token_id}</b>
                  </p>
                )}
                <Icon variant="arrowDown" wrapperClassName={s.openIcon} />
              </button>
            </div>
            <Avatar
              gender={profile?.character}
              wrapperClassName={s.avatar}
              subscribed={profile?.have_subscription}
              isGenesis={profile?.is_genesis}
            />
          </div>
        )}
      </div>
      <ProfileCard isOpen={isOpenProfile} close={close} />
    </div>
  );
}

export default ProfileInformation;
