import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListingTabType } from "types/listing";

export interface InitialState {
  tab: ListingTabType;
}
const initialState: InitialState = {
  tab: "all",
};

export const marketCollectionTabSlice = createSlice({
  name: "marketCollectionTab",
  initialState,
  reducers: {
    updateMarketCollectionTab(state, action: PayloadAction<InitialState>) {
      state.tab = action.payload.tab;
    },
  },
});

export const { updateMarketCollectionTab } = marketCollectionTabSlice.actions;

export const marketCollectionTabReducer = marketCollectionTabSlice.reducer;
