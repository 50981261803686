import React from "react";

import { convertDecimalTokenShort } from "utils/convertDecimalToken";
import { ILootboxItem } from "types/lootboxes";

import s from "./LootboxGift.module.scss";

interface IProps {
  gift: ILootboxItem;
}

const imagesDomain = process.env.REACT_APP_GIFTS_URL_DOMAIN || "";

function LootboxGift({ gift }: IProps) {
  if (gift.entity === "coin") {
    return (
      <div className={`${s.gift} ${s[gift.currency]}`}>
        <div className={s.imageWrapper}>
          <img
            src={`${imagesDomain}${gift.image_id}`}
            alt=""
            className={s.image}
          />
        </div>
        <div className={s.name}>
          {convertDecimalTokenShort(gift.amount, gift.currency)} $
          <span className={s.currency}>{gift.currency}</span>
        </div>
      </div>
    );
  }
  return (
    <div className={`${s.gift} ${s[gift.type]}`}>
      <div className={s.imageWrapper}>
        <img
          src={`${imagesDomain}${gift.image_id}`}
          alt=""
          className={s.image}
        />
      </div>
      <div className={s.name}>{gift.name}</div>
    </div>
  );
}

export default LootboxGift;
