import React from "react";

import Button from "components/common/Button";

import { useAppDispatch } from "hooks/store";
import { openTokensModal } from "store/modalsSlice";

import girlImage from "assets/images/tokensPage/girl_trc.webp";
import trcGoldCoinFlyImage from "assets/images/tokensPage/trcGoldCoinFly.webp";
import coinsImage from "assets/images/tokensPage/coins.webp";

import s from "./TokensBanner.module.scss";

function TokensBanner() {
  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(openTokensModal());
  };

  return (
    <div className={s.tokensBanner}>
      <div className={s.title}>Buy $TRC in Easy Steps!</div>
      <div className={s.buttonWrapper}>
        <Button className={s.button} onClick={openModal}>
          Buy TRC Token
        </Button>
        <img
          src={trcGoldCoinFlyImage}
          alt="trc decorative coin"
          className={s.trcGoldCoinFlyImage}
        />
      </div>

      <div className={s.girlImage}>
        <img src={girlImage} alt="girl with trc" className="fill-cover" />
      </div>

      <div className={s.coinsImage}>
        <img src={coinsImage} alt="coins" className="fill-cover" />
      </div>
    </div>
  );
}

export default TokensBanner;
