import { TOKEN_ADDRESS_MAP } from "constants/nftConstants";
import { useMemo } from "react";
import { SimpleListing, TokenAddress } from "types/listing";
import { RestructuredGetBalanceResponse } from "types/responses";

function useGetNftListFromBalance(
  balance: RestructuredGetBalanceResponse | null | undefined,
) {
  return useMemo<Record<TokenAddress, SimpleListing[]>>(() => {
    const nftList: Record<TokenAddress, SimpleListing[]> = {} as Record<
      TokenAddress,
      SimpleListing[]
    >;
    Object.keys(TOKEN_ADDRESS_MAP).forEach((key) => {
      const tokenKey = key as TokenAddress;
      if (balance && balance[TOKEN_ADDRESS_MAP[tokenKey]]) {
        nftList[tokenKey] =
          balance[TOKEN_ADDRESS_MAP[tokenKey]].token_metadata ?? [];
      } else {
        nftList[tokenKey] = [];
      }
    });

    return nftList;
  }, [balance]);
}

export default useGetNftListFromBalance;
