import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeWalletSwapModal } from "store/modalsSlice";
import Icon from "components/common/Icon";
import Button from "components/common/Button";
import ProjectPath from "constants/paths";

import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./WalletSwapModal.module.scss";

function WalletSwapModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    walletSwapModal: { isOpen },
  } = useAppSelector((state) => state.modals);

  const close = useCallback(() => {
    dispatch(closeWalletSwapModal());
    navigate(`/${ProjectPath.WALLET}`);
  }, [dispatch, navigate]);

  const onClick = () => {
    dispatch(closeWalletSwapModal());
    navigate(`/${ProjectPath.WALLET}`);
  };

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.walletSwap.title")}
      withCancelButton={false}
      actions={[
        <Button className={s.button} onClick={onClick}>
          {t("modals.walletSwap.button")}
        </Button>,
      ]}
    >
      <div className={s.textContainer}>
        <div className={s.clockWrapper}>
          <Icon variant="clock" />
        </div>

        <span className={s.text}>{t("modals.walletSwap.text")}</span>
      </div>
    </ModalContainerWithActions>
  );
}

export default WalletSwapModal;
