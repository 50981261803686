import React from "react";
import { Trans } from "react-i18next";

import s from "./Benefits.module.scss";

function Benefits() {
  return (
    <ul className={s.benefitList}>
      <li>
        <Trans
          i18nKey="burnPass.benefits.first"
          components={{ 1: <span className={s.highlighted} /> }}
        />
      </li>
      <li>
        <Trans
          i18nKey="burnPass.benefits.second"
          components={{
            1: <span className={s.highlighted} />,
            2: <span className={s.soon} />,
          }}
        />
      </li>
      <li>
        <Trans
          i18nKey="burnPass.benefits.third"
          components={{
            1: <span className={s.highlighted} />,
            2: <span className={s.soon} />,
          }}
        />
      </li>
      <li>
        <Trans
          i18nKey="burnPass.benefits.forth"
          components={{
            1: <span className={s.highlighted} />,
            2: <span className={s.soon} />,
          }}
        />
      </li>
    </ul>
  );
}

export default Benefits;
