import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import Icon from "components/common/Icon";
import { IconsVariant } from "types/icons";

import s from "./ListingStatus.module.scss";

type StatusType = "onMarket" | "myListing";

interface IProps {
  status: StatusType;
  wrapperClassName?: string;
}

function ListingStatus({ status, wrapperClassName = "" }: IProps) {
  const { t } = useTranslation();

  const text = (() => {
    switch (status) {
      case "onMarket":
        return t("item.marketStatus");
      case "myListing":
        return t("item.myListingStatus");

      default:
        return "";
    }
  })();

  return (
    <div className={wrapperClassName}>
      <div className={s.market}>
        <Icon variant={IconsVariant.moneyBag} className={s.marketIcon} />{" "}
        <span>{text}</span>
      </div>
    </div>
  );
}

export default ListingStatus;
