import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  closeActionModal,
  finishTaskRequest,
  setResultTaskRequest,
} from "store/modalsSlice";
import Button from "components/common/Button";

import { Coins, JustCoinType, JustCoinTypeUp } from "types/coins";
import Coin from "components/Coin";
import filterCurrencyInput from "utils/filterCurrencyInput";
import Selector from "components/common/Selector";
import ModalContainerWithActions from "../ModalContainerWithActions";
import s from "./ActionModal.module.scss";

function ActionModal() {
  const dispatch = useAppDispatch();
  const {
    actionModal: { isOpen, buttonText, title, input },
  } = useAppSelector((state) => state.modals);
  const close = useCallback(() => {
    dispatch(closeActionModal());
    dispatch(finishTaskRequest());
  }, [dispatch]);

  const [value, setValue] = useState(input?.initialValue || "");
  const [selectedCurrency, setSelectedCurrency] = useState<Coins>(
    input?.coin ?? JustCoinType.trc,
  );
  const selectedOption = {
    value: JustCoinTypeUp[selectedCurrency],
    id: selectedCurrency,
    icon: (
      <Coin currency={`${JustCoinType[selectedCurrency]}`} className={s.icon} />
    ),
  };
  const options = [
    {
      value: JustCoinTypeUp.trc,
      icon: <Coin currency={`${JustCoinType.trc}`} className={s.icon} />,
      id: JustCoinType.trc,
    },
    {
      value: JustCoinTypeUp.matic,
      icon: <Coin currency={`${JustCoinType.matic}`} className={s.icon} />,
      id: JustCoinType.matic,
    },
  ];

  useEffect(() => {
    setSelectedCurrency(input?.coin ?? JustCoinType.trc);
    setValue(input?.initialValue || "");
  }, [input?.coin, input?.initialValue]);

  const onButtonClick = () => {
    dispatch(closeActionModal());
    dispatch(
      setResultTaskRequest({
        result: "resolved",
        data: { price: value, actualPriceType: selectedCurrency },
      }),
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = filterCurrencyInput(e.target.value, 0);
    setValue(newValue);
  };

  const saveSelected = (selected: string) => {
    setSelectedCurrency(selected as Coins);
  };

  const actions = [
    <Button
      type="submit"
      onClick={onButtonClick}
      disabled={!value || value === "0"}
    >
      {buttonText}
    </Button>,
  ];

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={title}
      actions={actions}
    >
      <div className={s.modal}>
        <div className={s.content}>
          <div className={s.inputWrapper}>
            <Selector
              options={options}
              selectedOption={selectedOption}
              saveSelected={saveSelected}
            />
            <input
              value={value}
              onChange={handleChange}
              className={s.input}
              placeholder={input?.placeHolder}
            />
          </div>
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default ActionModal;
