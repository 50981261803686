import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { useAppDispatch } from "hooks/store";
import { addFilters } from "store/filtersSlice";
import { getFilters } from "api/listing";
import { showErrors } from "utils/showErrors";
import ApiError from "classes/ApiError";
import { GetListingResponse } from "types/responses";
import { ListingTabType, ListingTabTypeMap, TokenAddress } from "types/listing";
import { FiltersSliceType } from "types/filters";

interface UseGetFiltersParams {
  listingType?: ListingTabType;
  typeNft: TokenAddress;
  enabled?: boolean;
}

const useGetFilters = ({
  listingType = "all",
  typeNft,
  enabled = true,
}: UseGetFiltersParams) => {
  const dispatch = useAppDispatch();
  const deps: any[] = ["filters", listingType];
  const filtersSliceType: FiltersSliceType = `${typeNft}Filters${ListingTabTypeMap[listingType]}`;

  const queryInfo = useQuery<GetListingResponse, Error>(
    deps,
    async () => {
      return getFilters(listingType, { page: 1, page_size: 1, typeNft });
    },
    {
      enabled,
    },
  );
  useEffect(() => {
    if (queryInfo.isSuccess) {
      if (queryInfo.data) {
        dispatch(
          addFilters({
            data: queryInfo.data?.filters || [],
            filtersSliceType,
          }),
        );
      } else {
        dispatch(
          addFilters({
            data: [],
            filtersSliceType,
          }),
        );
      }
    }
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      showErrors(queryInfo.error);
    }
  }, [
    queryInfo.isSuccess,
    queryInfo.data,
    queryInfo.isError,
    queryInfo.error,
    dispatch,
    listingType,
    typeNft,
    filtersSliceType,
  ]);

  return queryInfo;
};

export default useGetFilters;
