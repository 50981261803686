import React from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "components/common/Icon";

import { modalDrawer, modalFadeIn } from "utils/motion";

import background from "assets/images/common/registerBackground.webp";

import s from "./RegistrationModalContainer.module.scss";

interface RegistrationModalContainerProps {
  children: React.ReactNode;
  open: boolean;
  theme?: "white" | "gray";
  disabled?: boolean;
  withButton?: boolean;
  modalClassName?: string;
  modalContentClassName?: string;
  close: () => void;
}

function RegistrationModalContainer({
  children,
  open,
  theme = "gray",
  disabled = false,
  withButton = true,
  modalClassName = "",
  modalContentClassName = "",
  close,
}: RegistrationModalContainerProps) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {open && (
        <div
          className={`${s.modalContainer} ${s[theme]} ${
            withButton ? s.withButton : ""
          }`}
        >
          <motion.button
            variants={modalFadeIn()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            type="button"
            onClick={close}
            className={s.background}
            disabled={disabled}
          />
          <motion.div
            variants={window.innerWidth > 640 ? modalFadeIn() : modalDrawer()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`${s.modal} ${modalClassName ?? ""}`}
          >
            <div className={`${s.content} ${modalContentClassName}`}>
              <div className={s.backgroundImageMobile}>
                invisible text for background
              </div>
              <div className={s.mainInfo}>{children}</div>
              <div className={s.backgroundImage}>
                <img src={background} alt="" />
              </div>
              {!disabled && (
                <button
                  type="button"
                  className={s.closeWrapper}
                  onClick={close}
                >
                  <Icon variant="close" color="white" withWrapper />
                </button>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

export default RegistrationModalContainer;
