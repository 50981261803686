import React, { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeBalanceModal } from "store/modalsSlice";

import { useTranslation } from "react-i18next";
import MainContent from "./MainContent";
import NFTSelectorContent from "./NFTSelectorContent";
import ModalContainerWithActions from "../ModalContainerWithActions";

function AuthModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { balanceModal } = useAppSelector((state) => state.modals);

  const close = useCallback(() => dispatch(closeBalanceModal()), [dispatch]);

  return (
    <ModalContainerWithActions
      open={balanceModal.isOpen}
      close={close}
      title={
        balanceModal.stage === "main"
          ? t("modals.balance.selectToken")
          : t("modals.balance.selectNFT")
      }
    >
      {balanceModal.stage === "main" && <MainContent />}
      {balanceModal.stage === "NFTSelector" && <NFTSelectorContent />}
    </ModalContainerWithActions>
  );
}

export default AuthModal;
