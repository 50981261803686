const roadmap = [
  {
    id: 0,
    completePercent: 100,
    phaseNumber: 1,
    openingData: "Complete",
    text: "Up to <b>35%</b> from initial NFT Towns purchases by referrals. <br><br> <a href='https://wiki.metatrace.me/invite-to-earn/in-game-gem-rewards'>GEM rewards</a> for your referrals' achievements in MetaTrace game.",
  },
  {
    id: 1,
    completePercent: 25,
    phaseNumber: 2,
    openingData: "(Q3 2024)",
    text: "<b>1%</b> from all purchases by referrals on Trace Market.",
  },
  {
    id: 2,
    completePercent: 0,
    phaseNumber: 3,
    openingData: "(Q3 2024)",
    text: "Commissions from <b>every expense</b> by referrals within the MetaTrace ecosystem.",
  },
  {
    id: 3,
    completePercent: 0,
    phaseNumber: 4,
    openingData: "(Q3 2024)",
    text: "Rewards for <b>$TRC staking</b> by referrals.",
  },
];

export default roadmap;
