import Icon from "components/common/Icon";
import React from "react";
import { openAuthModal } from "store/modalsSlice";
import { setIsCaptchaOpen } from "store/appStateSlice";
import { useAppDispatch } from "hooks/store";
import { useTranslation } from "react-i18next";

import s from "./LoginButton.module.scss";

function LoginButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onAuthModal = () => {
    dispatch(openAuthModal());
    dispatch(setIsCaptchaOpen(true));
  };

  return (
    <button type="button" onClick={onAuthModal} className={s.login}>
      <span className={s.loginText}>{t("common.logIn")}</span>
      <Icon variant="logInSvg" className={s.registerIconLogIn} />
    </button>
  );
}

export default LoginButton;
