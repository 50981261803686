import { useMemo } from "react";

interface UseGetEmptyArrayParams {
  desktopLength?: number;
  mobileLength?: number;
}

const useGetEmptyArray = ({
  desktopLength = 9,
  mobileLength = 6,
}: UseGetEmptyArrayParams) => {
  const skeletonLength = window.innerWidth > 520 ? desktopLength : mobileLength;

  const loadingArr = useMemo(
    () => Array.from({ length: skeletonLength }, (x, i) => i),
    [],
  );

  return loadingArr;
};

export default useGetEmptyArray;
