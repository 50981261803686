/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { makeTransaction } from "api/wallet";
import { convertDecimalTokenBackward } from "utils/convertDecimalToken";
import { showErrors } from "utils/showErrors";

import ProjectPath from "constants/paths";
import ApiError from "classes/ApiError";
import { Coins, CoinType } from "types/coins";
import { TransactionType } from "types/requests";
import { MakeTransactionResponse } from "types/responses";

interface UseMakeTransaction {
  transferEntity?: `${CoinType}`;
  transfer_type: TransactionType;
}

export interface MakeTransactionRequestHookObject {
  token_type: Coins | "car";
  value_or_id: number | string;
  value?: number;
  to?: string;
}

const useMakeTransaction = (params?: UseMakeTransaction) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { transferEntity, transfer_type } = params || {};

  return useMutation<
    MakeTransactionResponse,
    ApiError,
    MakeTransactionRequestHookObject
  >(
    ["make-transaction"],
    async (body) => {
      if (transferEntity === "nft") {
        if (body.token_type && transfer_type && body.value_or_id) {
          return makeTransaction({
            ...body,
            value_or_id: `${body.value_or_id}`,
            transfer_type,
          });
        }
      }
      if (
        body.token_type &&
        transfer_type &&
        body.value_or_id &&
        (transferEntity === "ace" ||
          transferEntity === "matic" ||
          transferEntity === "trc" ||
          transferEntity === "usdt" ||
          transferEntity === "wbtc" ||
          transferEntity === "weth")
      ) {
        return makeTransaction({
          ...body,
          value_or_id: convertDecimalTokenBackward(
            `${body.value_or_id}`,
            transferEntity,
          ),
          transfer_type,
        });
      }
      return Promise.reject(
        new Error(t("notifications.someInvalidValue.title")),
      );
    },
    {
      onSuccess: () => {
        if (transferEntity === "nft") {
          const NFTMessage =
            transfer_type === "internal"
              ? t("notifications.itemTransferred.title")
              : t("notifications.itemSent.title");
          navigate(`/${ProjectPath.WALLET_NFT}`);
          enqueueSnackbar({
            message: NFTMessage,
            variant: "success",
          });
          return queryClient.invalidateQueries({ queryKey: ["balance"] });
        }
        const CoinMessage =
          transfer_type === "internal"
            ? t("notifications.coinTransferred.title")
            : t("notifications.coinSent.title");
        enqueueSnackbar({
          message: CoinMessage,
          variant: "success",
        });
        return queryClient.invalidateQueries({ queryKey: ["balance"] });
      },
      onError: showErrors,
    },
  );
};

export default useMakeTransaction;
