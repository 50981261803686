import React from "react";
import { AttributeRange, Attribute as AttributeType } from "types/listing";
import Attribute from "./Attribute";
import s from "./Attributes.module.scss";

interface AttributesProps {
  attributes: AttributeType[];
}

function Attributes({ attributes }: AttributesProps) {
  return (
    <div className={s.attributes}>
      <div className={s.list}>
        {attributes.map((elem) => (
          <Attribute attribute={elem as AttributeRange} key={elem.trait_type} />
        ))}
      </div>
    </div>
  );
}

export default Attributes;
