/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Loader from "react-loaders";
import { Link } from "react-router-dom";
import cn from "classnames";

import s from "./Button.module.scss";

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
  classNameChildren?: string;
  isLoading?: boolean;
  disabled?: boolean;
  withGradient?: boolean;
  withOutline?: boolean;
  withBacklight?: boolean;
  variant?:
    | "filled"
    | "empty"
    | "filledBlue"
    | "filledGray"
    | "filledSkyBlue"
    | "filledLightPurple"
    | "filledDepthPurple"
    | "outlined";
}

interface ButtonFields
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseProps {}

interface LinkFields extends React.HTMLAttributes<HTMLDivElement>, BaseProps {
  link: string;
  isLocal?: boolean;
  aTagAttributes: React.AnchorHTMLAttributes<HTMLAnchorElement>;
}

export type ButtonProps = ButtonFields | LinkFields;

function Button({
  children = "",
  className = "",
  classNameChildren = "",
  isLoading = false,
  disabled = false,
  withGradient = false,
  withOutline = false,
  withBacklight = false,
  variant = "filled",
  ...props
}: ButtonProps) {
  const mainClassName = `${s.button} ${className} ${s[variant]} ${
    disabled ? s.disabled : ""
  } ${withGradient ? s.withGradient : ""} ${
    withBacklight ? s.backlighted : ""
  }`;

  const content = (
    <>
      {withOutline && <div className={s.outline} />}
      {withGradient && <div className={s.gradient} />}
      {isLoading && (
        <div className={s.loaderWrapper}>
          <Loader
            type="line-scale-pulse-out-rapid"
            active
            innerClassName={s.loader}
          />
        </div>
      )}
      <div
        className={cn(
          s.children,
          classNameChildren,
          isLoading ? s.loading : "",
        )}
      >
        {children}
      </div>
    </>
  );

  if ("link" in props) {
    const { link, aTagAttributes, isLocal = false } = props;
    return (
      <div className={mainClassName} {...props}>
        {content}
        {!isLocal && (
          <a
            href={link}
            className={cn("fill-link", s.link)}
            {...aTagAttributes}
          >
            link
          </a>
        )}
        {isLocal && (
          <Link
            to={link}
            className={cn("fill-link", s.link)}
            {...aTagAttributes}
          >
            link
          </Link>
        )}
      </div>
    );
  }

  return (
    <button
      type="button"
      className={mainClassName}
      disabled={disabled || isLoading}
      {...props}
    >
      {content}
    </button>
  );
}

export default Button;
