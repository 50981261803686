import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { modalFadeIn } from "utils/motion";

import s from "./SmallModalContainer.module.scss";

interface SmallModalContainerProps {
  children: React.ReactNode;
  open: boolean;
  disabled?: boolean;
  close: () => void;
}

function SmallModalContainer({
  children,
  open,
  disabled = false,
  close,
}: SmallModalContainerProps) {
  return (
    <AnimatePresence>
      {open && (
        <div className={`${s.smallModalContainer}`}>
          <motion.button
            variants={modalFadeIn()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            type="button"
            onClick={close}
            className={s.background}
            disabled={disabled}
          />
          <motion.div
            variants={modalFadeIn()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={s.modal}
          >
            <div className={s.content}>{children}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

export default SmallModalContainer;
