import { useQuery } from "@tanstack/react-query";

import { getReferralIncome } from "api/blockchain/referral";
import useGetProfile from "../useGetProfile";

const useGetReferralIncome = () => {
  const { data: profile } = useGetProfile();

  return useQuery<any, Error>(
    ["referral", "income"],
    async () => {
      if (!profile?.address) return null;
      const hashedData = localStorage.getItem("referral_income");
      if (hashedData) {
        return JSON.parse(hashedData);
      }
      const data = await getReferralIncome(profile.address);
      localStorage.setItem("referral_income", JSON.stringify(data));
      return data;
    },
    { enabled: !!profile },
  );
};

export default useGetReferralIncome;
