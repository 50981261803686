import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import c from "./CircleProgressBar.module.scss";

interface CircleProgressBarProps {
  percentage: number;
}

function CircleProgressBar({ percentage }: CircleProgressBarProps) {
  return (
    <div className={c.container}>
      <CircularProgressbar
        value={percentage}
        strokeWidth={12}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: "round",
          textSize: "1.375rem",
          pathTransitionDuration: 0.5,
          pathColor: `#20D468`,
          trailColor: "#E7EBF1",
          backgroundColor: "#A3A7BC",
        })}
        background
      />
      <div className={c.percentage}>{`${percentage.toString()}%`}</div>
    </div>
  );
}

export default CircleProgressBar;
