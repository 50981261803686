import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { closeWelcomeBuyModal } from "store/modalsSlice";
import Image from "./Image";
import ModalContainer from "./ModalContainer";

import s from "./WelcomeBuyModal.module.scss";

function WelcomeBuyModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modals.welcomeBuyModal);

  const close = useCallback(() => {
    dispatch(closeWelcomeBuyModal());
  }, []);

  return (
    <ModalContainer open={isOpen} close={close}>
      <div className={s.modal}>
        <div className={s.banner}>
          <Image image="bybit" className={s.logo} />
          <Image image="boyWithCoin" wrapperClassName={s.boy} />
          <Image image="goldCoinWithBeam" wrapperClassName={s.coin1} />
          <Image image="goldCoinWithBeam" wrapperClassName={s.coin2} />
          <Image image="goldCoinWithBeam" wrapperClassName={s.coin3} />
          <Image image="goldCoinWithBeam" wrapperClassName={s.coin4} />
          <button type="button" onClick={close} className={s.close}>
            <Image image="close" wrapperClassName={s.closeIcon} />
          </button>
        </div>
        <div className={s.content}>
          <div className={s.title}>{t("modals.welcomeBuy.title")}</div>
          <div
            className={s.description}
            dangerouslySetInnerHTML={{
              __html: t("modals.welcomeBuy.description"),
            }}
          />
          <div className={s.buttons}>
            <Button
              link="https://www.bybit.com/en/trade/spot/TRC/USDT"
              className={s.buyButton}
            >
              {t("modals.welcomeBuy.button")}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default WelcomeBuyModal;
