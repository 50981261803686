import cn from "classnames";
import Avatar from "components/Avatar";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Image from "components/common/Image";
import ProjectPath from "constants/paths";
import useGetProfile from "hooks/requests/useGetProfile";
import ConnectTelegramButton from "layouts/NavigationLayout/components/ConnectTelegramButton";
import React from "react";

import s from "./ProfilePart.module.scss";

function ProfilePart() {
  const { data: profile } = useGetProfile();

  return (
    <div className={cn(s.profile, profile?.is_genesis && s.genesisWrapper)}>
      <div className={s.userWrapper}>
        <Avatar
          gender={profile?.character}
          subscribed={profile?.have_subscription}
          isGenesis={profile?.is_genesis}
        />
        <div className={s.information}>
          <div className={s.nickname}>@{profile?.nick}</div>
          {profile?.is_genesis && (
            <div className={s.genesis}>
              <span className={s.genesisTitle}>Genesis Tracer:</span>
              <b className={s.genesisValue}>{profile.genesis_token_id}</b>
              <Icon className={s.genesisIcon} variant="star_empty_inside" />
            </div>
          )}
        </div>
      </div>
      <div className={s.buttons}>
        {/* <Button
          link={`/${ProjectPath.INVITE}`}
          isLocal
          className={cn(s.button, s.inviteToEarn)}
          classNameChildren={s.buttonContent}
          variant="filledLightPurple"
        >
          <Icon variant="personPlus" className={s.buttonIcon} />
          <span className={s.buttonText}>Invite To Earn</span>
        </Button> */}
        <Button
          link={`/${ProjectPath.BURN_PASS}`}
          isLocal
          className={cn(s.button, s.burnPass)}
          classNameChildren={s.buttonContent}
        >
          <Icon variant="star_empty_inside" className={s.buttonIcon} />
          <span className={s.buttonText}>Genesis Tracer</span>
        </Button>
        {!profile?.telegram_id && (
          <ConnectTelegramButton
            variant="filledBlue"
            className={cn(s.button)}
            classNameChildren={s.buttonContent}
          >
            <Icon
              variant="telegramSvg"
              className={cn(s.buttonIcon, s.telegramIcon)}
            />
            <span className={s.buttonText}>Add Telegram</span>
          </ConnectTelegramButton>
        )}
      </div>
      {profile?.is_genesis && (
        <Image
          wrapperClassName={s.genesisBackground}
          image="genesis_decoration"
        />
      )}
    </div>
  );
}

export default ProfilePart;
