import React from "react";
import Icon from "components/common/Icon";
import { TFuncKey, useTranslation } from "react-i18next";
import SimpleLoader from "components/common/SimpleLoader";
import s from "./InformationContainer.module.scss";

type InformationContainerProps = {
  tKey: TFuncKey;
  value?: number | string | null;
  isLoading?: boolean;
  withoutValue?: boolean;
};

function InformationContainer({
  tKey,
  value = null,
  isLoading = false,
  withoutValue = false,
}: InformationContainerProps) {
  const { t } = useTranslation();
  const title = t(tKey).toString();
  return (
    <>
      <div className={s.leftPart}>
        <Icon variant="information" />
        <div className={s.title}>{title}</div>
      </div>
      {isLoading && <SimpleLoader />}
      {withoutValue ||
        (!isLoading && <div className={s.value}>{value ?? "unknown"}</div>)}
    </>
  );
}

export default InformationContainer;
