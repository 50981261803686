export default (number: number | string) => {
  const numericValue = typeof number === "string" ? parseFloat(number) : number;

  const formatted = numericValue.toLocaleString("ru-RU", {
    maximumFractionDigits: 2,
    useGrouping: true,
  });

  return formatted.replace(/,/g, ".");
};

export const formatNumberShort = (number: number) => {
  if (number >= 1e9) {
    return `${(number / 1e9).toFixed(2)}B`;
  }
  if (number >= 1e6) {
    return `${(number / 1e6).toFixed(2)}M`;
  }
  if (number >= 1e3) {
    return `${(number / 1e3).toFixed(2)}K`;
  }
  return number.toString();
};
