export type Coins = "ace" | "trc" | "matic" | "usdt" | "wbtc" | "weth";

export enum JustCoinType {
  ace = "ace",
  trc = "trc",
  matic = "matic",
  usdt = "usdt",
  wbtc = "wbtc",
  weth = "weth",
}

export enum JustCoinTypeUp {
  ace = "ACE",
  trc = "TRC",
  matic = "MATIC",
  usdt = "USDT",
  wbtc = "BTC",
  weth = "ETH",
}

export enum CoinType {
  ace = "ace",
  trc = "trc",
  matic = "matic",
  nft = "nft",
  usdt = "usdt",
  wbtc = "wbtc",
  weth = "weth",
}

export enum ESwapCoins {
  ace = "ace",
  trc = "trc",
  matic = "matic",
  usdt = "usdt",
  wbtc = "wbtc",
  weth = "weth",
}

export enum EUSDPricedCoins {
  trc = "trc",
  matic = "matic",
  usdt = "usdt",
  wbtc = "btc",
  weth = "eth",
}

export enum JustCoinTypeMoonPay {
  ace = "ace",
  trc = "trc",
  matic = "matic_polygon",
}
