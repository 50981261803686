import { ApiErrorType } from "types/responses";

export default class ApiError extends Error {
  constructor(
    public errors: ApiErrorType[],
    public status?: number,
    message?: string,
  ) {
    super(message);
  }
}
