import React from "react";
import cn from "classnames";

import Show from "components/common/Show";
import Icon from "components/common/Icon";

import s from "./Phase.module.scss";

interface IProps {
  completePercent: number;
  phaseNumber: number;
  openingData: string;
  text: string;
}

function Phase({ completePercent, openingData, phaseNumber, text }: IProps) {
  const status = (() => {
    if (completePercent === 100) return "completed";
    if (completePercent > 0 && completePercent < 100) return "continuing";
    return "soon";
  })();

  return (
    <div className={s.phase}>
      <div className={s.topBar}>
        <div
          className={cn(s.filler, s[status])}
          style={{ width: `${completePercent}%` }}
        />
      </div>
      <div className={s.content}>
        <div className={s.topPart}>
          <div className={s.titlePart}>
            <span className={s.title}>Phase {phaseNumber}</span>
            <span className={s.openingData}>{openingData}</span>
          </div>
          <Show>
            <Show.When isTrue={status === "completed"}>
              <div className={s.outerRing}>
                <div className={s.completeIconWrapper}>
                  <Icon variant="check" className={s.completeIcon} />
                </div>
              </div>
            </Show.When>
            <Show.When isTrue={status === "continuing"}>
              <span className={s.completePercent}>{completePercent}%</span>
            </Show.When>
            <Show.Else>
              <span className={s.completePercent}>soon</span>
            </Show.Else>
          </Show>
        </div>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
        {status !== "completed" && <div className={s.background} />}
      </div>
    </div>
  );
}

export default Phase;
