import React from "react";
import InformationContainer from "pages/TransactionPage/InformationContainer";
import s from "./ExchangeRate.module.scss";

type ExchangeRateProps = {
  value: string;
  className?: string;
  isLoading?: boolean;
};

function ExchangeRate({
  value,
  className = "",
  isLoading = false,
}: ExchangeRateProps) {
  return (
    <div className={`${className} ${s.container}`}>
      <InformationContainer
        tKey="transaction.exchangeRate"
        value={value}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ExchangeRate;
