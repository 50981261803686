import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { AttributesObject, PriceType, TokenAddress } from "types/listing";
import Coin from "components/Coin";
import ProjectPath from "constants/paths";
import { Tooltip } from "react-tooltip";
import digitalizeLongNumber from "utils/digitalizeLongNumber";
import Stat from "../Stat";

import s from "./ListingItemForNftSelector.module.scss";

interface ListingItemForNftSelectorProps {
  id: number;
  attributes: AttributesObject;
  image: string;
  name: string;
  tokenAddress: TokenAddress;
  priceConverted?: string;
  price_type?: PriceType;
  isWalletItem?: boolean;
  quantity?: number;
  onClick?: (id: number, tokenAddress: TokenAddress) => void;
}

function ListingItemForNftSelector({
  id,
  attributes,
  image,
  name,
  tokenAddress = "car",
  priceConverted = "",
  price_type = "matic",
  isWalletItem = false,
  quantity = undefined,
  onClick = undefined,
}: ListingItemForNftSelectorProps) {
  const { t } = useTranslation();

  const onItemClick = () => {
    if (onClick) onClick(id, tokenAddress);
  };

  const link = useMemo(() => {
    if (isWalletItem) return `/${ProjectPath.WALLET_NFT}/${tokenAddress}/${id}`;
    return `/${ProjectPath.MARKET_ITEM}/${tokenAddress}/${id}`;
  }, [id, isWalletItem, tokenAddress]);

  const priceConvertedDigitalised = digitalizeLongNumber(priceConverted);

  const listingContent = (
    <div className={s.listingItem}>
      <div className={s.imgPart}>
        <img src={image} alt="item" className="fill-cover" />
        <div className={s.upStats}>
          {attributes?.Attack && (
            <Stat
              variant="attack"
              value={attributes.Attack?.value}
              className={s.statAttack}
            />
          )}
          {attributes?.Defense && (
            <Stat
              variant="defense"
              value={attributes.Defense?.value}
              className={s.statDefense}
            />
          )}
        </div>
        <div className={s.stats}>
          {attributes?.Tier && (
            <Stat variant="tier" value={attributes.Tier?.value} />
          )}
          {attributes?.Level && (
            <Stat variant="lvl" value={attributes.Level?.value} />
          )}
        </div>
        {quantity && tokenAddress === "town" && (
          <div className={s.quantity}>x {quantity}</div>
        )}
      </div>
      <div className={s.informationPart}>
        <div className={s.leftPart}>
          <div className={s.typeWrapper}>
            {(attributes?.Type || attributes?.Name) && (
              <div className={s.type}>
                {attributes.Type?.value ||
                  `${attributes.Name?.value
                    .toString()
                    .charAt(0)}${attributes.Name?.value
                    .toString()
                    .slice(1)
                    .toLowerCase()}`}
              </div>
            )}
            <div className={s.hash}>{name}</div>
          </div>
          {priceConverted && (
            <div className={s.priceWrapper}>
              <Coin currency={price_type} className={s.coin} />
              <div
                className={cn(s.price, "my-anchor-element")}
                id={`price-${id.toString()}`}
              >
                {priceConvertedDigitalised.length >= 10
                  ? `${
                      priceConvertedDigitalised.match(
                        /^(\d+\s+){0,2}\d+(?=\s\S)/,
                      )?.[0]
                    } K+`
                  : priceConvertedDigitalised}
              </div>
              <Tooltip
                anchorSelect={`#price-${id}`}
                place={window.innerWidth <= 520 ? "top" : "top-start"}
                id={`my-tooltip-${id}`}
                content={`${priceConvertedDigitalised} ${price_type.toUpperCase()}`}
                className={cn(s.tooltip, s.arrow)}
                noArrow
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onItemClick} className={s.wrapper}>
        {listingContent}
      </button>
    );
  }
  return (
    <Link to={link} className={s.wrapper}>
      {listingContent}
    </Link>
  );
}

export default ListingItemForNftSelector;
