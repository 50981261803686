import { loadMoonPay } from "@moonpay/moonpay-js";
import CryptoJS from "crypto-js";
import { JustCoinTypeMoonPay } from "types/coins";

type MoonPayModalProps = {
  type: `${JustCoinTypeMoonPay}`;
  walletAddress: string;
  email: string;
};

async function createMoonPay({
  type,
  walletAddress,
  email,
}: MoonPayModalProps) {
  const apiKey = process.env.REACT_APP_MOONPAY_PUBLIC_KEY || "";
  const secretApikey = process.env.REACT_APP_MOONPAY_SECRET_KEY || "";

  const moonPay = await loadMoonPay();
  const moonPaySdk = moonPay({
    flow: "buy",
    environment: "production",
    variant: "overlay",
    params: {
      apiKey,
      currencyCode: type,
      baseCurrencyCode: "usd",
      walletAddress,
      email,
      theme: "light",
    },
  });
  const urlForSignature = moonPaySdk?.generateUrlForSigning();

  const signature = CryptoJS.HmacSHA256(
    new URL(urlForSignature ?? "").search,
    secretApikey,
  ).toString(CryptoJS.enc.Base64);

  moonPaySdk?.updateSignature(signature);
  moonPaySdk?.show();
}

export default createMoonPay;
