import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import useGetProfile from "hooks/requests/useGetProfile";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import { useAppDispatch } from "hooks/store";
import useBuyListing from "hooks/requests/useBuyListing";
import useCancelListing from "hooks/requests/useCancelListing";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { openAuthModal, openConfirmationModal } from "store/modalsSlice";
import { updateMarketItem } from "store/marketItemSlice";

import Coin from "components/Coin";
import { ListedListing, Listing } from "types/listing";

import Icon from "components/common/Icon";
import Button from "components/common/Button";
import TransactionCounter from "pages/TransactionPage/TransactionCounter";
import ListingStatus from "../ListingStatus";

import s from "./ListingTable.module.scss";

interface Props {
  listings: Listing[] | ListedListing[];
  collectionId: string;
  setAmountNFT: (value: number) => void;
  amountNFT: number;
  usageFrom?: "market" | "wallet";
}

function MyListingActions({
  listing,
  collectionId,
  setAmountNFT,
  amountNFT,
  usageFrom,
}: {
  listing: Listing | ListedListing;
  collectionId: string;
  usageFrom: "market" | "wallet";
  amountNFT: number;
  setAmountNFT: (value: number) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { mutate: stopListing, isLoading: isClosing } = useCancelListing({
    listing_id: listing.id,
    tokenAddress: listing.token_address,
    collectionId,
    cancelFrom: usageFrom,
  });

  const openStopModal = useAwaitModalResponse({
    openModal: () => {
      dispatch(
        updateMarketItem({
          quantity: listing.amount,
          priceConverted: listing.priceConverted,
          priceType: listing.price_type,
        }),
      );
      dispatch(
        openConfirmationModal({
          title: t("modals.stopSell.title"),
          text: t("modals.stopSell.text"),
          buttonText: t("modals.stopSell.button"),
        }),
      );
    },
    onResolve: async () => {
      stopListing();
      if (amountNFT > 0) {
        setAmountNFT(amountNFT - 1);
      }
    },
  });

  const onStopItem = useCallback(() => {
    openStopModal();
  }, [openStopModal]);

  return (
    <div className={s.myActions}>
      <ListingStatus status="myListing" />
      <Button
        className={s.button}
        onClick={onStopItem}
        isLoading={isClosing}
        variant="filledGray"
      >
        {t("actions.cancel")}
      </Button>
    </div>
  );
}

function ListingActions({ listing }: { listing: Listing | ListedListing }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [quantity, setQuantity] = useState(1);

  const { mutate: buyListing, isLoading: isBaying } = useBuyListing({
    listing_id: listing.id,
    tokenAddress: listing.token_address,
    amount: quantity,
  });

  const openBuyModal = useAwaitModalResponse({
    openModal: () => {
      dispatch(
        updateMarketItem({
          quantity,
          priceConverted: listing.priceConverted,
          priceType: listing.price_type,
        }),
      );
      dispatch(
        openConfirmationModal({
          title: t("modals.buyItem.title"),
          text: t("modals.buyItem.text"),
          buttonText: t("modals.buyItem.button"),
        }),
      );
    },
    onResolve: () => {
      buyListing();
    },
  });

  const onBuyItem = useCallback(() => {
    openBuyModal();
  }, [openBuyModal]);

  return (
    <div className={s.myActions}>
      <TransactionCounter
        quantity={quantity}
        setQuantity={setQuantity}
        max={listing.amount || 1}
        wrapperClassName={s.counter}
        buttonClassName={s.counterButton}
        className={s.counterInput}
      />
      <Button className={s.button} onClick={onBuyItem} isLoading={isBaying}>
        {t("actions.buy")}
      </Button>
    </div>
  );
}

function ListingTable({
  listings,
  collectionId,
  usageFrom = "market",
  setAmountNFT,
  amountNFT,
}: Props) {
  const { t } = useTranslation();
  const cookie = useGetAuthCookie();
  const dispatch = useAppDispatch();
  const { data: profile, isLoading: isProfileLoading } = useGetProfile();
  const [isPriceSortedForward, setIsPriceSortedForward] = useState(false);

  const listingsSorted = useMemo(() => {
    return listings.slice().sort((a, b) => {
      // Сортировка по полю price_type
      if (a.price_type === "trc" && b.price_type === "matic") return -1; // 'trc' идет перед 'matic'
      if (a.price_type === "matic" && b.price_type === "trc") return 1; // 'matic' идет после 'trc'

      // Сортировка по полю priceConverted
      if (a.priceConverted && b.priceConverted) {
        const priceA = parseFloat(a.priceConverted);
        const priceB = parseFloat(b.priceConverted);
        // Если isPriceSortedForward === true, то сортируем по убыванию
        if (isPriceSortedForward) {
          return priceB - priceA;
        }
        // Иначе сортируем по возрастанию
        return priceA - priceB;
      }

      // Если одно из значений priceConverted отсутствует, то считаем его меньшим
      if (!a.priceConverted && b.priceConverted) return -1;
      if (a.priceConverted && !b.priceConverted) return 1;

      // Если оба значения priceConverted отсутствуют, считаем их равными
      return 0;
    });
  }, [listings, isPriceSortedForward]);

  const onLogInClick = () => {
    dispatch(openAuthModal("logIn"));
  };

  const onPriceSortClick = () => {
    setIsPriceSortedForward((prev) => !prev);
  };

  return (
    <table className={s.table}>
      <thead>
        <tr className={s.headerRow}>
          <th className={cn(s.columnTitle, s.priceTitle)}>
            <span>Price per item</span>
            <Icon
              variant="arrowDown"
              className={cn(s.icon, isPriceSortedForward ? s.sorted : "")}
            />
            <button
              type="button"
              onClick={onPriceSortClick}
              className="fill-link"
            >
              sort price
            </button>
          </th>
          <th className={cn(s.columnTitle, s.quantityTitle)}>Quantity</th>
          <th className={cn(s.columnTitle, s.listingTitle)}>Listings</th>
        </tr>
      </thead>
      <tbody className={s.content}>
        {listingsSorted.map((listing) => {
          const isMyListing =
            listing?.user_id === "my" || profile?.user_id === listing?.user_id;
          return (
            <tr className={s.row} key={listing.id}>
              <td className={cn(s.cell, s.priceCell)}>
                <span className={s.mobileTitle}>Price</span>
                <div className={s.price}>
                  <span className={s.value}>{listing.priceConverted}</span>
                  <span className={cn(s.currency, "uppercase")}>
                    {listing.price_type}
                  </span>
                  <Coin
                    currency={listing.price_type}
                    variant="outerRing"
                    wrapperClassName={s.coinIconWrapper}
                    className={s.coinIcon}
                  />
                </div>
              </td>
              <td className={cn(s.cell, s.quantityCell)}>
                <span className={s.mobileTitle}>Quantity</span>
                <span className={s.quantity}>{listing.amount}</span>
              </td>
              <td className={cn(s.cell, s.actionsCell)}>
                {!cookie && (
                  <Button
                    className={s.button}
                    onClick={onLogInClick}
                    variant="filledSkyBlue"
                  >
                    Log in
                  </Button>
                )}
                {cookie && listing && isMyListing && (
                  <MyListingActions
                    listing={listing}
                    collectionId={collectionId}
                    usageFrom={usageFrom}
                    setAmountNFT={setAmountNFT}
                    amountNFT={amountNFT}
                  />
                )}
                {cookie && listing && !isMyListing && (
                  <ListingActions listing={listing} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ListingTable;
