import { useQuery } from "@tanstack/react-query";

import { getReferralLevel } from "api/blockchain/referral";
import useGetProfile from "../useGetProfile";

const useGetReferralOpenedLevels = () => {
  const { data: profile } = useGetProfile();

  return useQuery<any, Error>(
    ["referral", "level", profile?.address],
    async () => {
      if (!profile?.address) return null;
      const hashedData = localStorage.getItem("referral_level");
      if (hashedData) {
        return JSON.parse(hashedData);
      }
      const data = await getReferralLevel(profile.address);
      localStorage.setItem("referral_level", JSON.stringify(data));
      return data;
    },
    { enabled: !!profile },
  );
};

export default useGetReferralOpenedLevels;
