import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { openAuthModal } from "store/modalsSlice";
import ProjectPath from "constants/paths";
import { setIsCaptchaOpen } from "store/appStateSlice";

function ProtectedRoute({ element: Component, ...rest }: any) {
  const cookie = useGetAuthCookie();
  const location = useLocation();
  const dispatch = useDispatch();

  if (!cookie) {
    dispatch(openAuthModal());
    dispatch(setIsCaptchaOpen(true));
    return (
      <Navigate
        to={`/${ProjectPath.MARKET}`}
        state={{ from: location }}
        replace
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest} />;
}

export default ProtectedRoute;
