import React from "react";

import s from "./PopUpTooltip.module.scss";

interface PopUpTooltipProps {
  text: string;
  buttonStyles?: string;
  buttonText?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  swap?: boolean;
}

function PopUpTooltip({
  text,
  onClick = undefined,
  buttonStyles = "",
  buttonText = "",
  children = "",
  swap = false,
}: PopUpTooltipProps) {
  return (
    <div className={swap ? s.tooltip : s.tooltipRelative}>
      {children && children}
      {!children && (
        <button
          type="button"
          className={`${buttonStyles} ${s.button}`}
          onClick={onClick}
        >
          {buttonText}
        </button>
      )}
      <div className={s.tooltiptext}>
        <p className={s.text}>{text}</p>
        <div className={s.arrow} />
      </div>
    </div>
  );
}

export default PopUpTooltip;
