import { EUSDPricedCoins } from "../types/coins";

// eslint-disable-next-line import/prefer-default-export
export const walletPricedCurrencies: EUSDPricedCoins[] = [
  EUSDPricedCoins.trc,
  EUSDPricedCoins.usdt,
  EUSDPricedCoins.matic,
  EUSDPricedCoins.wbtc,
  EUSDPricedCoins.weth,
];
