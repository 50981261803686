import React from "react";
import economy from "assets/images/dashboard/core-cards/1.webp";
import trade from "assets/images/dashboard/core-cards/2.webp";
import reward from "assets/images/dashboard/core-cards/3.webp";
import Card from "./parts/Card";

import s from "./CoreSection.module.scss";

const cards = [
  {
    title: "Deep In-Game Economy",
    description:
      "Enhance your gameplay experience, mint and upgrade in-game NFTs.",
    image: economy,
  },
  {
    title: "Trade on TraceMarket",
    description:
      "Use TRC as the currency for easy trading NFTs on TraceMarket, such as Cars, Pets, etc.",
    image: trade,
  },
  {
    title: "Event Rewards",
    description:
      "Earn TRC by participating in MetaTrace events and showcasing your gaming skills.",
    image: reward,
  },
];

function CoreSection() {
  return (
    <div>
      <div className={s.title}>The Core of MetaTrace</div>
      <div className={s.cardContainer}>
        {cards.map((card) => (
          <Card
            key={card.title}
            title={card.title}
            description={card.description}
            image={card.image}
          />
        ))}
      </div>
    </div>
  );
}

export default CoreSection;
