import { useQuery } from "@tanstack/react-query";
import getNftTotalSupply from "api/blockchain/nfts";
import { useEffect } from "react";
import { TokenAddress } from "types/listing";
import { TotalSupplyResponse } from "types/responses";
import { showErrors } from "utils/showErrors";

function useGetTotalSupplyByNftType(nft: TokenAddress) {
  const queryInfo = useQuery<TotalSupplyResponse, Error>(
    ["totalSupply", nft],
    () => getNftTotalSupply(nft),
    {
      staleTime: 8.64e7, // 24 hours
    },
  );

  useEffect(() => {
    if (queryInfo.error) showErrors(new Error(queryInfo.error.toString()));
  }, [queryInfo.error]);

  return queryInfo;
}

export default useGetTotalSupplyByNftType;
