import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeFillUpModal } from "store/modalsSlice";
import { useTranslation } from "react-i18next";
import Icon from "components/common/Icon";
import useGetBalance from "hooks/requests/useGetBalance";
import useCopy from "hooks/useCopy";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./FillUpModal.module.scss";

function FillUpModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modals.fillUpModal);
  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();

  const close = useCallback(() => dispatch(closeFillUpModal()), [dispatch]);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(balance?.address || "");
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleCopyButtonClickMobile = useCopy({
    text: balance?.address || "",
    notificationText: t("walletActions.copyTooltip"),
  });

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.fillUp.title")}
    >
      <div className={s.container}>
        <div className={s.warning}>
          <Icon variant="information" className={s.infoIcon} color="golden" />
          <p className={s.warningText}>{t("modals.fillUp.notification")}</p>
        </div>
        <div className={s.wallet}>
          <h3 className={s.title}>{t("modals.fillUp.walletAddress")}</h3>
          <div className={s.walletAddress}>
            <p className={s.walletAddressNumber}>{balance?.address || ""}</p>
            <div className={s.copy}>
              <button
                className={s.copyButton}
                type="button"
                onClick={
                  window.innerWidth > 520
                    ? handleCopyButtonClick
                    : handleCopyButtonClickMobile
                }
              >
                <p className={s.copyText}> {t("walletActions.copy")}</p>

                <Icon variant="copy_rect" className={s.icon} />
              </button>
              {isCopied && (
                <div className={s.alertBox}>
                  {t("walletActions.copyTooltip")}
                  <span className={s.alertBoxArrow} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default FillUpModal;
