import { ValuesType } from "hooks/useSubmitAuthForm";
import * as Yup from "yup";

function createFormDataSchema(): Yup.ObjectSchema<ValuesType> {
  return Yup.object().shape({
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
      .required(),
  });
}

export default createFormDataSchema;
