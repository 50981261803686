import React, { useEffect } from "react";
import { useAppSelector } from "hooks/store";

function CaptchaWatcher() {
  const isAuthModalOpen = useAppSelector(
    (state) => state.modals.logInModal.isOpen,
  );

  useEffect(() => {
    const badge = document.querySelector(".grecaptcha-badge") as HTMLElement;

    if (badge) {
      if (isAuthModalOpen) {
        badge.classList.add("grecaptcha-visible");
      } else {
        badge.classList.remove("grecaptcha-visible");
      }
    }
  }, [isAuthModalOpen]);

  return null;
}

export default CaptchaWatcher;
