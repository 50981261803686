import React from "react";
import ClickAwayListener from "react-click-away-listener";
import { AnimatePresence, motion } from "framer-motion";
import { menuSlide } from "utils/motion";

import s from "./NavigationMenuContainer.module.scss";

interface MenuContainerProps {
  children: React.ReactNode;
  open: boolean;
  alignment?: "left" | "right";
}

function NavigationMenuContainer({
  children,
  open,
  alignment = "right",
}: MenuContainerProps) {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={menuSlide({ alignment })}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={`${s.menu} ${s[alignment]}`}
        >
          <div className={s.content}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default NavigationMenuContainer;
