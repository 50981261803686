import React from "react";

import { IconColor } from "types/icons";

import s from "./ProgressBar.module.scss";

enum ProgressBarVariants {
  line = "line",
  sectors = "sectors",
}

interface ProgressBarProps {
  value: number;
  max?: number;
  variant?: `${ProgressBarVariants}`;
  color?: IconColor;
}

function ProgressBar({
  value,
  max = 100,
  variant = "line",
  color = "golden",
}: ProgressBarProps) {
  if (variant === ProgressBarVariants.sectors) {
    const sectors = Array.from({ length: max }, (_, i: number) => i);
    return (
      <div className={`${s.progressBar} ${s[color]} ${s[variant]}`}>
        {sectors.map((elem, index) => (
          <div className={s.sector} key={elem}>
            <div
              className={s.fill}
              style={{ width: value > index ? "100%" : "0%" }}
            />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={`${s.progressBar} ${s[color]} ${s[variant]}`}>
      <div className={s.fill} style={{ width: `${(value / max) * 100}%` }} />
    </div>
  );
}

export default ProgressBar;
