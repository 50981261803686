import SimpleLoader from "components/common/SimpleLoader";
import { Field } from "formik";
import CoinSelect from "pages/TransactionPage/CoinSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { CoinType, ESwapCoins } from "types/coins";
import { EWalletSwapCoinChooseModalType } from "types/modals";
import { getDisabledCoins } from "../../utils";

import s from "../../SwapContent.module.scss";

function ToToken({
  toCurrency,
  fromCurrency,
  isLoading,
  label,
  setToCurrency,
}: {
  toCurrency: ESwapCoins;
  isLoading: boolean;
  label: string;
  fromCurrency: ESwapCoins;
  setToCurrency: (coin: ESwapCoins) => void;
}) {
  const { t } = useTranslation();

  const handleToCoinClick = (coin: `${CoinType}`): void => {
    setToCurrency(coin as ESwapCoins);
  };

  const coins = [
    ESwapCoins.trc,
    ESwapCoins.matic,
    ESwapCoins.usdt,
    ESwapCoins.ace,
    ESwapCoins.wbtc,
    ESwapCoins.weth,
  ];

  const disabledCoins =
    getDisabledCoins(
      EWalletSwapCoinChooseModalType.to,
      fromCurrency,
      toCurrency,
    ) || [];

  const filteredCoins = coins.filter(
    (coin) => !disabledCoins.includes(coin as ESwapCoins),
  );

  return (
    <div className={s.tokenWrapper}>
      <div className={s.inputWrapper}>
        <label htmlFor="toCurrency" className={s.label}>
          {t("swap.toLabel")}
        </label>
        <div>
          <CoinSelect
            coin={
              CoinType[
                toCurrency.toLowerCase() as unknown as keyof typeof CoinType
              ]
            }
            coinsToChoose={filteredCoins as ESwapCoins[]}
            handleClick={handleToCoinClick}
          />
          <Field
            className={s.input}
            name="toCurrency"
            value={toCurrency}
            readOnly
            type="hidden"
          />
        </div>
      </div>
      <div className={s.inputWrapper}>
        {isLoading && (
          <div className={s.loaderContainer}>
            <SimpleLoader />
          </div>
        )}
        {!isLoading && (
          <>
            <label htmlFor="toAmount" className={s.label}>
              {label}
            </label>
            <Field name="toAmount" readOnly className={s.input} />
          </>
        )}
      </div>
    </div>
  );
}

export default ToToken;
