import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeLootBoxWinningModal } from "store/modalsSlice";

import Image from "components/common/Image";
import Icon from "components/common/Icon";
import LootboxGift from "pages/LootboxPage/LootboxGift";
import Button from "components/common/Button";
import ModalContainer from "../ModalContainer";

import s from "./LootBoxWinningModal.module.scss";

function LootBoxWinningModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen, gift } = useAppSelector(
    (state) => state.modals.lootBoxWinningModal,
  );

  const close = useCallback(() => {
    dispatch(closeLootBoxWinningModal());
  }, [closeLootBoxWinningModal]);

  return (
    <ModalContainer
      open={isOpen}
      close={close}
      withButton={false}
      modalClassName={s.modalWrapper}
    >
      <div className={s.modal}>
        <Image image="beamsForModal" wrapperClassName={s.beams} />
        <Image image="effectForModal" wrapperClassName={s.beams} />
        <div className={s.centralLight} />
        <div className={s.header}>
          <div className={s.title}>Your Reward!</div>
          <button type="button" onClick={close} className={s.close}>
            <Icon variant="close" className={s.iconCross} />
          </button>
          <Image image="star" wrapperClassName={s.star1} />
          <Image image="star" wrapperClassName={s.star2} />
        </div>
        <div className={s.contentWrapper}>
          <Image image="star" wrapperClassName={s.star3} />
          <Image image="star" wrapperClassName={s.star4} />
          <Image image="star" wrapperClassName={s.star5} />
          <Image image="star" wrapperClassName={s.star6} />
          <div className={s.blink1} />
          <div className={s.blink2} />
          <div className={s.blink3} />
          <div className={s.blink4} />
          <div className={s.content}>
            {gift && (
              <div className={s.gift}>
                <LootboxGift gift={gift} />
              </div>
            )}
            <div className={s.explanation}>
              Your reward is on its way to your wallet! Please note that while
              the magic is happening, transactions may take some time to
              process.
            </div>
            <Button className={s.button} onClick={close}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default LootBoxWinningModal;
