import React, { useMemo, useState } from "react";

import Button from "components/common/Button";

import CookieImage from "assets/images/common/cookie.webp";

import s from "./CookiePanel.module.scss";

function CookiePanel() {
  const [rerender, setRerender] = useState(0);

  const isCookieAgreed = useMemo(() => {
    const localValue = localStorage.getItem("COOKIE-AGREED");
    if (localValue === "true") return true;
    return false;
  }, [rerender]);

  if (isCookieAgreed) return null;

  const agreeToCookie = () => {
    localStorage.setItem("COOKIE-AGREED", "true");
    setRerender((prev) => prev + 1);
  };

  return (
    <div className={s.cookiePanelWrapper}>
      <div className={s.cookiePanel}>
        <div className={s.leftPart}>
          <img src={CookieImage} alt="cookie" className={s.image} />
          <p className={s.text}>
            We’re baking cookies to make your experience as smooth as butter and
            to keep tabs on the stats – Yum!
          </p>
        </div>
        <Button className={s.button} variant="outlined" onClick={agreeToCookie}>
          <span className={s.buttonText}>Perfect!</span>
        </Button>
      </div>
    </div>
  );
}

export default CookiePanel;
