import React from "react";
import useGetAllNftTotalSupply from "hooks/requests/polygon/useGetAllNftTotalSupply";
import useGetListingTotalAmount from "hooks/requests/useGetListingTotalAmount";
import { NFT_TRENDY_MAP, TOKEN_ADDRESS_MAP } from "constants/nftConstants";
import NftCategory from "./NftCategory/NftCategory";
import createNftCategories from "./utils";

import s from "./NftCategoryList.module.scss";

function NftCategoryList() {
  const { carData, passData, partData, petData, townData } =
    useGetAllNftTotalSupply();
  const [
    { data: allTotalCarListings },
    { data: allTotalPassListings },
    { data: allTotalCypetListings },
    { data: allTotalPartsListings },
    { data: allTotalTownsListings },
  ] = useGetListingTotalAmount("all");

  const categories = createNftCategories([
    {
      totalSupply: carData?.data?.totalSupply,
      total_listings: allTotalCarListings?.total_listings ?? 0,
      type: TOKEN_ADDRESS_MAP.car,
      trendy: NFT_TRENDY_MAP.car,
      isLoading: carData?.isLoading,
    },
    {
      totalSupply: passData?.data?.totalSupply,
      total_listings: allTotalPassListings?.total_listings ?? 0,
      type: TOKEN_ADDRESS_MAP.pass,
      trendy: NFT_TRENDY_MAP.pass,
      isLoading: passData?.isLoading,
    },
    {
      totalSupply: petData?.data?.totalSupply,
      total_listings: allTotalCypetListings?.total_listings ?? 0,
      type: TOKEN_ADDRESS_MAP.pet,
      trendy: NFT_TRENDY_MAP.pet,
      isLoading: petData?.isLoading,
    },
    {
      totalSupply: partData?.data?.totalSupply,
      total_listings: allTotalPartsListings?.total_listings ?? 0,
      type: TOKEN_ADDRESS_MAP.part,
      trendy: NFT_TRENDY_MAP.part,
      isLoading: partData?.isLoading,
    },
    {
      totalSupply: townData?.data?.totalSupply,
      total_listings: allTotalTownsListings?.total_listings ?? 0,
      type: TOKEN_ADDRESS_MAP.town,
      trendy: NFT_TRENDY_MAP.town,
      isLoading: townData?.isLoading,
    },
  ]);

  return (
    <div className={s.categoryList}>
      {categories.map(
        ({
          image,
          link,
          title,
          totalSupply,
          listedPercentage,
          isTrendy,
          isLoading,
        }) => (
          <NftCategory
            key={title}
            title={title}
            link={link}
            totalSupply={totalSupply}
            image={image}
            listedPercentage={listedPercentage}
            isTrendy={isTrendy}
            isLoading={isLoading}
          />
        ),
      )}
    </div>
  );
}

export default NftCategoryList;
