import { web3 } from "..";
import levelAbi from "./levelAbi";

interface IReferralLevelResponse {
  0: bigint;
  1: bigint;
  2: bigint;
}

interface IReferralLevelConverted {
  0: number;
  1: number;
  2: number;
}

const convertReferralLevelResponse = (
  response: IReferralLevelResponse,
): IReferralLevelConverted => {
  return {
    0: Number(response[0]),
    1: Number(response[1]),
    2: Number(response[2]),
  };
};

const REWARD_CONTRACT = process.env.REACT_APP_REWARD_CONTRACT || "";

const getReferralLevel = async (
  walletAddress: string,
): Promise<IReferralLevelConverted> => {
  const referralContract = new web3.eth.Contract(levelAbi, REWARD_CONTRACT);
  const result: IReferralLevelResponse = await referralContract.methods
    .getUserLine(walletAddress)
    .call();

  return convertReferralLevelResponse(result);
};

export default getReferralLevel;
