import { useQuery, useQueryClient } from "@tanstack/react-query";
import { verifyTgConnection } from "api/auth";
import ApiError from "classes/ApiError";
import TG_CODE from "constants/telegram";
import { useAppSelector } from "hooks/store";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsTgConnectEnabled } from "store/appStateSlice";
import { showErrors } from "utils/showErrors";

const DEBOUNCE_TIME = 2000;
const SUCCESS_MESSAGE = "Telegram was successfully connected";

const useRequestVerifyTgConnection = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const code = sessionStorage.getItem(TG_CODE);
  const [attempts, setAttempts] = useState<number>(0);
  const { isTgConnectEnabled } = useAppSelector((state) => state.appState);
  const lastErrorRef = useRef<string | null>(null);

  const queryInfo = useQuery({
    queryKey: ["verify-telegram-connect"],
    queryFn: () => verifyTgConnection({ code: code ?? "" }),
    retry: false,
    enabled: isTgConnectEnabled,
    refetchInterval: isTgConnectEnabled ? DEBOUNCE_TIME : false,
    onSuccess: () => {
      enqueueSnackbar({
        message: SUCCESS_MESSAGE,
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
    onError: (error: ApiError) => {
      if (lastErrorRef.current !== error?.errors[0]?.meta.error) {
        showErrors(error);
        lastErrorRef.current = error?.errors[0]?.meta.error;
      }
      if (error && error.status === 409) {
        dispatch(setIsTgConnectEnabled(false));
        return;
      }
      if (attempts >= 2) {
        dispatch(setIsTgConnectEnabled(false));
      }
    },
    onSettled: () => {
      setTimeout(() => {
        setAttempts((prev) => prev + 1);
        if (attempts >= 2) {
          dispatch(setIsTgConnectEnabled(false));
        }
      }, DEBOUNCE_TIME);
    },
  });

  useEffect(() => {
    if (queryInfo?.data?.telegram_id) {
      dispatch(setIsTgConnectEnabled(false));
    }
  }, [queryInfo?.data?.telegram_id, dispatch]);

  const startChecking = () => {
    dispatch(setIsTgConnectEnabled(true));
    setAttempts(0);
  };

  return {
    isChecking: isTgConnectEnabled,
    startChecking,
    telegramId: queryInfo?.data?.telegram_id,
  };
};

export default useRequestVerifyTgConnection;
