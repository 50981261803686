const roundTo = (
  number: number | string,
  length: number,
  fixed: boolean,
): string => {
  const tens: number = 10 ** length;
  const floatNumber: number =
    typeof number === "string" ? parseFloat(number) : number;

  return fixed
    ? floatNumber.toFixed(length)
    : (Math.round(floatNumber * tens) / tens).toString();
};

export default roundTo;
