import React, { useCallback, useEffect, useState } from "react";
import ReactSlider from "react-slider";
import Checkbox from "components/common/Checkbox";
import { useAppDispatch } from "hooks/store";
import { changeFilterValue } from "store/filtersSlice";
import {
  StringFiltersRestructured,
  IntegerFiltersRestructured,
  FiltersSliceType,
} from "types/filters";
import { ListingFilterValue } from "types/requests";
import { TFuncKey, useTranslation } from "react-i18next";
import s from "./FilterContent.module.scss";

interface RangeContentProps {
  filter: IntegerFiltersRestructured;
  filtersSliceType: FiltersSliceType;
  appliedFilters: ListingFilterValue;
}

export function RangeContent({
  filter,
  filtersSliceType,
  appliedFilters,
}: RangeContentProps) {
  const dispatch = useAppDispatch();
  const [leftValuePosition, setLeftValuePosition] = useState(0);
  const [rightValuePosition, setRightValuePosition] = useState(100);
  const [sliderValues, setSliderValues] = useState<number[]>([
    filter.value_int_min,
    filter.value_int_max,
  ]);
  const min = filter.value_int_min;
  const max = filter.value_int_max;

  useEffect(() => {
    const range = max - min;
    const leftPos = ((sliderValues[0] - min) / range) * 100;
    const rightPos = ((sliderValues[1] - min) / range) * 100;
    setLeftValuePosition(leftPos);
    setRightValuePosition(rightPos);
  }, [filter.value, max, min, sliderValues]);

  useEffect(() => {
    if (
      appliedFilters &&
      Array.isArray(appliedFilters) &&
      appliedFilters.length
    ) {
      setSliderValues(appliedFilters.map((el) => Number(el)));
    } else {
      setSliderValues([min, max]);
    }
  }, [appliedFilters, max, min]);

  const onChange = (value: number[]) => {
    setSliderValues(value);
    dispatch(
      changeFilterValue({
        trait_type: filter.trait_type,
        value,
        filtersSliceType,
      }),
    );
  };

  return (
    <div className={s.sliderWrapper}>
      <div className={s.values}>
        <div
          className={s.value}
          // style={{
          //   left: `${leftValuePosition}%`,
          //   transform: `translateX(${-leftValuePosition}%)`,
          // }}
        >
          {sliderValues[0]}
        </div>
        <div
          className={s.value}
          style={{
            right: `0%`,
            // left: `${rightValuePosition}%`,
            // transform: `translateX(${-rightValuePosition}%)`,
          }}
        >
          {sliderValues[1]}
        </div>
      </div>
      <ReactSlider
        value={sliderValues}
        min={min}
        max={max}
        onChange={onChange}
        className={s.slider}
        thumbClassName={s.thumb}
        pearling
        renderTrack={(props, state) => (
          <div
            {...props}
            className={state.index === 1 ? `${s.middle} ${s.track}` : s.track}
          />
        )}
        renderThumb={(props) => <div {...props} />}
      />
      <div className={s.labels}>
        <div className={s.label}>{min}</div>
        <div className={s.label}>{max}</div>
      </div>
    </div>
  );
}

interface SelectContentProps {
  filter: StringFiltersRestructured;
  appliedFilters: ListingFilterValue;
  filtersSliceType: FiltersSliceType;
}

export function SelectContent({
  filter,
  appliedFilters,
  filtersSliceType,
}: SelectContentProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  useEffect(() => {
    if (appliedFilters && Array.isArray(appliedFilters)) {
      setSelectedCheckboxes(appliedFilters.map((el) => el.toString()));
    }
  }, [appliedFilters]);

  const handleCheck = useCallback(
    (item: string) => () => {
      const updatedValues = selectedCheckboxes.includes(item)
        ? selectedCheckboxes.filter((elem) => elem !== item)
        : [...selectedCheckboxes, item];

      setSelectedCheckboxes(updatedValues);
      dispatch(
        changeFilterValue({
          trait_type: filter?.trait_type,
          value: updatedValues,
          filtersSliceType,
        }),
      );
    },
    [dispatch, filter?.trait_type, filtersSliceType, selectedCheckboxes],
  );

  return (
    <div className={s.list}>
      {filter.values_string.map((elem) => {
        const translatedTitle =
          filter.trait_type === "Rarity" ||
          filter.trait_type === "Element" ||
          filter.trait_type === "Category"
            ? t(
                `item.${filter.trait_type.toLowerCase()}Type.${elem.toLowerCase()}` as TFuncKey,
              ).toString()
            : undefined;
        return (
          <Checkbox
            checked={selectedCheckboxes.includes(elem)}
            label={translatedTitle || elem}
            onChange={handleCheck(elem)}
            key={elem}
          />
        );
      })}
    </div>
  );
}
