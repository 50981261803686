import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { setWindowSize } from "store/appStateSlice";

function WindowSizeSetter() {
  const dispatch = useAppDispatch();
  const { isDesktop, isMobile, isTablet } = useAppSelector(
    (state) => state.appState.windowSize,
  );

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 780 && !isDesktop) {
        dispatch(setWindowSize("isDesktop"));
      }
      if (window.innerWidth <= 780 && window.innerWidth > 520 && !isTablet) {
        dispatch(setWindowSize("isTablet"));
      }
      if (window.innerWidth <= 520 && !isMobile) {
        dispatch(setWindowSize("isMobile"));
      }
    };
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [isDesktop, isMobile]);

  return null;
}

export default WindowSizeSetter;
