import ApiError from "classes/ApiError";
import {
  AuthRequest,
  AuthTgVerifyCodeRequest,
  SetReferralCodeRequest,
  SignUpRequest,
  TgAuthRequest,
  VerifyRequest,
  AuthTgVerifyDataRequest,
} from "types/requests";
import {
  ApiErrorResponse,
  AuthResponse,
  GetAuthTgLinkResponse,
  SetReferralCodeResponse,
  SignUpResponse,
  VerifyAuthTgResponse,
  VerifyCodeResponse,
  VerifyTgConnectionResponse,
  VerifyAuthTgDataResponse,
} from "types/responses";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const requestAuth = async ({
  email,
  token,
}: AuthRequest): Promise<AuthResponse> => {
  const response = await fetch(`${endpoint}/auth/send`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, token, source: "market" }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const requestVerify = async ({
  email,
  code,
}: VerifyRequest): Promise<VerifyCodeResponse> => {
  const response = await fetch(`${endpoint}/auth/verify`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      code,
    }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    if ("error" in errorData) throw new Error(errorData.error);
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const signUp = async ({
  nick,
  name,
  referralCode,
  characterId,
}: SignUpRequest): Promise<SignUpResponse> => {
  const response = await fetch(`${endpoint}/auth/sign-up`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ nick, name, referralCode, characterId }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    if ("error" in errorData) throw new Error(errorData.error);
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const logOut = async (): Promise<null> => {
  const response = await fetch(`${endpoint}/auth/logout/`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return null;
};

export const setReferralCode = async ({
  referralCode,
}: SetReferralCodeRequest): Promise<SetReferralCodeResponse> => {
  const response = await fetch(`${endpoint}/auth/setReferral`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      referralCode,
    }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const verifyAuthTg = async ({
  code,
}: AuthTgVerifyCodeRequest): Promise<VerifyAuthTgResponse> => {
  const response = await fetch(`${endpoint}/auth/tg/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const requestTgAuth = async ({
  token,
}: TgAuthRequest): Promise<GetAuthTgLinkResponse> => {
  const response = await fetch(`${endpoint}/auth/tg/get-link`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(token ? { token, source: "market" } : {}),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse | ApiError = await response.json();
    if (errorData.errors) {
      throw new ApiError(errorData.errors, response.status);
    } else if ("message" in errorData) {
      throw new ApiError([], response.status, errorData?.message);
    }
  }
  return response.json();
};

export const verifyTgConnection = async ({
  code,
}: AuthTgVerifyCodeRequest): Promise<VerifyTgConnectionResponse> => {
  const response = await fetch(`${endpoint}/auth/tg/connect`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const verifyAuthTgData = async ({
  tgData,
}: AuthTgVerifyDataRequest): Promise<VerifyAuthTgDataResponse> => {
  const response = await fetch(`${endpoint}/auth/tg/verify-data/${tgData}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};
