import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CustomSnackbar from "components/common/CustomSnackbar/CustomSnackbar";
import AuthTokenSetter from "helpers/AuthTokenSetter";
import HeightSetter from "helpers/HeightSetter";
import LangInitializer from "helpers/LangInitializer";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import store from "store";
import "swiper/css";
import TgWebAppInitializer from "./helpers/TgWebAppInitializer";
import "./i18n/config";
import Router from "./Router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const devMode = process.env.REACT_APP_DEVELOPMENT || false;

function App() {
  return (
    <SnackbarProvider
      Components={{
        success: CustomSnackbar,
        error: CustomSnackbar,
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor="hsla(218, 37%, 93%, 1)"
            highlightColor="hsla(218, 37%, 94%, 1)"
          >
            <Router />
            <HeightSetter />
            <AuthTokenSetter />
            <LangInitializer />
            <TgWebAppInitializer />
          </SkeletonTheme>
          {devMode && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
