import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getBalance } from "api/wallet";
import { showErrors } from "utils/showErrors";
import deleteCookie from "utils/deleteCookie";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { useAppDispatch } from "hooks/store";

import ApiError from "classes/ApiError";
import { RestructuredGetBalanceResponse } from "types/responses";
import { dropAuthToken } from "store/appStateSlice";
import { dropWalletAddress, setWalletAddress } from "store/userProfileSlice";
import { PriceType, TokenAddress } from "types/listing";

const useGetBalance = (type?: TokenAddress | PriceType) => {
  const cookie = useGetAuthCookie();
  const dispatch = useAppDispatch();

  const queryInfo = useQuery<RestructuredGetBalanceResponse | null, Error>(
    ["balance"],
    async () => {
      return getBalance(type);
    },
    { enabled: Boolean(cookie), cacheTime: 0 },
  );

  useEffect(() => {
    dispatch(setWalletAddress(queryInfo?.data?.address ?? ""));
  }, [queryInfo?.data?.address, dispatch]);

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      if (queryInfo.error.status === 403) {
        if (cookie) {
          deleteCookie();
        }
        dispatch(dropAuthToken());
        dispatch(dropWalletAddress());
      }
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error, cookie, dispatch]);

  return queryInfo;
};

export default useGetBalance;
