import React, { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeShopCompleteModal } from "store/modalsSlice";

import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./ShopCompleteModal.module.scss";

function ShopCompleteModal() {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modals.shopCompleteModal);

  const close = useCallback(() => {
    dispatch(closeShopCompleteModal());
  }, [dispatch]);

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title="The purchase was successful!"
    >
      <div className={s.content}>
        <div className={s.textRow}>
          <div className={s.checkIconWrapper}>
            <Icon variant="check" className={s.checkIcon} />
          </div>
          <div className={s.text}>
            You have successfully purchased <b>Trace Pro!</b> You will be able
            to renew your subscription upon its expiration.
          </div>
        </div>
        <Button className={s.additionalCloseButton} onClick={close}>
          Got it
        </Button>
      </div>
    </ModalContainerWithActions>
  );
}

export default ShopCompleteModal;
