import React, { ReactComponentElement, useMemo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { AttributesObject, PriceType, TokenAddress } from "types/listing";
import Coin from "components/Coin";
import ProjectPath from "constants/paths";
import { Tooltip } from "react-tooltip";
import digitalizeLongNumber from "utils/digitalizeLongNumber";
import { TownCategory } from "constants/townConstants";
import Stat from "../Stat";
import TownCornerIcon from "../TownCorenerIcon";
import TownCoordinates from "../TownCoordinates";

import s from "./ListingItem.module.scss";

interface ListingItemProps {
  id: number | string;
  tokenId: number | string;
  attributes: AttributesObject;
  image: string;
  name: string;
  tokenAddress: TokenAddress;
  priceConverted?: string;
  price_type?: PriceType;
  isWalletItem?: boolean;
  quantity?: number;
  onClick?: (id: number | string) => void;
  action?: React.ReactNode;
  isListed?: boolean;
}

function ListingItem({
  id,
  tokenId,
  attributes,
  image,
  name,
  tokenAddress = "car",
  priceConverted = "",
  price_type = "matic",
  isWalletItem = false,
  isListed = false,
  quantity = undefined,
  onClick = undefined,
  action = undefined,
}: ListingItemProps) {
  const onItemClick = () => {
    if (onClick) onClick(id);
  };

  const link = useMemo(() => {
    if (isWalletItem)
      return `/${ProjectPath.WALLET_NFT}/${tokenAddress}/${tokenId}?itemId=${id}&onMarket=${isListed}`;
    return `/${ProjectPath.MARKET_ITEM}/${tokenAddress}/${tokenId}?itemId=${id}`;
  }, [id, isWalletItem, tokenAddress, tokenId]);

  const priceConvertedDigitalized = digitalizeLongNumber(priceConverted);

  const listingContent = (
    <div className={s.listingItem}>
      <div className={s.imgPart}>
        <img src={image} alt="item" className="fill-cover" />
        <div className={s.upStats}>
          {attributes?.Category && (
            <TownCornerIcon
              value={attributes.Category?.value as TownCategory}
              className={s.townCornerIcon}
            />
          )}
          {attributes?.Attack && (
            <Stat
              variant="attack"
              value={attributes.Attack?.value}
              className={s.statAttack}
            />
          )}
          {attributes?.Defense && (
            <Stat
              variant="defense"
              value={attributes.Defense?.value}
              className={s.statDefense}
            />
          )}
        </div>
        <div className={s.stats}>
          {attributes?.Tier && (
            <Stat variant="tier" value={attributes.Tier?.value} />
          )}
          {attributes?.Level && (
            <Stat variant="lvl" value={attributes.Level?.value} />
          )}
        </div>
        {attributes?.Lat && attributes?.Lon && (
          <TownCoordinates
            latValue={attributes.Lat.value as number}
            lonValue={attributes.Lon.value as number}
            className={s.coordinates}
          />
        )}
        {quantity && tokenAddress === "town" && (
          <div className={s.quantity}>x {quantity}</div>
        )}
      </div>
      <div className={s.informationPart}>
        <div className={s.leftPart}>
          <div className={s.typeWrapper}>
            {(attributes?.Type || attributes?.Name) && (
              <div className={s.type}>
                {attributes.Type?.value ||
                  `${attributes.Name?.value
                    .toString()
                    .charAt(0)}${attributes.Name?.value
                    .toString()
                    .slice(1)
                    .toLowerCase()}`}
              </div>
            )}
            <div className={s.hash}>{name}</div>
          </div>
          {priceConverted && (
            <div className={s.priceWrapper}>
              <Coin currency={price_type} className={s.coin} />
              <div
                className={cn(s.price, "my-anchor-element")}
                id={`price-${id.toString()}`}
              >
                {priceConvertedDigitalized.length >= 10
                  ? `${
                      priceConvertedDigitalized.match(
                        /^(\d+\s+){0,2}\d+(?=\s\S)/,
                      )?.[0]
                    } K+`
                  : priceConvertedDigitalized}
              </div>
              <Tooltip
                anchorSelect={`#price-${id}`}
                place={window.innerWidth <= 520 ? "top" : "top-start"}
                id={`my-tooltip-${id}`}
                content={`${priceConvertedDigitalized} ${price_type.toUpperCase()}`}
                className={cn(s.tooltip, s.arrow)}
                noArrow
              />
            </div>
          )}
          {action && action}
        </div>
      </div>
    </div>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onItemClick} className={s.wrapper}>
        {listingContent}
      </button>
    );
  }
  return (
    <Link to={link} className={s.wrapper}>
      {listingContent}
    </Link>
  );
}

export default ListingItem;
