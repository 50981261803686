import AppReleaseBanner from "components/AppReleaseBanner";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import EditContent from "./EditContent";
import ItemContent from "./ItemContent";
import ListingContent from "./ListingContent";

import s from "./ItemPage.module.scss";

function ItemPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isListed =
    (searchParams.get("onMarket") === "true" &&
      !location.pathname.includes("town")) ||
    location.pathname.includes("market");

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, []);

  const [isEditingPrice, setIsEditingPrice] = useState(false);

  return (
    <div className={s.itemPage} ref={ref}>
      <AppReleaseBanner wrapperClassName={s.sideBanner} />
      <div className={s.container}>
        {isEditingPrice && (
          <EditContent setIsEditingPrice={setIsEditingPrice} />
        )}
        {isListed && !isEditingPrice && (
          <ListingContent setIsEditingPrice={setIsEditingPrice} />
        )}
        {!isListed && !isEditingPrice && <ItemContent />}
      </div>
    </div>
  );
}

export default ItemPage;
