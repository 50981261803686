import React from "react";
import InformationContainer from "../InformationContainer";

import s from "./PolygonContainer.module.scss";

type ContainerProps = {
  className?: string;
};

function PolygonContainer({ className = "" }: ContainerProps) {
  return (
    <div className={`${className} ${s.cntainer}`}>
      <InformationContainer tKey="transaction.polygon" withoutValue />
    </div>
  );
}

export default PolygonContainer;
