import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import benefits from "constants/traceProBenefits";

import proImage from "assets/images/shop/pro.webp";

import s from "./BenefitsPart.module.scss";

function BenefitsPart() {
  const { t } = useTranslation();

  return (
    <section className={s.benefitsPart}>
      <div className={s.topPart}>
        <h5 className={s.title}>{t("gameShop.benefits.title")}</h5>
        <p className={s.description}>{t("gameShop.benefits.text")}</p>
      </div>
      <div className={s.benefits}>
        {benefits.map((benefit) => (
          <div className={cn(s.benefit, s[benefit.codename])} key={benefit.id}>
            <div className={s.pro}>
              <img src={proImage} alt="pro" className="fill-contain" />
            </div>
            <div className={s.imageZone}>
              <div className={s.shadow} />
              <img
                src={benefit.image}
                alt={benefit.codename}
                className={s.image}
              />
            </div>
            <div className={s.titleZone}>
              <div className={s.line} />
              <h6
                className={s.benefitTitle}
                dangerouslySetInnerHTML={{ __html: benefit.title }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BenefitsPart;
