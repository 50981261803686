import React from "react";

import personWithGlasses from "assets/images/referral/person_with_glasses.webp";
import persons from "assets/images/referral/persons.webp";

import Icon from "components/common/Icon";

import s from "./TopPart.module.scss";

function TopPart() {
  return (
    <div className={s.topPart}>
      <div className={s.content}>
        <h1 className={s.title}>Invite To Earn</h1>
        <p className={s.description}>
          Share your referral code and receive TRC income from your referrals
          purchases!
        </p>
        <div className={s.more}>
          <span className={s.text}>More details</span>
          <Icon variant="external_resource" className={s.externalIcon} />
          <a
            href="https://wiki.metatrace.me/app-overview/social-fi/invite-to-earn"
            className="fill-link"
          >
            More details
          </a>
        </div>
      </div>
      <div className={s.personWithGlassesWrapper}>
        <img src={personWithGlasses} alt="" className="fill-cover" />
      </div>
      <div className={s.personsWrapper}>
        <img src={persons} alt="" className="fill-cover" />
      </div>
    </div>
  );
}

export default TopPart;
