import AppReleaseBanner from "components/AppReleaseBanner";
import BannerSlider from "components/common/BannerSlider";
import NftCategoryList from "components/NftCategoryList";
import useGetWindowWidth from "hooks/useGetWindowWidth";
import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/store";

import s from "./MarketPage.module.scss";

function MarketPage() {
  const { t } = useTranslation();
  const { isTgWebApp } = useAppSelector((state) => state.appState);
  const windowWidth = useGetWindowWidth();

  return (
    <div className={s.marketPage}>
      <div className={s.container}>
        {windowWidth > 520 && (
          <div className={s.sliderContainer}>
            <BannerSlider
              className={s.slider}
              sliders={[
                {
                  id: 1,
                  title: t("market.banner_1.title"),
                  text: t("market.banner_1.text"),
                  image: "marketplaceBg1",
                  titleClassName: s.bannerTitle,
                  textClassName: s.bannerText,
                  contentClassName: s.slideContent,
                },
              ]}
            />
            {/* <BannerSlider
            //     className={s.slider}
            //     sliders={[
            //       {
            //         id: 2,
            //         title: t("market.banner_1.title"),
            //         text: t("market.banner_1.text"),
            //         image: "marketplaceMobileBg1",
            //         titleClassName: s.bannerTitle,
            //         textClassName: s.bannerText,
            //         contentClassName: s.slideContent,
            //       },
            //     ]}
            //   /> */}
          </div>
        )}
        {!isTgWebApp && <div className={s.title}>{t("market.title")}</div>}
        <NftCategoryList />
      </div>
      <AppReleaseBanner wrapperClassName={s.sideBanner} />
    </div>
  );
}

export default MarketPage;
