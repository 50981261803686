import React from "react";

import useWhichPage from "hooks/useWhichPage";

import AppReleaseBanner from "components/AppReleaseBanner";
import SellContent from "./SellContent";
import SendContent from "./SendContent";
import SwapContent from "./SwapContent";
import TransferContent from "./TransferContent";
import WithSwapContentProtected from "./WithSwapContentProtected";

import s from "./TransactionPage.module.scss";

function TransactionPage() {
  const { isSendAction, isSellAction, isTransferAction, isSwapAction } =
    useWhichPage();

  return (
    <div className={s.transactionPage}>
      {isSendAction && <SendContent />}
      {isSellAction && <SellContent />}
      {isTransferAction && <TransferContent />}
      {isSwapAction && (
        <WithSwapContentProtected>
          <SwapContent>
            <AppReleaseBanner />
          </SwapContent>
        </WithSwapContentProtected>
      )}
    </div>
  );
}

export default TransactionPage;
