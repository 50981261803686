import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";

import Coin from "components/Coin";
import Icon from "components/common/Icon";

import person1 from "assets/images/referral/person1.svg";
import person2 from "assets/images/referral/person2.svg";
import person3 from "assets/images/referral/person3.svg";

import medal1 from "assets/images/referral/medal1.png";
import medal2 from "assets/images/referral/medal2.png";
import medal3 from "assets/images/referral/medal3.png";

import s from "./LevelCard.module.scss";

const personImageObject = {
  1: person1,
  2: person2,
  3: person3,
};

const medalImageObject = {
  1: medal1,
  2: medal2,
  3: medal3,
};

const titleObject = {
  1: "ONE",
  2: "TWO",
  3: "THREE",
};

const countObject = {
  1: "1st",
  2: "2nd",
  3: "3rd",
};

const countStringifyObject = {
  1: "First",
  2: "Second",
  3: "Third",
};

const referralPercentObject = {
  1: "20%",
  2: "10%",
  3: "5%",
};

const conditionsObject = {
  1: [1, 5, 20],
  2: [2, 10, 40],
  3: [4, 20, 80],
};

const conditionTownName = ["Exclusive", "Millionaire", "Common"];

interface IProps {
  level: 1 | 2 | 3;
  amount: number;
  acquired: boolean;
}

function LevelCard({ level, amount, acquired }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
    }
  }, [isOpen]);

  const variants = {
    open: { opacity: 1, height },
    closed: { opacity: 0, height: 0 },
  };

  const toggleConditionPart = () => {
    setIsOpen((prev) => !prev);
  };

  const isClosed = amount === 0;

  return (
    <div
      className={cn(
        s.levelCard,
        s[`level-${level}`],
        !acquired && s.notAcquired,
      )}
    >
      <div className={s.topLine} />
      <div className={s.content}>
        <div className={s.titleWrapper}>
          <h6 className={s.title}>Level {titleObject[level]}</h6>
          <Icon
            variant="arrowDown"
            className={cn(s.arrowDownIcon, isOpen && s.active)}
          />
        </div>
        <div className={cn(s.line, s.lineAmount)}>
          <div className={s.personWrapper}>
            <img
              src={personImageObject[level]}
              alt=""
              className={s.personImage}
            />
          </div>
          <span className={s.lineText}>{countObject[level]} referrals:</span>
          <span className={s.lineValue}>{amount}</span>
        </div>
        <div className={cn(s.line, s.lineReferral)}>
          <Coin currency="matic" wrapperClassName={s.coinIcon} />
          <span className={s.lineValue}>{referralPercentObject[level]}</span>
          <span className={s.lineText}>from purchases</span>
        </div>

        <button
          type="button"
          className="fill-link"
          onClick={toggleConditionPart}
        >
          open condition part
        </button>
      </div>
      <div className={s.medalWrapper}>
        <img src={medalImageObject[level]} alt="" className="fill-cover" />
        <Icon variant="lock" className={s.lockIcon} />
      </div>
      <motion.div
        className={s.conditionPartWrapper}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div className={s.conditionPart} ref={contentRef}>
          <p className={s.conditionTitle}>
            {countStringifyObject[level]} level conditions (one is enough):
          </p>
          <ul className={s.conditions}>
            {conditionsObject[level].map((elem, index) => (
              <li className={s.condition} key={elem}>
                Own <b>{elem}</b> {conditionTownName[index]} Towns
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
    </div>
  );
}

export default LevelCard;
