import TextField from "components/common/TextField";
import { Formik, FormikHelpers, FormikProps } from "formik";
import useRequestVerifyCode from "hooks/requests/useRequestVerifyCode";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useReCAPTCHAv3 from "hooks/useReCAPTCHAv3";
import useRequestAuth, {
  isGetAuthResponse,
} from "hooks/requests/useRequestAuth";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  setAuthCaptchaSource,
  setIsAuthCaptchaOpened,
  solveCaptcha,
} from "store/appStateSlice";
import { changeAuthModalStage } from "store/modalsSlice";
import { setEmail } from "store/userProfileSlice";
import * as Yup from "yup";
import ModalButton from "../ModalButton";
import NewAuthSwitcher from "../NewAuthSwitcher";

import s from "./VerifyCodeContent.module.scss";

interface ValuesType {
  code?: number | string;
}

const initialValues: ValuesType = {
  code: "",
};

interface VerifyCodeContentProps {
  isRegistration?: boolean;
}

function VerifyCodeContent({ isRegistration = false }: VerifyCodeContentProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mutateAsync: sendRequest } = useRequestVerifyCode();
  const { recaptchaToken } = useReCAPTCHAv3("login");
  const { email } = useAppSelector((state) => state.modals.logInModal);
  const { mutateAsync: auth } = useRequestAuth();
  const [resended, setResended] = useState(false);
  const [countdown, setCountdown] = useState(90);
  const [seconds, setSeconds] = useState(90);

  const onClickBack = useCallback(() => {
    if (isRegistration) {
      dispatch(changeAuthModalStage("register"));
    } else {
      dispatch(changeAuthModalStage("logIn"));
    }
  }, [dispatch, isRegistration]);

  const formDataSchema: Yup.ObjectSchema<ValuesType> = Yup.object().shape({
    code: Yup.number().positive().integer().required(),
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resended && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setResended(false);
    }
    return () => clearInterval(interval);
  }, [countdown, resended]);

  const handleResend = useCallback(async () => {
    const data = await auth({
      email,
      token: recaptchaToken,
      source: "login/registration",
    });
    if (isGetAuthResponse(data) && data.ok) {
      setResended(true);
      setSeconds((prev) => prev + 10);
      setCountdown(seconds);
    }
  }, [email, recaptchaToken, seconds, auth]);

  const onSubmit = useCallback(
    async ({ code }: ValuesType, actions: FormikHelpers<ValuesType>) => {
      actions.setSubmitting(true);
      const data = await sendRequest({ code: `${code}` });

      if (data.ok) {
        dispatch(setEmail(data.email));
        if (typeof window.gtag === "function") {
          window.gtag("event", "loged_in", {
            event_category: "log_in",
          });
        }
      }
      actions.setSubmitting(false);
    },
    [dispatch, sendRequest],
  );

  return (
    <div className={s.modal}>
      <NewAuthSwitcher isRegistrationLocked isRegistration={isRegistration} />
      <Formik
        initialValues={initialValues}
        validationSchema={formDataSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }: FormikProps<ValuesType>) => (
          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.formContainer}>
              <div className={s.enterCode}>{t("modals.verify.title")}</div>
              <TextField
                // label={t("modals.verify.codeLabel")}
                placeholder="282728292"
                name="code"
                hasError={touched.code && Boolean(errors.code)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
              />
            </div>
            <div className={s.formContainer}>
              <div className={s.buttons}>
                <ModalButton
                  type="button"
                  onClick={onClickBack}
                  className={s.back}
                  variant="cancel"
                >
                  {t("common.goBack")}
                </ModalButton>
                <ModalButton
                  className={s.submit}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!dirty || Boolean(errors.code)}
                >
                  {t("modals.verify.verify")}
                </ModalButton>
              </div>
              <div className={s.resend}>
                {resended ? (
                  <p className={s.resendedButton}>
                    You can resend the code in: {Math.floor(countdown / 60)} min{" "}
                    {countdown % 60} sec
                  </p>
                ) : (
                  <button
                    type="button"
                    onClick={handleResend}
                    className={s.resendButton}
                  >
                    Resend the code
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default VerifyCodeContent;
