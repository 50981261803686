import TelegramTabContext from "helpers/TelegramTabContextProvider";
import useTrackTelegramTab from "helpers/useTrackTelegramTab";
import useRequestAuthVerifyTg from "hooks/requests/useRequestAuthVerifyTg";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeAuthModalStage } from "store/modalsSlice";
import ModalButton from "../ModalButton";
import NewAuthSwitcher from "../NewAuthSwitcher";

import s from "./VerifyTelegram.module.scss";

interface VerifyTelegramProps {
  isRegistration: boolean;
}

function VerifyTelegram({ isRegistration }: VerifyTelegramProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { refetch: verifyTgLink, isFetching } = useRequestAuthVerifyTg();
  const tgAuthTab = useContext(TelegramTabContext);

  const handleTabClosed = useCallback(() => {
    verifyTgLink();
  }, [verifyTgLink]);

  const onClickBack = () => {
    if (isRegistration) {
      dispatch(changeAuthModalStage("register"));
    } else {
      dispatch(changeAuthModalStage("logIn"));
    }
  };

  useTrackTelegramTab(handleTabClosed, tgAuthTab);

  const onClickVerify = () => {
    verifyTgLink();
  };

  return (
    <div className={s.modal}>
      <NewAuthSwitcher
        isRegistrationLocked
        isRegistration={isRegistration}
        className={s.switcher}
      />
      <div className={s.title}>
        {isRegistration ? "Register via Telegram" : t("common.loginTg")}
      </div>
      <div className={s.buttons}>
        <ModalButton
          type="button"
          onClick={onClickBack}
          className={s.back}
          variant="cancel"
        >
          {t("common.goBack")}
        </ModalButton>
        <ModalButton
          className={s.submit}
          type="button"
          onClick={onClickVerify}
          isLoading={isFetching}
        >
          {t("common.verify")}
        </ModalButton>
      </div>
    </div>
  );
}

export default VerifyTelegram;
