import { useQueryClient } from "@tanstack/react-query";

const useGetRefreshReferralRequests = () => {
  const queryClient = useQueryClient();

  return () => {
    localStorage.removeItem("referral_level");
    localStorage.removeItem("referral_income");
    queryClient.invalidateQueries({ queryKey: ["referral", "income"] });
    queryClient.invalidateQueries({ queryKey: ["referral", "level"] });
    queryClient.invalidateQueries({ queryKey: ["referral", "code"] });
    queryClient.invalidateQueries({ queryKey: ["referral", "list"] });
  };
};

export default useGetRefreshReferralRequests;
