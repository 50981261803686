type FieldsToConvert =
  | "matic"
  | "usdt"
  | "trc"
  | "ace"
  | "wbtc"
  | "weth"
  | "amount"
  | "value_or_id"
  | "total_output"
  | "price"
  | "min_fee_trc"
  | "fee";

const convertFieldsToString = (
  json: string,
  fields: FieldsToConvert[],
): string => {
  let result = json;
  fields.forEach((field) => {
    const regex = new RegExp(`("${field}":\\s*)(\\d+(\\.\\d+)?)`, "g");
    result = result.replace(regex, `$1"$2"`);
  });
  return result;
};

const convertFieldsToNumber = (
  json: string,
  fields: FieldsToConvert[],
): string => {
  let result = json;
  fields.forEach((field) => {
    const regex = new RegExp(`("${field}":\\s*)"(\\d+(\\.\\d+)?)"`, "g");
    result = result.replace(regex, `$1$2`);
  });
  return result;
};

export { convertFieldsToString, convertFieldsToNumber };
