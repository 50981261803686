import cn from "classnames";
import { useTranslation } from "react-i18next";

import AppReleaseBanner from "components/AppReleaseBanner";
import BalanceContainer from "components/BalanceContainer";
import {
  FillUpModal,
  MoonPayModal,
  WalletActionModal,
} from "components/modals";
import ProjectPath from "constants/paths";
import { walletPricedCurrencies } from "constants/wallet";
import useGetBalance from "hooks/requests/useGetBalance";
import useGetBTCandETHprices from "hooks/requests/useGetBTCandETHprices";
import useGetCurrenciesUSDPrices from "hooks/requests/useGetCurrenciesUSDPrices";
import useGetProfile from "hooks/requests/useGetProfile";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useGetNftListFromBalance from "hooks/useGetNftListFromBalance";
import createMoonPay from "moonPay";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  openBuyTraceProModal,
  openFillUpModal,
  openWalletActionModal,
  openWelcomeWideModal,
} from "store/modalsSlice";
import { JustCoinType } from "types/coins";
import Actions from "./parts/Actions";
import NFTs from "./parts/NFTs";
import Tabs, { ITab } from "./parts/Tabs";
import BuyWidget from "./parts/BuyWidget";

import s from "./WalletPage.module.scss";

interface WalletPageProps {
  tab: `/${ProjectPath.WALLET}` | `/${ProjectPath.WALLET_NFT}`;
}

function WalletPage({ tab }: WalletPageProps) {
  const { t } = useTranslation();
  useGetProfile();
  const dispatch = useAppDispatch();
  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();
  const { data: currenciesUSDPrices } = useGetCurrenciesUSDPrices(
    walletPricedCurrencies,
  );
  const { walletAddress, email, hasSubscription } = useAppSelector(
    (state) => state.userProfile,
  );
  const { data: WPrices } = useGetBTCandETHprices();
  const nftList = useGetNftListFromBalance(balance);
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs: ITab[] = useMemo(
    () => [
      {
        title: t("common.currency"),
        link: `/${ProjectPath.WALLET}`,
      },
      {
        title: "NFT",
        link: `/${ProjectPath.WALLET_NFT}`,
      },
    ],
    [t],
  );

  const currentTabIdx: number = tabs.findIndex((_tab) => _tab.link === tab);

  useEffect(() => {
    if (searchParams.get("welcomeModal")) {
      dispatch(openWelcomeWideModal());
      if (hasSubscription === false) {
        dispatch(openBuyTraceProModal());
      }
      setSearchParams(undefined);
    }
  }, [dispatch, searchParams, setSearchParams, hasSubscription]);

  const openWalletModal = useCallback(
    (entity: `${JustCoinType}`) => () => {
      dispatch(openWalletActionModal(entity));
    },
    [dispatch],
  );

  const handleOpenFillUpModal = useCallback(() => {
    dispatch(openFillUpModal());
  }, [dispatch]);

  return (
    <div className={s.walletPage}>
      <div className={s.container}>
        <Actions
          openFillUpModal={handleOpenFillUpModal}
          currenciesUSDPrices={currenciesUSDPrices}
        />
        <div className={cn(s.widget)}>
          <div className={s.tabs}>
            <Tabs tabs={tabs} currentTabIdx={currentTabIdx} />
          </div>
          {tab === `/${ProjectPath.WALLET}` && (
            <div className={s.coinsBalance}>
              {(isBalanceLoading || balance?.trc !== undefined) && (
                <BalanceContainer
                  value={balance?.trcConverted || 0}
                  valueUSD={
                    currenciesUSDPrices?.trc && balance?.trcConverted
                      ? currenciesUSDPrices.trc * Number(balance.trcConverted)
                      : undefined
                  }
                  priceUSD={currenciesUSDPrices?.trc}
                  currency="trc"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("trc")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                />
              )}

              {(isBalanceLoading || balance?.ace !== undefined) && (
                <BalanceContainer
                  value={balance?.aceConverted || 0}
                  valueUSD={
                    WPrices?.acePrice && balance?.aceConverted
                      ? WPrices.acePrice * Number(balance.aceConverted)
                      : undefined
                  }
                  priceUSD={WPrices?.acePrice}
                  currency="ace"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("ace")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                  numberAfterDot={8}
                />
              )}

              {(isBalanceLoading || balance?.matic !== undefined) && (
                <BalanceContainer
                  value={balance?.maticConverted || 0}
                  valueUSD={
                    currenciesUSDPrices?.matic && balance?.maticConverted
                      ? currenciesUSDPrices.matic *
                        Number(balance.maticConverted)
                      : undefined
                  }
                  priceUSD={currenciesUSDPrices?.matic}
                  currency="matic"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("matic")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                />
              )}

              {(isBalanceLoading || balance?.usdt !== undefined) && (
                <BalanceContainer
                  value={balance?.usdtConverted || 0}
                  valueUSD={
                    currenciesUSDPrices?.usdt && balance?.usdtConverted
                      ? currenciesUSDPrices.usdt * Number(balance.usdtConverted)
                      : undefined
                  }
                  priceUSD={currenciesUSDPrices?.usdt}
                  currency="usdt"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("usdt")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                />
              )}

              {(isBalanceLoading || balance?.wbtc !== undefined) && (
                <BalanceContainer
                  value={balance?.wbtcConverted || 0}
                  valueUSD={
                    WPrices?.btcPrice && balance?.wbtcConverted
                      ? WPrices.btcPrice * Number(balance.wbtcConverted)
                      : undefined
                  }
                  priceUSD={WPrices?.btcPrice}
                  currency="wbtc"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("wbtc")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                  numberAfterDot={5}
                />
              )}

              {(isBalanceLoading || balance?.weth !== undefined) && (
                <BalanceContainer
                  value={balance?.wethConverted || 0}
                  valueUSD={
                    WPrices?.ethPrice && balance?.wethConverted
                      ? WPrices.ethPrice * Number(balance.wethConverted)
                      : undefined
                  }
                  priceUSD={WPrices?.ethPrice}
                  currency="weth"
                  variant="wallet"
                  loading={isBalanceLoading}
                  onClick={openWalletModal("weth")}
                  coinIconWrapper={s.coinIconWrapper}
                  approximately={false}
                  numberAfterDot={5}
                />
              )}
            </div>
          )}

          {tab === `/${ProjectPath.WALLET_NFT}` && <NFTs nftList={nftList} />}
        </div>
      </div>
      <AppReleaseBanner wrapperClassName={s.sideBanner} />
      <WalletActionModal />
      <FillUpModal />

      <MoonPayModal
        onClick={() =>
          createMoonPay({ type: "matic_polygon", walletAddress, email })
        }
      />
    </div>
  );
}

export default WalletPage;
