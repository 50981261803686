import mexc from "assets/images/dashboard/mexc.svg";
import bitmart from "assets/images/dashboard/bitmart.svg";
import quickswap from "assets/images/dashboard/quickswap.svg";
import uniswap from "assets/images/dashboard/uniswap.svg";
import bitget from "assets/images/dashboard/bitget.png";
import bingx from "assets/images/dashboard/bingx.svg";
import bybit from "assets/images/dashboard/bybit.svg";

import cmc from "assets/images/dashboard/cmc.svg";
import coingecko from "assets/images/dashboard/coingecko.svg";
import coinpaprika from "assets/images/dashboard/coinpaprika.png";
import cryptorank from "assets/images/dashboard/cryptorank.png";

export const tokensTRCExchangePlatforms = [
  {
    id: 0,
    title: "Bybit",
    source: bybit,
    link: "https://www.bybit.com/en/trade/spot/TRC/USDT",
  },
  {
    id: 1,
    title: "Uniswap",
    source: uniswap,
    link: "https://app.uniswap.org/swap?outputCurrency=0x40d9fc77027a281d85de1fa660c887e645ae26c3",
  },
  {
    id: 2,
    title: "QuickSwap",
    source: quickswap,
    link: "https://quickswap.exchange/#/swap?outputCurrency=0x40d9fc77027a281d85de1fa660c887e645ae26c3",
  },
  {
    id: 3,
    title: "MEXC",
    source: mexc,
    link: "https://www.mexc.com/ru-RU/login?inviteCode=mexc-1hZbR",
  },
  {
    id: 4,
    title: "BitMart",
    source: bitmart,
    link: "https://www.bitmart.com/register-referral/en-US?r=ed7a4X",
  },
  {
    id: 5,
    title: "BingX",
    source: bingx,
    link: "https://bit.ly/TRC_BingX",
  },
  {
    id: 6,
    title: "Bitget",
    source: bitget,
    link: "https://www.bitget.com/en/spot/TRCUSDT?type=spot",
  },
];
export const tokensTRCRatingsPlatforms = [
  {
    id: 0,
    title: "CoinMarketCap",
    source: cmc,
    link: "https://coinmarketcap.com/ru/currencies/metatrace/",
  },
  {
    id: 1,
    title: "CoinGecko",
    source: coingecko,
    link: "https://www.coingecko.com/en/coins/metatrace",
  },
  {
    id: 2,
    title: "Coinpaprika",
    source: coinpaprika,
    link: "https://coinpaprika.com/valjuta/trc-metatrace/",
  },
  {
    id: 3,
    title: "CryptoRank",
    source: cryptorank,
    link: "https://cryptorank.io/price/metatrace",
  },
];

export const tokensACEExchangePlatforms = [
  {
    id: 0,
    title: "Uniswap",
    source: uniswap,
    link: "https://app.uniswap.org/explore/tokens/polygon/0x9627a3d6872bE48410fCEce9b1dDD344Bf08c53e",
  },
];

export const tokensACERatingsPlatforms = [
  {
    id: 0,
    title: "CoinMarketCap",
    source: cmc,
    link: "https://coinmarketcap.com/currencies/metatrace-me/",
  },
  {
    id: 1,
    title: "CoinGecko",
    source: coingecko,
    link: "https://www.coingecko.com/en/coins/metatrace-utility-token",
  },
];
