import BackButton from "components/common/BackButton";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import WalletSwapCoinChooseModal from "components/modals/WalletSwapCoinChooseModal";
import WalletSwapModal from "components/modals/WalletSwapModal";
import PopUpTooltip from "components/PopUpTooltip";
import { Formik, FormikProps } from "formik";
import useGetBalance from "hooks/requests/useGetBalance";
import useGetSwapCost from "hooks/requests/useGetSwapCost";
import useMakeSwap from "hooks/requests/useMakeSwap";
import { useAppSelector } from "hooks/store";
import useDebounce from "hooks/useDebounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ESwapCoins } from "types/coins";
import { EWalletSwapCoinChooseModalType } from "types/modals";
import * as Yup from "yup";
import GasContainer from "../GasContainer";
import ExchangeRate from "./parts/ExchangeRate";
import FromToken from "./parts/FromToken";
import SwapFee from "./parts/SwapFee";
import ToToken from "./parts/ToToken";
import swapStyle from "./SwapContent.module.scss";
import {
  calculateMinFromAmount,
  createGetSwapCostBody,
  createMakeSwapBody,
  getDisabledCoins,
  getToCurrencyByFromCurrency,
  SwapCostFormValues,
  updateInitialValues,
} from "./utils";

import s from "../TransactionPage.module.scss";

type SwapContentProps = {
  /** children MUST be only side banners */
  children?: React.ReactNode;
};

function SwapContent({ children = undefined }: SwapContentProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { currency } = useParams();
  const formikRef = useRef<FormikProps<SwapCostFormValues>>(null);

  const coinChooseModalType = useAppSelector(
    (state) => state.modals.walletSwapCoinChooseModal.type,
  );

  const [fromCurrency, setFromCurrency] = useState<ESwapCoins>(
    () => (currency as ESwapCoins | null) ?? ESwapCoins.trc,
  );

  const [toCurrency, setToCurrency] = useState<ESwapCoins>(() =>
    searchParams.get("from") === "widget"
      ? ESwapCoins.trc
      : getToCurrencyByFromCurrency(ESwapCoins[fromCurrency]),
  );

  const defaultMinFromAmount = calculateMinFromAmount(fromCurrency);
  const [minFromAmount, setMinFromAmount] = useState(defaultMinFromAmount);
  const [fromAmountRequest, setFromAmountRequest] = useState<string | null>(
    null,
  );
  const debouncedFromAmountRequest = useDebounce(
    Number(fromAmountRequest) ?? 0,
    500,
  );
  const [initialValues, setInitialValues] = useState<SwapCostFormValues | null>(
    null,
  );
  const { mutate: makeSwap, isLoading: isMakingSwap } = useMakeSwap();
  const { data } = useGetBalance();
  const balance = useMemo(
    () => ({
      [ESwapCoins.matic]: data?.maticConverted || "0",
      [ESwapCoins.trc]: data?.trcConverted || "0",
      [ESwapCoins.usdt]: data?.usdtConverted || "0",
      [ESwapCoins.ace]: data?.aceConverted || "0",
      [ESwapCoins.wbtc]: data?.wbtcConverted || "0",
      [ESwapCoins.weth]: data?.wethConverted || "0",
    }),
    [data],
  );
  const balanceFull = useMemo(
    () => ({
      [ESwapCoins.matic]: data?.maticConvertedFull || "0",
      [ESwapCoins.trc]: data?.trcConvertedFull || "0",
      [ESwapCoins.usdt]: data?.usdtConvertedFull || "0",
      [ESwapCoins.ace]: data?.aceConverted || "0",
      [ESwapCoins.wbtc]: data?.wbtcConverted || "0",
      [ESwapCoins.weth]: data?.wethConverted || "0",
    }),
    [data],
  );
  const disabledCoins = useMemo(
    () =>
      getDisabledCoins(
        EWalletSwapCoinChooseModalType.to,
        fromCurrency,
        toCurrency,
      ) ?? [],
    [fromCurrency, toCurrency],
  );
  const { data: swapCostData, isLoading } = useGetSwapCost({
    ...createGetSwapCostBody(
      fromCurrency,
      toCurrency,
      debouncedFromAmountRequest || defaultMinFromAmount,
    ),
    enabled: debouncedFromAmountRequest
      ? debouncedFromAmountRequest >= minFromAmount
      : !disabledCoins.includes(toCurrency),
  });

  useEffect(() => {
    if (swapCostData?.data) {
      if (fromAmountRequest === null) {
        setInitialValues(
          updateInitialValues(
            swapCostData.data,
            fromCurrency,
            toCurrency,
            minFromAmount.toString(),
            t,
          ),
        );
      } else {
        const { exchangeRate, swapFee, toAmount, toAmountLabel } =
          updateInitialValues(
            swapCostData.data,
            fromCurrency,
            toCurrency,
            debouncedFromAmountRequest
              ? debouncedFromAmountRequest.toString()
              : minFromAmount.toString(),
            t,
          );
        if (formikRef.current) {
          formikRef.current.setFieldValue("toAmount", toAmount);
          formikRef.current.setFieldValue("exchangeRate", exchangeRate);
          formikRef.current.setFieldValue("swapFee", swapFee);
          formikRef.current.setFieldValue("toAmountLabel", toAmountLabel);
        }
      }
    }
  }, [
    fromCurrency,
    toCurrency,
    swapCostData,
    debouncedFromAmountRequest,
    t,
    fromAmountRequest,
    minFromAmount,
  ]);

  useEffect(() => {
    const newMinFromAmount = calculateMinFromAmount(fromCurrency);

    if (fromCurrency === toCurrency) {
      setToCurrency(getToCurrencyByFromCurrency(ESwapCoins[toCurrency]));
    }

    if (
      (fromCurrency === ESwapCoins.usdt ||
        fromCurrency === ESwapCoins.matic ||
        fromCurrency === ESwapCoins.wbtc ||
        fromCurrency === ESwapCoins.weth) &&
      disabledCoins.includes(toCurrency)
    ) {
      setToCurrency(ESwapCoins.trc);
    }

    if (
      (fromCurrency === ESwapCoins.trc || fromCurrency === ESwapCoins.ace) &&
      disabledCoins.includes(toCurrency)
    ) {
      setToCurrency(ESwapCoins.matic);
    }

    setMinFromAmount(newMinFromAmount);
    formikRef.current?.setFieldValue("fromAmount", newMinFromAmount);
  }, [fromCurrency, toCurrency]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      fromAmount: Yup.string()
        .required(t("swap.validationMessages.required"))
        .test(
          "minimum",
          `${t(
            "swap.validationMessages.minimum",
          )} ${minFromAmount} ${fromCurrency.toUpperCase()}`,
          (value) => {
            return parseFloat(value) >= minFromAmount;
          },
        )
        .test("enoughMoney", t("swap.validationMessages.enoughMoney"), () => {
          return balance[fromCurrency]
            ? Number(balance[fromCurrency]) >= minFromAmount
            : true;
        })
        .test(
          "range",
          `${t("swap.validationMessages.rangePart1")} ${minFromAmount} ${t(
            "swap.validationMessages.rangePart2",
          )} ${balance[fromCurrency]} ${t(
            "swap.validationMessages.rangePart3",
          )}`,
          (value) => {
            const numericValue = parseFloat(value);
            return balance[fromCurrency]
              ? numericValue >= minFromAmount &&
                  numericValue <= Number(balanceFull[fromCurrency])
              : true;
          },
        ),
    });
  }, [balance, balanceFull, fromCurrency, minFromAmount, t]);

  const handleSwap = () => {
    const currentFromCurrency = fromCurrency;
    const currentToCurrency = toCurrency;
    const newMinFromAmount = calculateMinFromAmount(currentToCurrency);

    setFromCurrency(currentToCurrency);
    setToCurrency(currentFromCurrency);
    setMinFromAmount(newMinFromAmount);
    setFromAmountRequest(`${newMinFromAmount}`);
    formikRef.current?.setFieldValue("fromAmount", newMinFromAmount);
  };

  const onSubmit = useCallback(
    (values: SwapCostFormValues) => {
      makeSwap(
        createMakeSwapBody(fromCurrency, toCurrency, Number(values.fromAmount)),
      );
      formikRef.current?.resetForm();
      setFromAmountRequest(null);
    },
    [fromCurrency, toCurrency, makeSwap],
  );

  return (
    <>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <BackButton className={s.backButton} />
          <div className={s.title}>
            <span>{t("common.pages.swap")}</span>
            <Icon
              variant="swap_alt2"
              wrapperClassName={s.iconWrapper}
              className={s.icon}
              withWrapper
            />
          </div>
        </div>

        {balance && initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
            validateOnMount
          >
            {({
              values,
              errors,
              handleSubmit,
            }: FormikProps<SwapCostFormValues>) => {
              const isButtonDisabled = (() => {
                return (
                  Number(values.fromAmount) === 0 || Boolean(errors.fromAmount)
                );
              })();
              return (
                <form onSubmit={handleSubmit}>
                  <div className={swapStyle.fullTokensContainer}>
                    <div className={swapStyle.tokensContainer}>
                      <FromToken
                        balance={balance[fromCurrency]}
                        balanceFull={balanceFull[fromCurrency]}
                        fromCurrency={fromCurrency.toUpperCase()}
                        errors={errors}
                        setFromAmountRequest={setFromAmountRequest}
                        setFromCurrency={setFromCurrency}
                      />
                      <ToToken
                        toCurrency={toCurrency}
                        fromCurrency={fromCurrency}
                        isLoading={isLoading}
                        label={values.toAmountLabel}
                        setToCurrency={setToCurrency}
                      />
                      <PopUpTooltip text="Switch pair" swap>
                        <Button
                          className={swapStyle.swapButton}
                          onClick={handleSwap}
                          variant="filledSkyBlue"
                        >
                          <Icon variant="swap" className={swapStyle.swapIcon} />
                        </Button>
                      </PopUpTooltip>
                    </div>
                    {errors.fromAmount && (
                      <div className={swapStyle.errorContainer}>
                        <div className={swapStyle.errorText}>
                          {errors.fromAmount.toString()}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={swapStyle.bottomPart}>
                    <div className={swapStyle.bottomContent}>
                      <ExchangeRate
                        className={swapStyle.container}
                        value={values.exchangeRate}
                        isLoading={isLoading}
                      />
                      <SwapFee
                        className={`${swapStyle.container} ${swapStyle.borderedContainer}`}
                        value={values.swapFee}
                        isLoading={isLoading}
                      />
                      <GasContainer className={swapStyle.container} />
                    </div>
                    <Button
                      type="submit"
                      className={s.submit}
                      isLoading={isMakingSwap}
                      disabled={isButtonDisabled}
                    >
                      {t("common.pages.swap")}
                    </Button>
                  </div>
                  <div className={s.mobileSideBanner}>{children}</div>
                </form>
              );
            }}
          </Formik>
        )}

        <WalletSwapModal />

        <WalletSwapCoinChooseModal
          disabledCoins={getDisabledCoins(
            coinChooseModalType,
            fromCurrency,
            toCurrency,
          )}
          setCoin={
            coinChooseModalType === EWalletSwapCoinChooseModalType.from
              ? setFromCurrency
              : setToCurrency
          }
        />
      </div>
      <div className={s.sideBanner}>{children}</div>
    </>
  );
}

export default SwapContent;
