export enum IconsVariant {
  adsArrow = "ads-arrow",
  adsSvg = "adsSvg",
  arrowDown = "arrowDown",
  arrowDownRoundedSvg = "arrowDownRoundedSvg",
  arrowPointing = "arrowPointing",
  arrowsIn = "arrowsIn",
  arrowThick = "arrowThick",
  attack = "attack",
  attackSvg = "attackSvg",
  backspace = "backspace",
  battery = "battery",
  box = "box",
  buy_crypto = "buy_crypto",
  buycrypto_alt = "buycrypto_alt",
  change_price = "change_price",
  chats = "chats",
  dots = "dots",
  gem = "gem",
  home = "home",
  lottery = "lottery",
  discord = "discord",
  towns = "towns",
  defenseSvg = "defenseSvg",
  elementSvg = "elementSvg",
  raritySvg = "raritySvg",
  clockAlarmSvg = "clockAlarmSvg",
  logInSvg = "logInSvg",
  logOutSvg = "logOutSvg",
  location = "location",
  Mega_Town = "Mega_Town",
  Small_Town = "Small_Town",
  Millionaire = "Millionaire",
  Medium_Town = "Medium_Town",
  Big_Town = "Big_Town",
  Large_Town = "Large_Town",
  Exclusive_Town = "Exclusive_Town",
  check = "check",
  chest = "chest",
  clock = "clock",
  close = "close",
  copy = "copy",
  copy_rect = "copy_rect",
  dashboard = "dashboard",
  diamond = "diamond",
  down = "down",
  edit = "edit",
  energy = "energy",
  global = "global",
  information = "information",
  label = "label",
  locker = "locker",
  logOut = "logOut",
  luck = "luck",
  lvlUp = "lvlUp",
  market = "market",
  matic = "matic",
  moneyBag = "moneyBag",
  moonPay = "moonPay",
  pensil = "pensil",
  people = "people",
  person = "person",
  plus = "plus",
  prize = "prize",
  protect = "protect",
  repair = "repair",
  restart = "restart",
  sell = "sell",
  settings = "settings",
  settingsSvg = "settingsSvg",
  sort = "sort",
  speed = "speed",
  spiral = "spiral",
  star = "star",
  stop_selling = "stop_selling",
  svgWallet = "svgWallet",
  swap = "swap",
  swap_alt = "swap_alt",
  swap_alt2 = "swap_alt2",
  telegram = "telegram",
  tier = "tier",
  trace_pro = "trace_pro",
  trade = "trade",
  transfer = "transfer",
  trendyCrown = "trendyCrown",
  up = "up",
  wallet = "wallet",
  walletSvg = "walletSvg",
  weight = "weight",
  X = "X",
  income = "income",
  profile = "profile",
  send = "send",
  exchange = "exchange",
  success = "success",
  error = "error",
  external_resource = "external_resource",
  personPlus = "personPlus",
  lock = "lock",
  telegramSvg = "telegramSvg",
  crown = "crown",
  game_shop = "game_shop",
  warningExclamationSvg = "warningExclamationSvg",
  star_empty_inside = "star_empty_inside",
  arrowRight = "arrowRight",
  instagram = "instagram",
  telegramFilled = "telegramFilled",
  tokens = "tokens",
}

export type IconColor =
  | "golden"
  | "purple"
  | "green"
  | "green2"
  | "blue"
  | "white"
  | "whiteBlueBack"
  | "whiteOrangeBack"
  | "attack"
  | "defense"
  | "element"
  | "gray"
  | "Mega_Town"
  | "Small_Town"
  | "Millionaire"
  | "Medium_Town"
  | "Big_Town"
  | "Large_Town"
  | "Exclusive_Town";

export type IconMapKey =
  | IconsVariant.arrowThick
  | IconsVariant.pensil
  | IconsVariant.moneyBag
  | IconsVariant.locker
  | IconsVariant.moonPay
  | IconsVariant.swap
  | IconsVariant.clock
  | IconsVariant.trendyCrown
  | IconsVariant.settingsSvg
  | IconsVariant.arrowDownRoundedSvg
  | IconsVariant.walletSvg
  | IconsVariant.attackSvg
  | IconsVariant.raritySvg
  | IconsVariant.defenseSvg
  | IconsVariant.elementSvg
  | IconsVariant.adsSvg
  | IconsVariant.clockAlarmSvg
  | IconsVariant.logInSvg
  | IconsVariant.logOutSvg
  | IconsVariant.exchange
  | IconsVariant.income
  | IconsVariant.profile
  | IconsVariant.send
  | IconsVariant.success
  | IconsVariant.error
  | IconsVariant.telegramSvg
  | IconsVariant.warningExclamationSvg
  | IconsVariant.arrowRight;

export type IconMapValue = (className: string) => JSX.Element;
