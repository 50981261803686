import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { setAuthToken } from "store/appStateSlice";

function AuthTokenSetter() {
  const dispatch = useAppDispatch();
  const { authToken } = useAppSelector((state) => state.appState);
  const cookie = useGetAuthCookie();

  useEffect(() => {
    if (!authToken && cookie) {
      dispatch(setAuthToken(cookie));
    }
  }, [authToken, cookie]);

  return null;
}

export default AuthTokenSetter;
