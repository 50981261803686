import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { IconsVariant } from "types/icons";

import Icon from "components/common/Icon";

import s from "./ActionElement.module.scss";

interface IProps {
  title: string;
  iconVariant: `${IconsVariant}`;
  codename: "fillUp" | "buyCrypto" | "exchange" | "transfer";
  link?: string;
  handleClick?: () => void;
}

function ActionElement({
  title,
  iconVariant,
  codename,
  link = "",
  handleClick = undefined,
}: IProps) {
  return (
    <div className={cn(s.wrapper, s[codename])}>
      <div key={title} className={s.action}>
        {codename === "fillUp" && (
          <div className={s.iconWrapper}>
            <Icon className={cn(s.icon)} variant={iconVariant} />
          </div>
        )}
        {codename !== "fillUp" && (
          <Icon className={cn(s.icon)} variant={iconVariant} />
        )}
        {link && <NavLink to={link} className="fill-link" />}
        {handleClick && (
          <button type="button" onClick={handleClick} className="fill-link">
            {title}
          </button>
        )}
      </div>
      <div className={s.title}>{title}</div>
    </div>
  );
}

export default ActionElement;
