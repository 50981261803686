import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import ApiError from "classes/ApiError";

import { showErrors } from "utils/showErrors";

import { getCurrenciesUSDPrices } from "api/wallet";

const useGetCurrenciesUSDPrices = (currencies: string[]) => {
  const currenciesUSDPrices = useQuery<any | null, Error>(
    ["currenciesUSDPrices"],
    async () => {
      return getCurrenciesUSDPrices(currencies);
    },
  );

  useEffect(() => {
    if (
      currenciesUSDPrices.isError &&
      currenciesUSDPrices.error instanceof ApiError
    ) {
      showErrors(currenciesUSDPrices.error);
    }
  }, [currenciesUSDPrices.isError, currenciesUSDPrices.error]);

  return currenciesUSDPrices;
};

export default useGetCurrenciesUSDPrices;
