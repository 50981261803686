import React from "react";
import EconomicModel from "./parts/EconomicModel";
import TokenUtilization from "./parts/TokenUtilization";

import s from "./EconomicModelSection.module.scss";

function EconomicModelSection() {
  return (
    <div className={s.wrapper}>
      <EconomicModel />
      <TokenUtilization />
    </div>
  );
}

export default EconomicModelSection;
