import SimpleLoader from "components/common/SimpleLoader";
import useGetDashboard from "hooks/requests/useGetDashboard";
import React from "react";
import { NavLink } from "react-router-dom";
import socialMediaList from "./socialMedia";

import s from "./SocialMediaSection.module.scss";

function SocialMediaSection() {
  const { data, isLoading } = useGetDashboard();

  const ratingMap: Record<string, number | undefined> = {
    discord: data?.discordFollowersCount,
    telegram: data?.telegramFollowersCount,
    twitter: data?.twitterFollowersCount,
  };

  return (
    <div className={s.wrapper}>
      <div className={s.title}>Social Media</div>
      <div className={s.content}>
        {socialMediaList.map((item) => (
          <NavLink
            to={item.link}
            className={s.card}
            target="_blank"
            key={item.id}
          >
            <div>
              <img src={item.source} alt="" className={s.icon} />
              <span className={s.name}>{item.name}</span>
            </div>
            {isLoading && <SimpleLoader />}
            {!isLoading && (
              <div className={s.rating}>
                {`${`${(item.rating ? item.rating : ratingMap[item.id] ?? "~")
                  .toString()
                  .slice(0, 2)}`}k`}
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default SocialMediaSection;
