/* eslint-disable eqeqeq */
const objectToQueryString = (object: any, toLowerCase: boolean = false) => {
  if (!object) {
    return "";
  }
  const keys = Object.keys(object);
  const filteredKeys = keys.filter((key) => object[key] != undefined);

  if (filteredKeys.length === 0) {
    return "";
  }

  const queryParams = filteredKeys
    .flatMap((key) => {
      if (Array.isArray(object[key])) {
        const joinedValues = object[key]
          .map((value: any) => encodeURIComponent(value))
          .join(",");
        if (toLowerCase && key !== "typeNft") {
          return `${key.toLowerCase()}=${joinedValues}`;
        }
        return `${key}=${joinedValues}`;
      }
      if (toLowerCase && key !== "typeNft") {
        return `${key.toLowerCase()}=${object[key]}`;
      }
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");

  return `?${queryParams}`;
};

export default objectToQueryString;
