import React from "react";
import Loader from "react-loaders";

import s from "./ModalButton.module.scss";

type ButtonVariant = "default" | "cancel";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
}

function ModalButton({
  children = "",
  className = "",
  isLoading = false,
  disabled = false,
  variant = "default",
  ...props
}: Props) {
  const mainClassName = `${s.button} ${className} ${s[variant]} ${
    disabled ? s.disabled : ""
  }`;

  return (
    <button
      type="button"
      className={mainClassName}
      disabled={disabled || isLoading}
      {...props}
    >
      <div className={s.gradient} />
      {isLoading && (
        <div className={s.loaderWrapper}>
          <Loader
            type="line-scale-pulse-out-rapid"
            active
            innerClassName={s.loader}
          />
        </div>
      )}
      <div className={`${s.children} ${isLoading ? s.loading : ""}`}>
        {children}
      </div>
    </button>
  );
}

export default ModalButton;
