import useGetWindowWidth from "hooks/useGetWindowWidth";
import React, { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AreaStrokeColorMap, ChartLinesType, linesMap } from "../../types";
import dataPoints from "./dataPoints";
import CustomActiveDot from "./parts/CustomActiveDot";
import CustomLegend from "./parts/CustomLegend";
import CustomTickXAxis from "./parts/CustomTickXAxis";
import CustomTickYAxis from "./parts/CustomTickYAxis";
import CustomTooltip from "./parts/CustomTooltip";

import s from "./Chart.module.scss";

function Chart() {
  const ticks = [1, 2, 3, 4, 5];
  const [activeIndex, setActiveIndex] = useState(null);
  const windowWidth = useGetWindowWidth();

  const handleMouseMove = (state: any) => {
    if (state.isTooltipActive) {
      setActiveIndex(
        Number.isInteger(state.activeLabel) ? state.activeLabel : null,
      );
    } else {
      setActiveIndex(null);
    }
  };

  const customHorizontalCoordinatesGenerator = (data: any) => {
    return data?.yAxis?.ticks?.map((entry: any) => data?.yAxis?.scale(entry));
  };

  const customVerticalCoordinatesGenerator = (data: any) => {
    return data?.xAxis?.ticks?.map((entry: any) => data?.xAxis?.scale(entry));
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={windowWidth <= 520 ? 640 : 550}
      className={s.container}
    >
      <AreaChart
        data={dataPoints}
        onMouseMove={handleMouseMove}
        margin={{
          left: windowWidth <= 520 ? 0 : 10,
          top: windowWidth <= 520 ? 27 : 0,
          right: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray="1 0"
          horizontalCoordinatesGenerator={customHorizontalCoordinatesGenerator}
          verticalCoordinatesGenerator={customVerticalCoordinatesGenerator}
        />
        <XAxis
          dataKey="year"
          type="number"
          domain={["dataMin", "dataMax"]}
          ticks={
            windowWidth <= 620
              ? ticks.filter((tick: number) => tick % 2 !== 0)
              : ticks
          }
          tick={<CustomTickXAxis />}
          tickLine={false}
          tickFormatter={(tick) => (tick % 1 === 0 ? tick : "")}
          padding={{ right: 10 }}
        />
        <YAxis
          ticks={ticks}
          tick={<CustomTickYAxis />}
          domain={["dataMin", "dataMax"]}
          padding={{ top: 10 }}
          tickLine={false}
        >
          <Label
            className={s.YAxisLabel}
            position="insideLeft"
            angle={-90}
            value="Total Supply (Billion)"
            offset={windowWidth <= 520 ? 80 : 0}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Legend
          content={<CustomLegend />}
          wrapperStyle={{ paddingTop: "1.8rem", height: "fit-content" }}
        />

        {dataPoints?.map((points) =>
          points.year && points.year % 1 !== 0
            ? Object.entries(points)
                .filter(([type, value]) => type !== "year" && value !== null)
                .map(([type, value]) => (
                  <ReferenceDot
                    key={`dot-${type}-${points.year}-${value}`}
                    x={points.year ?? undefined}
                    y={value ?? undefined}
                    r={0}
                    fill="none"
                    stroke="none"
                  />
                ))
            : null,
        )}

        {Object.values(linesMap).map((area: ChartLinesType) => (
          <Area
            key={area}
            connectNulls
            type="linear"
            dataKey={linesMap[area]}
            stroke={AreaStrokeColorMap[area]}
            strokeWidth={2}
            fillOpacity={1}
            fill={`url(#${linesMap[area]})`}
            activeDot={<CustomActiveDot />}
          />
        ))}

        {activeIndex !== null && (
          <ReferenceLine
            x={activeIndex}
            stroke="#6B7A99"
            strokeDasharray="3 0"
          />
        )}

        <defs>
          <linearGradient
            id={linesMap.rewards}
            x1="44%"
            y1="0%"
            x2="90%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.rewards}
              stopOpacity={0.8}
            />
            <stop
              offset="55%"
              stopColor={AreaStrokeColorMap.rewards}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.ecosystem}
            x1="44%"
            y1="0%"
            x2="90%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.ecosystem}
              stopOpacity={0.8}
            />
            <stop
              offset="55%"
              stopColor={AreaStrokeColorMap.ecosystem}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.marketing}
            x1="44%"
            y1="0%"
            x2="90%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.marketing}
              stopOpacity={0.8}
            />
            <stop
              offset="55%"
              stopColor={AreaStrokeColorMap.marketing}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient id={linesMap.team} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={AreaStrokeColorMap.team}
              stopOpacity={0.8}
            />
            <stop
              offset="65%"
              stopColor={AreaStrokeColorMap.team}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.seed}
            x1="44%"
            y1="0%"
            x2="56%"
            y2="100%"
          >
            <stop
              offset="5%"
              stopColor={AreaStrokeColorMap.seed}
              stopOpacity={0.8}
            />
            <stop
              offset="35%"
              stopColor={AreaStrokeColorMap.seed}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.strategic}
            x1="38%"
            y1="0%"
            x2="62%"
            y2="100%"
          >
            <stop
              offset="5%"
              stopColor={AreaStrokeColorMap.strategic}
              stopOpacity={0.86}
            />
            <stop
              offset="60%"
              stopColor={AreaStrokeColorMap.strategic}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.advisors}
            x1="44%"
            y1="0%"
            x2="56%"
            y2="100%"
          >
            <stop
              offset="5%"
              stopColor={AreaStrokeColorMap.advisors}
              stopOpacity={0.8}
            />
            <stop
              offset="60%"
              stopColor={AreaStrokeColorMap.advisors}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.preSale}
            x1="44%"
            y1="0%"
            x2="56%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.preSale}
              stopOpacity={0.8}
            />
            <stop
              offset="65%"
              stopColor={AreaStrokeColorMap.preSale}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.ambassador}
            x1="44%"
            y1="0%"
            x2="56%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.ambassador}
              stopOpacity={0.8}
            />
            <stop
              offset="55%"
              stopColor={AreaStrokeColorMap.ambassador}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient
            id={linesMap.publicSale}
            x1="44%"
            y1="0%"
            x2="56%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={AreaStrokeColorMap.publicSale}
              stopOpacity={0.8}
            />
            <stop
              offset="55%"
              stopColor={AreaStrokeColorMap.publicSale}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default Chart;
