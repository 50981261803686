import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import Icon from "components/common/Icon";

import { IconsVariant } from "types/icons";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { createLink, getPathIcon } from "./utils";

import ChainItem from "./ChainItem";

import s from "./NavigationChain.module.scss";

interface IChainItem {
  id: number;
  title: string;
  link?: string;
  icon?: `${IconsVariant}`;
}

const notAllowedPaths = ["item"];

const pathsToChange = ["car", "part", "pass", "pet", "town"];
const upperCasePaths = ["ace", "usdt", "matic", "trc", "nft"];

function NavigationChain() {
  const { pathname } = useLocation();

  const chains: IChainItem[] = useMemo(() => {
    const path = pathname.startsWith("/") ? pathname.slice(1) : pathname;

    const separatedElements = path.split("/");

    return separatedElements
      .map((elem, index) => {
        const obj: IChainItem = { id: index, title: elem };
        const icon = getPathIcon(elem);
        const link = createLink(separatedElements, index);

        if (icon) {
          obj.icon = icon;
        }
        if (link) {
          obj.link = link;
        }
        if (pathsToChange.includes(elem)) {
          if (elem === "pass")
            obj.title = `NFT ${capitalizeFirstLetter(elem)}es`;
          else obj.title = `NFT ${capitalizeFirstLetter(elem)}s`;
        }
        if (upperCasePaths.includes(obj.title)) {
          obj.title = obj.title.toUpperCase();
        }
        return obj;
      })
      .filter((elem) => {
        return !notAllowedPaths.includes(elem.title);
      });
  }, [pathname]);

  return (
    <div className={s.navigationChain}>
      {chains.map((item: IChainItem, index) => {
        return (
          <div className={s.navigationChainItem} key={item.id}>
            <ChainItem title={item.title} icon={item.icon} link={item.link} />
            {index < chains.length - 1 && (
              <Icon variant="arrowRight" wrapperClassName={s.arrowIcon} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default NavigationChain;
