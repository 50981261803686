import React from "react";
import { useAppSelector } from "hooks/store";
import BottomPanelContent from "./BottomPanelContent";

function BottomPanel() {
  const { windowSize } = useAppSelector((state) => state.appState);

  if (window.innerWidth <= 980) {
    return <BottomPanelContent />;
  }
  return null;
}

export default BottomPanel;
