import { NFTCollectionCurrencies } from "constants/NFTCollectionCurrencies";
import { FiltersSliceType, StringListingFilter } from "types/filters";
import { TokenAddress } from "types/listing";

function extractTokenAddress(filterString: FiltersSliceType): TokenAddress {
  const match = filterString.match(/^(.*?)Filters/);
  //   if (!match) throw new Error("unknown nft type");
  if (!match) return "car";
  return match[1] as TokenAddress;
}

export default (filterSliceType: FiltersSliceType): StringListingFilter => {
  const type = extractTokenAddress(filterSliceType);

  const currencies = NFTCollectionCurrencies[type].map((elem) =>
    elem.toUpperCase(),
  );

  const coinsFilterElement: StringListingFilter = {
    trait_type: "Currency",
    values_string: currencies,
  };

  return coinsFilterElement;
};
