import React, { useEffect, useRef } from "react";
import cn from "classnames";

import { TokenType } from "pages/TokensPage/TokensPage";

import s from "./TokensChart.module.scss";

interface Props {
  tokenType: TokenType;
}

function TokensChart({ tokenType }: Props) {
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;

    const symbol =
      tokenType === "trc"
        ? "BYBIT:TRCUSDT"
        : "UNISWAP3POLYGON:ACEWMATIC_1DE036.USD";

    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "${symbol}",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "3",
        "locale": "en",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "allow_symbol_change": true,
        "calendar": false,
        "hide_volume": true,
        "support_host": "https://www.tradingview.com"
      }`;

    if (container.current) {
      container.current.innerHTML = ""; // Clear the container before appending new script
      container.current.appendChild(script);
    }
  }, [tokenType]);

  return (
    <div className={s.tokensChart}>
      <div
        className={cn(s.chartContainer, "tradingview-widget-container")}
        ref={container}
        style={{ height: "100%", width: "100%" }}
      />
      <p className={s.chartCopyRight}>
        Stock information in this chart is provided by{" "}
        <a href="https://www.tradingview.com/">TradingView</a>
      </p>
    </div>
  );
}

export default TokensChart;
