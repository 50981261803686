/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import gem from "assets/images/gem.svg";
import boy from "assets/images/avatars/boy.webp";
import boyGenesis from "assets/images/avatars/boy_genesis.webp";
import girl from "assets/images/avatars/girl.webp";
import girlGenesis from "assets/images/avatars/girl_genesis.webp";
import pro from "assets/images/shop/pro2.svg";
import star from "assets/images/lootboxes/star.png";
import lootboxesBanner from "assets/images/lootboxes/banner.webp";
import beams from "assets/images/common/beamsStronger.svg";
import purpleTriangle from "assets/images/lootboxes/purpleTriangle.svg";
import beamsForModal from "assets/images/common/beamsForModal.webp";
import effectForModal from "assets/images/common/effectForModal.png";
import marketplaceBg1 from "assets/images/marketplaceBg1.webp";
import marketplaceMobileBg1 from "assets/images/marketplaceMobileBg1.webp";
import genesis_decoration from "assets/images/burnPass/genesis_decoration.webp";
import coins from "assets/images/dashboard/economic-model/coins.webp";
import utilization from "assets/images/dashboard/economic-model/utilization.webp";

import s from "./Image.module.scss";

const images = {
  gem,
  boy,
  boyGenesis,
  girl,
  girlGenesis,
  pro,
  star,
  lootboxesBanner,
  beams,
  purpleTriangle,
  beamsForModal,
  effectForModal,
  marketplaceBg1,
  marketplaceMobileBg1,
  genesis_decoration,
  coins,
  utilization,
};

export type ImageType = keyof typeof images;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: ImageType;
  wrapperClassName?: string;
  className?: string;
}

function Image({
  image,
  wrapperClassName = "",
  className = "",
  ...props
}: Props) {
  return (
    <div className={`${s.imageWrapper} ${wrapperClassName}`}>
      <img
        {...props}
        src={images[image]}
        alt={image}
        className={`${s.image} ${className}`}
      />
    </div>
  );
}

export default Image;
