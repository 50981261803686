import React from "react";
import { ChartLinesType, linesMapLegend } from "../../../../types";
import tooltipData from "./tooltipData";

import s from "./CustomTooltip.module.scss";

function CustomTooltip({ active, payload, label }: any) {
  if (
    active &&
    payload &&
    payload.length &&
    Number.isInteger(payload[0]?.payload?.year)
  ) {
    return (
      <div className={s.tooltip}>
        <div style={{ fontWeight: "600" }}>{`Total: ${
          tooltipData[payload[0].payload?.year]?.total
        } B`}</div>
        {payload?.map((entry: any) => (
          <div key={`${entry?.name}-${payload?.payload?.year}`}>
            <span style={{ color: entry?.stroke }}>
              {`${linesMapLegend[entry?.name as ChartLinesType]}: `}
            </span>
            <span style={{ color: entry?.stroke }}>{`${tooltipData[
              entry?.payload?.year
            ]?.[entry?.name].toFixed(3)} B`}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
}

export default CustomTooltip;
