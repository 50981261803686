import React from "react";

import { useAppDispatch, useAppSelector } from "hooks/store";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { closeTokensModal, openAuthModal } from "store/modalsSlice";
import { tokensTRCExchangePlatforms } from "constants/tokens";
import ProjectPath from "constants/paths";

import Icon from "components/common/Icon";
import Button from "components/common/Button";

import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./TokensBuyModal.module.scss";

function TokensBuyModal() {
  const dispatch = useAppDispatch();
  const cookie = useGetAuthCookie();
  const { isOpen } = useAppSelector((state) => state.modals.tokensModal);

  const close = () => {
    dispatch(closeTokensModal());
  };

  const openRegistrationModal = () => {
    dispatch(openAuthModal("register"));
    close();
  };

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title="Choose how to buy TRC tokens"
      withCancelButton={false}
      modalClassName={s.modalWrapper}
    >
      <div className={s.modal}>
        {!cookie && (
          <div className={s.content}>
            <p className={s.title}>Choose how to buy TRC tokens</p>
            <div className={s.walletContainer}>
              <Button className={s.button} onClick={openRegistrationModal}>
                <div className={s.buttonContent}>
                  <Icon variant="wallet" className={s.icon} />
                  <span className={s.buttonText}>Create Wallet</span>
                </div>
              </Button>
            </div>
          </div>
        )}
        {cookie && (
          <div className={s.content}>
            <p className={s.title}>Buy TRC</p>
            <div className={s.walletContainer}>
              <Button
                link={`/${ProjectPath.WALLET_SWAP}/usdt`}
                onClick={close}
                className={s.button}
                isLocal
              >
                <div className={s.buttonContent}>
                  <Icon variant="swap_alt2" className={s.icon} />
                  <span className={s.buttonText}>Swap TRC</span>
                </div>
              </Button>
            </div>
          </div>
        )}
        <div className={s.content}>
          <p className={s.title}>Buy TRC on other Platforms</p>
          <div className={s.platforms}>
            {tokensTRCExchangePlatforms.map((elem) => (
              <div className={s.platform} key={elem.id}>
                <img src={elem.source} alt={elem.title} className={s.logo} />
                <div className={s.name}>{elem.title}</div>
                <a href={elem.link} className="fill-link">
                  {elem.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default TokensBuyModal;
