import { useQueryClient } from "@tanstack/react-query";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { enqueueSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { closeAuthConfirmationModal, endRegistration } from "store/modalsSlice";
import SmallModalContainer from "../../SmallModalContainer";

import s from "./AuthConfirmationModal.module.scss";

function AuthConfirmationModal() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isOpen } = useAppSelector(
    (state) => state.modals.authConfirmationModal,
  );
  const { t } = useTranslation();

  const close = useCallback(() => {
    dispatch(closeAuthConfirmationModal());
  }, [dispatch]);

  const onContinueClick = useCallback(async () => {
    enqueueSnackbar({
      variant: "success",
      message: "Registration successfully completed",
    });
    queryClient.invalidateQueries({ queryKey: ["profile"] });
    setTimeout(() => dispatch(endRegistration()), 300);
    close();
  }, [dispatch, close]);

  return (
    <SmallModalContainer open={isOpen} close={close}>
      <div className={s.modal}>
        <div className={s.title}>{t("modals.mnemonicConfirmation.title")}</div>
        <div className={s.description}>
          {t("modals.mnemonicConfirmation.title")}
        </div>
        <div className={s.buttons}>
          <button type="button" onClick={close} className={s.back}>
            {t("common.goSave")}
          </button>
          <Button className={s.continue} onClick={onContinueClick}>
            {t("common.continue")}
          </Button>
        </div>
      </div>
    </SmallModalContainer>
  );
}

export default AuthConfirmationModal;
