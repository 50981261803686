import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { logOut } from "api/auth";
import { useAppDispatch } from "hooks/store";
import { setAuthToken } from "store/appStateSlice";
import { showErrors } from "utils/showErrors";

import ProjectPath from "constants/paths";
import ApiError from "classes/ApiError";
import {
  dropEmail,
  dropHasSubscription,
  dropReferralCode,
  dropWalletAddress,
} from "store/userProfileSlice";
import { updateMarketCollectionTab } from "store/marketCollectionTabSlice";
import { closeAuthModal } from "store/modalsSlice";

const useLogOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { pathname } = location;

  const navPath = pathname?.includes("market")
    ? pathname
    : `/${ProjectPath.MARKET}`;
  return useMutation<null, ApiError, void>(
    ["logOut"],
    async () => {
      return logOut();
    },
    {
      onSuccess: () => {
        dispatch(setAuthToken(""));
        dispatch(dropEmail());
        dispatch(dropWalletAddress());
        dispatch(dropReferralCode());
        dispatch(dropHasSubscription());
        dispatch(updateMarketCollectionTab({ tab: "all" }));
        dispatch(closeAuthModal());
        navigate(navPath);
        queryClient.removeQueries(["profile"]);
        queryClient.removeQueries(["balance"]);
        sessionStorage.removeItem("welcomeDownload");
        sessionStorage.removeItem("tracePro");
      },
      onError: showErrors,
    },
  );
};

export default useLogOut;
