import car from "assets/images/nftCategories/cars.webp";
import pass from "assets/images/nftCategories/pass.webp";
import part from "assets/images/nftCategories/parts.webp";
import pet from "assets/images/nftCategories/cypet.webp";
import town from "assets/images/nftCategories/town.webp";
import {
  ListingTotalAmountResponse,
  TotalSupplyResponse,
} from "types/responses";
import { TokenAddress } from "types/listing";
import ProjectPath from "constants/paths";
import { NftCategoryProps } from "./NftCategory/NftCategory";

const imageMap: Record<TokenAddress, any> = {
  car,
  pass,
  pet,
  town,
  part,
};

function calculateListedPercentage(
  totalListing: number | undefined,
  totalSupply: string | undefined,
) {
  if (
    totalListing === undefined ||
    totalSupply === undefined ||
    totalSupply === "..."
  )
    return "...";

  if (totalListing === 0 || totalSupply === "0") return "0";

  return parseFloat(((totalListing / +totalSupply) * 100).toString()).toFixed(
    2,
  );
}

function createNftCategories(
  categories: Array<
    Partial<TotalSupplyResponse> &
      ListingTotalAmountResponse & {
        type: TokenAddress;
        trendy?: boolean;
        isLoading: boolean;
      }
  >,
): NftCategoryProps[] {
  return categories.map((category) => {
    const totalSupply = category.totalSupply ?? "...";
    return {
      image: imageMap[category.type],
      link: `/${ProjectPath.MARKET}/${category.type}`,
      title: `NFT ${
        category.type === "pet"
          ? "Cypet"
          : category.type.charAt(0).toUpperCase() + category.type.slice(1)
      }`,
      totalSupply,
      listedPercentage: calculateListedPercentage(
        category?.total_listings,
        totalSupply,
      ),
      isTrendy: category.trendy ?? false,
      isLoading: category.isLoading,
    };
  });
}

export default createNftCategories;
