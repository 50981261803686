import React from "react";
import { Dot } from "recharts";

function CustomActiveDot(props: any) {
  /* eslint-disable react/destructuring-assignment */
  /* eslint-disable react/jsx-props-no-spreading */
  return Number.isInteger(props.payload.year) ? <Dot {...props} /> : null;
}

export default CustomActiveDot;
