import React from "react";

import ListingItem from "components/Listing/ListingItem";
import { SimpleListing, TokenAddress } from "types/listing";

type ListingListProps = {
  nftList: Record<TokenAddress, SimpleListing[]>;
  type: TokenAddress | "all";
};

function ListingList({ nftList, type }: ListingListProps) {
  return (
    <>
      {type !== "all" &&
        nftList[type as TokenAddress].map((nft) => {
          return (
            <ListingItem
              key={nft.token_id}
              tokenAddress={type as TokenAddress}
              id={
                nft?.metadata?.listings?.[0]?.id && type !== "town"
                  ? nft.metadata.listings[0].id
                  : nft.token_id
              }
              tokenId={nft.token_id}
              attributes={nft.metadata.attributesObj}
              image={nft.metadata.image}
              name={nft.metadata.name}
              isWalletItem
              quantity={nft.metadata.balance}
              isListed={!!nft.metadata.listings?.length}
            />
          );
        })}
      {type === "all" &&
        Object.keys(nftList).map((key) => {
          return nftList[key as TokenAddress]?.map((nft) => (
            <ListingItem
              key={nft.token_id}
              tokenAddress={key as TokenAddress}
              id={
                nft?.metadata?.listings?.[0]?.id && key !== "town"
                  ? nft.metadata.listings[0].id
                  : nft.token_id
              }
              tokenId={nft.token_id}
              attributes={nft.metadata.attributesObj}
              image={nft.metadata.image}
              name={nft.metadata.name}
              isWalletItem
              quantity={nft.metadata.balance}
              isListed={!!nft.metadata.listings?.length}
            />
          ));
        })}
    </>
  );
}

export default ListingList;
