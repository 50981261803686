/* eslint-disable import/prefer-default-export */

import { convertDecimalTokenShort } from "utils/convertDecimalToken";
import { convertFieldsToString } from "utils/convertJSONFields";

import ApiError from "classes/ApiError";
import { ApiErrorResponse, GasResponse } from "types/responses";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getGasPrice = async (): Promise<GasResponse> => {
  const response = await fetch(`${endpoint}/suggested/`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }
  const stringData = await response.text();

  const convertedData = convertFieldsToString(stringData, [
    "matic",
    "ace",
    "trc",
    "usdt",
  ]);
  const data: GasResponse = JSON.parse(convertedData);

  return {
    suggested: convertDecimalTokenShort(data.suggested, "matic") || "0",
  };
};
