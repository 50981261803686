import AppReleaseBanner from "components/AppReleaseBanner";
import BackButton from "components/common/BackButton";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ListingItemNew from "components/Listing/ListingItemNew";
import ListingItemSkeleton from "components/Listing/ListingItemSkeleton";
import PopUpTooltip from "components/PopUpTooltip";
import ProjectPath from "constants/paths";
import { Formik, FormikHelpers, FormikProps } from "formik";
import useGetBalance from "hooks/requests/useGetBalance";
import useMakeTransaction from "hooks/requests/useMakeTransaction";
import { useAppDispatch } from "hooks/store";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import useHandleInputChange from "hooks/useHandleInputChange";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openBalanceModal } from "store/modalsSlice";
import { CoinType } from "types/coins";
import { SimpleListing, TokenAddress } from "types/listing";
import { getBalanceItem } from "utils/getSMTFromResponse";
import * as Yup from "yup";
import CoinSelect from "../CoinSelect";
import GasContainer from "../GasContainer";
import LabelContainer from "../LabelContainer";
import TransactionInput from "../TransactionInput";
import ts from "./TransferContent.module.scss";

import s from "../TransactionPage.module.scss";

interface BalanceModalResponse {
  coin: `${CoinType}`;
  chosen: number;
  tokenAddress: TokenAddress;
}

interface ValuesType {
  quantity?: number | string;
}

const initialValues: ValuesType = { quantity: "" };

const QUANTITY = "quantity";

function TransferContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const entity = useMemo<any>(() => searchParams.get("entity"), [searchParams]);
  const back = useMemo<any>(() => searchParams.get("back"), [searchParams]);
  const tokenAddress = useMemo<any>(
    () => searchParams.get("token_address"),
    [searchParams],
  );
  const itemId = useMemo(
    () => Number(searchParams.get("itemId")),
    [searchParams],
  );
  const [version, setVersion] = useState(0);
  const dispatch = useAppDispatch();
  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();

  const [isFocused, setIsFocused] = useState(false);
  const [transferEntity, setTransferEntity] = useState<`${CoinType}`>(entity);
  const [NFTItemId, setNFTItemId] = useState<number | undefined>(
    itemId || undefined,
  );
  const [NFTItem, setNFTItem] = useState<SimpleListing | undefined>(undefined);

  const { mutate: makeTransaction, isLoading: isMakingTransaction } =
    useMakeTransaction({ transferEntity, transfer_type: "internal" });

  useEffect(() => {
    if (balance) {
      setNFTItem(getBalanceItem(balance, itemId, tokenAddress));
    }
  }, [balance, itemId, tokenAddress]);

  const openModal = useAwaitModalResponse<BalanceModalResponse>({
    openModal: () => {
      dispatch(
        openBalanceModal({
          withACE: true,
          withMATIC: false,
          withNFT: {
            car: true,
          },
          withTRC: true,
          withUSDT: false,
        }),
      );
    },
    onResolve: (res) => {
      if (balance && res?.coin === "nft" && res.chosen) {
        setTransferEntity("nft");
        setNFTItemId(res.chosen);
        setNFTItem(getBalanceItem(balance, res.chosen, tokenAddress));
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.set("token_address", res.tokenAddress);
          prev.set("itemId", `${res.chosen}`);
          return prev;
        });
      }
      if (res?.coin !== "nft" && res?.coin) {
        setTransferEntity(res.coin);
        setNFTItemId(undefined);
        setNFTItem(undefined);
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.delete("token_address");
          prev.delete("itemId");
          prev.delete("back");
          return prev;
        });
      }
    },
  });

  const openModalForNft = useAwaitModalResponse<BalanceModalResponse>({
    openModal: () => {
      dispatch(
        openBalanceModal({
          stage: "NFTSelector",
          withNFT: {
            car: true,
          },
        }),
      );
    },
    onResolve: (res) => {
      if (balance && res?.coin === "nft" && res.chosen) {
        setTransferEntity("nft");
        setNFTItemId(res.chosen);
        setNFTItem(getBalanceItem(balance, res.chosen, tokenAddress));
        setSearchParams((prev) => {
          prev.set("entity", res.coin);
          prev.set("token_address", res.tokenAddress);
          prev.set("itemId", `${res.chosen}`);
          prev.set("back", "transfer");
          return prev;
        });
      }
    },
  });

  const handleChoose = useCallback(
    (coin: `${CoinType}`) => {
      if (coin === "nft") {
        openModalForNft();
      }
      if (coin !== "nft" && coin) {
        setTransferEntity(coin);
        setNFTItemId(undefined);
        setNFTItem(undefined);
        setSearchParams((prev) => {
          prev.set("entity", coin);
          prev.delete("token_address");
          prev.delete("itemId");
          return prev;
        });
      }
    },
    [openModalForNft, setSearchParams],
  );

  const {
    maticConverted: matic,
    trcConverted: trc,
    aceConverted: ace,
    usdtConverted: usdt,
    maticConvertedFull: maticFull,
    trcConvertedFull: trcFull,
    aceConvertedFull: aceFull,
    usdtConvertedFull: usdtFull,
  } = balance || {};

  const getTransferEntityValue = useCallback((): string => {
    switch (transferEntity) {
      case CoinType.ace:
        return ace || "0";
      case CoinType.trc:
        return trc || "0";
      case CoinType.matic:
        return matic || "0";
      case CoinType.usdt:
        return usdt || "0";
      default:
        return "0";
    }
  }, [transferEntity, ace, trc, matic, usdt]);

  const onMaxClick = useCallback(
    (setFieldValue: FormikHelpers<ValuesType>["setFieldValue"]) => () => {
      switch (transferEntity) {
        case CoinType.ace:
          setFieldValue(QUANTITY, aceFull || "0");
          break;
        case CoinType.trc:
          setFieldValue(QUANTITY, trcFull || "0");
          break;
        case CoinType.matic:
          setFieldValue(QUANTITY, maticFull || "0");
          break;
        case CoinType.usdt:
          setFieldValue(QUANTITY, usdtFull || "0");
          break;

        default:
          setFieldValue(QUANTITY, "0");
          break;
      }
    },
    [aceFull, maticFull, transferEntity, trcFull, usdtFull],
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleUnFocus = () => {
    setIsFocused(false);
  };

  const onSubmit = useCallback(
    async ({ quantity }: ValuesType) => {
      if (transferEntity === "nft") {
        if (NFTItemId) {
          makeTransaction({
            token_type: tokenAddress,
            value_or_id: NFTItemId,
          });
        }
        return;
      }
      makeTransaction({
        token_type: transferEntity,
        value_or_id: quantity || "0",
      });
      setVersion((prev) => prev + 1);
    },
    [transferEntity, makeTransaction, NFTItemId, tokenAddress],
  );

  const formDataSchema: Yup.ObjectSchema<ValuesType> = Yup.object().shape({
    quantity:
      transferEntity === "nft"
        ? Yup.number().positive()
        : Yup.number().positive().required(),
  });

  if (!CoinType[entity as CoinType] || entity === CoinType.usdt) {
    navigate(`/${ProjectPath.WALLET}`);
  }

  const lackOfBalance = (quantity: string | number | undefined): boolean => {
    if (transferEntity === "nft" || !balance) return false;
    return (
      Number(quantity || 0) > Number(balance[`${transferEntity}ConvertedFull`])
    );
  };

  const route = useMemo(() => {
    if (back === "transfer") {
      return `/${ProjectPath.WALLET_TRANSFER}?entity=trc`;
    }
    if (back === "item") {
      return `/${ProjectPath.WALLET_NFT}/${tokenAddress}/${NFTItemId}`;
    }
    setTransferEntity("trc");
    return `/${ProjectPath.WALLET}`;
  }, [back, NFTItemId, tokenAddress]);

  const handleAmountChange = useHandleInputChange();

  return (
    <>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <BackButton className={s.backButton} route={route} />
          <div className={s.title}>
            <span>{t("common.pages.transfer")}</span>
            <Icon
              variant="up"
              wrapperClassName={s.iconWrapper}
              className={s.icon}
              withWrapper
            />
          </div>
        </div>
        {transferEntity === "nft" && (
          <div className={s.chosenNFT}>
            {isBalanceLoading || !NFTItem ? (
              <ListingItemSkeleton />
            ) : (
              <ListingItemNew
                key={NFTItem.token_id}
                id={NFTItem.token_id}
                attributes={NFTItem.metadata.attributesObj}
                image={NFTItem.metadata.image}
                name={NFTItem.metadata.name}
                onClick={openModal}
                tokenAddress={tokenAddress}
              />
            )}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={formDataSchema}
          onSubmit={onSubmit}
          key={version}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }: FormikProps<ValuesType>) => {
            const isButtonDisabled = (() => {
              if (transferEntity === "nft") {
                return !NFTItemId;
              }
              return (
                !dirty ||
                Boolean(errors.quantity) ||
                lackOfBalance(values.quantity)
              );
            })();
            return (
              <form onSubmit={handleSubmit} className={s.form}>
                <div className={s.inputs}>
                  {transferEntity !== "nft" && (
                    <div className={ts.selectCurrencyWrapper}>
                      <div
                        className={`${ts.selectCurrency} ${
                          ((touched.quantity && Boolean(errors.quantity)) ||
                            lackOfBalance(values.quantity)) &&
                          ts.inputError
                        }
                      ${isFocused && ts.inputFocused}
                  `}
                      >
                        <div className={ts.leftPartSelectCoin}>
                          <p className={ts.balance}>
                            Balance: {getTransferEntityValue()}
                          </p>
                          <CoinSelect
                            coin={
                              CoinType[
                                transferEntity.toLowerCase() as unknown as keyof typeof CoinType
                              ]
                            }
                            coinsToChoose={["trc", "ace", "nft"]}
                            handleClick={handleChoose}
                          />
                        </div>
                        <div className={ts.rightPartSelectMax}>
                          <p className={ts.enterAmount}>Enter an amount:</p>
                          <div className={ts.balancePart}>
                            <input
                              // type="number"
                              placeholder="0"
                              className={ts.input}
                              onChange={(e) =>
                                handleAmountChange(e, setFieldValue)
                              }
                              onFocus={handleFocus}
                              onBlur={(e) => {
                                handleBlur(e);
                                handleUnFocus();
                              }}
                              value={values.quantity}
                              name={QUANTITY}
                              // eslint-disable-next-line jsx-a11y/no-autofocus
                              autoFocus
                            />
                            <PopUpTooltip
                              buttonStyles={ts.maxButton}
                              buttonText="Max"
                              text="All in"
                              onClick={onMaxClick(setFieldValue)}
                            />
                          </div>
                        </div>
                      </div>
                      {lackOfBalance(values.quantity) && (
                        <p className={ts.error}>
                          Insufficient funds on balance
                        </p>
                      )}
                    </div>
                  )}
                  <div className={ts.nftTransfer}>
                    {transferEntity === "nft" && (
                      <LabelContainer label={t("transaction.from")}>
                        <TransactionInput
                          unTouchable
                          input={false}
                          before={
                            <div className={s.profileInput}>
                              <Icon
                                variant="wallet"
                                className={s.walletIcon}
                                wrapperClassName={s.wrapperClassNameForWallet}
                                withWrapper
                              />
                              <div className={s.profile}>
                                {t("common.pages.wallet")}
                              </div>
                            </div>
                          }
                        />
                      </LabelContainer>
                    )}
                    {transferEntity === "nft" && (
                      <Icon variant="arrowDownRoundedSvg" className={s.arrow} />
                    )}
                    <LabelContainer
                      label={
                        transferEntity === "nft"
                          ? t("transaction.to")
                          : t("transaction.addressLabel")
                      }
                    >
                      <TransactionInput
                        unTouchable
                        input={false}
                        before={
                          <div className={s.profileInput}>
                            <Icon
                              variant="person"
                              className={s.profileIcon}
                              wrapperClassName={s.wrapperClassName}
                              withWrapper
                            />
                            <div className={s.profile}>
                              {t("common.profile")}
                            </div>
                          </div>
                        }
                      />
                    </LabelContainer>
                  </div>
                </div>
                <div className={s.bottomPart}>
                  <div className={s.containers}>
                    <GasContainer />
                  </div>
                  <Button
                    type="submit"
                    className={s.submit}
                    isLoading={isMakingTransaction}
                    disabled={isButtonDisabled}
                  >
                    {t("common.pages.send")}
                  </Button>
                </div>
                <AppReleaseBanner wrapperClassName={s.mobileSideBanner} />
              </form>
            );
          }}
        </Formik>
      </div>
      <AppReleaseBanner wrapperClassName={s.sideBanner} />
    </>
  );
}

export default TransferContent;
