import {
  GetCollectionBaseResponse,
  GetListingBaseResponse,
  RestructuredBaseCollectionResponse,
  RestructuredBaseListingResponse,
} from "types/responses";
import { Collection, Listing, RawListing } from "types/listing";

import restructureAttributes from "./restructureAttributes";
import { convertDecimalTokenShort } from "./convertDecimalToken";

const restructureListing = (listing: RawListing): Listing => {
  const attributesObj = restructureAttributes(listing.metadata.attributes);
  if (attributesObj.Name) {
    attributesObj.Name.value = `${attributesObj?.Name?.value
      .toString()
      .charAt(0)}${attributesObj?.Name?.value
      .toString()
      .slice(1)
      .toLowerCase()}`;
  }
  return {
    ...listing,
    priceConverted: convertDecimalTokenShort(
      listing.price,
      listing.price_type,
    ),
    metadata: {
      ...listing.metadata,
      attributesObj,
    },
  };
};

export const restructureListingResponse = (
  data: GetListingBaseResponse,
): RestructuredBaseListingResponse => {
  const newData: RestructuredBaseListingResponse = {
    ...data,
    listings: data.listings?.map(restructureListing),
  };

  return newData;
};

export const restructureCollectionResponse = (
  data: GetCollectionBaseResponse,
  deepRestructure: boolean = false,
): RestructuredBaseCollectionResponse => {
  const newData: RestructuredBaseCollectionResponse = {
    ...data,
    collections: data.collections?.map((elem) => {
      const attributesObj = restructureAttributes(elem.metadata.attributes);
      if (attributesObj.Name) {
        attributesObj.Name.value = `${attributesObj?.Name?.value
          .toString()
          .charAt(0)}${attributesObj?.Name?.value
          .toString()
          .slice(1)
          .toLowerCase()}`;
      }
      const restructuredCollection: Collection = {
        ...elem,
        priceConverted: convertDecimalTokenShort(elem.price, elem.price_type),
        metadata: {
          ...elem.metadata,
          attributesObj,
        },
        listings: undefined,
      };

      if (deepRestructure && elem.listings) {
        restructuredCollection.listings = elem.listings.map(restructureListing);
      }

      return restructuredCollection;
    }),
  };

  return newData;
};
