import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ProjectPath from "constants/paths";
import { JustCoinType } from "types/coins";

type WithSwapContentProtectedProps = {
  children: JSX.Element;
};
function WithSwapContentProtected({ children }: WithSwapContentProtectedProps) {
  const navigate = useNavigate();
  const { currency } = useParams();

  const isValid = [
    JustCoinType.matic,
    JustCoinType.trc,
    JustCoinType.usdt,
    JustCoinType.ace,
    JustCoinType.weth,
    JustCoinType.wbtc,
  ].includes(currency as JustCoinType);

  useEffect(() => {
    if (!isValid) {
      navigate(`/${ProjectPath.WALLET}`);
    }
  }, [isValid, navigate]);

  if (!isValid) return null;

  return children;
}

export default WithSwapContentProtected;
