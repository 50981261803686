import { motion } from "framer-motion";
import React from "react";

import s from "./Card.module.scss";

type CardProps = {
  title: string;
  description: string;
  image: string;
};
function Card({ title, description, image }: CardProps) {
  const hoverAnimation = {
    scale: [1, 1.04, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  };

  return (
    <motion.div className={s.card} whileHover={hoverAnimation}>
      <div className={s.image}>
        <img src={image} alt={title} />
      </div>
      <div className={s.content}>
        <h2 className={s.title}>{title}</h2>
        <p className={s.description}>{description}</p>
      </div>
    </motion.div>
  );
}

export default Card;
