import React from "react";
import { AdditiveOperator } from "typescript";
import { ChartLinesType, linesMapLegend } from "../../../../types";

import s from "./CustomLegend.module.scss";

const additionalInfoMap: Record<ChartLinesType, number> = {
  marketing: 12.44,
  publicSale: 0.2,
  preSale: 0.85,
  ambassador: 0.65,
  strategic: 3,
  seed: 3.7,
  advisors: 3,
  team: 10,
  ecosystem: 19.3,
  rewards: 46.86,
};
function CustomLegend(props: any) {
  const { payload } = props;

  return (
    <ul className={s.pointsContainer}>
      {payload.map((entry: any) => (
        <li key={`item-${entry.value}`} className={s.point}>
          <svg width="34" height="14" className={s.icon}>
            <rect
              x="0"
              y="0"
              width="34"
              height="14"
              rx="6"
              ry="6"
              fill={entry.payload.stroke}
            />
          </svg>
          <div
            style={{ color: `${entry.payload.stroke}` }}
            className={s.text}
          >{`${linesMapLegend[entry.value as ChartLinesType]} (${
            additionalInfoMap[entry.value as ChartLinesType]
          }%)`}</div>
        </li>
      ))}
    </ul>
  );
}

export default CustomLegend;
