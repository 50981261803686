import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import Icon from "components/common/Icon";

import { ISimpleNavigationElement } from "../types";

import NavigationBackground from "./NavigationBackground";
import NavigationLink from "../../../components/NavigationLink";

import s from "../NavigationElement.module.scss";

function SimpleNavigationElement({
  icon,
  isResourceLocal,
  link,
  title,
  mustBeAuthorized,
  isTargetBlank,
  isNew,
}: ISimpleNavigationElement) {
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    if (pathname.startsWith(link)) return true;
    if (link === "/" && pathname === "") return true;
    return false;
  }, [pathname, link]);

  return (
    <div className={`${s.navigationElement} ${isActive ? s.isActive : ""}`}>
      {icon && <Icon variant={icon} className={s.icon} />}

      <div className={s.title}>{title}</div>

      {isNew && (
        <div className={s.new}>
          <Icon variant="crown" className={s.crown} />
          <span className={s.newText}>NEW</span>
        </div>
      )}

      <NavigationBackground />

      <NavigationLink
        title={title}
        link={link}
        isResourceLocal={isResourceLocal}
        mustBeAuthorized={mustBeAuthorized}
        isTargetBlank={isTargetBlank}
      />
    </div>
  );
}

export default SimpleNavigationElement;
