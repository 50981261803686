import React from "react";

import s from "./ModalTitle.module.scss";

interface ModalTitleProps {
  children: React.ReactNode;
}

function ModalTitle({ children }: ModalTitleProps) {
  return <div className={s.title}>{children}</div>;
}

export default ModalTitle;
