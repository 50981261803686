export type TownCategory =
  | "Small Town"
  | "Medium Town"
  | "Big Town"
  | "Large Town"
  | "Millionaire Town"
  | "Mega Town"
  | "Exclusive Town";

export type TownIcon =
  | "Mega_Town"
  | "Small_Town"
  | "Millionaire"
  | "Medium_Town"
  | "Big_Town"
  | "Large_Town"
  | "Exclusive_Town";

export const CITY_CATEGORY_TO_ICON: Record<TownCategory, TownIcon> = {
  "Small Town": "Small_Town",
  "Medium Town": "Medium_Town",
  "Big Town": "Big_Town",
  "Large Town": "Large_Town",
  "Millionaire Town": "Millionaire",
  "Mega Town": "Mega_Town",
  "Exclusive Town": "Exclusive_Town",
};
