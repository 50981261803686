import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import restructureFilters, { sortArray } from "utils/restructureFilters";
import transformToAppliedFilters from "utils/transformToAppliedFilters";
import {
  AddFilterPayload,
  ApplyFilterPayload,
  ChangeFilterPayload,
  FilterRestructured,
  FiltersSliceType,
  ListingFilter,
  RemoveSettledFilterPayload,
  SetSortPayload,
  SortVariant,
  StringListingFilter,
} from "types/filters";
import { RequestListingFilter } from "types/requests";
import getCurrencyFilterElement from "utils/getCurrencyFilterElement";

export interface InitialFilterState {
  filtersData: ListingFilter[];
  filters: FilterRestructured;
  appliedFilter: RequestListingFilter;
  filtersFieldsSettled: string[];
  sort: SortVariant | undefined;
}

type InitialStateQ = {
  [K in FiltersSliceType]: InitialFilterState;
};

export interface FilterSliceActions {
  addFilters: (payload: AddFilterPayload) => void;
  changeFilterValue: (payload: ChangeFilterPayload) => void;
  applyFilters: (payload: string) => void;
  resetFilters: () => void;
  setFilterField: (payload: string) => void;
  removeSettledFilterField: (payload: string) => void;
  setSort: (payload: SortVariant) => void;
}

const initialFilterState: InitialFilterState = {
  filters: {},
  filtersData: [],
  appliedFilter: {},
  filtersFieldsSettled: [],
  sort: undefined,
};

const initialState: InitialStateQ = {
  carFiltersAll: { ...initialFilterState },
  petFiltersAll: { ...initialFilterState },
  passFiltersAll: { ...initialFilterState },
  partFiltersAll: { ...initialFilterState },
  townFiltersAll: { ...initialFilterState },
  carFiltersMy: { ...initialFilterState },
  petFiltersMy: { ...initialFilterState },
  passFiltersMy: { ...initialFilterState },
  partFiltersMy: { ...initialFilterState },
  townFiltersMy: { ...initialFilterState },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    addFilters(state, action: PayloadAction<AddFilterPayload>) {
      const currencyFilterElement = getCurrencyFilterElement(
        action.payload.filtersSliceType,
      );
      const sortedFilter = [
        currencyFilterElement,
        ...sortArray(action.payload.data),
      ];
      state[action.payload.filtersSliceType].filtersData = sortedFilter;
      state[action.payload.filtersSliceType].filters = restructureFilters(
        sortedFilter,
        state[action.payload.filtersSliceType].appliedFilter,
      );
    },
    changeFilterValue(state, action: PayloadAction<ChangeFilterPayload>) {
      const thisFilter =
        state[action.payload.filtersSliceType].filters[
          action.payload.trait_type
        ];
      if (thisFilter) {
        thisFilter.value = action.payload.value;
        if (thisFilter.type === "range") {
          if (
            (thisFilter.value[0] !== thisFilter.value_int_min ||
              thisFilter.value[1] !== thisFilter.value_int_max) &&
            !thisFilter.isActive
          ) {
            thisFilter.isActive = true;
            state[action.payload.filtersSliceType].filtersFieldsSettled.push(
              thisFilter.trait_type,
            );
          } else if (
            thisFilter.value[0] === thisFilter.value_int_min &&
            thisFilter.value[1] === thisFilter.value_int_max &&
            thisFilter.isActive
          ) {
            thisFilter.isActive = false;
            state[action.payload.filtersSliceType].filtersFieldsSettled = state[
              action.payload.filtersSliceType
            ].filtersFieldsSettled.filter(
              (elem) => elem !== thisFilter.trait_type,
            );
          }
        }
        if (thisFilter.type === "selector") {
          if (thisFilter.value.length > 0 && !thisFilter.isActive) {
            thisFilter.isActive = true;
            state[action.payload.filtersSliceType].filtersFieldsSettled.push(
              thisFilter.trait_type,
            );
          } else if (thisFilter.value.length === 0 && thisFilter.isActive) {
            thisFilter.isActive = false;
            state[action.payload.filtersSliceType].filtersFieldsSettled = state[
              action.payload.filtersSliceType
            ].filtersFieldsSettled.filter(
              (elem) => elem !== thisFilter.trait_type,
            );
          }
        }
      }
    },
    applyFilters(state, action: PayloadAction<ApplyFilterPayload>) {
      state[action.payload.filtersSliceType].appliedFilter =
        transformToAppliedFilters(
          state[action.payload.filtersSliceType].filters,
        );
      if (state[action.payload.filtersSliceType].sort) {
        state[action.payload.filtersSliceType].appliedFilter.Sort =
          state[action.payload.filtersSliceType].sort;
      }
    },
    resetFilters(state, action: PayloadAction<FiltersSliceType>) {
      state[action.payload].appliedFilter = {};
      state[action.payload].filters = restructureFilters(
        state[action.payload].filtersData,
        state[action.payload].appliedFilter,
      );
      state[action.payload].filtersFieldsSettled = [];

      state[action.payload].sort = undefined;
    },
    setFilterField(state, action: PayloadAction<ApplyFilterPayload>) {
      state[action.payload.filtersSliceType].filtersFieldsSettled = [
        ...state[action.payload.filtersSliceType].filtersFieldsSettled,
        action.payload.value,
      ];
    },
    setSort(state, action: PayloadAction<SetSortPayload>) {
      state[action.payload.filtersSliceType].sort = action.payload.sort;
      if (
        !state[action.payload.filtersSliceType].filtersFieldsSettled.includes(
          "Sort",
        )
      ) {
        state[action.payload.filtersSliceType].filtersFieldsSettled.push(
          "Sort",
        );
      }
    },
    removeSettledFilterField(
      state,
      action: PayloadAction<RemoveSettledFilterPayload>,
    ) {
      state[action.payload.filtersSliceType].filtersFieldsSettled = state[
        action.payload.filtersSliceType
      ].filtersFieldsSettled.filter((elem) => elem !== action.payload.value);
    },
  },
});

export const {
  addFilters,
  changeFilterValue,
  applyFilters,
  resetFilters,
  setFilterField,
  removeSettledFilterField,
  setSort,
} = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;
