import React from "react";
import Coin from "components/Coin";
import { PriceType } from "types/listing";
import { useTranslation } from "react-i18next";

import digitalizeLongNumber from "utils/digitalizeLongNumber";
import s from "./Price.module.scss";

export type PriceProps = {
  priceType: PriceType;
  priceConverted: string;
  quantity?: number;
};

function Price({ priceType, priceConverted, quantity = 0 }: PriceProps) {
  const { t } = useTranslation();
  const priseConvertedDigitalised = digitalizeLongNumber(priceConverted);
  return (
    <div className={s.priceWrapper}>
      <div className={s.title}>{t("common.price")}:</div>
      <div className={s.price}>
        <Coin currency={priceType} className={s.coin} />
        <span className={s.value}>{priseConvertedDigitalised}</span>
        {Boolean(quantity) && <span className={s.quantity}>x {quantity}</span>}
      </div>
      {Boolean(quantity) && (
        <>
          <div className={s.title}>Total price: </div>
          <span className={s.value}>
            {Number(priceConverted) * quantity}{" "}
            <span className="uppercase">{priceType}</span>
          </span>
        </>
      )}
    </div>
  );
}

export default Price;
