import React, { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  closeConfirmationModal,
  finishTaskRequest,
  setResultTaskRequest,
} from "store/modalsSlice";

import Button from "components/common/Button";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./ConfirmationModal.module.scss";

interface ConfirmationModalProps {
  children?: React.ReactNode;
}

function ConfirmationModal({ children = undefined }: ConfirmationModalProps) {
  const dispatch = useAppDispatch();
  const {
    confirmationModal: { isOpen, buttonText, title, text },
  } = useAppSelector((state) => state.modals);

  const close = useCallback(() => {
    dispatch(closeConfirmationModal());
    dispatch(finishTaskRequest());
  }, [dispatch]);

  const onButtonClick = () => {
    dispatch(closeConfirmationModal());
    dispatch(setResultTaskRequest({ result: "resolved" }));
  };

  const actions = [<Button onClick={onButtonClick}>{buttonText}</Button>];

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={title}
      actions={actions}
    >
      <div className={s.modal}>
        <div className={s.information}>
          {children && <div className={s.childrenContainer}>{children}</div>}
          {text && <div className={s.text}>{text}</div>}
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default ConfirmationModal;
