import noNft from "assets/images/noNFT.webp";
import Button from "components/common/Button";
import ProjectPath from "constants/paths";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import s from "./NoBurnPass.module.scss";

function NoBurnPass() {
  const { t } = useTranslation();

  return (
    <div>
      <div className={s.description}>
        {t("burnPass.noBurnPass.description")}
      </div>
      <Link className={s.link} to={`/${ProjectPath.MARKET}`}>
        <Button className={s.button}>{t("burnPass.noBurnPass.button")}</Button>
      </Link>
      <img src={noNft} alt="No pass" className={s.noNft} />
    </div>
  );
}

export default NoBurnPass;
