import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import burnPass from "api/burnPass";
import ApiError from "classes/ApiError";
import { BURN_PASS_STATUS_NAME_LOCAL_STORAGE } from "constants/burnPass";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { setHasGenesis } from "store/userProfileSlice";
import { BurnPassResponse } from "types/responses";
import { showErrors } from "utils/showErrors";

const useBurnPass = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const queryInfo = useMutation<BurnPassResponse, ApiError, number>({
    mutationKey: ["burnPass"],
    mutationFn: (tokenId: number) => burnPass(tokenId),
    onSuccess: () => {
      enqueueSnackbar({
        variant: "success",
        message: "Congratulations, you are now the owner of Genesis Status!",
      });
      dispatch(setHasGenesis(true));
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      queryClient.invalidateQueries({ queryKey: ["balance"] });
    },
    onError: (error) => {
      localStorage.removeItem(BURN_PASS_STATUS_NAME_LOCAL_STORAGE);
      showErrors(error);
    },
  });

  return queryInfo;
};

export default useBurnPass;
