import { Variants } from "framer-motion";

export const modalFadeIn = (): Variants => {
  return {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
};

export const modalDrawer = (): Variants => {
  return {
    hidden: {
      translateY: "100%",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    visible: {
      translateY: "0%",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
};

interface MenuSlideParams {
  alignment?: "left" | "right";
}

export const menuSlide = ({
  alignment = "right",
}: MenuSlideParams): Variants => {
  if (alignment === "right") {
    return {
      hidden: {
        translateX: "100%",
        transition: {
          duration: 0.3,
          ease: "easeInOut",
        },
      },
      visible: {
        translateX: "0%",
        transition: {
          duration: 0.3,
          ease: "easeInOut",
        },
      },
    };
  }
  return {
    hidden: {
      translateX: "-100%",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    visible: {
      translateX: "0%",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
};

export const selectorSlide = (): Variants => {
  return {
    hidden: {
      // translateY: "-20%",
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    visible: {
      // translateY: "0%",
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };
};
