import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getListingById } from "api/listing";
import { showErrors } from "utils/showErrors";

import ApiError from "classes/ApiError";
import { Collection, Listing } from "types/listing";
import { GetListingByIdRequest } from "types/requests";
import { updateMarketItem } from "store/marketItemSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/store";

type UseGetListingByIdParams = GetListingByIdRequest & {
  enabled?: boolean;
};

const useGetListingById = ({
  enabled = true,
  ...params
}: UseGetListingByIdParams) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deps: any[] = [
    "listing",
    "id" in params ? params.id : params.collectionID,
  ];

  const queryInfo = useQuery<Listing | Collection, Error>(
    deps,
    async () => {
      return getListingById(params);
    },
    {
      enabled,
    },
  );

  useEffect(() => {
    const type = queryInfo?.data?.metadata?.attributesObj?.Type?.value;
    const listingHeaderTitle = type ? type.toString() : "";
    dispatch(
      updateMarketItem({
        image: queryInfo?.data?.metadata?.image || "",
        title: listingHeaderTitle,
        hash: queryInfo?.data?.metadata?.name || "",
        priceType: queryInfo?.data?.price_type || "trc",
        priceConverted: queryInfo?.data?.priceConverted || "",
        quantity: undefined,
      }),
    );
  }, [
    dispatch,
    queryInfo?.data?.metadata?.attributesObj?.Type?.value,
    queryInfo?.data?.metadata?.image,
    queryInfo?.data?.metadata?.name,
    queryInfo?.data?.priceConverted,
    queryInfo?.data?.price_type,
    queryInfo?.data?.tokenId,
    t,
  ]);

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error]);

  return queryInfo;
};

export default useGetListingById;
