import { TokenAddress } from "types/listing";

const contractaddressCars =
  process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS_CARS || "";
const contractaddressPass =
  process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS_PASS || "";
const contractaddressCypet =
  process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS_CYPET || "";
const contractaddressParts =
  process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS_PARTS || "";
const contractaddressTowns =
  process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS_TOWNS || "";

const ContractsMap: Record<TokenAddress, string> = {
  car: contractaddressCars,
  pass: contractaddressPass,
  town: contractaddressTowns,
  part: contractaddressParts,
  pet: contractaddressCypet,
};

export default ContractsMap;
