import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Listing from "components/Listing";
import ListingItem from "components/Listing/ListingItem";
import { BURN_PASS_STATUS_NAME_LOCAL_STORAGE } from "constants/burnPass";
import useBurnPass from "hooks/requests/useBurnPass";
import useGetBalance from "hooks/requests/useGetBalance";
import { useAppSelector } from "hooks/store";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NoBurnPass from "../NoBurnPass";

import s from "./PassList.module.scss";

function PassList() {
  const { data, isLoading } = useGetBalance("pass");
  const { t } = useTranslation();
  const { isLoading: isBurning, mutate: burnPass } = useBurnPass();
  const { hasGenesis } = useAppSelector((state) => state.userProfile);
  const isPassBurningLS = !!localStorage.getItem(
    BURN_PASS_STATUS_NAME_LOCAL_STORAGE,
  );

  const passes = data?.pass.token_metadata;

  useEffect(() => {
    if (hasGenesis) {
      localStorage.removeItem(BURN_PASS_STATUS_NAME_LOCAL_STORAGE);
    }
  }, [hasGenesis]);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault();
    burnPass(id);
    localStorage.setItem("passBurning", "true");
  };

  return (
    <div>
      {isLoading || passes?.length ? (
        <div>
          <div className={s.warning}>
            <Icon variant="warningExclamationSvg" className={s.warningIcon} />
            <span className={s.warningText}>{t("burnPass.warning")}</span>
          </div>
          <Listing className={s.listing} loading={isLoading}>
            {passes?.map((pass) => (
              <ListingItem
                key={pass.token_id}
                tokenAddress="pass"
                id={pass.token_id}
                tokenId={pass.token_id}
                attributes={pass.metadata.attributesObj}
                image={pass.metadata.image}
                name=""
                isWalletItem
                quantity={pass.metadata.balance}
                action={
                  <>
                    <div className={s.nameWrapper}>
                      <span className={s.name}>NFT Pass</span>
                      <span className={s.hash}>#{pass.token_id}</span>
                    </div>
                    <Button
                      className={s.button}
                      isLoading={isBurning}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        clickHandler(e, pass.token_id)
                      }
                      disabled={hasGenesis || isPassBurningLS}
                    >
                      {t("burnPass.button")}
                    </Button>
                  </>
                }
              />
            ))}
          </Listing>
        </div>
      ) : (
        <NoBurnPass />
      )}
    </div>
  );
}

export default PassList;
