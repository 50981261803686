import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Link } from "react-router-dom";

import Icon from "components/common/Icon";
import Burger from "components/common/Burger";
import NavigationLink from "layouts/NavigationLayout/components/NavigationLink";

import ProjectPath from "constants/paths";
import { closeNavigationMenu, openNavigationMenu } from "store/menusSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useWhichPage from "hooks/useWhichPage";

import s from "./BottomPanelContent.module.scss";

function BottomPanelContent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.menus.navigationMenu);
  const { isTgWebApp } = useAppSelector((state) => state.appState);
  const { isMarket, isWallet, isGameShop } = useWhichPage();

  const onBurgerClick = () => {
    if (isOpen) {
      dispatch(closeNavigationMenu());
    } else {
      dispatch(openNavigationMenu());
    }
  };

  return (
    <div className={cn(s.bottom, isTgWebApp && s.bottomTg)}>
      <div className={s.container}>
        <div
          className={`${s.navigationItem} ${isMarket ? s.active : ""} ${
            s.wallet
          }`}
        >
          <Icon variant="market" className={s.icon} color="white" />
          <span className={s.subTitle}>{t("common.pages.market")}</span>
          <NavigationLink
            link={`/${ProjectPath.MARKET}`}
            mustBeAuthorized
            isResourceLocal
            title="wallet"
          />
        </div>
        <div
          className={`${s.navigationItem} ${isWallet ? s.active : ""} ${
            s.wallet
          }`}
        >
          <Icon variant="wallet" className={s.icon} color="white" />
          <span className={s.subTitle}>{t("common.pages.wallet")}</span>
          <NavigationLink
            link={`/${ProjectPath.WALLET}`}
            mustBeAuthorized
            isResourceLocal
            title="wallet"
          />
        </div>
        {!isTgWebApp && (
          <button
            type="button"
            className={`${s.navigationItem} ${isOpen ? s.active : ""}`}
            onClick={onBurgerClick}
          >
            <div className={s.burgerWrapper}>
              <Burger
                active={isOpen}
                onClick={onBurgerClick}
                id="burgerButton"
              />
            </div>
            <span className={s.subTitle}>{t("common.menu")}</span>
          </button>
        )}
        {false && (
          <div className={`${s.navigationItem} ${isGameShop ? s.active : ""}`}>
            <Icon variant="game_shop" className={s.icon} color="white" />
            <span className={s.subTitle}>{t("common.pages.shop")}</span>
            <NavigationLink
              link={`/${ProjectPath.GAME_SHOP}`}
              mustBeAuthorized={false}
              isResourceLocal
              title="gameShop"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BottomPanelContent;
