/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { useAppSelector } from "hooks/store";
import useGetAuthCookie from "hooks/useGetAuthCookie";

import {
  LogOutButton,
  MoreNavigationElement,
  NavigationElement,
  NewLanguageSelector,
  navigationItems,
  navigationMore,
} from "layouts/NavigationLayout";
import NavigationMenuContainer from "../NavigationMenuContainer";

import TopControlPart from "./TopControlPart";
import ProfilePart from "./ProfilePart";
import AuthorizationPart from "./AuthorizationPart";
import SocialsPart from "./SocialsPart";

import s from "./NavigationMenu.module.scss";

function NavigationMenu() {
  const { isOpen } = useAppSelector((state) => state.menus.navigationMenu);
  const cookie = useGetAuthCookie();

  return (
    <NavigationMenuContainer open={isOpen} alignment="left">
      <div className={s.navigationMenu}>
        <TopControlPart />
        <div className={s.space} />
        {cookie && <ProfilePart />}
        {!cookie && <AuthorizationPart />}
        <div className={s.space} />
        <div className={s.navigation}>
          {navigationItems.map((elem) => (
            <NavigationElement {...elem} key={elem.id} />
          ))}
          <MoreNavigationElement {...navigationMore} />
        </div>
        <div className={s.space} />
        <NewLanguageSelector variant="mobile" />
        <div className={s.space} />
        {cookie && <LogOutButton />}
        <div className={s.space} />
        <div className={s.separator} />
        <div className={s.space} />
        <SocialsPart />
      </div>
    </NavigationMenuContainer>
  );
}

export default NavigationMenu;
