import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CaptchaSource } from "types/captcha";

type WindowSizeVariants = "isMobile" | "isTablet" | "isDesktop";

interface InitialState {
  windowSize: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
  authToken: string;
  isCaptchaSolved: boolean;
  isCaptchaOpen: boolean;
  isTgWebApp: boolean | undefined;
  isAuthCaptchaOpened: boolean;
  authCaptchaSource: CaptchaSource | null;
  isTgConnectEnabled: boolean;
}

const windowSizeFalse = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const initialState: InitialState = {
  windowSize: { isMobile: false, isTablet: false, isDesktop: true },
  authToken: "",
  isCaptchaSolved: false,
  isCaptchaOpen: false,
  isTgWebApp: undefined,
  isAuthCaptchaOpened: false,
  authCaptchaSource: null,
  isTgConnectEnabled: false,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setWindowSize(state, action: PayloadAction<WindowSizeVariants>) {
      switch (action.payload) {
        case "isMobile":
          state.windowSize = { ...windowSizeFalse, isMobile: true };
          break;
        case "isTablet":
          state.windowSize = { ...windowSizeFalse, isTablet: true };
          break;
        case "isDesktop":
          state.windowSize = { ...windowSizeFalse, isDesktop: true };
          break;
        default:
          break;
      }
    },
    setAuthToken(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
    },
    dropAuthToken(state) {
      state.authToken = "";
    },
    solveCaptcha(state, action: PayloadAction<boolean>) {
      state.isCaptchaSolved = action.payload;
    },
    setIsCaptchaOpen(state, action: PayloadAction<boolean>) {
      state.isCaptchaOpen = action.payload;
    },
    setIsTgWebApp(state, action: PayloadAction<boolean>) {
      state.isTgWebApp = action.payload;
    },
    setIsAuthCaptchaOpened(state, action: PayloadAction<boolean>) {
      state.isAuthCaptchaOpened = action.payload;
    },
    setAuthCaptchaSource(state, action: PayloadAction<CaptchaSource>) {
      state.authCaptchaSource = action.payload;
    },
    dropAuthCaptchaSource(state) {
      state.authCaptchaSource = null;
    },
    setIsTgConnectEnabled(state, action: PayloadAction<boolean>) {
      state.isTgConnectEnabled = action.payload;
    },
  },
});

export const {
  setWindowSize,
  setAuthToken,
  dropAuthToken,
  solveCaptcha,
  setIsCaptchaOpen,
  setIsTgWebApp,
  setIsAuthCaptchaOpened,
  setAuthCaptchaSource,
  dropAuthCaptchaSource,
  setIsTgConnectEnabled,
} = appStateSlice.actions;

export const appStateReducer = appStateSlice.reducer;
