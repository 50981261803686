import cn from "classnames";
import Icon from "components/common/Icon";
import { CITY_CATEGORY_TO_ICON, TownCategory } from "constants/townConstants";
import React from "react";
import { TFuncKey, useTranslation } from "react-i18next";
import { TraitType } from "types/listing";
import CharacteristicValue from "./CharacteristicValue";
import ProgressBar from "./ProgressBar";

import s from "./Characteristic.module.scss";

interface CharacteristicProps {
  variant: TraitType;
  value: number | string;
  max?: number;
}

// TODO: Заменить props => context

function Characteristic({
  variant,
  value,
  max = undefined,
}: CharacteristicProps) {
  const { t } = useTranslation();

  switch (variant) {
    case "Tier":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {t("item.stat.tier")} {value}
              </div>
            </div>
          </div>
          <Icon
            variant="star"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="whiteBlueBack"
          />
        </div>
      );
    case "Level":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {value} {t("item.level")}
              </div>
            </div>
          </div>
          <Icon
            variant="arrowThick"
            withWrapper
            className={s.iconArrow}
            wrapperClassName={s.iconWrapper}
            color="whiteOrangeBack"
          />
        </div>
      );
    case "Durability":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>{t("item.durability")}</div>
              <CharacteristicValue value={value} variant="percentage" />
            </div>
            <div className={s.progressBar}>
              <ProgressBar max={max} value={Number(value)} color="purple" />
            </div>
          </div>
          <Icon
            variant="repair"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="purple"
          />
        </div>
      );
    case "Earning":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>{t("item.earning")}</div>
              <CharacteristicValue
                value={value}
                max={max}
                variant="fraction"
                secondLine="2 min"
              />
            </div>
            <div className={s.progressBar}>
              <ProgressBar max={max} value={Number(value)} color="green" />
            </div>
          </div>
          <Icon
            variant="wallet"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="green"
          />
        </div>
      );
    case "Breeding":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>{t("item.breeding")}</div>
              <CharacteristicValue value={value} max={max} variant="fraction" />
            </div>
            <div className={s.progressBar}>
              <ProgressBar
                max={max}
                value={Number(value)}
                color="green2"
                variant="sectors"
              />
            </div>
          </div>
          <Icon
            variant="plus"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="green2"
          />
        </div>
      );
    case "Energy":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>{t("item.energy")}</div>
              <CharacteristicValue value={value} max={max} variant="fraction" />
            </div>
            <div className={s.progressBar}>
              <ProgressBar max={max} value={Number(value)} color="blue" />
            </div>
          </div>
          <Icon
            variant="energy"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="blue"
          />
        </div>
      );
    case "Attack":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {t("item.attack")} {value}
              </div>
            </div>
          </div>
          <Icon
            variant="attackSvg"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="attack"
          />
        </div>
      );
    case "Defense":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {t("item.defense")} {value}
              </div>
            </div>
          </div>
          <Icon
            variant="defenseSvg"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="defense"
          />
        </div>
      );
    case "Rarity":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {t(
                  `item.rarityType.${value
                    .toString()
                    .toLowerCase()}` as TFuncKey,
                ).toString()}
              </div>
            </div>
          </div>
          <Icon
            variant="raritySvg"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="whiteBlueBack"
          />
        </div>
      );
    case "Element":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={s.title}>
                {t(
                  `item.elementType.${value
                    .toString()
                    .toLowerCase()}` as TFuncKey,
                ).toString()}
              </div>
            </div>
          </div>
          <Icon
            variant="elementSvg"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="element"
          />
        </div>
      );
    case "Lat":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.informationVertical}>
              <div className={s.label}>{t("item.stat.lat")}</div>
              <div className={s.value}>{value}</div>
            </div>
          </div>
          <Icon
            variant="location"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="gray"
          />
        </div>
      );
    case "Lon":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.informationVertical}>
              <div className={s.label}>{t("item.stat.lon")}</div>
              <div className={s.value}>{value}</div>
            </div>
          </div>
          <Icon
            variant="location"
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color="gray"
          />
        </div>
      );
    case "Category":
      return (
        <div className={`${s.characteristic} ${s[variant]}`}>
          <div className={s.leftPart}>
            <div className={s.information}>
              <div className={cn(s.title, s.category)}>
                {t(
                  `item.categoryType.${value
                    .toString()
                    .toLowerCase()}` as TFuncKey,
                ).toString()}
              </div>
            </div>
          </div>
          <Icon
            variant={CITY_CATEGORY_TO_ICON[value as TownCategory]}
            withWrapper
            className={s.icon}
            wrapperClassName={s.iconWrapper}
            color={CITY_CATEGORY_TO_ICON[value as TownCategory]}
          />
        </div>
      );

    default:
      return null;
  }
}

export default Characteristic;
