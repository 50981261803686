/* eslint-disable import/prefer-default-export */
import ApiError from "classes/ApiError";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";

export const showErrors = (error: Error) => {
  if (error instanceof ApiError && error?.errors?.length > 0) {
    error.errors.forEach((err) => {
      if (err?.meta?.error) {
        enqueueSnackbar({ variant: "error", message: err.meta.error });
      } else {
        enqueueSnackbar({
          variant: "error",
          message: t("notifications.somethingWentWrong.title").toString(),
        });
      }
    });
  } else if (error.message) {
    enqueueSnackbar({ variant: "error", message: error.message });
  }
};
