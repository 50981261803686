import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Icon from "components/common/Icon";

import { useTranslation } from "react-i18next";
import SimpleLoader from "components/common/SimpleLoader";

import s from "./NftCategory.module.scss";

export type NftCategoryProps = {
  image: string;
  link: string;
  title: string;
  totalSupply: string;
  listedPercentage: string;
  isTrendy: boolean;
  className?: string;
  isLoading: boolean;
};

function NftCategory({
  image,
  link,
  title,
  totalSupply,
  listedPercentage,
  isTrendy,
  className = "",
  isLoading,
}: NftCategoryProps) {
  const { t } = useTranslation();
  return (
    <Link to={link} className={className}>
      <div className={s.category}>
        <div className={s.imageBlock}>
          <img src={image} alt="nft-category" className="fill-cover" />
          <div className={s.border} />
          {isTrendy && (
            <div className={cn(s.trendy)}>
              <Icon variant="trendyCrown" className={s.icon} />
              <div>{t("common.trendy")}</div>
            </div>
          )}
        </div>
        <div className={s.infoBlock}>
          <div className={s.title}>{title}</div>
          <div className={s.stat}>
            {isLoading ? (
              <SimpleLoader />
            ) : (
              <>
                <div className={s.supply}>
                  {totalSupply} {t("common.items")}
                </div>
                <div className={s.percentage}>
                  {listedPercentage}% {t("common.listed")}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default NftCategory;
