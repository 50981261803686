/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Loader from "react-loaders";
import { NavLink } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";

import * as lang from "i18n/en.json";

import { walletPricedCurrencies } from "constants/wallet";

import { IconsVariant } from "types/icons";
import { EUSDPricedCoins } from "types/coins";

import {
  openMoonPayModal,
  openWalletSelectTransferTypeModal,
} from "store/modalsSlice";

import roundTo from "utils/roundTo";
import digitalizeLongNumber from "utils/digitalizeLongNumber";

import { useAppDispatch } from "hooks/store";
import useCopy from "hooks/useCopy";
import useGetBalance from "hooks/requests/useGetBalance";

import Icon from "components/common/Icon";
import Button from "components/common/Button";
import WalletSelectTransferTypeModal from "components/modals/WalletSelectTransferTypeModal/WalletSelectTransferTypeModal";

import ActionsMobile from "../ActionsMobile";
import ActionElement from "./ActionElement";

import s from "./Actions.module.scss";

export interface IAction {
  title: string;
  iconVariant: `${IconsVariant}`;
  codename: "fillUp" | "buyCrypto" | "exchange" | "transfer";
  link?: string;
  handleClick?: () => void;
}

interface ActionsProps {
  openFillUpModal: () => void;
  currenciesUSDPrices: {
    [key in EUSDPricedCoins]: number;
  };
}

function Actions({ openFillUpModal, currenciesUSDPrices }: ActionsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: balance, isLoading: isBalanceLoading } = useGetBalance();
  const [balanceSum, setBalanceSum] = useState<string>("");

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isBalanceLoading || !balance || !currenciesUSDPrices) return;

    const sum: number = walletPricedCurrencies.reduce(
      (prev: number, current: EUSDPricedCoins) => {
        let currentValue: string;
        const currentPrice: number = currenciesUSDPrices[current] || 0;

        if (
          current === EUSDPricedCoins.wbtc ||
          current === EUSDPricedCoins.weth
        ) {
          const changedCurrentBTC =
            current === EUSDPricedCoins.wbtc ? "wbtc" : "weth";
          currentValue = balance[`${changedCurrentBTC}Converted`] || "0";
        } else {
          currentValue = balance[`${current}Converted`] || "0";
        }
        return prev + Number(currentValue) * currentPrice;
      },
      0,
    );

    setBalanceSum(digitalizeLongNumber(roundTo(sum, 2, true)));
  }, [isBalanceLoading, balance, currenciesUSDPrices]);

  const balanceElement = useMemo(() => {
    const [whole, fractional = "00"] = balanceSum.split(".");
    if (whole && fractional) {
      return (
        <span className={s.value}>
          <span>{whole}</span>.
          <span className={s.fractional}>{fractional}</span>
        </span>
      );
    }
    return (
      <span className={s.value}>
        <span>0</span>.<span className={s.fractional}>00</span>
      </span>
    );
  }, [balanceSum]);

  const handleCopyButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(balance?.address || "");
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const actions: IAction[] = useMemo(
    () => [
      {
        title: t(`walletActions.fillUp`),
        iconVariant: "plus",
        codename: "fillUp",
        handleClick: openFillUpModal,
      },
      {
        title: t(`walletActions.buttons.buyCrypto`),
        iconVariant: "buycrypto_alt",
        codename: "buyCrypto",
        handleClick: () => {
          dispatch(openMoonPayModal());
        },
      },
      {
        title: t(`walletActions.buttons.exchange`),
        iconVariant: IconsVariant.swap_alt2,
        codename: "exchange",
        link: "/wallet/swap/usdt",
      },
      {
        title: t(`walletActions.buttons.transfer`),
        iconVariant: IconsVariant.up,
        codename: "transfer",
        handleClick: () => {
          dispatch(openWalletSelectTransferTypeModal());
        },
      },
    ],
    [openFillUpModal, t, dispatch],
  );

  if (window.innerWidth <= 415) {
    return (
      <ActionsMobile
        openFillUpModal={openFillUpModal}
        currenciesUSDPrices={currenciesUSDPrices}
      />
    );
  }

  return (
    <div className={s.root}>
      <header className={s.header}>
        <h3 className={s.title}>{t("common.balance")}</h3>

        <div className={s.copy}>
          <Button
            className={cn(s.copyButton, s.copyButton_disabled)}
            variant="empty"
            type="button"
            onClick={handleCopyButtonClick}
          >
            {t("walletActions.copy")}

            <Icon variant="copy_rect" />
          </Button>
          {isCopied && (
            <div className={s.alertBox}>
              {t("walletActions.copyTooltip")}
              <span className={s.alertBoxArrow} />
            </div>
          )}
        </div>
      </header>

      <div className={s.balance}>
        {balanceElement}

        <span className={s.currency}>$</span>
      </div>

      <div className={s.actions}>
        {actions.map((action) => (
          <ActionElement {...action} />
        ))}
      </div>

      {isBalanceLoading && (
        <div className={s.loader}>
          <Loader type="line-scale-pulse-out-rapid" active />
        </div>
      )}

      <WalletSelectTransferTypeModal />
    </div>
  );
}

export default Actions;
