import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getGasPrice } from "api/gas";
import { showErrors } from "utils/showErrors";

import ApiError from "classes/ApiError";
import { GasResponse } from "types/responses";

const useGetGasPrice = () => {
  const queryInfo = useQuery<GasResponse | null, Error>(
    ["gas"],
    async () => {
      return getGasPrice();
    },
    {
      refetchInterval: 30000,
    },
  );

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error]);

  return queryInfo;
};

export default useGetGasPrice;
