import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { copyToClipboard } from "utils/copyToClipboard";

interface UseCopyParams {
  text: string;
  notificationText?: string;
}

const useCopy = ({
  text,
  notificationText = "Data has been copied",
}: UseCopyParams) => {
  const { t } = useTranslation();

  return async () => {
    try {
      await copyToClipboard(text);
      enqueueSnackbar(notificationText);
    } catch (error: any) {
      console.log("error: ", error);
      if (error?.message) {
        enqueueSnackbar({ message: error.message, variant: "error" });
      } else {
        enqueueSnackbar({
          message: t("notifications.somethingWentWrong.title"),
          variant: "error",
        });
      }
    }
  };
};

export default useCopy;
