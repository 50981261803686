import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "components/common/Icon";

import s from "./CommissionContainer.module.scss";

const commissionPercent = process.env.REACT_APP_COMMISSION_PERCENT || 0;

function CommissionContainer() {
  const { t } = useTranslation();

  if (!commissionPercent) {
    return null;
  }

  return (
    <div className={s.commissionContainer}>
      <div className={s.leftPart}>
        <Icon variant="information" className={s.icon} />
        <div className={s.suggestedTitle}>{t("transaction.fee")}:</div>
      </div>
      <div className={s.value}>{commissionPercent}%</div>
    </div>
  );
}

export default CommissionContainer;
