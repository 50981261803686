import { useQueries, UseQueryResult } from "@tanstack/react-query";
import getNftTotalSupply from "api/blockchain/nfts";
import { TOWNS_TOTAL_SUPPLY } from "constants/listing";
import { TOKEN_ADDRESS_MAP } from "constants/nftConstants";
import { AllTotalSupplyResponse, TotalSupplyResponse } from "types/responses";
import { showErrors } from "utils/showErrors";

interface Response {
  carData: UseQueryResult<TotalSupplyResponse, Error>;
  passData: UseQueryResult<TotalSupplyResponse, Error>;
  partData: UseQueryResult<TotalSupplyResponse, Error>;
  petData: UseQueryResult<TotalSupplyResponse, Error>;
  townData: UseQueryResult<TotalSupplyResponse, Error>;
}

const useGetAllNftTotalSupply = () => {
  const nfts = Object.values(TOKEN_ADDRESS_MAP);

  const queryInfo = useQueries({
    queries: nfts.map((nft) => ({
      queryKey: [`totalSupply_${nft}`],
      queryFn: () => getNftTotalSupply(nft),
      onError: showErrors,
      staleTime: 8.64e7, // 24 hours,
    })),
  });

  const updatedQueryInfo = queryInfo.reduce(
    (acc, query, index) => ({
      ...acc,
      [`${nfts[index]}Data`]: query,
    }),
    {} as Response,
  );

  return updatedQueryInfo;
};

export default useGetAllNftTotalSupply;
