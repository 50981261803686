import { TFunction } from "react-i18next";
import { Coins, ESwapCoins, JustCoinType } from "types/coins";
import { GetSwapCostRequest, MakeSwapRequest } from "types/requests";
import { GetSwapCostResponseData } from "types/responses";
import {
  convertDecimalTokenShort,
  convertDecimalTokenBackward,
} from "utils/convertDecimalToken";
import {
  MIN_SWAP_ACE,
  MIN_SWAP_MATIC,
  MIN_SWAP_TRC,
  MIN_SWAP_USDT,
  MIN_SWAP_WBTC,
  MIN_SWAP_WETH,
} from "constants/swapConstants";
import { EWalletSwapCoinChooseModalType } from "types/modals";

export type SwapCostFormValues = {
  exchangeRate: string;
  swapFee: string;
  toAmount: string;
  toAmountLabel: string;
  fromAmount: string;
};

interface UseGetSwapCostParams extends GetSwapCostRequest {
  from: string;
  fromCurrency: ESwapCoins;
  toCurrency: ESwapCoins;
  enabled?: boolean;
}

export function createGetSwapCostBody(
  from: ESwapCoins,
  to: ESwapCoins,
  value: number,
): UseGetSwapCostParams {
  return {
    type: "swap_cost",
    from: convertDecimalTokenBackward(`${value}`, from),
    fromCurrency: from,
    toCurrency: to,
  };
}

export function createMakeSwapBody(
  from: Coins,
  to: Coins,
  value: number,
): MakeSwapRequest {
  return {
    type: "swap",
    [from]: convertDecimalTokenBackward(`${value}`, from),
    [to]: 0,
  };
}

const MAX_DECIMAL_PLACES_MAP: Record<Coins, number> = {
  trc: 2,
  matic: 8,
  weth: 8,
  wbtc: 4,
  usdt: 4,
  ace: 2,
};

const MINIMUM_AMOUNTS_MAP = {
  ace: MIN_SWAP_ACE,
  trc: MIN_SWAP_TRC,
  matic: MIN_SWAP_MATIC,
  usdt: MIN_SWAP_USDT,
  wbtc: MIN_SWAP_WBTC,
  weth: MIN_SWAP_WETH,
};

export function calculateExchangeRate(
  from: string,
  to: string,
  fromCurrency: ESwapCoins,
  toCurrency: ESwapCoins,
): string {
  const fromConverted = parseFloat(
    convertDecimalTokenShort(from, fromCurrency) ?? "1",
  );

  const toConverted = parseFloat(
    convertDecimalTokenShort(to, ESwapCoins[toCurrency]) ?? "1",
  );

  const exchangeRateToRaw = toConverted / fromConverted ?? "unknown";

  const maxDecimalPlaces = MAX_DECIMAL_PLACES_MAP[toCurrency];

  // Округляем, если результат - число
  const exchangeRateTo =
    typeof exchangeRateToRaw === "number"
      ? exchangeRateToRaw.toFixed(maxDecimalPlaces)
      : exchangeRateToRaw;

  const minimum = MINIMUM_AMOUNTS_MAP[ESwapCoins[fromCurrency]];

  return `
    1 ${fromCurrency.toUpperCase()} ≈ 
    ${exchangeRateTo
      .replace(/0+$/, "")
      .replace(/\.$/, "")} ${toCurrency.toUpperCase()} 
    (Min ${minimum ?? "unknown"} ${fromCurrency.toUpperCase()})
  `;
}

export function calculateTotalOutput(
  total_output: string,
  toCurrency: ESwapCoins,
): string {
  return convertDecimalTokenShort(total_output, ESwapCoins[toCurrency]) ?? "0";
}

export function updateInitialValues(
  {
    matic,
    trc,
    usdt,
    ace,
    wbtc,
    weth,
    min_fee,
    min_fee_currency,
    fee,
    total_output,
  }: GetSwapCostResponseData,
  fromCurrency: ESwapCoins,
  toCurrency: ESwapCoins,
  fromAmount: string,
  t: TFunction<"translation", undefined>,
): SwapCostFormValues {
  const feePercent = convertDecimalTokenShort(fee, "trc");

  const currenciesMap = {
    matic,
    trc,
    usdt,
    ace,
    wbtc,
    weth,
  };

  return {
    fromAmount,
    toAmount: fromAmount ? calculateTotalOutput(total_output, toCurrency) : "0",
    toAmountLabel: `(-${feePercent ?? "unknown"}% ${t("transaction.fee")})`,
    swapFee: `${feePercent ?? "unknown"} % (Min ${
      min_fee && min_fee_currency
        ? convertDecimalTokenShort(min_fee, min_fee_currency)
        : "unknown"
    } ${min_fee_currency?.toLocaleUpperCase() ?? ""})`,
    exchangeRate: calculateExchangeRate(
      currenciesMap[fromCurrency],
      currenciesMap[toCurrency],
      fromCurrency,
      toCurrency,
    ),
  };
}

export function calculateMinFromAmount(currency: Coins): number {
  switch (currency) {
    case JustCoinType.matic:
      return MIN_SWAP_MATIC;

    case JustCoinType.trc:
      return MIN_SWAP_TRC;

    case JustCoinType.usdt:
      return MIN_SWAP_USDT;

    case JustCoinType.ace:
      return MIN_SWAP_ACE;

    case JustCoinType.wbtc:
      return MIN_SWAP_WBTC;

    case JustCoinType.weth:
      return MIN_SWAP_WETH;

    default:
      return MIN_SWAP_TRC;
  }
}

export const getToCurrencyByFromCurrency = (
  currency: ESwapCoins,
): ESwapCoins => {
  switch (currency) {
    case ESwapCoins.matic:
      return ESwapCoins.trc;

    default:
      return ESwapCoins.matic;
  }
};

export const getDisabledCoins = (
  type: EWalletSwapCoinChooseModalType,
  fromCurrency: ESwapCoins,
  // TODO: remove toCurrency if will not needed
  toCurrency: ESwapCoins,
): ESwapCoins[] | undefined => {
  const disabledCurrencies: ESwapCoins[] = [];

  switch (type) {
    case EWalletSwapCoinChooseModalType.to:
      disabledCurrencies.push(fromCurrency);

      switch (fromCurrency) {
        case ESwapCoins.matic:
          disabledCurrencies.push(ESwapCoins.usdt);
          disabledCurrencies.push(ESwapCoins.wbtc);
          disabledCurrencies.push(ESwapCoins.weth);
          break;

        case ESwapCoins.usdt:
          disabledCurrencies.push(ESwapCoins.matic);
          disabledCurrencies.push(ESwapCoins.ace);
          disabledCurrencies.push(ESwapCoins.wbtc);
          disabledCurrencies.push(ESwapCoins.weth);
          break;

        case ESwapCoins.trc:
          disabledCurrencies.push(ESwapCoins.ace);
          break;

        case ESwapCoins.ace:
          disabledCurrencies.push(ESwapCoins.usdt);
          disabledCurrencies.push(ESwapCoins.trc);
          disabledCurrencies.push(ESwapCoins.wbtc);
          disabledCurrencies.push(ESwapCoins.weth);
          break;

        case ESwapCoins.wbtc:
          disabledCurrencies.push(ESwapCoins.matic);
          disabledCurrencies.push(ESwapCoins.ace);
          disabledCurrencies.push(ESwapCoins.weth);
          disabledCurrencies.push(ESwapCoins.usdt);
          break;

        case ESwapCoins.weth:
          disabledCurrencies.push(ESwapCoins.matic);
          disabledCurrencies.push(ESwapCoins.ace);
          disabledCurrencies.push(ESwapCoins.wbtc);
          disabledCurrencies.push(ESwapCoins.usdt);
          break;

        default:
          break;
      }
      break;

    default:
      break;
  }

  return disabledCurrencies;
};

export const getCurrencyNumberLengthAfterDot = (
  currency: ESwapCoins,
): number => {
  switch (currency) {
    case ESwapCoins.trc:
    case ESwapCoins.ace:
      return 2;
    case ESwapCoins.matic:
    case ESwapCoins.usdt:
      return 4;
    case ESwapCoins.wbtc:
    case ESwapCoins.weth:
      return 4;
    default: {
      const exhaustiveCheck: never = currency;
      return exhaustiveCheck;
    }
  }
};
