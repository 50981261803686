import { useEffect } from "react";
import useRequestAuthVerifyTgData from "hooks/requests/useRequestAuthVerifyTgData";
import { setIsTgWebApp } from "../store/appStateSlice";
import { useAppDispatch } from "../hooks/store";

function TgWebAppInitializer() {
  const dispatch = useAppDispatch();
  const { refetch: verifyTgData } = useRequestAuthVerifyTgData({
    WebApp: window.Telegram.WebApp,
  });

  useEffect(() => {
    if (window.Telegram.WebApp.initData) {
      verifyTgData();

      dispatch(setIsTgWebApp(true));
      const webApp = window.Telegram.WebApp;
      webApp.expand();
      webApp.ready();
      webApp.disableVerticalSwipes();

      setTimeout(() => {
        document.documentElement.style.setProperty(
          "--height",
          `${window.innerHeight}px`,
        );
      }, 500);
    } else {
      dispatch(setIsTgWebApp(false));
    }
  }, [dispatch, verifyTgData]);

  return null;
}

export default TgWebAppInitializer;
