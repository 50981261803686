/* eslint-disable import/prefer-default-export */

import { JustCoinType } from "types/coins";
import { TokenAddress } from "types/listing";

export const NFTCollectionCurrencies: Record<TokenAddress, JustCoinType[]> = {
  car: [JustCoinType.matic, JustCoinType.usdt, JustCoinType.trc],
  pass: [JustCoinType.matic, JustCoinType.trc],
  part: [JustCoinType.matic, JustCoinType.usdt, JustCoinType.trc],
  town: [JustCoinType.matic, JustCoinType.trc],
  pet: [JustCoinType.matic, JustCoinType.trc],
};
