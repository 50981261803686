import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { applyFilters } from "store/filtersSlice";
import { closeFilterMenu } from "store/menusSlice";
import Button from "components/common/Button";
import { FiltersSliceType } from "types/filters";
import { TokenAddress } from "types/listing";

import s from "./MenuBottomPanel.module.scss";

interface MenuBottomPanelProps {
  collectionType: TokenAddress;
  filterSliceType: FiltersSliceType;
  setIsAllFiltersCollapsed: Dispatch<SetStateAction<boolean>>;
}
function MenuBottomPanel({
  collectionType,
  filterSliceType,
  setIsAllFiltersCollapsed,
}: MenuBottomPanelProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filtersFieldsSettled } = useAppSelector(
    (state) => state.filters[filterSliceType],
  );

  const applyClick = () => {
    dispatch(
      applyFilters({
        value: collectionType ?? "",
        filtersSliceType: filterSliceType,
      }),
    );
    if (window.innerWidth <= 1280) {
      dispatch(closeFilterMenu());
    }
    setIsAllFiltersCollapsed(true);
  };

  const isAvailable = useMemo(() => {
    return filtersFieldsSettled.length > 0;
  }, [filtersFieldsSettled]);

  return (
    <div className={s.applyContainer}>
      <Button className={s.apply} disabled={!isAvailable} onClick={applyClick}>
        {t("common.apply")}
      </Button>
    </div>
  );
}

export default MenuBottomPanel;
