import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { closeMoonPayModal } from "store/modalsSlice";
import ModalContainerWithActions from "../ModalContainerWithActions";
import s from "./MoonPayModal.module.scss";

type MoonPayModalProps = {
  onClick: () => void;
};
function MoonPayModal({ onClick }: MoonPayModalProps) {
  const { t } = useTranslation();
  const {
    moonPayModal: { isOpen },
  } = useAppSelector((state) => state.modals);
  const dispatch = useAppDispatch();

  const close = useCallback(() => {
    dispatch(closeMoonPayModal());
  }, [dispatch]);

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.moonPay.title")}
      withCancelButton={false}
      actions={[
        <Button className={s.button} onClick={onClick}>
          {t("modals.moonPay.button")}
        </Button>,
      ]}
    >
      <div className={s.textContainer}>
        <span className={s.text}>
          {t("modals.moonPay.text_part_1")}
          <span className={s.moonPay}> MoonPay </span>
          {t("modals.moonPay.text_part_2")}
        </span>
      </div>
    </ModalContainerWithActions>
  );
}

export default MoonPayModal;
