import Button from "components/common/Button";
import { ButtonProps } from "components/common/Button/Button";
import withConnectToTelegram from "hocs/withConnectToTelegram";
import React from "react";

function ConnectTelegramButton(props: ButtonProps) {
  const TgButton = withConnectToTelegram(Button);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TgButton {...props} />;
}

export default ConnectTelegramButton;
