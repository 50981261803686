import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeSwap } from "api/wallet";
import { showErrors } from "utils/showErrors";
import ApiError from "classes/ApiError";
import { MakeSwapRequest } from "types/requests";
import { MakeSwapResponse } from "types/responses";
import { useAppDispatch } from "hooks/store";
import { openWalletSwapModal } from "store/modalsSlice";

const useMakeSwap = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation<MakeSwapResponse, ApiError, MakeSwapRequest>(
    ["swap"],
    async (body) => {
      return makeSwap(body);
    },
    {
      onSuccess: () => {
        dispatch(openWalletSwapModal());
        return queryClient.invalidateQueries({ queryKey: ["balance"] });
      },
      onError: showErrors,
    },
  );
};

export default useMakeSwap;
