import React, { useCallback } from "react";
import { enqueueSnackbar } from "notistack";

import ModalTitle from "components/modals/AuthModal/ModalTitle";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { openAuthConfirmationModal } from "store/modalsSlice";
import { useTranslation } from "react-i18next";

import s from "./MnemonicContent.module.scss";

function MnemonicContent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mnemonic, mnemonicString } = useAppSelector(
    (state) => state.modals.logInModal,
  );

  const onContinueClick = useCallback(() => {
    dispatch(openAuthConfirmationModal());
  }, [dispatch]);

  const onCopy = () => {
    navigator.clipboard.writeText(mnemonicString);
    enqueueSnackbar({ variant: "default", message: "Phrase has been copied" });
  };

  return (
    <div className={s.modal}>
      <ModalTitle>{t("modals.mnemonic.title")}</ModalTitle>
      <div className={s.description}>{t("modals.mnemonic.subtitle")}</div>
      <div className={s.grid}>
        {mnemonic.map((word, index) => (
          <div className={s.wordWrapper} key={word}>
            <div className={s.index}>{index + 1}</div>
            <div className={s.word}>{word}</div>
          </div>
        ))}
      </div>
      <div className={s.buttons}>
        <button type="button" className={s.copyButton} onClick={onCopy}>
          <div className={s.copyButtonText}>{t("modals.mnemonic.copy")}</div>
          <Icon
            variant="copy"
            className={s.copy}
            withWrapper
            wrapperClassName={s.copyWrapper}
          />
        </button>
        <Button className={s.continue} onClick={onContinueClick}>
          {t("common.continue")}
        </Button>
      </div>
    </div>
  );
}

export default MnemonicContent;
