import React from "react";
import Skeleton from "react-loading-skeleton";

import s from "./ListingItemSkeleton.module.scss";

function ListingItemSkeleton() {
  return (
    <div className={s.listingItem}>
      <Skeleton height="100%" containerClassName="skeleton" />
    </div>
  );
}

export default ListingItemSkeleton;
