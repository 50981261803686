/* eslint-disable import/prefer-default-export */
import { SimpleListing, TokenAddress } from "types/listing";
import { RestructuredGetBalanceResponse } from "types/responses";

export const getBalanceItem = (
  balance: RestructuredGetBalanceResponse,
  itemId: number,
  tokenAddress: TokenAddress,
): SimpleListing | undefined => {
  return balance[tokenAddress]?.token_metadata.find(
    (elem) => elem.token_id === itemId,
  );
};
