import { convertDecimalTokenShort } from "utils/convertDecimalToken";
import { web3 } from "..";
import incomeAbi from "./incomeAbi";

type IReferralIncomeResponse = bigint;

type IReferralIncomeConverted = string;

const REWARD_CONTRACT = process.env.REACT_APP_REWARD_CONTRACT || "";

const getReferralIncome = async (
  walletAddress: string,
): Promise<IReferralIncomeConverted> => {
  const referralContract = new web3.eth.Contract(incomeAbi, REWARD_CONTRACT);
  const result: IReferralIncomeResponse = await referralContract.methods
    .totalReward(walletAddress)
    .call();
  return convertDecimalTokenShort(result.toString(), "matic") || "0";
};

export default getReferralIncome;
