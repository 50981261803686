import React from "react";

import ACE from "assets/images/coins/ACE.png";
import TRC from "assets/images/coins/TRC.png";
import MATIC from "assets/images/coins/MATIC.png";
import USDT from "assets/images/coins/USDT.png";
import WBTC from "assets/images/coins/WBTC.png";
import WETH from "assets/images/coins/WETH.png";
import NFT from "assets/images/coins/NFT.png";

import { CoinType } from "types/coins";

import s from "./Coin.module.scss";

interface CoinProps {
  currency: `${CoinType}`;
  variant?: "default" | "outerRing";
  className?: string;
  wrapperClassName?: string;
}

function Coin({
  currency,
  variant = "default",
  className = "",
  wrapperClassName = "",
}: CoinProps) {
  const getCoin = () => {
    switch (currency) {
      case CoinType.ace:
        return ACE;
      case CoinType.trc:
        return TRC;
      case CoinType.matic:
        return MATIC;
      case CoinType.nft:
        return NFT;
      case CoinType.usdt:
        return USDT;
      case CoinType.wbtc:
        return WBTC;
      case CoinType.weth:
        return WETH;
      default:
        return ACE;
    }
  };

  if (variant === "outerRing") {
    return (
      <div className={`${s.wrapper} ${s[currency]} ${wrapperClassName || ""}`}>
        <div className={`${s.coin} ${className || ""}`}>
          <img src={getCoin()} alt={currency} className="fill-contain" />
        </div>
      </div>
    );
  }
  return (
    <div className={`${s.coin} ${className || ""}`}>
      <img src={getCoin()} alt={currency} className="fill-contain" />
    </div>
  );
}

export default Coin;
