import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Collection,
  Listing,
  ListingTabType,
  ListingTabTypeMap,
  TokenAddress,
} from "types/listing";
import { closeFilterMenu, openFilterMenu } from "store/menusSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { FiltersSliceType } from "types/filters";
import Icon from "components/common/Icon";
import { RequestListingFilter } from "types/requests";
import createListingParams from "pages/MarketCollectionPage/utils";
import useGetFilters from "hooks/requests/useGetFilters";
import useGetListing from "hooks/requests/useGetListing";
import useGetWindowWidth from "hooks/useGetWindowWidth";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithResetContainer from "./parts/WithResetContainer";
import SortButton from "./parts/SortButton";
import MenuContainer from "../MenuContainer";
import MenuBottomPanel from "../MenuBottomPanel";

import s from "./FilterMenu.module.scss";

interface FilterMenuProps {
  listingType: ListingTabType;
}

function FilterMenu({ listingType }: FilterMenuProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const windowWidth = useGetWindowWidth();
  const { collectionType = "car" } = useParams<{
    collectionType: TokenAddress;
  }>();
  useGetFilters({
    listingType,
    typeNft: collectionType ?? "car",
  });
  const { tab } = useAppSelector((state) => state.marketCollectionTab);
  const filterSliceType: FiltersSliceType = `${collectionType ?? "car"}Filters${
    ListingTabTypeMap[listingType]
  }`;
  const { isOpen } = useAppSelector((state) => state.menus.filterMenu);
  const getListingParams = createListingParams(
    "" as RequestListingFilter,
    tab,
    collectionType ?? "car",
  );
  const { data } = useGetListing(getListingParams);
  const [isAllFiltersCollapsed, setIsAllFiltersCollapsed] = useState(true);

  const listings = useMemo(() => {
    if (data === undefined) return [];
    return data.pages.flatMap((p): (Collection | Listing)[] => {
      if (!p) return [];
      if ("collections" in p) return p.collections;
      return p.listings;
    });
  }, [data]);

  useEffect(() => {
    if (windowWidth > 1280) {
      dispatch(openFilterMenu());
    } else {
      dispatch(closeFilterMenu());
    }
  }, [dispatch, windowWidth]);

  const close = useCallback(() => {
    dispatch(closeFilterMenu());
  }, [dispatch]);

  if (!listings.length || !listings[0]) {
    return null;
  }

  return (
    <div>
      {windowWidth <= 1280 ? (
        <MenuContainer
          open={isOpen}
          close={close}
          headerTitle={t("menus.filter.title")}
          onBackgroundClose={window.innerWidth <= 1340}
          classNameHeader={s.menuHeader}
          classNameHeaderContainer={s.menuHeaderContainer}
          classNameHeaderTitle={s.menuHeaderTitle}
          classNameHeaderIcon={s.menuCloseIcon}
          headerWithCloseButton
        >
          <div className={s.menu}>
            <SortButton filtersSliceType={filterSliceType} />
            <WithResetContainer
              collectionType={collectionType}
              filterSliceType={filterSliceType}
              listingType={listingType}
              isAllFiltersCollapsed={isAllFiltersCollapsed}
              setIsAllFiltersCollapsed={setIsAllFiltersCollapsed}
            />
          </div>
          <MenuBottomPanel
            collectionType={collectionType}
            filterSliceType={filterSliceType}
            setIsAllFiltersCollapsed={setIsAllFiltersCollapsed}
          />
        </MenuContainer>
      ) : (
        <div className={s.menu}>
          <div className={s.titleContainer}>
            <div className={s.iconWrapper}>
              <Icon variant="settingsSvg" className={s.settingsIcon} />
            </div>
            <span className={s.title}>{t("menus.filter.desckTitle")}</span>
          </div>
          <SortButton filtersSliceType={filterSliceType} />
          <WithResetContainer
            filterSliceType={filterSliceType}
            collectionType={collectionType}
            listingType={listingType}
            key={filterSliceType}
            isAllFiltersCollapsed={isAllFiltersCollapsed}
            setIsAllFiltersCollapsed={setIsAllFiltersCollapsed}
          />
          <MenuBottomPanel
            collectionType={collectionType}
            filterSliceType={filterSliceType}
            setIsAllFiltersCollapsed={setIsAllFiltersCollapsed}
          />
        </div>
      )}
    </div>
  );
}

export default FilterMenu;
