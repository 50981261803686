import React from "react";
import Skeleton from "react-loading-skeleton";

import s from "./Characteristic.module.scss";

function CharacteristicSkeleton() {
  return (
    <div className={s.characteristic}>
      <Skeleton height="100%" containerClassName="skeleton fill" />
    </div>
  );
}

export default CharacteristicSkeleton;
