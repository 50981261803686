import BackButton from "components/common/BackButton";
import ProjectPath from "constants/paths";
import ListingStatus from "pages/ItemPage/ListingStatus";
import React from "react";
import { useLocation } from "react-router-dom";
import { TokenAddress } from "types/listing";
import cn from "classnames";
import s from "./ListingHeader.module.scss";

type ListingHeaderProps = {
  title: string;
  tokenAddress: TokenAddress;
  name?: string;
  isMyToken: boolean;
  isInWallet: boolean;
};
function ListingHeader({
  title,
  tokenAddress,
  name = "",
  isMyToken,
  isInWallet,
}: ListingHeaderProps) {
  const location = useLocation();
  return (
    <div className={s.titleWrapper}>
      <BackButton
        className={cn(s.backButton, { [s.backButtonInWallet]: isInWallet })}
        iconClassName={s.icon}
        route={
          location.pathname.includes("wallet")
            ? `/${ProjectPath.WALLET_NFT}`
            : `/${ProjectPath.MARKET}/${tokenAddress}`
        }
      />
      <div className={cn({ [s.blockWrapper]: isInWallet })}>
        <div className={s.title}>{title}</div>
        {isMyToken && isInWallet && (
          <ListingStatus status="onMarket" wrapperClassName={s.status} />
        )}
      </div>
      {name && <div className={s.hash}>{name}</div>}
    </div>
  );
}

export default ListingHeader;
