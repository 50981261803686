import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setReferralCode } from "api/auth";
import { showErrors } from "utils/showErrors";
import ApiError from "classes/ApiError";
import { SetReferralCodeResponse } from "types/responses";

interface RequestParams {
  referralCode: string;
}

const useRequestSetReferralCode = () => {
  const queryClient = useQueryClient();

  return useMutation<SetReferralCodeResponse, ApiError, RequestParams>(
    ["verify-code"],
    async ({ referralCode }) => {
      if (referralCode) {
        return setReferralCode({ referralCode });
      }
      return Promise.reject(new Error("Referral code is incorrect"));
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: ["profile"] });
      },
      onError: showErrors,
    },
  );
};

export default useRequestSetReferralCode;
