import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AttributesObject, PriceType, TokenAddress } from "types/listing";
import ProjectPath from "constants/paths";
import { TownCategory } from "constants/townConstants";
import TownCornerIcon from "../TownCorenerIcon";
import TownCoordinates from "../TownCoordinates";
import Stat from "../Stat";

import s from "./ListingItemNew.module.scss";

interface ListingItemNewProps {
  id: number | string;
  attributes: AttributesObject;
  image: string;
  name: string;
  tokenAddress: TokenAddress;
  priceConverted?: string;
  price_type?: PriceType;
  isWalletItem?: boolean;
  quantity?: number;
  onClick?: (id: number | string) => void;
  setIsEditingPrice?: (value: boolean) => void;
}

function ListingItemNew({
  id,
  attributes,
  image,
  name,
  tokenAddress = "car",
  priceConverted = "",
  price_type = "matic",
  isWalletItem = false,
  quantity = undefined,
  onClick = undefined,
  setIsEditingPrice = undefined,
}: ListingItemNewProps) {
  const { t } = useTranslation();

  const onItemClick = () => {
    if (onClick) onClick(id);
  };

  const to = useMemo(() => {
    if (isWalletItem) return `/${ProjectPath.WALLET_NFT}/${tokenAddress}/${id}`;
    return `/${ProjectPath.MARKET_ITEM}/${tokenAddress}/${id}`;
  }, [id, isWalletItem, tokenAddress]);

  const handleStopEditing = useCallback(() => {
    if (setIsEditingPrice) setIsEditingPrice(false);
  }, [setIsEditingPrice]);

  const listingContent = (
    <div className={s.listingItem}>
      <div className={s.imgPart}>
        <img src={image} alt="item" className="fill-cover" />
        <div className={s.upStats}>
          {attributes?.Category && (
            <TownCornerIcon
              value={attributes.Category?.value as TownCategory}
              className={s.townCornerIcon}
            />
          )}
          {attributes?.Attack && (
            <Stat
              variant="attack"
              value={attributes.Attack?.value}
              className={s.statAttack}
            />
          )}
          {attributes?.Defense && (
            <Stat
              variant="defense"
              value={attributes.Defense?.value}
              className={s.statDefense}
            />
          )}
        </div>
        <div className={s.stats}>
          {attributes?.Tier && (
            <Stat variant="tier" value={attributes.Tier?.value} />
          )}
          {attributes?.Level && (
            <Stat variant="lvl" value={attributes.Level?.value} />
          )}
        </div>
        {attributes?.Lat && attributes?.Lon && (
          <TownCoordinates
            latValue={attributes.Lat.value as number}
            lonValue={attributes.Lon.value as number}
            className={s.coordinates}
          />
        )}
        {quantity && tokenAddress === "town" && (
          <div className={s.quantity}>x {quantity}</div>
        )}
      </div>
      <div className={s.typeWrapper}>
        {(attributes?.Type || attributes?.Name) && (
          <div className={s.type}>
            {attributes.Type?.value ||
              `${attributes.Name?.value
                .toString()
                .charAt(0)}${attributes.Name?.value
                .toString()
                .slice(1)
                .toLowerCase()}`}
          </div>
        )}
        <div className={s.hash}>{name}</div>
      </div>
    </div>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onItemClick} className={s.wrapper}>
        {listingContent}
      </button>
    );
  }
  return (
    <Link to={to} className={s.wrapper} onClick={handleStopEditing}>
      {listingContent}
    </Link>
  );
}

export default ListingItemNew;
