import { useEffect, useState, useCallback } from "react";

const useReCAPTCHAv3 = (action: string) => {
  const [token, setToken] = useState<string>("");

  const executeReCAPTCHA = useCallback(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY || "", { action })
        .then((captchaToken: string) => {
          setToken(captchaToken);
        });
    });
  }, [action]);

  useEffect(() => {
    executeReCAPTCHA();
  }, [executeReCAPTCHA]);

  return { recaptchaToken: token, executeReCAPTCHA };
};
export default useReCAPTCHAv3;
