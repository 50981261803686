import BackButton from "components/common/BackButton";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ListingItemNew from "components/Listing/ListingItemNew";
import ProjectPath from "constants/paths";
import getCoinsToChoose from "helpers/GetCoinsToChoose";
import useCreateListing from "hooks/requests/useCreateListing";
import useGetListingById from "hooks/requests/useGetListingById";
import GasContainer from "pages/TransactionPage/GasContainer";
import LabelContainer from "pages/TransactionPage/LabelContainer";
import PolygonContainer from "pages/TransactionPage/PolygonContainer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { JustCoinType } from "types/coins";
import { Collection, Listing, TokenAddress } from "types/listing";
import filterCurrencyInput from "utils/filterCurrencyInput";
import CoinSelectNoNFT from "../CoinSelectNoNFT";

import s from "./EditContent.module.scss";

function isListing(item: Listing | Collection): item is Listing {
  return (item as Listing).id !== undefined;
}

interface EditContentProps {
  setIsEditingPrice: (value: boolean) => void;
}

function EditContent({ setIsEditingPrice }: EditContentProps) {
  const { tokenId, tokenAddress } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [id] = useState<string>(params.get("itemId") || "");

  const { data: listingItem, isError } = useGetListingById(
    tokenAddress === "town"
      ? {
          collectionID: id || tokenId || "",
          typeNft: "town",
        }
      : {
          id: Number(id) || Number(tokenId),
        },
  );

  const coins: JustCoinType[] = useMemo(
    () => getCoinsToChoose(tokenAddress as TokenAddress),
    [tokenAddress],
  );

  useEffect(() => {
    if (isError) {
      navigate(`/${ProjectPath.MARKET}`);
    }
  }, [isError, navigate]);

  const [value, setValue] = useState(listingItem?.priceConverted || "");
  const [selectedCurrency, setSelectedCurrency] = useState<`${JustCoinType}`>(
    listingItem?.price_type ?? JustCoinType.trc,
  );

  const { mutate: reCreateListing, isLoading: isUpdating } = useCreateListing({
    tokenAddress: listingItem?.token_address!,
  });

  const isFormValid = useMemo(() => {
    if (!listingItem) return false;
    return true;
  }, [listingItem]);

  const onCoinClick = useCallback((coin: `${JustCoinType}`) => {
    setSelectedCurrency(coin);
  }, []);

  const onPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = filterCurrencyInput(e.target.value, 0);
    setValue(newValue);
  };

  const onConfirmClick = useCallback(() => {
    if (!listingItem) return;
    reCreateListing({
      price: value,
      price_type: selectedCurrency,
      token_address: tokenAddress!,
      tokenId: listingItem.tokenId,
      amount: tokenAddress === "town" ? 1 : undefined,
      listing_id: Number(id),
    });
  }, [listingItem, reCreateListing, tokenAddress, value, selectedCurrency, id]);

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <BackButton className={s.backButton} route={`/${ProjectPath.WALLET}`} />
        <div className={s.title}>
          <span>{t("common.pages.edit")}</span>
          <Icon
            variant="edit"
            wrapperClassName={s.iconWrapper}
            className={s.icon}
            withWrapper
          />
        </div>
      </div>
      <div className={s.chosenNFT}>
        {listingItem && isListing(listingItem) && (
          <ListingItemNew
            setIsEditingPrice={setIsEditingPrice}
            key={listingItem.id}
            id={listingItem.id}
            attributes={listingItem.metadata.attributesObj}
            image={listingItem.metadata.image}
            name={listingItem.metadata.name}
            tokenAddress={listingItem.token_address}
            onClick={() => {}}
          />
        )}
      </div>
      <div className={s.inputs}>
        <LabelContainer label={`${t("common.price")}:`}>
          <div className={s.sellWrapper}>
            <div className={s.inputWrapper}>
              <CoinSelectNoNFT
                coin={
                  JustCoinType[
                    selectedCurrency.toLowerCase() as unknown as keyof typeof JustCoinType
                  ]
                }
                handleClick={onCoinClick}
                coinsToChoose={coins}
              />
              <input
                placeholder={`${t("transaction.pricePlaceholder")} ${
                  JustCoinType[
                    selectedCurrency.toLowerCase() as unknown as keyof typeof JustCoinType
                  ]
                }`}
                className={s.input}
                onChange={onPriceChange}
                value={value}
              />
            </div>
          </div>
        </LabelContainer>
      </div>
      <div className={s.bottomPart}>
        <Button
          className={s.submit}
          disabled={!isFormValid}
          onClick={onConfirmClick}
          isLoading={isUpdating}
        >
          {t("common.pages.edit")}
        </Button>
        <div className={s.containers}>
          <PolygonContainer />
          <div className={s.separator} />
          <GasContainer />
        </div>
      </div>
    </div>
  );
}

export default EditContent;
