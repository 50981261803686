/* eslint-disable */
import { JustCoinType } from "types/coins";
import { TokenAddress } from "types/listing";

export const PROHIBITED_COIN_TYPE_LISTING_MAP: Record<
  TokenAddress,
  JustCoinType[]
> = {
  car: [JustCoinType.trc],
  pass: [],
  part: [JustCoinType.trc],
  town: [],
  pet: [],
};

export const TOWNS_TOTAL_SUPPLY = "3534";
