import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ProjectPath from "constants/paths";

import { ESwapCoins } from "types/coins";

import { closeWalletSelectTransferTypeModal } from "store/modalsSlice";

import { useAppDispatch, useAppSelector } from "hooks/store";

import Icon from "components/common/Icon";
import ModalContainer from "../ModalContainer";

import s from "./WalletSelectTransferTypeModal.module.scss";
import ModalContainerWithActions from "../ModalContainerWithActions";

function WalletSelectTransferTypeModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    walletSelectTransferTypeModal: { isOpen },
  } = useAppSelector((state) => state.modals);

  const close = useCallback(
    () => dispatch(closeWalletSelectTransferTypeModal()),
    [dispatch],
  );

  const onActionClick = (action: string) => () => {
    navigate(action);
    dispatch(closeWalletSelectTransferTypeModal());
  };

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.walletAction.selectAction")}
    >
      <div className={s.modal}>
        <button
          type="button"
          onClick={onActionClick(
            `/${ProjectPath.WALLET_TRANSFER}?entity=${ESwapCoins.trc}`,
          )}
          className={s.action}
        >
          <div className={s.title}>{t("modals.walletAction.transfer")}</div>
          <Icon
            variant="profile"
            wrapperClassName={s.iconWrapper}
            className={s.icon}
            withWrapper
          />
        </button>

        <button
          type="button"
          onClick={onActionClick(
            `/${ProjectPath.WALLET_SEND}?entity=${ESwapCoins.usdt}`,
          )}
          className={s.action}
        >
          <div className={s.title}>{t("modals.walletAction.send")}</div>
          <Icon
            variant="send"
            wrapperClassName={s.iconWrapper}
            className={s.icon}
            withWrapper
          />
        </button>
      </div>
    </ModalContainerWithActions>
  );
}

export default WalletSelectTransferTypeModal;
