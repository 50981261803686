import ApiError from "classes/ApiError";
import {
  Collection,
  Listing,
  ListingTabType,
  TokenAddress,
} from "types/listing";
import {
  BuyListingRequest,
  CancelListingRequest,
  CreateListingRequest,
  GetListingByIdRequest,
  GetListingRequest,
  RequestListingFilter,
  UpdateListingRequest,
} from "types/requests";
import {
  ApiErrorResponse,
  BuyListingResponse,
  CancelListingResponse,
  CreateListingResponse,
  GetListingResponse,
  ListingTotalAmountResponse,
  RestructuredListingResponse,
  UpdateListingResponse,
} from "types/responses";
import objectToQueryString from "utils/objectToQueryString";
import {
  restructureCollectionResponse,
  restructureListingResponse,
} from "utils/restructureListingResponse";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getListingTotalAmount = async (
  selector: ListingTabType,
  type: TokenAddress,
  filters?: RequestListingFilter,
): Promise<ListingTotalAmountResponse> => {
  const queryString = objectToQueryString(
    { ...filters, page: 0, page_size: 0, typeNft: type },
    true,
  );

  let url = "";
  if (selector === "all") {
    url = `${endpoint}/listing/get${queryString}`;
  } else {
    url = `${endpoint}/listing/user${queryString}`;
  }

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data: GetListingResponse = await response.json();
  return { total_listings: data?.total_listings };
};

export const getListing = async (
  selector: ListingTabType,
  params?: GetListingRequest,
  filters?: RequestListingFilter[],
): Promise<RestructuredListingResponse> => {
  const queryString = objectToQueryString({ ...params, ...filters }, true);

  let url = "";
  if (selector === "all") {
    url = `${endpoint}/listing/get${queryString}`;
  } else {
    url = `${endpoint}/listing/user${queryString}`;
  }

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data: GetListingResponse = await response.json();
  if ("listings" in data) {
    return restructureListingResponse(data);
  }
  return restructureCollectionResponse(data);
};

export const getFilters = async (
  selector: ListingTabType,
  params: GetListingRequest,
): Promise<GetListingResponse> => {
  const queryString = objectToQueryString(params);

  let url = "";
  if (selector === "all") {
    url = `${endpoint}/listing/get${queryString}`;
  } else {
    url = `${endpoint}/listing/user${queryString}`;
  }

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};

export const getListingById = async (
  params: GetListingByIdRequest,
): Promise<Listing | Collection> => {
  const queryString = objectToQueryString(params);

  const url = `${endpoint}/listing/get${queryString}`;

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data: GetListingResponse = await response.json();
  if ("collections" in data) {
    return restructureCollectionResponse(data, true).collections[0];
  }
  return restructureListingResponse(data).listings[0];
};

export const createListing = async (
  body: CreateListingRequest,
): Promise<CreateListingResponse> => {
  if (body.token_address === "town" && body.amount && body.amount > 4) {
    fetch(`${endpoint}/listing/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    });
    return {} as CreateListingResponse;
  }
  const response = await fetch(`${endpoint}/listing/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();
  return data?.data;
};

export const buyListing = async (
  body: BuyListingRequest,
): Promise<BuyListingResponse> => {
  const response = await fetch(`${endpoint}/listing/buy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();
  return data?.data;
};

export const updateListing = async (
  body: UpdateListingRequest,
): Promise<UpdateListingResponse> => {
  const response = await fetch(`${endpoint}/listing/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();
  return data?.data;
};

export const cancelListing = async (
  body: CancelListingRequest,
): Promise<CancelListingResponse> => {
  const response = await fetch(`${endpoint}/listing/cancel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();
  return data?.data;
};
