import React from "react";

import roadmap from "constants/invite2EarnRoadmap";

import Phase from "./Phase";

import s from "./Roadmap.module.scss";

function Roadmap() {
  return (
    <section className={s.roadmap}>
      <div className={s.topPart}>
        <h4 className={s.title}>Invite to Earn Roadmap</h4>
        <div className={s.keyWards}>
          <div className={s.keyWard}>Fast</div>
          <div className={s.keyWard}>Transparent</div>
          <div className={s.keyWard}>Safe</div>
        </div>
      </div>
      <div className={s.phases}>
        {roadmap.map((elem) => (
          <Phase
            completePercent={elem.completePercent}
            openingData={elem.openingData}
            phaseNumber={elem.phaseNumber}
            text={elem.text}
            key={elem.id}
          />
        ))}
      </div>
    </section>
  );
}

export default Roadmap;
