import ApiError from "classes/ApiError";
import { ApiErrorResponse } from "types/responses";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

async function burnPass(tokenId: number) {
  const response = await fetch(`${endpoint}/burn-pass-for-genesis`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      token_id: tokenId,
    }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();

  return data;
}

export default burnPass;
