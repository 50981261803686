import { useMutation, useQueryClient } from "@tanstack/react-query";

import ApiError from "classes/ApiError";
import { makeSubscription } from "api/user";
import { useAppDispatch } from "hooks/store";
import { showErrors } from "utils/showErrors";
import { openShopCompleteModal } from "store/modalsSlice";
import { GetProfileResponse, MakeSubscription } from "types/responses";

const useMakeSubscribe = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation<MakeSubscription, ApiError>(
    ["make-subscription"],
    async () => {
      return makeSubscription();
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<GetProfileResponse>(["profile"], (profile) => {
          if (!profile || !data) return profile;

          return {
            ...profile,
            have_subscription: true,
            expire_at: `${data.expire_at}`,
          };
        });
        dispatch(openShopCompleteModal());
      },
      onError: showErrors,
    },
  );
};

export default useMakeSubscribe;
