/* eslint-disable import/prefer-default-export */
export enum Languages {
  EN = "en",
  ES = "es",
  ID = "id",
  // KO = "ko",
  PL = "pl",
  PT = "pt",
  // ZH = "zh",
  TR = "tr",
  RU = "ru",
  UA = "ua",
}

export enum NewLanguages {
  EN = "English",
  ES = "Español",
  ID = "Indonesia",
  // KO = "한국어",
  PL = "Polski",
  PT = "Português",
  // ZH = "汉语",
  TR = "Türkçe",
  RU = "Русский",
  UA = "Українська",
}

export interface NewLanguage {
  language: string;
  abbreviation: string;
  icon: string;
}
