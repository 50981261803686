import React from "react";
import Loader from "react-loaders";
import { IconsVariant } from "types/icons";
import Icon from "../Icon";
import s from "./ButtonRoundIcon.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  containerClassName?: string;
  buttonClassName?: string;
  iconClassName?: string;
  childrenClassName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  width?: number;
  iconVariant: IconsVariant;
}

function ButtonRoundIcon({
  children = "",
  containerClassName = "",
  buttonClassName = "",
  iconClassName = "",
  childrenClassName = "",
  isLoading = false,
  disabled = false,
  iconVariant,
  width = undefined,
  ...props
}: Props) {
  return (
    <button
      type="button"
      disabled={disabled || isLoading}
      {...props}
      className={`${s.buttonContainer} ${containerClassName}`}
    >
      <div
        className={`${s.button} ${buttonClassName}`}
        style={width ? { width } : {}}
      >
        {isLoading ? (
          <Loader
            type="line-scale-pulse-out-rapid"
            active
            innerClassName={s.loader}
          />
        ) : (
          <Icon
            variant={iconVariant}
            className={`${s.icon} ${iconClassName}`}
          />
        )}
      </div>
      <div className={`${s.children} ${childrenClassName}`}>{children}</div>
    </button>
  );
}

export default ButtonRoundIcon;
