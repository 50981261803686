import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";

import { modalDrawer, modalFadeIn } from "utils/motion";
import Icon from "components/common/Icon";
import Button from "components/common/Button";

import s from "./ModalContainerWithActions.module.scss";

interface ModalContainerWithActionsProps {
  children: React.ReactNode;
  /** title should be localised string */
  title?: string | ReactNode;
  open: boolean;
  disabled?: boolean;
  withCancelButton?: boolean;
  actions?: ReactElement[];
  modalClassName?: string;
  close: () => void;
  headerVariant?: "common" | "warning";
}

function ModalContainerWithActions({
  children,
  open,
  title = "",
  withCancelButton = true,
  disabled = false,
  actions = undefined,
  modalClassName = undefined,
  close,
  headerVariant = "common",
}: ModalContainerWithActionsProps) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {open && (
        <div className={s.modalContainer}>
          <motion.button
            variants={modalFadeIn()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            type="button"
            onClick={close}
            className={s.background}
            disabled={disabled}
          />
          <motion.div
            variants={window.innerWidth > 520 ? modalFadeIn() : modalDrawer()}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={cn(s.modal, modalClassName)}
          >
            {title && (
              <header className={cn(s.header, s[`header_${headerVariant}`])}>
                <div className={s.title}>{title}</div>
                <button type="button" className={s.close} onClick={close}>
                  <Icon variant="close" color="white" withWrapper />
                </button>
              </header>
            )}
            <div className={s.content}>{children}</div>
            {!!actions?.length && (
              <footer className={s.footer}>
                {withCancelButton && (
                  <Button
                    onClick={close}
                    className={`${s.button} ${s.cancel}`}
                    variant="filledGray"
                  >
                    {t("common.cancel")}
                  </Button>
                )}
                {actions?.map((action, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    {React.cloneElement(action, { className: s.button })}
                  </React.Fragment>
                ))}
              </footer>
            )}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

export default ModalContainerWithActions;
