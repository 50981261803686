/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { motion } from "framer-motion";

import close from "./images/close.svg";
import boyWithCoin from "./images/boyWithCoin.png";
import goldCoinWithBeam from "./images/goldCoinWithBeam.png";
import bybit from "./images/bybit.svg";

import s from "./Image.module.scss";

const images = {
  goldCoinWithBeam,
  boyWithCoin,
  bybit,
  close,
};

type ImageType = keyof typeof images;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: ImageType;
  wrapperClassName?: string;
  className?: string;
  animation?: boolean;
  animationType?: string;
}

function Image({
  image,
  wrapperClassName = "",
  className = "",
  animation = false,
  animationType = "spinning",
  ...props
}: Props) {
  if (animation && animationType === "spinning") {
    return (
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{
          duration: 45,
          ease: "linear",
          repeat: Infinity,
        }}
        className={`${s.imageWrapper} ${wrapperClassName}`}
      >
        <img
          {...props}
          src={images[image]}
          alt={image}
          className={`${s.image} ${className}`}
        />
      </motion.div>
    );
  }

  return (
    <div className={`${s.imageWrapper} ${wrapperClassName}`}>
      <img
        {...props}
        src={images[image]}
        alt={image}
        className={`${s.image} ${className}`}
      />
    </div>
  );
}

export default Image;
