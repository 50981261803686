import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "components/common/Icon";

import s from "./AgreementCheckbox.module.scss";

interface IProps {
  isAgreementChecked: boolean;
  toggleAgreement: () => void;
}

function AgreementCheckbox({ isAgreementChecked, toggleAgreement }: IProps) {
  const { t } = useTranslation();

  return (
    <div className={s.agreement}>
      <button
        type="button"
        className={`${s.checkbox} ${isAgreementChecked ? s.checked : ""}`}
        onClick={toggleAgreement}
      >
        <Icon variant="check" className={s.check} />
      </button>
      <div
        className={s.text}
        dangerouslySetInnerHTML={{
          __html: t("common.termsAgreement"),
        }}
      />
    </div>
  );
}

export default AgreementCheckbox;
