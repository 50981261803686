import wheelImage from "assets/images/shop/wheel.webp";
import bitcoinImage from "assets/images/shop/bitcoin.webp";
import ethereumImage from "assets/images/shop/ethereum.webp";
import maticImage from "assets/images/shop/matic.webp";
import aceImage from "assets/images/shop/ace-box.webp";
import trcImage from "assets/images/shop/trc-box.webp";
// import carImage from "assets/images/shop/car.webp";
import petImage from "assets/images/shop/pet.webp";

const benefits = [
  {
    id: 0,
    title: "<b>Premium</b> Fortune Wheel",
    image: wheelImage,
    codename: "wheel",
  },
  {
    id: 1,
    title: "Bitcoin",
    image: bitcoinImage,
    codename: "bitcoin",
  },
  {
    id: 2,
    title: "Ethereum",
    image: ethereumImage,
    codename: "ethereum",
  },
  {
    id: 3,
    title: "Matic",
    image: maticImage,
    codename: "matic",
  },
  {
    id: 4,
    title: "ACE",
    image: aceImage,
    codename: "ace",
  },
  {
    id: 5,
    title: "TRC",
    image: trcImage,
    codename: "trc",
  },
  // {
  //   id: 6,
  //   title: "NFT Cars",
  //   image: carImage,
  //   codename: "car",
  // },
  {
    id: 6,
    title: "<b>Epic</b> NFT Cypets",
    image: petImage,
    codename: "pet",
  },
];

export default benefits;
