import Button from "components/common/Button";
import React from "react";
import Chart from "./parts/Chart";

import s from "./TokenomicsChartSection.module.scss";

function TokenomicsChartSection() {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <span className={s.title}>{`TRC TOKENOMIC'S PROGRESSION`}</span>
        <Button
          link="https://wiki.metatrace.me/project-economics/trc-tokenomic"
          className={s.button}
        >
          Learn More
        </Button>
      </div>
      <div className={s.chartContainer}>
        <Chart />
      </div>
    </div>
  );
}

export default TokenomicsChartSection;
