import React, { useMemo } from "react";
import cn from "classnames";

import Image from "components/common/Image";

import s from "./Avatar.module.scss";

type IProps = {
  gender?: "0" | "1";
  subscribed?: boolean;
  isGenesis?: boolean;
  wrapperClassName?: string;
};

function Avatar({
  gender = "0",
  subscribed = false,
  wrapperClassName = "",
  isGenesis = false,
}: IProps) {
  const avatarTitle = useMemo(() => {
    if (isGenesis) {
      if (gender === "0") return "boyGenesis";
      if (gender === "1") return "girlGenesis";
    }
    if (gender === "1") return "girl";
    return "boy";
  }, [gender, isGenesis]);

  return (
    <div
      className={cn(
        s.avatarWrapper,
        wrapperClassName,
        subscribed && s.subscribed,
      )}
    >
      <Image image={avatarTitle} wrapperClassName={s.avatarImageWrapper} />
      {subscribed && <Image image="pro" wrapperClassName={s.proImageWrapper} />}
    </div>
  );
}

export default Avatar;
