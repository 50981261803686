import Image from "components/common/Image";
import React from "react";
import { NavLink } from "react-router-dom";
import matic from "assets/images/dashboard/matic.svg";
import useGetDashboard from "hooks/requests/useGetDashboard";

import s from "./EconomicModel.module.scss";

function EconomicModel() {
  const { data } = useGetDashboard();

  return (
    <div className={s.wrapper}>
      <div>
        <div className={s.title}>Stable Economic Model</div>
        <div className={s.description}>
          The integration of TRC into the game is based on a complex and
          resilient economic model that balances inflation and gaming pressures,
          ensuring healthy development of the whole ecosystem.
        </div>
        <div className={s.tradeIdUsers}>
          <div>
            <div className={s.percentageInfo}>
              <div className={s.percentageTitle}>ACTIVE WALLETS</div>
              <div className={s.percentage}>
                {data?.percentageTraceUsersCount ?? "~"}
              </div>
            </div>

            <div className={s.count}>
              {data?.traceUsersCount
                ? data?.traceUsersCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                : "unknown"}
            </div>
          </div>

          <NavLink
            className={s.viewInExplorer}
            to="https://polygonscan.com/token/0x480F3BE03da2Dc81681cd19A3b8DB7cE1805459D"
            target="_blank"
          >
            <div className={s.iconWrapper}>
              <img src={matic} alt="matic" />
            </div>
            <div className={s.viewTitle}>View in explorer</div>
          </NavLink>
        </div>
      </div>
      <Image image="coins" className={s.banner} />
    </div>
  );
}

export default EconomicModel;
