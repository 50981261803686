import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import Button from "components/common/Button";
import { closeWalletSuccessfulPurchaseModal } from "store/modalsSlice";
import { useTranslation } from "react-i18next";
import ModalContainerWithActions from "../ModalContainerWithActions";
import s from "./WalletSuccessfulPurchaseModal.module.scss";

interface WalletSuccessfulPurchaseModalProps {
  children?: React.ReactNode;
}

function WalletSuccessfulPurchaseModal({
  children = undefined,
}: WalletSuccessfulPurchaseModalProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    walletSuccessfulPurchaseModal: { isOpen },
  } = useAppSelector((state) => state.modals);
  const close = useCallback(() => {
    dispatch(closeWalletSuccessfulPurchaseModal());
  }, [dispatch]);

  const onButtonClick = () => {
    dispatch(closeWalletSuccessfulPurchaseModal());
  };

  const actions = [
    <Button type="submit" onClick={onButtonClick}>
      {t("modals.walletSuccessfulPurchase.button")}
    </Button>,
  ];

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.walletSuccessfulPurchase.title")}
      actions={actions}
      withCancelButton={false}
    >
      <div className={s.modal}>
        <div className={s.information}>
          {children && (
            <div
              className={s.childrenContainer}
              id="walletSuccessfulPurchaseModal"
            >
              {children}
            </div>
          )}
          <div className={s.text}>
            {t("modals.walletSuccessfulPurchase.text")}
          </div>
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default WalletSuccessfulPurchaseModal;
