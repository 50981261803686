import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "types/menus";

const initialState: InitialState = {
  navigationMenu: {
    isOpen: false,
  },
  profileMenu: {
    isOpen: false,
  },
  filterMenu: {
    isOpen: false,
  },
};

export const menusSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    openNavigationMenu(state) {
      state.navigationMenu.isOpen = true;
    },
    closeNavigationMenu(state) {
      state.navigationMenu.isOpen = false;
    },
    openProfileMenu(state) {
      state.profileMenu.isOpen = true;
    },
    closeProfileMenu(state) {
      state.profileMenu.isOpen = false;
    },
    openFilterMenu(state) {
      state.filterMenu.isOpen = true;
    },
    closeFilterMenu(state) {
      state.filterMenu.isOpen = false;
    },
  },
});

export const {
  openNavigationMenu,
  closeNavigationMenu,
  openProfileMenu,
  closeProfileMenu,
  openFilterMenu,
  closeFilterMenu,
} = menusSlice.actions;

export const menusReducer = menusSlice.reducer;
