import React, { useMemo } from "react";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

import useGetReferralOpenedLevels from "hooks/requests/blockchain/useGetReferralOpenedLevels";
import useGetReferralList from "hooks/requests/referral/useGetReferralList";
import useGetRefreshReferralRequests from "hooks/requests/blockchain/useGetRefreshReferralRequests";
import useGetReferralIncome from "hooks/requests/blockchain/useGetReferralIncome";
import ProjectPath from "constants/paths";

import TopPart from "./TopPart";
import ControlPart from "./ControlPart";
import LevelCard from "./LevelCard";

import s from "./ReferralPage.module.scss";
import Roadmap from "./Roadmap";

function ReferralPage() {
  const { isFetching: isLoadingIncome } = useGetReferralIncome();
  const { data: referralOpenedLevels, isFetching: isLoadingLevel } =
    useGetReferralOpenedLevels();
  const { data: referralList } = useGetReferralList();
  const refreshReferralRequests = useGetRefreshReferralRequests();

  const isRefreshing = isLoadingIncome || isLoadingLevel;

  const referralArray = useMemo(() => {
    if (!referralList || !referralOpenedLevels) return [];
    return [
      {
        id: 0,
        level: 1,
        amount: referralList.referrals[0],
        acquired: !!referralOpenedLevels[0],
      },
      {
        id: 1,
        level: 2,
        amount: referralList.referrals[1],
        acquired: !!referralOpenedLevels[1],
      },
      {
        id: 2,
        level: 3,
        amount: referralList.referrals[2],
        acquired: !!referralOpenedLevels[2],
      },
    ];
  }, [referralList, referralOpenedLevels]);

  return (
    <div className={s.referralPage}>
      <div className={s.container}>
        <TopPart />
        <ControlPart />
        <section className={s.levels}>
          {referralArray.map((elem) => (
            <LevelCard
              key={elem.level}
              amount={elem.amount}
              level={elem.level as 1 | 2 | 3}
              acquired={elem.acquired}
            />
          ))}
        </section>
        <div className={s.mobileControls}>
          <Button
            className={s.buyTownsButton}
            variant="filledSkyBlue"
            link={`/${ProjectPath.TOWNS}`}
          >
            <Icon variant="Mega_Town" className={s.townIcon} />
            <span className={s.townButtonText}>Buy NFT Towns</span>
          </Button>
          <Button
            className={s.refreshButton}
            onClick={refreshReferralRequests}
            isLoading={isRefreshing}
          >
            <Icon variant="restart" className={s.restartIcon} />
          </Button>
        </div>
        <Roadmap />
      </div>
    </div>
  );
}

export default ReferralPage;
