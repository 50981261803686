import React, { useMemo, useState } from "react";
import Listing from "components/Listing";
import Button from "components/common/Button";
import nonft from "assets/images/noNFT.webp";
import { NavLink } from "react-router-dom";
import { SimpleListing, TokenAddress } from "types/listing";
import ListingList from "../ListingList";

import s from "./NFTs.module.scss";

interface NFTsProps {
  nftList: Record<TokenAddress, SimpleListing[]>;
}

function NFTs({ nftList }: NFTsProps) {
  const [state, setState] = useState<TokenAddress | "all">("all");

  const allCount = useMemo(() => {
    return Object.values(nftList).reduce((acc, list) => acc + list.length, 0);
  }, [nftList]);

  const allEmpty = Object.values(nftList).every((arr) => arr.length === 0);

  if (allEmpty) {
    return (
      <div className={s.nonft}>
        <div className={s.nonftIconWrapper}>
          <img src={nonft} alt="" className={s.nonftIcon} />
        </div>
        <p className={s.nonftText}>No NFTs found in your wallet</p>
        <Button className={s.nonftButton}>
          Go to market
          <NavLink className={s.link} to="/market" />
        </Button>
      </div>
    );
  }

  return (
    <div className={s.nfts}>
      <div className={s.nftTags}>
        {allCount > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${s.all} ${
              state === "all" ? s.active : undefined
            }`}
            onClick={() => setState("all")}
          >
            All <span className={s.count}>{allCount}</span>
          </button>
        )}
        {nftList.car.length > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${state === "car" ? s.active : undefined}`}
            onClick={() => setState("car")}
          >
            Cars <span className={s.count}>{nftList.car.length}</span>
          </button>
        )}
        {nftList.pass.length > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${state === "pass" ? s.active : undefined}`}
            onClick={() => setState("pass")}
          >
            Passes <span className={s.count}>{nftList.pass.length}</span>
          </button>
        )}
        {nftList.part.length > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${state === "part" ? s.active : undefined}`}
            onClick={() => setState("part")}
          >
            Parts <span className={s.count}>{nftList.part.length}</span>
          </button>
        )}
        {nftList.town.length > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${state === "town" ? s.active : undefined}`}
            onClick={() => setState("town")}
          >
            Towns <span className={s.count}>{nftList.town.length}</span>
          </button>
        )}
        {nftList.pet.length > 0 && (
          <button
            type="button"
            className={`${s.nftTag} ${state === "pet" ? s.active : undefined}`}
            onClick={() => setState("pet")}
          >
            Pets <span className={s.count}>{nftList.pet.length}</span>
          </button>
        )}
      </div>
      <Listing className={s.listing}>
        <ListingList nftList={nftList} type={state} />
      </Listing>
    </div>
  );
}

export default NFTs;
