import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import getLanguage from "utils/getLanguage";
import enTranslation from "./en.json";
import esTranslation from "./es.json";
import idTranslation from "./id.json";
// import koTranslation from "./ko.json";
// import zhTranslation from "./zh.json";
import uaTranslation from "./uа.json";
import ruTranslation from "./ru.json";
import plTranslation from "./pl.json";
import ptTranslation from "./pt.json";
import trTranslation from "./tr.json";

export const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  id: {
    translation: idTranslation,
  },
  // ko: {
  //   translation: koTranslation,
  // },
  // zh: {
  //   translation: zhTranslation,
  // },
  ru: {
    translation: ruTranslation,
  },
  ua: {
    translation: uaTranslation,
  },
  pl: {
    translation: plTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  tr: {
    translation: trTranslation,
  },
} as const;

const lng = getLanguage() in resources ? getLanguage() : "en";

i18n.use(initReactI18next).init({
  lng,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  resources,
});

export default i18n;
