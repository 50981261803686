import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import AuthorizationWatcher from "helpers/AuthorizationWatcher";
import WindowSizeSetter from "helpers/WindowSizeSetter";
import CaptchaWatcher from "helpers/CaptchaWatcher";
import useGetAuthCookie from "hooks/useGetAuthCookie";

import SmallItemCard from "components/common/SmallItemCard";
import {
  ActionModal,
  AuthModal,
  BalanceModal,
  ConfirmationModal,
} from "components/modals";
import AuthConfirmationModal from "components/modals/AuthModal/AuthConfirmationModal";
import BuyTraceProModal from "components/modals/BuyTraceProModal";
import LootBoxWinningModal from "components/modals/LootBoxWinningModal";
import WalletSuccessfulPurchaseModal from "components/modals/WalletSuccessfulPurchaseModal";
import WelcomeBuyModal from "components/modals/WelcomeBuyModal";
import WelcomeWideModal from "components/modals/WelcomeWideModal";
import CommonModal from "components/modals/CommonModal";
import WalletWarningListingModal from "components/modals/WalletWarningListingModal";

import s from "./AppLayout.module.scss";

function AppLayout() {
  const cookie = useGetAuthCookie();
  // const { collectionType } = useParams<{ collectionType: TokenAddress }>();
  // const { pathname } = useLocation();
  // const filterSliceType: FiltersSliceType = `${collectionType ?? "car"}Filters${
  //   pathname?.endsWith("my") ? ListingTabTypeMap.my : ListingTabTypeMap.all
  // }`;
  // const { appliedFilter } = useAppSelector(
  //   (state) => state.filters[filterSliceType],
  // );
  // const pageRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (appliedFilter) {
  //     pageRef.current?.scrollTo({ top: 0 });
  //   }
  // }, [appliedFilter]);

  return (
    <div className={s.appLayout}>
      {/* <div
        className={`${s.content} ${cookie ? s.authenticated : ""}`}
        ref={pageRef}
      > */}
      <Outlet />
      {/* </div> */}
      {/* Helpers */}
      <ScrollRestoration />
      <WindowSizeSetter />
      <AuthorizationWatcher />
      <CaptchaWatcher />
      {/* Menus */}
      {/* Modals */}
      {/* <WelcomeWideModal /> */}
      <WelcomeBuyModal />
      <AuthModal />
      <AuthConfirmationModal />
      <ActionModal />
      <BalanceModal />
      <WalletSuccessfulPurchaseModal>
        <SmallItemCard />
      </WalletSuccessfulPurchaseModal>
      <ConfirmationModal>
        <SmallItemCard />
      </ConfirmationModal>
      <LootBoxWinningModal />
      <CommonModal />
      {/* <BuyTraceProModal /> */}
      {/* Menus */}
    </div>
  );
}

export default AppLayout;
