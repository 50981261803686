/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import Icon from "components/common/Icon";
import NavigationBackground from "./NavigationBackground";
import SimpleNavigationElement from "./SimpleNavigationElement";
import { INavigationMore } from "../types";

import s from "../NavigationElement.module.scss";

function MoreNavigationElement({ title, icon, subItems }: INavigationMore) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const variants = {
    open: { opacity: 1, height },
    closed: { opacity: 1, height: 0 },
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={`${s.navigationElementWrapper} ${isOpen ? s.isOpen : ""}`}>
      <div
        className={`${s.navigationElement}  ${isOpen ? s.isOpenNotActive : ""}`}
      >
        <Icon variant={icon} className={s.icon} />
        <div className={s.title}>{title}</div>
        <NavigationBackground />

        <button type="button" className={s.link} onClick={toggle}>
          toggle
        </button>
      </div>

      <motion.div
        className={s.contentWrapper}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div className={s.content} ref={contentRef}>
          {subItems.map((elem) => (
            <SimpleNavigationElement {...elem} key={elem.id} />
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default MoreNavigationElement;
