import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { getBalanceItem } from "api/wallet";
import { showErrors } from "utils/showErrors";
import deleteCookie from "utils/deleteCookie";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { useAppDispatch } from "hooks/store";

import ApiError from "classes/ApiError";
import { RestructuredBalanceItemResponse } from "types/responses";
import { dropAuthToken } from "store/appStateSlice";
import { dropWalletAddress } from "store/userProfileSlice";
import { GetBalanceItemRequest } from "types/requests";

const useGetBalanceItem = ({
  token_id,
  type,
}: Partial<GetBalanceItemRequest>) => {
  const cookie = useGetAuthCookie();
  const dispatch = useAppDispatch();

  const enabled = Boolean(cookie) && Boolean(token_id) && Boolean(type);

  const queryInfo = useQuery<RestructuredBalanceItemResponse | null, Error>(
    ["balance-item", token_id, type],
    async () => {
      if (token_id && type) return getBalanceItem({ token_id, type });
      return Promise.reject(new Error("token_id and type are required"));
    },
    { enabled, cacheTime: 0 },
  );

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      if (queryInfo.error.status === 403) {
        if (cookie) {
          deleteCookie();
        }
        dispatch(dropAuthToken());
        dispatch(dropWalletAddress());
      }
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error, cookie, dispatch]);

  return queryInfo;
};

export default useGetBalanceItem;
