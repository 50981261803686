import { useQuery } from "@tanstack/react-query";

import { getReferralCode } from "api/user";
import { GetReferralCodeResponse } from "types/responses";

const useGetReferralCode = () => {
  return useQuery<GetReferralCodeResponse, Error>(
    ["referral", "code"],
    async () => {
      return getReferralCode();
    },
  );
};

export default useGetReferralCode;
