import React from "react";
import { Link } from "react-router-dom";

import ProjectPath from "constants/paths";

import Icon from "components/common/Icon";
import NavigationLink from "../NavigationLink";

import s from "./BalanceAmount.module.scss";

function BalanceAmount() {
  return (
    <div className={s.balance}>
      <Icon variant="wallet" className={s.wallet} />
      {/* <p className={s.count}>
        45 493.22 <span className={s.trc}>TRC</span>
      </p> */}
      <div className={s.plus}>
        <Icon variant="plus" className={s.icon} color="white" />
      </div>
      <NavigationLink
        link={`/${ProjectPath.WALLET}`}
        title="to wallet"
        mustBeAuthorized
        isResourceLocal
      />
    </div>
  );
}

export default BalanceAmount;
