import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../Icon";

import s from "./BackButton.module.scss";

type BackButtonProps = {
  className?: string;
  iconClassName?: string;
  route?: string;
  onNavigate?: () => void;
  children?: React.ReactNode;
};
function BackButton({
  className = "",
  iconClassName = "",
  route = undefined,
  onNavigate = undefined,
  children = undefined,
}: BackButtonProps) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (onNavigate) {
      onNavigate();
    }

    if (route) {
      navigate(route);
    } else {
      navigate(-1);
    }
  };

  return (
    <button type="button" onClick={handleNavigate} className={className}>
      <Icon
        variant="arrowDown"
        wrapperClassName={s.iconWrapper}
        className={`${s.icon} ${iconClassName}`}
        withWrapper
      />
      {children}
    </button>
  );
}

export default BackButton;
