import cn from "classnames";
import AppReleaseBanner from "components/AppReleaseBanner";
import Attributes from "components/Attributes";
import Characteristic from "components/Characteristic";
import CharacteristicSkeleton from "components/Characteristic/CharacteristicSkeleton";
import BackButton from "components/common/BackButton";
import Collapse from "components/common/Collapse";
import { Price } from "components/common/Price";
import ProjectPath from "constants/paths";
import useGetListingById from "hooks/requests/useGetListingById";
import useGetProfile from "hooks/requests/useGetProfile";
import useGetEmptyArray from "hooks/useGetEmptyArray";
import React, { useEffect, useMemo, useState } from "react";
import { TFuncKey, useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ListingStatus from "../ListingStatus";
import ListingTable from "../ListingTable";
import ListingControls from "./parts/ListingControls";
import ListingHeader from "./parts/ListingHeader";

import s from "./ListingContent.module.scss";

interface ListingContentProps {
  setIsEditingPrice: (value: boolean) => void;
}

function ListingContent({ setIsEditingPrice }: ListingContentProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tokenId, tokenAddress } = useParams();

  const [params] = useSearchParams();
  const [id] = useState<string>(params.get("itemId") || "");

  const location = useLocation();
  const isInWallet = location.pathname.includes("wallet");

  const { data: profile, isLoading: isProfileLoading } = useGetProfile();
  const {
    data: listingItem,
    isLoading: isItemLoading,
    isError,
  } = useGetListingById(
    tokenAddress === "town"
      ? {
          collectionID: id || tokenId || "",
          typeNft: "town",
        }
      : {
          id: Number(id) || Number(tokenId),
        },
  );
  const [amountNFT, setAmountNFT] = useState<number | undefined>();

  useEffect(() => {
    const hasAmountNFT =
      listingItem && "amount_nft" in listingItem && !isItemLoading;
    if (hasAmountNFT) {
      setAmountNFT(listingItem.amount_nft);
    }
  }, [isItemLoading]);

  useEffect(() => {
    if (isError) {
      navigate(`/${ProjectPath.MARKET}`);
    }
  }, [isError, navigate]);

  const isLoading = isProfileLoading || isItemLoading;
  const metadata = listingItem?.metadata;
  const attributes = listingItem?.metadata?.attributes;
  const attributesObj = listingItem?.metadata?.attributesObj;
  const isMyToken =
    listingItem &&
    "user_id" in listingItem &&
    profile?.user_id === listingItem?.user_id;
  const loadingArr = useGetEmptyArray({ desktopLength: 8, mobileLength: 6 });

  const listingHeaderTitle = attributesObj?.Type?.value
    ? attributesObj?.Type?.value.toString()
    : "";
  const listingHeaderCypetTitle = attributesObj?.Name?.value.toString() ?? "";

  const NFTTitle = useMemo(() => {
    if (listingItem?.token_address === "pet") return listingHeaderCypetTitle;
    if (listingItem?.token_address === "town")
      return `${listingItem.metadata.attributesObj.Name?.value}` || "Town";
    if (listingItem?.token_address === "part")
      return `${listingItem.metadata.attributesObj.Name?.value}` || "Part";
    return listingHeaderTitle;
  }, [listingHeaderTitle, listingHeaderCypetTitle, listingItem]);

  const NFTName = useMemo(() => {
    if (listingItem?.token_address === "town") {
      return "";
    }
    return metadata?.name || "";
  }, [listingItem, metadata]);

  const shouldShowPrice = listingItem?.token_address !== "town";
  const shouldShowControls =
    listingItem?.token_address !== "town" && listingItem;
  const shouldShowCharacteristics = !!attributes?.length && !isItemLoading;
  const shouldShowAttributes =
    !!attributes?.length &&
    listingItem?.token_address !== "pet" &&
    listingItem?.token_address !== "town" &&
    listingItem?.token_address !== "part";
  const shouldShowTable =
    listingItem?.token_address === "town" &&
    "listings" in listingItem &&
    listingItem.listings;

  const shouldShowBalance =
    listingItem && "amount_nft" in listingItem && listingItem.amount_nft;

  return (
    <div className={s.container}>
      <div className={cn(s.itemPart, { [s.myItemPart]: isMyToken })}>
        <AppReleaseBanner wrapperClassName={s.sideBanner} />
        <BackButton
          className={s.backButton}
          iconClassName={s.icon}
          route={
            location.pathname.includes("wallet")
              ? `/${ProjectPath.WALLET_NFT}`
              : `/${ProjectPath.MARKET}/${tokenAddress}`
          }
        >
          {t("common.back")}
        </BackButton>
        {isMyToken && isInWallet && (
          <ListingStatus status="onMarket" wrapperClassName={s.market} />
        )}
        <div className={s.image}>
          {metadata && (
            <img src={metadata?.image} alt="item" className="fill-cover" />
          )}
          {shouldShowBalance && <div className={s.amount}>x {amountNFT}</div>}
        </div>
        {listingItem?.token_address && (
          <ListingHeader
            title={NFTTitle}
            name={NFTName}
            tokenAddress={listingItem.token_address}
            isInWallet={isInWallet}
            isMyToken={!!isMyToken}
          />
        )}

        <div className={cn(s.information, { [s.myInformation]: isMyToken })}>
          {shouldShowPrice && listingItem?.price_type && (
            <Price
              priceType={listingItem?.price_type}
              priceConverted={listingItem?.priceConverted || ""}
            />
          )}
        </div>

        {shouldShowControls && (
          <ListingControls
            isLoading={isLoading}
            isMyToken={isMyToken || false}
            id={("id" in listingItem && listingItem.id) || 0}
            setIsEditingPrice={setIsEditingPrice}
            price_type={listingItem.price_type}
            priceConverted={listingItem.priceConverted}
            tokenAddress={listingItem.token_address}
            className={cn(s.controls, { [s.myControls]: isMyToken })}
            tokenId={listingItem.tokenId ?? ""}
          />
        )}
      </div>
      <div className={s.characteristics}>
        {shouldShowCharacteristics &&
          attributes.map((attribute: any) =>
            "max_value" in attribute ? (
              <Characteristic
                value={attribute.value}
                max={attribute.max_value}
                variant={attribute.trait_type}
                key={attribute.trait_type}
              />
            ) : (
              <Characteristic
                value={attribute.value}
                variant={attribute.trait_type}
                key={attribute.trait_type}
              />
            ),
          )}
        {isItemLoading &&
          loadingArr.map((elem) => <CharacteristicSkeleton key={elem} />)}
      </div>
      {shouldShowAttributes && (
        <Collapse tKey={"item.attributes.title" as TFuncKey} title="Attributes">
          <Attributes attributes={attributes} />
        </Collapse>
      )}
      {shouldShowTable && (
        <ListingTable
          setAmountNFT={setAmountNFT}
          amountNFT={amountNFT || 0}
          listings={listingItem.listings || []}
          collectionId={listingItem.tokenId}
        />
      )}
      <AppReleaseBanner wrapperClassName={s.mobileSideBanner} />
    </div>
  );
}

export default ListingContent;
