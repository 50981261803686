import React from "react";
import { useTranslation } from "react-i18next";
import { motion, Variants } from "framer-motion";

import Icon from "components/common/Icon";

import { AttributeRange } from "types/listing";

import s from "./Attribute.module.scss";

interface AttributeProps {
  attribute: AttributeRange;
}

function Attribute({ attribute }: AttributeProps) {
  const { t } = useTranslation();

  const percentage = (attribute.value / attribute.max_value) * 100;

  const variants: Variants = {
    hidden: { width: "0%", transition: { delay: 0.1 } },
    show: {
      width: `${percentage}%`,
      transition: { delay: 0.1 },
    },
  };

  switch (attribute.trait_type) {
    case "Power":
      return (
        <div className={s.attribute}>
          <Icon variant="battery" className={s.icon} />
          <div className={s.content}>
            <div className={s.topBar}>
              <div className={s.title}>{t("item.power")}</div>
              <div className={s.value}>{attribute.value}</div>
            </div>
            <div className={s.progressBar}>
              <motion.div
                whileInView="show"
                viewport={{ once: false, amount: 1 }}
                variants={variants}
                initial="hidden"
                className={s.base}
              />
            </div>
          </div>
        </div>
      );
    case "Strength":
      return (
        <div className={s.attribute}>
          <Icon variant="repair" className={s.icon} />
          <div className={s.content}>
            <div className={s.topBar}>
              <div className={s.title}>{t("item.strength")}</div>
              <div className={s.value}>{attribute.value}</div>
            </div>
            <div className={s.progressBar}>
              <motion.div
                whileInView="show"
                viewport={{ once: false, amount: 1 }}
                variants={variants}
                initial="hidden"
                className={s.base}
              />
            </div>
          </div>
        </div>
      );
    case "Weight":
      return (
        <div className={s.attribute}>
          <Icon variant="weight" className={s.icon} />
          <div className={s.content}>
            <div className={s.topBar}>
              <div className={s.title}>{t("item.weight")}</div>
              <div className={s.value}>{attribute.value}</div>
            </div>
            <div className={s.progressBar}>
              <motion.div
                whileInView="show"
                viewport={{ once: false, amount: 1 }}
                variants={variants}
                initial="hidden"
                className={s.base}
              />
            </div>
          </div>
        </div>
      );
    case "Aerodynamics":
      return (
        <div className={s.attribute}>
          <Icon variant="speed" className={s.icon} />
          <div className={s.content}>
            <div className={s.topBar}>
              <div className={s.title}>{t("item.aerodynamics")}</div>
              <div className={s.value}>{attribute.value}</div>
            </div>
            <div className={s.progressBar}>
              <motion.div
                whileInView="show"
                viewport={{ once: false, amount: 1 }}
                variants={variants}
                initial="hidden"
                className={s.base}
              />
            </div>
          </div>
        </div>
      );
    case "Luck":
      return (
        <div className={s.attribute}>
          <Icon variant="luck" className={s.icon} />
          <div className={s.content}>
            <div className={s.topBar}>
              <div className={s.title}>{t("item.luck")}</div>
              <div className={s.value}>{attribute.value}</div>
            </div>
            <div className={s.progressBar}>
              <motion.div
                whileInView="show"
                viewport={{ once: false, amount: 1 }}
                variants={variants}
                initial="hidden"
                className={s.base}
              />
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
}

export default Attribute;
