import React, { useRef, useState } from "react";

import TextField from "components/common/TextField";
import { Formik, FormikProps } from "formik";
import useSubmitAuthForm from "hooks/useSubmitAuthForm";
import { useTranslation } from "react-i18next";
import AgreementCheckbox from "../AgreementCheckbox";
import ModalButton from "../ModalButton";
import NewAuthSwitcher from "../NewAuthSwitcher";
import createFormDataSchema from "../utils";

import s from "./RegisterContent.module.scss";

interface ValuesType {
  email: string;
}

const initialValues = { email: "" };

function RegisterContent() {
  const { t } = useTranslation();
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const formikRef = useRef<FormikProps<ValuesType>>(null);
  const { onSubmit } = useSubmitAuthForm();
  const formDataSchema = createFormDataSchema();

  const toggleAgreement = () => {
    setIsAgreementChecked((prev) => !prev);
  };

  return (
    <div className={s.modal}>
      <NewAuthSwitcher className={s.switcher} />
      <Formik
        initialValues={initialValues}
        validationSchema={formDataSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
        }: FormikProps<ValuesType>) => {
          return (
            <form className={s.form} onSubmit={handleSubmit}>
              <div className={s.formContainer}>
                <div className={s.enterEmail}>{t("modals.register.title")}</div>
                <TextField
                  placeholder="satoshi@gmail.com"
                  hasError={touched.email && Boolean(errors.email)}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <AgreementCheckbox
                  isAgreementChecked={isAgreementChecked}
                  toggleAgreement={toggleAgreement}
                />
              </div>
              <div className={s.buttonContainer}>
                <ModalButton
                  className={s.submit}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={
                    !dirty || Boolean(errors.email) || !isAgreementChecked
                  }
                >
                  {t("common.next")}
                </ModalButton>
                <div className={s.info}>{t("modals.register.disclaimer")}</div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RegisterContent;
