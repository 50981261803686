import ApiError from "classes/ApiError";
import { ApiErrorResponse } from "types/responses";
import { DashboardResponse } from "types/dashboard";

const endpoint = process.env.REACT_APP_DASHBOARD_ENDPOINT || "";

// eslint-disable-next-line import/prefer-default-export
export const getDashboardData = async (): Promise<DashboardResponse> => {
  const response = await fetch(`${endpoint}/dashboard`, {
    method: "GET",
  });
  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  return response.json();
};
