export default [
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_logic",
        type: "address",
      },
      { indexed: false, internalType: "bytes", name: "_data", type: "bytes" },
    ],
    payable: false,
    stateMutability: "payable",
    type: "constructor",
  },
  {
    constant: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    payable: false,
    type: "event",
  },
  {
    constant: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    payable: false,
    type: "event",
  },
  {
    constant: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    payable: false,
    type: "event",
  },
  {
    constant: false,
    payable: false,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    constant: false,
    payable: false,
    stateMutability: "payable",
    type: "receive",
  },
];
