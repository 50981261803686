/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import logo from "assets/images/logo.svg";

import { useAppDispatch } from "hooks/store";
import { closeNavigationMenu } from "store/menusSlice";

import SimpleNavigationElement from "./NavigationElement/parts/SimpleNavigationElement";
import NavigationElement from "./NavigationElement";
import {
  navigationItems,
  navigationSocials,
  navigationMore,
  navigationSupport,
} from "./navigationItems";

import MoreNavigationElement from "./NavigationElement/parts/MoreNavigationElement";

import s from "./Sidebar.module.scss";

function Sidebar() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const close = useCallback(() => {
    dispatch(closeNavigationMenu());
  }, [dispatch]);

  useEffect(() => {
    close();
  }, [pathname, close]);

  return (
    <div className={s.sidebar}>
      <div className={s.container}>
        <div className={s.logo}>
          <img src={logo} alt="logo" className="fill-contain" />
          <a
            href="https://metatrace.me/"
            className="fill-link"
            rel="noreferrer"
            target="__blank"
          >
            logo
          </a>
        </div>

        <div className={s.navigation}>
          {navigationItems.map((elem) => (
            <NavigationElement {...elem} key={elem.id} />
          ))}
          <MoreNavigationElement {...navigationMore} />
        </div>
        <div className={s.separator} />
        <div className={s.navigation}>
          <SimpleNavigationElement {...navigationSupport} />
        </div>
        <div className={s.separator} />
        <div className={s.navigation}>
          {navigationSocials.map((elem) => (
            <SimpleNavigationElement {...elem} key={elem.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
