import React from "react";
import cn from "classnames";
import { Tooltip } from "react-tooltip";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

import useGetReferralIncome from "hooks/requests/blockchain/useGetReferralIncome";
import useGetRefreshReferralRequests from "hooks/requests/blockchain/useGetRefreshReferralRequests";
import useGetReferralCode from "hooks/requests/referral/useGetReferralCode";
import useCopy from "hooks/useCopy";
import ProjectPath from "constants/paths";

import s from "./ControlPart.module.scss";

function ControlPart() {
  const { data: referralIncome, isFetching: isLoadingIncome } =
    useGetReferralIncome();
  const refreshReferralRequests = useGetRefreshReferralRequests();

  const { data: referralCode } = useGetReferralCode();

  const copyCode = useCopy({
    text: referralCode?.code || "",
    notificationText: "Code has been copied",
  });

  const isRefreshing = isLoadingIncome;

  return (
    <div className={s.controlPart}>
      <div className={cn(s.label, s.labelCode)}>
        <span className={s.text}>Your Referral Code</span>
        <Icon
          variant="information"
          className={s.infoIcon}
          id="code-information-icon"
        />
        <Tooltip
          anchorSelect="#code-information-icon"
          place={window.innerWidth <= 520 ? "top" : "top-start"}
          id="my-tooltip-code-information-icon"
          className={cn("tooltip", "arrow", s.tooltip)}
          noArrow
        >
          Use this unique code to invite your
          <br /> referrals.
        </Tooltip>
      </div>
      <div className={cn(s.label, s.labelIncome)}>
        <span className={s.text}>Your Total Income</span>
        <Icon
          variant="information"
          className={cn(s.infoIcon)}
          id="income-information-icon"
        />
        <Tooltip
          anchorSelect="#income-information-icon"
          place={window.innerWidth <= 520 ? "top" : "top-start"}
          id="my-tooltip-income-information-icon"
          className={cn("tooltip", "arrow", s.tooltip)}
          noArrow
        >
          The total income received from purchases
          <br /> made by your referrals. To receive income, <br />
          you need to have the corresponding
          <br /> referral level.
        </Tooltip>
      </div>
      <div className={cn(s.panel, s.background, s.panelCode)}>
        <span className={s.value}>{referralCode?.code || ""}</span>
        <Icon variant="copy_rect" className={s.copyIcon} />
        <button type="button" className="fill-link" onClick={copyCode}>
          copy
        </button>
      </div>
      <div className={cn(s.panel, s.background, s.panelIncome)}>
        <span className={s.value}>{referralIncome} MATIC</span>
        <Button
          className={s.refreshButton}
          onClick={refreshReferralRequests}
          isLoading={isRefreshing}
        >
          <span className={s.refreshButtonText}>Refresh</span>
          <Icon variant="restart" className={s.restartIcon} />
        </Button>
      </div>
      <div className={cn(s.panel, s.panelBuyTown)}>
        <Button
          className={s.buyTownsButton}
          variant="filledSkyBlue"
          link={`/${ProjectPath.TOWNS}`}
        >
          <Icon variant="Mega_Town" className={s.townIcon} />
          <span className={s.townButtonText}>Buy NFT Towns</span>
        </Button>
      </div>
    </div>
  );
}

export default ControlPart;
