import React, { useEffect, useState } from "react";
import cn from "classnames";
import AppStore from "assets/images/appReleaseBanner/AppStore.webp";
import GooglePlay from "assets/images/appReleaseBanner/GooglePlay.webp";
import { useTranslation } from "react-i18next";
import useImage from "hooks/useImage";
import { useAppSelector } from "hooks/store";

import s from "./AppReleaseBanner.module.scss";

const imageNameMap: Record<string, string> = {
  main: "boy_main",
  laptop: "boy_laptop",
  mobile: "boy_mobile",
};

interface IProps {
  wrapperClassName?: string;
}

function AppReleaseBanner({ wrapperClassName = "" }: IProps) {
  const { isTgWebApp } = useAppSelector((state) => state.appState);
  const { t } = useTranslation();
  const [imageName, setImageName] = useState(imageNameMap.main);
  const { image } = useImage(`images/appReleaseBanner/${imageName}.webp`);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        setImageName(imageNameMap.main);
      }
      if (window.innerWidth > 520 && window.innerWidth <= 1280) {
        setImageName(imageNameMap.laptop);
      }
      if (window.innerWidth <= 520) {
        setImageName(imageNameMap.mobile);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isTgWebApp) {
    return null;
  }

  return (
    <div className={cn(s.wrapper, wrapperClassName)}>
      <div className={cn(s.appReleaseBanner)}>
        <div className={s.description}>
          <div className={s.title}>
            {t("commonBanners.appReleaseBanner.title")}
          </div>
          <div className={s.subTitle}>
            {t("commonBanners.appReleaseBanner.description")}
          </div>
        </div>
        <div className={s.buttons}>
          <a
            className={s.button}
            href="https://metatrace.onelink.me/h2K0/7s7xqg25"
          >
            <img src={AppStore} alt="AppStore" />
          </a>
          <a
            className={s.button}
            href="https://metatrace.onelink.me/h2K0/7s7xqg25"
          >
            <img src={GooglePlay} alt="GooglePlay" />
          </a>
        </div>
        <div className={s.sign}>{t("commonBanners.appReleaseBanner.sign")}</div>
        {image && (
          <div className={s.imageWrapper}>
            <img className={s.image} src={image} alt="boy" />
          </div>
        )}
      </div>
    </div>
  );
}

export default AppReleaseBanner;
