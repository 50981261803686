import useGetWindowWidth from "hooks/useGetWindowWidth";
import React from "react";

export interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: number;
  };
}

function createLabel(value: number) {
  if (value === 1) return "1 st year";
  if (value === 2) return "2 nd year";
  if (value === 3) return "3 rd year";
  if (value >= 4) return `${value} th year`;
  return "";
}

function CustomTickXAxis({
  x = undefined,
  y = undefined,
  payload = undefined,
}: CustomTickProps) {
  const label = createLabel(payload?.value ?? 0);
  const windowWidth = useGetWindowWidth();

  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx={0} cy={-8} r={2.5} fill="#6B7A99" />
      <text
        x={0}
        y={10}
        dy={5}
        fontSize={windowWidth <= 1280 ? "1.45rem" : "1rem"}
        textAnchor={payload?.value === 5 ? "end" : "middle"}
        fill="#605D62"
      >
        {label}
      </text>
    </g>
  );
}

export default CustomTickXAxis;
