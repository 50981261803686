import React, { useState, useMemo } from "react";
import cn from "classnames";

import Icon from "components/common/Icon";

import useGetDashboard from "hooks/requests/useGetDashboard";
import formatNumber, { formatNumberShort } from "utils/formatNumber";
import { TokenType } from "pages/TokensPage/TokensPage";

import trcPriceImage from "assets/images/dashboard/trcPrice.png";
import acePriceImage from "assets/images/dashboard/acePrice.png";

import CircleProgressBar from "../CircleProgressBar";

import s from "./TokensStats.module.scss";

interface Props {
  tokenType: TokenType;
}

function TokensStats({ tokenType }: Props) {
  const { data, isLoading } = useGetDashboard();
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const price = useMemo(() => {
    if (tokenType === "trc") return data?.trcData.price || 0;
    if (tokenType === "ace") return data?.aceData.price || 0;
    return 0;
  }, [tokenType, data]);

  const priceChange = data?.trcData.priceChange || "0";

  const marketCap = useMemo(() => {
    if (tokenType === "trc") return data?.trcData.marketCap || 0;
    if (tokenType === "ace") return data?.aceData.marketCap || 0;
    return 0;
  }, [tokenType, data]);

  const marketCapChange = data?.trcData.marketCapChange || "0";

  const volume = useMemo(() => {
    if (tokenType === "trc") return data?.trcData.volume || 0;
    if (tokenType === "ace") return data?.aceData.volume || 0;
    return 0;
  }, [tokenType, data]);

  const volumeChange = useMemo(() => {
    if (tokenType === "trc") return data?.coinmarketcapData.volumeChange || "0";
    if (tokenType === "ace") return data?.aceData.marketCapChange || "0";
    return "0";
  }, [tokenType, data]);

  const circulatingSupply = useMemo(() => {
    if (tokenType === "trc") return data?.trcData.circulating_supply || 0;
    if (tokenType === "ace") return data?.aceData.circulating_supply || 0;
    return 0;
  }, [tokenType, data]);
  const percentage = data?.trcData.percentage || 0;

  return (
    <div className={s.tokensStats}>
      <div className={s.stat}>
        <div className={s.topLine}>
          <div className={s.coinImage}>
            <img
              src={tokenType === "trc" ? trcPriceImage : acePriceImage}
              alt="trc"
              className="fill-cover"
            />
          </div>
          <div className={s.title}>
            {tokenType === "trc" ? "TRC" : "ACE"} PRICE
          </div>
          {tokenType === "trc" && (
            <div
              className={cn(
                s.change,
                priceChange[0] === "-" ? s.lower : s.higher,
              )}
            >
              {priceChange}
            </div>
          )}
        </div>
        <div className={cn(s.valuePart, isLoading && s.hidden)}>
          $ <b>{price}</b>
        </div>
      </div>

      <div className={s.separator} />

      <div className={s.stat}>
        <div className={s.topLine}>
          <div className={s.title}>MARKET CAP</div>
          {tokenType === "trc" && (
            <div
              className={cn(
                s.change,
                marketCapChange[0] === "-" ? s.lower : s.higher,
              )}
            >
              {marketCapChange}
            </div>
          )}
        </div>
        <div className={cn(s.valuePart, isLoading && s.hidden)}>
          $ <b>{formatNumber(marketCap)}</b>
        </div>
      </div>

      <div className={s.separator} />

      <div className={s.stat}>
        <div className={s.topLine}>
          <div className={s.title}>24H VOLUME</div>
          <div
            className={cn(
              s.change,
              volumeChange[0] === "-" ? s.lower : s.higher,
            )}
          >
            {volumeChange}
          </div>
        </div>
        <div className={cn(s.valuePart, isLoading && s.hidden)}>
          $ <b>{formatNumber(volume)}</b>
        </div>
      </div>

      <div className={s.separator} />

      {!isOpen && (
        <button className={s.button} type="button" onClick={open}>
          <div className={s.topLine}>
            <span className={s.buttonText}>View all</span>
            <Icon variant="arrowDown" className={s.buttonIcon} />
          </div>
          <div className={s.more}>
            <span className={s.dot} />
            <span className={s.dot} />
            <span className={s.dot} />
          </div>
        </button>
      )}

      {isOpen && (
        <>
          {tokenType === "trc" && (
            <>
              <div className={s.stat}>
                <div className={s.topLine}>
                  <div className={cn(s.title, s.burned)}>TRC Burned 🔥</div>
                </div>
                <div className={cn(s.valuePart, isLoading && s.hidden)}>
                  <b>1 550 000</b>
                </div>
              </div>

              <div className={s.separator} />

              <div className={cn(s.stat, s.horizontal)}>
                <div>
                  <div className={s.topLine}>
                    <div className={s.title}>TRC UNLOCKED :</div>
                  </div>
                  <div className={cn(s.valuePart, isLoading && s.hidden)}>
                    <b>{formatNumberShort(circulatingSupply)}</b>
                  </div>
                </div>
                <CircleProgressBar percentage={percentage} />
              </div>
            </>
          )}
          {tokenType === "ace" && (
            <div className={s.stat}>
              <div className={s.topLine}>
                <div className={s.title}>TOTAL SUPPLY :</div>
              </div>
              <div className={cn(s.valuePart, isLoading && s.hidden)}>
                <b>{formatNumberShort(circulatingSupply)}</b>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TokensStats;
