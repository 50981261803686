import { useEffect } from "react";

const useTrackTelegramTab = (
  onTabClosed: () => void,
  tgAuthTab: React.MutableRefObject<Window | null> | null,
) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tgAuthTab?.current?.closed) {
        clearInterval(intervalId);
        if (onTabClosed) {
          onTabClosed();
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [onTabClosed, tgAuthTab]);
};

export default useTrackTelegramTab;
