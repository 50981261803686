import React from "react";
import cn from "classnames";

import Button from "components/common/Button";

import { useAppDispatch } from "hooks/store";
import { openTokensModal } from "store/modalsSlice";

import coinImage from "assets/images/tokensPage/trcBigCoin.webp";

import s from "./BuyTRCBannerSection.module.scss";

function BuyTRCBannerSection() {
  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(openTokensModal());
  };

  return (
    <section className={s.banner}>
      <div className={s.coin}>
        <img
          src={coinImage}
          alt="trc coin"
          className={cn(s.coinImage, "fill-cover")}
        />
        <div className={s.coinShadow} />
      </div>
      <div className={s.content}>
        <div className={s.title}>Buy $TRC in Easy Steps!</div>
        <div className={s.description}>
          Join the Future of Gaming! Buy TRC Tokens Now and Be Part of the
          Revolution!
        </div>
        <Button onClick={openModal} className={s.button}>
          <span className={s.buttonContent}>Buy TRC Token</span>
        </Button>
      </div>
    </section>
  );
}

export default BuyTRCBannerSection;
