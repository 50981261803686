import { NFTCollectionCurrencies } from "constants/NFTCollectionCurrencies";
import { JustCoinType } from "types/coins";
import { TokenAddress } from "types/listing";

function getCoinsToChoose(nftType: TokenAddress): JustCoinType[] {
  if (nftType in NFTCollectionCurrencies) {
    return NFTCollectionCurrencies[nftType];
  }
  const exhaustiveCheck = nftType;
  throw new Error(`Unhandled nftType: ${exhaustiveCheck}`);
}

export default getCoinsToChoose;
