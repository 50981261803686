/* eslint-disable import/prefer-default-export */
import i18n from "i18n/config";
import { SortVariant } from "types/filters";

export const getSortTitle = (sortVariant: SortVariant) => {
  switch (sortVariant) {
    case "PriceAsc":
      return i18n.t("sortVariants.minPrice");
    case "PriceDsc":
      return i18n.t("sortVariants.maxPrice");
    case "CreateDsc":
      return i18n.t("sortVariants.newest");

    default:
      return "";
  }
};
