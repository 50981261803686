import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, UpdateMarketItemPayload } from "types/marketItem";

const initialState: InitialState = {
  title: "",
  hash: "",
  priceType: "trc",
  priceConverted: "",
  image: "",
  quantity: undefined,
};

export const marketItemSlice = createSlice({
  name: "marketItem",
  initialState,
  reducers: {
    updateMarketItem(state, action: PayloadAction<UpdateMarketItemPayload>) {
      if (action.payload.title) {
        state.title = action.payload.title;
      }
      if (action.payload.hash) {
        state.hash = action.payload.hash;
      }
      if (action.payload.priceType) {
        state.priceType = action.payload.priceType;
      }
      if (action.payload.priceConverted) {
        state.priceConverted = action.payload.priceConverted;
      }
      if (action.payload.image) {
        state.image = action.payload.image;
      }
      if (action.payload.quantity) {
        state.quantity = action.payload.quantity;
      }
    },
  },
});

export const { updateMarketItem } = marketItemSlice.actions;

export const marketItemReducer = marketItemSlice.reducer;
