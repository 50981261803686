import React, { useMemo } from "react";

import { CoinType } from "types/coins";
import Coin from "components/Coin";

import {
  tgInputOnBlur,
  tgInputOnFocus,
  tgInputOnKeyDown,
} from "helpers/TgWebAppInputHandlers";
import s from "./TextField.module.scss";
import { useAppSelector } from "../../../hooks/store";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  coinStart?: `${CoinType}`;
  classNames?: {
    input?: string;
    icon?: string;
    wrapper?: string;
  };
  label?: string;
  hasError?: boolean;
  maxValueLength?: number | undefined;
  withStartLetter?: boolean;
}

function TextField({
  label = "",
  coinStart = undefined,
  classNames = { input: "", icon: "" },
  hasError = false,
  maxValueLength = undefined,
  withStartLetter = false,
  ...props
}: TextFieldProps) {
  const wrapperClassName = useMemo(
    () => `${s.inputWrapper} ${classNames.wrapper} ${hasError ? s.error : ""}`,
    [classNames.wrapper, hasError],
  );
  const inputClassName = useMemo(
    () => `${s.input} ${classNames.input}`,
    [classNames.input],
  );
  const iconClassName = useMemo(
    () => `${s.icon} ${classNames.icon}`,
    [classNames.icon],
  );
  const { isTgWebApp } = useAppSelector((state) => state.appState);

  const onFocus = (event: React.FocusEvent<HTMLElement>) => {
    tgInputOnFocus(event, !!isTgWebApp);
    if (isTgWebApp) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 600);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    tgInputOnBlur(event, !!isTgWebApp);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    tgInputOnKeyDown(event, !!isTgWebApp);
  };

  if (label) {
    return (
      <label className={s.labelWrapper}>
        <span className={s.label}>{label}</span>
        <div className={wrapperClassName}>
          {coinStart && (
            <div className={s.leftPart}>
              <Coin className={iconClassName} currency={coinStart} />
            </div>
          )}
          <input
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            className={inputClassName}
            {...props}
          />
          {maxValueLength && (
            <div className={s.rightPart}>
              {typeof props.value === "string" && (
                <span className={s.length}>
                  {withStartLetter && props.value
                    ? props.value.length - 1
                    : props.value.length}
                </span>
              )}
              <span className={s.maxLength}>
                /{withStartLetter ? maxValueLength - 1 : maxValueLength}
              </span>
            </div>
          )}
        </div>
      </label>
    );
  }
  return (
    <div className={wrapperClassName}>
      {coinStart && (
        <div className={s.leftPart}>
          <Coin className={iconClassName} currency={coinStart} />
        </div>
      )}
      <input
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClassName}
        {...props}
      />
      {maxValueLength && (
        <div className={s.rightPart}>
          {typeof props.value === "string" && (
            <span className={s.length}>{props.value.length}</span>
          )}
          <span className={s.maxLength}>{maxValueLength}</span>
        </div>
      )}
    </div>
  );
}

export default TextField;
