import alchemy from "assets/images/dashboard/partners/alchemy.svg";
import polygon from "assets/images/dashboard/partners/polygon.svg";
import bitget from "assets/images/dashboard/partners/bitget.svg";
import coingecko from "assets/images/dashboard/partners/coingecko.svg";
import cmc from "assets/images/dashboard/partners/coinmarketcap.svg";
import mexc from "assets/images/dashboard/partners/mexc.svg";
import unity from "assets/images/dashboard/partners/unity.svg";
import bitmart from "assets/images/dashboard/partners/bitmart.svg";
import sbt from "assets/images/dashboard/partners/sbt.svg";
import exmo from "assets/images/dashboard/partners/exmo.svg";
import paybis from "assets/images/dashboard/partners/paybis.svg";
import amgcup from "assets/images/dashboard/partners/amgcup.svg";
import copajoy from "assets/images/dashboard/partners/copajoy.svg";
import ggc from "assets/images/dashboard/partners/ggc.svg";
import icoholder from "assets/images/dashboard/partners/icoholder.svg";
import lbank from "assets/images/dashboard/partners/lbank.svg";
import metacade from "assets/images/dashboard/partners/metacade.svg";
import onetreeplanted from "assets/images/dashboard/partners/onetreeplanted.svg";
import brazilmetaverso from "assets/images/dashboard/partners/brazilmetaverso.svg";
import racecup from "assets/images/dashboard/partners/racecup.svg";
import tela2 from "assets/images/dashboard/partners/tela2.svg";
import twitter from "assets/images/dashboard/partners/twitter.svg";
import moonpay from "assets/images/dashboard/partners/moonpay.svg";

const partners = [
  {
    id: "polygon-p",
    image: polygon,
  },
  {
    id: "bitget-p",
    image: bitget,
  },
  {
    id: "coin-gecko-p",
    image: coingecko,
  },
  {
    id: "coin-market-cup-p",
    image: cmc,
  },
  {
    id: "moonpay-p",
    image: moonpay,
  },
  {
    id: "mexc-p",
    image: mexc,
  },
  {
    id: "unity-p",
    image: unity,
  },
  {
    id: "bitmart-p",
    image: bitmart,
  },
  {
    id: "twitter-p",
    image: twitter,
  },
  {
    id: "sbt-p",
    image: sbt,
  },
  {
    id: "alchemy-p",
    image: alchemy,
  },
  {
    id: "exmo-p",
    image: exmo,
  },
  {
    id: "paybis-p",
    image: paybis,
  },
  {
    id: "amg-cup-p",
    image: amgcup,
  },
  {
    id: "copa-joy-p",
    image: copajoy,
  },
  {
    id: "ggc-p",
    image: ggc,
  },
  {
    id: "icoholder-p",
    image: icoholder,
  },
  {
    id: "lbank-p",
    image: lbank,
  },
  {
    id: "metacade-p",
    image: metacade,
  },
  {
    id: "one-tree-planted-p",
    image: onetreeplanted,
  },
  {
    id: "brazil-metaverso-p",
    image: brazilmetaverso,
  },
  {
    id: "race-cup-p",
    image: racecup,
  },
  {
    id: "tela2-p",
    image: tela2,
  },
  {
    id: "empty-p",
    image: "",
  },
];

export default partners;
