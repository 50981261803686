import React from "react";
import cn from "classnames";

import s from "./TransactionCounter.module.scss";

interface TransactionCounterProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  max: number;
  min?: number;
  disabled?: boolean;
  hasError?: boolean;
  wrapperClassName?: string;
  buttonClassName?: string;
  unTouchable?: boolean;
}

function TransactionCounter({
  quantity,
  setQuantity,
  min = 0,
  max = 1,
  disabled = false,
  hasError = false,
  wrapperClassName = "",
  buttonClassName = "",
  unTouchable = false,
  ...props
}: TransactionCounterProps) {
  const mainClassName = cn(
    s.counterWrapper,
    disabled ? s.disabled : "",
    wrapperClassName,
    hasError ? s.error : "",
    unTouchable ? s.unTouchable : "",
  );

  const increment = () => {
    if (quantity >= max) return;
    setQuantity((prev) => prev + 1);
  };
  const decrement = () => {
    if (quantity <= min) return;
    setQuantity((prev) => prev - 1);
  };
  const selectAllText = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.target.select();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (max < 10 && value > 10) {
      const stringValue = `${value}`;
      const decade = Number(stringValue[0]) * 10;
      if (value - decade > max || Number(stringValue[1]) === 0) {
        setQuantity(max);
        return;
      }
      setQuantity(value - decade);
      return;
    }

    if (value > max) {
      setQuantity(max);
      return;
    }
    if (value < min) {
      setQuantity(min);
      return;
    }
    setQuantity(value);
  };

  return (
    <div className={mainClassName}>
      <button
        className={cn(s.button, buttonClassName)}
        type="button"
        onClick={decrement}
      >
        -
      </button>
      <input
        {...props}
        type="text"
        className={cn(s.input, props.className || "")}
        value={quantity}
        onChange={onChange}
        onFocus={selectAllText}
      />
      <button
        className={cn(s.button, buttonClassName)}
        type="button"
        onClick={increment}
      >
        +
      </button>
    </div>
  );
}

export default TransactionCounter;
