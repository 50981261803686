import React from "react";

import LoginButton from "layouts/NavigationLayout/components/LoginButton";
import RegistrationButton from "layouts/NavigationLayout/components/RegistrationButton";

import s from "./AuthorizationPart.module.scss";

function AuthorizationPart() {
  return (
    <div className={s.authorizationPart}>
      <LoginButton />
      <RegistrationButton />
    </div>
  );
}

export default AuthorizationPart;
