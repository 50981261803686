import Web3 from "web3";

const WEB3_PROVIDER = process.env.REACT_APP_WEB3_PROVIDER || "";
const WEB3_POLYGON_PROVIDER = process.env.REACT_APP_WEB3_POLYGON_PROVIDER || "";

const web3 = new Web3(new Web3.providers.HttpProvider(WEB3_PROVIDER));
const web3Polygon = new Web3(
  new Web3.providers.HttpProvider(WEB3_POLYGON_PROVIDER),
);

export { web3, web3Polygon };
