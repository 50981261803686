export const tgInputOnFocus = (
  event: React.FocusEvent<HTMLElement>,
  isTgWebApp: boolean,
) => {
  if (isTgWebApp) {
    setTimeout(() => {
      event.target.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 500);
  }
};

export const tgInputOnBlur = (
  event: React.FocusEvent<HTMLElement>,
  isTgWebApp: boolean,
) => {
  if (isTgWebApp) {
    setTimeout(() => {
      document.documentElement.style.setProperty(
        "--height",
        `${window.innerHeight}px`,
      );
    }, 250);
  }
};

export const tgInputOnKeyDown = (
  event: React.KeyboardEvent<HTMLElement>,
  isTgWebApp: boolean,
) => {
  if (isTgWebApp && event.key === "Enter") {
    event.currentTarget.blur();
  }
};
