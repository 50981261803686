import React, { useCallback, useMemo } from "react";

import Listing from "components/Listing";
import { SimpleListing, TokenAddress } from "types/listing";
import ListingItemForNftSelector from "components/Listing/ListingItemForNftSelector";
import useGetBalance from "hooks/requests/useGetBalance";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeBalanceModal, setResultTaskRequest } from "store/modalsSlice";
import useGetNftListFromBalance from "hooks/useGetNftListFromBalance";

import s from "./NFTSelectorContent.module.scss";

interface NFTItemWithTokenAddress extends SimpleListing {
  tokenAddress: TokenAddress;
}

function NFTSelectorContent() {
  const dispatch = useAppDispatch();
  const { data: balance } = useGetBalance();
  const nftList = useGetNftListFromBalance(balance);

  const {
    balanceModal: { withNFT },
  } = useAppSelector((state) => state.modals);

  const onChooseNFT = useCallback(
    (id: number, tokenAddress: TokenAddress) => {
      dispatch(closeBalanceModal());
      dispatch(
        setResultTaskRequest({
          result: "resolved",
          data: { coin: "nft", tokenAddress, chosen: id },
        }),
      );
    },
    [dispatch],
  );

  const NFTs = useMemo<NFTItemWithTokenAddress[][]>(() => {
    if (!withNFT) return [];
    return Object.keys(nftList)
      .filter((key) => key in withNFT && withNFT[key as TokenAddress])
      .map((key) => {
        return nftList[key as TokenAddress].map((nft) => ({
          ...nft,
          tokenAddress: key as TokenAddress,
        }));
      });
  }, [nftList, withNFT]);

  return (
    <div className={s.modal}>
      <div className={s.NFTs}>
        <Listing className={s.listing}>
          {NFTs.map((collection) => {
            return collection?.map((nft) => (
              <ListingItemForNftSelector
                key={nft.token_id}
                id={nft.token_id}
                attributes={nft.metadata.attributesObj}
                image={nft.metadata.image}
                name={nft.metadata.name}
                onClick={onChooseNFT}
                tokenAddress={nft.tokenAddress}
                quantity={nft.metadata.balance}
              />
            ));
          })}
        </Listing>
      </div>
    </div>
  );
}

export default NFTSelectorContent;
