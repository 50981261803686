import React from "react";

import {
  NewLanguageSelector,
  ProfileInformation,
} from "layouts/NavigationLayout";
import BalanceAmount from "layouts/NavigationLayout/components/BalanceAmount";

import useGetAuthCookie from "hooks/useGetAuthCookie";

import NavigationChain from "./NavigationChain";

import RegistrationButton from "../components/RegistrationButton";
import LoginButton from "../components/LoginButton";

import s from "./Header.module.scss";

function Header() {
  const cookie = useGetAuthCookie();

  return (
    <div className={s.header}>
      <div className={s.headerContainer}>
        {cookie ? (
          <>
            <NavigationChain />
            <div className={s.settingsInformation}>
              {window.innerWidth > 600 && <NewLanguageSelector />}
              {window.innerWidth > 600 && <ProfileInformation />}
            </div>
          </>
        ) : (
          <>
            {window.innerWidth > 650 && <NavigationChain />}
            <div className={s.rightPartSettings}>
              <NewLanguageSelector />
              <LoginButton />
              <RegistrationButton />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
