import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { JustCoinType } from "types/coins";

import ProjectPath from "constants/paths";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeWalletActionModal, openMoonPayModal } from "store/modalsSlice";
import Icon from "components/common/Icon";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./WalletActionModal.module.scss";

function WalletActionModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    walletActionModal: { entity, isOpen },
  } = useAppSelector((state) => state.modals);

  const close = useCallback(
    () => dispatch(closeWalletActionModal()),
    [dispatch],
  );

  const onActionClick = (action: string) => () => {
    navigate(action);
    dispatch(closeWalletActionModal());
  };

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("modals.walletAction.selectAction")}
    >
      <div className={s.modal}>
        {entity === "matic" && (
          <button
            type="button"
            onClick={() => dispatch(openMoonPayModal())}
            className={s.action}
          >
            <div className={s.title}>{t("modals.walletAction.moonPay")}</div>
            <Icon variant="moonPay" className={s.moonPayIcon} />
          </button>
        )}
        {(entity === "ace" || entity === "trc") && (
          <button
            type="button"
            onClick={onActionClick(
              `/${ProjectPath.WALLET_TRANSFER}?entity=${entity}`,
            )}
            className={s.action}
          >
            <div className={s.title}>{t("modals.walletAction.transfer")}</div>
            <Icon
              variant="profile"
              wrapperClassName={s.iconWrapper}
              className={s.icon}
              withWrapper
            />
          </button>
        )}
        <button
          type="button"
          onClick={onActionClick(
            `/${ProjectPath.WALLET_SEND}?entity=${entity}`,
          )}
          className={s.action}
        >
          <div className={s.title}>{t("modals.walletAction.send")}</div>
          <Icon
            variant="send"
            wrapperClassName={s.iconWrapper}
            className={s.icon}
            withWrapper
          />
        </button>
        {[
          JustCoinType.matic,
          JustCoinType.trc,
          JustCoinType.usdt,
          JustCoinType.ace,
          JustCoinType.wbtc,
          JustCoinType.weth,
        ].includes(JustCoinType[entity]) && (
          <button
            type="button"
            onClick={onActionClick(`/${ProjectPath.WALLET_SWAP}/${entity}`)}
            className={s.action}
          >
            <div className={s.title}>{t("modals.walletAction.swap")}</div>
            <Icon
              variant="exchange"
              wrapperClassName={s.iconWrapper}
              className={s.icon}
              withWrapper
            />
          </button>
        )}
      </div>
    </ModalContainerWithActions>
  );
}

export default WalletActionModal;
