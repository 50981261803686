/* eslint-disable */
import Icon from "components/common/Icon";
import { SnackbarContent, useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { IconsVariant } from "types/icons";

import s from "./CustomSnackBar.module.scss";

// Custom Snackbar component
const CustomSnackbar = React.forwardRef(
  (
    props: { id: number; message: string; variant: "success" | "error" },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { message, id, variant } = props;
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent className={s.wrapper} ref={ref}>
        <Icon variant={variant as IconsVariant} className={s[variant]} />
        <div className={s.message}>{message}</div>
        <button onClick={handleDismiss} className="close-button" type="button">
          <Icon variant="close" className={s.close} />
        </button>
      </SnackbarContent>
    );
  },
);

export default CustomSnackbar;
