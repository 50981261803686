import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import s from "./Selector.module.scss";
import Icon from "../Icon";

type OptionType = {
  icon?: React.ReactNode;
  value: string;
  id: string;
};

interface IProps {
  selectedOption: OptionType;
  className?: string;
  options: OptionType[];
  saveSelected: (id: string) => void;
}

function Selector({
  selectedOption,
  className = "",
  options,
  saveSelected,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  const variants = {
    closed: {
      height: 0,
      transition: {
        duration: 0.1,
      },
    },
    opened: {
      height: "auto",
      transition: {
        duration: 0.3,
      },
    },
  };

  const toggleOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const selectOption = (id: string) => {
    const option = options.find((opt: OptionType) => id === opt.id);
    if (option) {
      saveSelected(option.id);
    }
  };

  return (
    <button type="button" onClick={toggleOpening}>
      <div className={`${s.selector} ${className}`}>
        <div className={s.icon}>{selectedOption.icon}</div>
        <div className={s.title}>{selectedOption.value}</div>
        <div>
          {!isOpen && <Icon variant="arrowDown" className={s.arrowDown} />}
          {isOpen && <Icon variant="arrowDown" className={s.arrowUp} />}
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={variants}
              initial="closed"
              animate="opened"
              exit="closed"
              style={{ overflow: "hidden" }}
            >
              <div className={s.optionsContainer}>
                {options.map((option) => (
                  <button
                    type="button"
                    className={`${s.optionBlock} ${
                      selectedOption.id === option.id ? s.selected : ""
                    }`}
                    onClick={() => selectOption(option.id)}
                  >
                    {option.icon && <div className={s.icon}>{option.icon}</div>}
                    <input
                      key={option.id}
                      value={option.value}
                      type="button"
                      className={s.option}
                    />
                  </button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </button>
  );
}

export default Selector;
