/* eslint-disable import/prefer-default-export */

import ProjectPath from "constants/paths";
import { IconsVariant } from "types/icons";
import { NftMap, TokenAddress } from "types/listing";

export const getPathTitle = (partOfPathname: string) => {
  //   switch (key) {
  //     case value:
  //       break;
  //     default:
  //       return partOfPathname;
  //   }
};

export const getPathIcon = (
  partOfPathname: string,
): `${IconsVariant}` | undefined => {
  switch (partOfPathname) {
    case ProjectPath.MARKET:
      return "market";
    case ProjectPath.LOOTBOXES:
      return "box";
    case ProjectPath.ADVERTISEMENT:
      return "ads-arrow";
    default:
      return undefined;
  }
};

export const createLink = (
  pathElements: string[],
  index: number,
): string | undefined => {
  if (pathElements[index] === "item" || pathElements[index] === "swap") {
    return undefined;
  }

  if (
    pathElements[index - 1] === "item" &&
    NftMap[pathElements[index] as TokenAddress]
  ) {
    return `/${ProjectPath.MARKET}/${pathElements[index]}`;
  }

  if (
    pathElements[index - 1] === "nft" &&
    NftMap[pathElements[index] as TokenAddress]
  ) {
    return undefined;
  }

  // Проверка, является ли элемент числом
  const isNumber = /^\d+$/.test(pathElements[index]);

  if (isNumber) {
    return undefined;
  }

  // Проверка, является ли это последним элементом
  if (index === pathElements.length - 1) {
    return undefined;
  }

  // Формирование ссылки до указанного индекса (включительно)
  const partialPath = pathElements.slice(0, index + 1).join("/");

  // Возвращение итоговой ссылки
  return `/${partialPath}`;
};
