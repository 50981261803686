import { useQueries } from "@tanstack/react-query";
import { getListingTotalAmount } from "api/listing";
import { TOKEN_ADDRESS_MAP } from "constants/nftConstants";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { TokenAddress } from "types/listing";
import { RequestListingFilter } from "types/requests";
import { showErrors } from "utils/showErrors";

type ListingSelector = "all" | "my" | "both";

function createListingQuery(
  type: TokenAddress,
  cookie: string | null,
  selector: ListingSelector,
  filters?: RequestListingFilter,
) {
  const queryArray = [];
  const deps: any[] = ["listingsTotalAmount", type];

  if (filters) {
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      if (filters?.[key as keyof RequestListingFilter]) {
        deps.push(filters?.[key as keyof RequestListingFilter]);
      }
    });
  }

  if (selector === "all" || selector === "both")
    queryArray.push({
      queryKey: [...deps, "all"],
      queryFn: () => getListingTotalAmount("all", type, filters),
      onError: showErrors,
    });
  if (selector === "my" || selector === "both")
    queryArray.push({
      queryKey: [...deps, "my"],
      queryFn: () => getListingTotalAmount("my", type, filters),
      enabled: !!cookie,
      onError: showErrors,
    });
  return queryArray;
}
const useGetListingTotalAmount = (
  selector: ListingSelector,
  filters?: RequestListingFilter,
  type?: TokenAddress,
) => {
  const cookie = useGetAuthCookie();
  let tokenTypes: TokenAddress[] = [];

  if (!type) {
    tokenTypes = Object.values(TOKEN_ADDRESS_MAP);
  } else {
    tokenTypes = [type];
  }
  const queries = tokenTypes.flatMap((tokenType) =>
    createListingQuery(tokenType, cookie, selector, filters),
  );

  const queryInfo = useQueries({ queries });
  return queryInfo;
};

export default useGetListingTotalAmount;
