import React from "react";

import Icon from "components/common/Icon";

import s from "./SocialsPart.module.scss";

function SocialsPart() {
  return (
    <div className={s.socialsPart}>
      <div className={s.social}>
        <Icon variant="chats" className={s.icon} />
        <div className={s.title}>Support Center</div>
        <a href="https://t.me/MetaTrace_Help_Bot" className="fill-link">
          Support Center
        </a>
      </div>
      <div className={s.social}>
        <Icon variant="X" className={s.icon} />
        <div className={s.title}>Follow us</div>
        <a href="https://twitter.com/trace_meta" className="fill-link">
          twitter
        </a>
      </div>
      <div className={s.social}>
        <Icon variant="telegram" className={s.icon} />
        <div className={s.title}>Join our community</div>
        <a href="https://t.me/MetaTrace" className="fill-link">
          telegram
        </a>
      </div>
    </div>
  );
}

export default SocialsPart;
