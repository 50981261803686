import { useQuery } from "@tanstack/react-query";
import TG_CODE from "constants/telegram";
import useReCAPTCHAv3 from "hooks/useReCAPTCHAv3";
import { useEffect } from "react";
import { AuthResponse, GetAuthTgLinkResponse } from "types/responses";
import useRequestAuth, { isGetAuthTgLinkResponse } from "./useRequestAuth";

const useGetAuthTgLink = () => {
  const { recaptchaToken } = useReCAPTCHAv3("login");
  const { mutateAsync: auth } = useRequestAuth();

  const queryInfo = useQuery<GetAuthTgLinkResponse | AuthResponse, Error>(
    ["authTgLink"],
    async () => {
      return auth({
        token: recaptchaToken,
        source: "loginViaTg",
      });
    },
    {
      enabled: !!recaptchaToken,
      staleTime: 120000, // 2 minutes
    },
  );

  useEffect(() => {
    if (queryInfo?.data && isGetAuthTgLinkResponse(queryInfo?.data)) {
      sessionStorage.setItem(TG_CODE, queryInfo?.data?.code);
    }
  }, [queryInfo?.data]);

  return queryInfo;
};

export default useGetAuthTgLink;
