import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { cancelListing } from "api/listing";
import { showErrors } from "utils/showErrors";

import ProjectPath from "constants/paths";
import ApiError from "classes/ApiError";
import { CancelListingRequest } from "types/requests";
import {
  CancelListingResponse,
  RestructuredBalanceItemResponse,
} from "types/responses";
import { Collection, TokenAddress } from "types/listing";

type UseCancelListingProps = {
  tokenAddress: TokenAddress;
  collectionId?: string;
  cancelFrom?: "market" | "wallet";
};
const useCancelListing = ({
  listing_id,
  tokenAddress,
  collectionId,
  cancelFrom = "market",
}: CancelListingRequest & UseCancelListingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<CancelListingResponse, ApiError>(
    ["cancel-listing"],
    async () => {
      if (listing_id) {
        return cancelListing({ listing_id });
      }
      return Promise.reject(new Error(t("notifications.noListingId.title")));
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          message: t("notifications.itemClosed.title"),
          variant: "success",
        });
        queryClient.invalidateQueries({ queryKey: ["listings", "my"] });
        if (tokenAddress !== "town") {
          navigate(`/${ProjectPath.WALLET_NFT}`);
          return;
        }
        if (cancelFrom === "market") {
          queryClient.setQueryData<Collection>(
            ["listing", collectionId],
            (listingItem) => {
              if (!listingItem) return listingItem;
              if ("listings" in listingItem) {
                const filteredSubListings = listingItem.listings?.filter(
                  (listing) => listing.id !== listing_id,
                );
                return { ...listingItem, listings: filteredSubListings };
              }
              return listingItem;
            },
          );
        } else {
          queryClient.setQueryData<RestructuredBalanceItemResponse>(
            ["balance-item", collectionId, tokenAddress],
            (balanceItem) => {
              if (!balanceItem) return balanceItem;
              const filteredListings = balanceItem.metadata.listings?.filter(
                (listing) => listing.id !== listing_id,
              );
              return {
                ...balanceItem,
                metadata: {
                  ...balanceItem.metadata,
                  listings: filteredListings,
                },
              };
            },
          );
        }
      },
      onError: showErrors,
    },
  );
};

export default useCancelListing;
