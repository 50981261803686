import Image from "components/common/Image";
import React from "react";

import s from "./TokenUtilization.module.scss";

function TokenUtilization() {
  return (
    <div className={s.wrapper}>
      <Image
        image="utilization"
        className={s.banner}
        wrapperClassName={s.bannerWrapper}
      />
      <div>
        <div className={s.title}>Future Token Utilization</div>
        <div className={s.description}>
          MetaTrace is continuously developing in-game mechanics with a focus on
          incorporating TRC seamlessly, crafting a new gaming experience without
          burdening players with complex crypto transactions.
        </div>
      </div>
    </div>
  );
}

export default TokenUtilization;
