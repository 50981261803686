import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { closeSortModal, setResultTaskRequest } from "store/modalsSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";

import { SortVariant } from "types/filters";

import Icon from "components/common/Icon";
import ModalContainer from "../ModalContainer";

import s from "./SortModal.module.scss";
import ModalContainerWithActions from "../ModalContainerWithActions";

function SortModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sortModal } = useAppSelector((state) => state.modals);

  const close = useCallback(() => dispatch(closeSortModal()), [closeSortModal]);

  const onVariantClick = (variant: SortVariant) => () => {
    close();
    dispatch(
      setResultTaskRequest({
        result: "resolved",
        data: { variant },
      }),
    );
  };

  return (
    <ModalContainerWithActions
      open={sortModal.isOpen}
      close={close}
      title={t("sortVariants.title")}
    >
      <div className={s.modal}>
        <button
          type="button"
          className={s.variant}
          onClick={onVariantClick("PriceAsc")}
        >
          {t("sortVariants.minPrice")}
          <Icon
            variant="arrowDownRoundedSvg"
            className={s.icon}
            withWrapper
            wrapperClassName={s.iconWrapper}
          />
        </button>
        <button
          type="button"
          className={s.variant}
          onClick={onVariantClick("PriceDsc")}
        >
          {t("sortVariants.maxPrice")}
          <Icon
            variant="arrowDownRoundedSvg"
            className={cn(s.icon, s.arrowUp)}
            withWrapper
            wrapperClassName={s.iconWrapper}
          />
        </button>
        <button
          type="button"
          className={s.variant}
          onClick={onVariantClick("CreateDsc")}
        >
          {t("sortVariants.newest")}
          <Icon
            variant="star"
            className={s.icon}
            withWrapper
            wrapperClassName={s.iconWrapper}
          />
        </button>
      </div>
    </ModalContainerWithActions>
  );
}

export default SortModal;
