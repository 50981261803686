import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { getListing } from "api/listing";
import { showErrors } from "utils/showErrors";

import ApiError from "classes/ApiError";
import { GetListingRequest, RequestListingFilter } from "types/requests";
import { RestructuredListingResponse } from "types/responses";

interface UseGetListingParams extends GetListingRequest {
  listingType?: "all" | "my";
}

const useGetListing = ({
  listingType = "all",
  ...queryParams
}: UseGetListingParams) => {
  const deps: any[] = ["listings", listingType];

  const keys = Object.keys(queryParams);
  keys.forEach((key) => {
    if (queryParams[key as keyof GetListingRequest]) {
      deps.push(queryParams[key as keyof GetListingRequest]);
    }
    if (queryParams?.filters?.[key as keyof RequestListingFilter]) {
      deps.push(queryParams?.filters?.[key as keyof RequestListingFilter]);
    }
  });

  const queryInfo = useInfiniteQuery<
    RestructuredListingResponse | null,
    ApiError
  >(
    deps,
    async ({ pageParam = 1 }) => {
      const paramsWithPagination = {
        ...queryParams,
        page: pageParam,
        page_size: 6,
      };
      return getListing(listingType, paramsWithPagination);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.page_index_next) {
          return lastPage.page_index_next < 0
            ? undefined
            : lastPage.page_index_next;
        }
        return undefined;
      },
      enabled: !!queryParams?.typeNft,
    },
  );

  useEffect(() => {
    if (queryInfo.isError && queryInfo.error instanceof ApiError) {
      showErrors(queryInfo.error);
    }
  }, [queryInfo.isError, queryInfo.error]);

  return queryInfo;
};

export default useGetListing;
