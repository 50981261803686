import { useAppSelector } from "hooks/store";
import React from "react";
import { Price } from "../Price";
import s from "./SmallItemCard.module.scss";

function SmallItemCard() {
  const { image, title, hash, priceType, priceConverted, quantity } =
    useAppSelector((state) => state.marketItem);
  return (
    <div className={s.content}>
      <div className={s.image}>
        {image && <img src={image} alt="item" className="fill-cover" />}
      </div>
      <div className={s.info}>
        <div className={s.titleWrapper}>
          <div className={s.title}>{title}</div>
          <div className={s.hash}>{hash}</div>
        </div>
        <Price
          priceType={priceType}
          priceConverted={priceConverted}
          quantity={quantity}
        />
      </div>
    </div>
  );
}

export default SmallItemCard;
