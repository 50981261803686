import React from "react";

import Icon from "components/common/Icon";

import s from "./TownCoordinates.module.scss";

interface Props {
  latValue: number;
  lonValue: number;
  className?: string;
}

function TownCoordinates({ latValue, lonValue, className = "" }: Props) {
  return (
    <div className={`${s.coordinatesWrapper} ${className}`}>
      <div className={s.coordinates}>
        <Icon wrapperClassName={s.icon} withWrapper variant="location" />
        <div className={s.valuePart}>
          <span className={s.value}>{latValue}</span>
          <span className={s.separator}>/</span>
          <span className={s.value}>{lonValue}</span>
        </div>
        <div className={s.emptySpace} />
      </div>
    </div>
  );
}

export default TownCoordinates;
