import React, { useState } from "react";

import TokensBuyModal from "components/modals/TokensBuyModal";

import TokensSection from "./sections/TokensSection";
import TokenomicsChartSection from "./sections/TokenomicsChartSection";
import CoreSection from "./sections/CoreSection";
import EconomicModelSection from "./sections/EconomicModelSection";
import BuyTRCBannerSection from "./sections/BuyTRCBannerSection";

import s from "./TokensPage.module.scss";
import PartnerSection from "./sections/PartnerSection";
import SocialMediaSection from "./sections/SocialMediaSection";

export type TokenType = "trc" | "ace";

function TokensPage() {
  const [tokenType, setTokenType] = useState<TokenType>("trc");

  const changeTokenType = (type: TokenType) => {
    setTokenType(type);
  };

  return (
    <div className={s.tokensPage}>
      <div className={s.container}>
        <TokensSection
          tokenType={tokenType}
          changeTokenType={changeTokenType}
        />
        {tokenType === "trc" && (
          <>
            <div className={s.space} />
            <CoreSection />
            <div className={s.space} />
            <EconomicModelSection />
            <div className={s.space} />
            <TokenomicsChartSection />
          </>
        )}
        <div className={s.space} />
        <PartnerSection />
        <div className={s.space} />
        <SocialMediaSection />
        <div className={s.space} />
        <BuyTRCBannerSection />
      </div>
      <TokensBuyModal />
    </div>
  );
}

export default TokensPage;
