function filterCurrencyInput(
  value: string,
  maxLengthAfterPoint: number,
): string {
  if (maxLengthAfterPoint === 0) {
    return value.replace(/[^0-9]/g, "");
  }

  const valueWithoutLetters = value.replace(/[^0-9.,]/g, "");
  const valueWithOnlyDots = valueWithoutLetters.replace(/,/g, ".");
  const valueWithOnlyDot = valueWithOnlyDots.replace(/\./g, (match, index) =>
    index === valueWithOnlyDots.indexOf(".") ? match : "",
  );

  return valueWithOnlyDot.replace(
    new RegExp(`^(\\.|[^.]*)(\\.\\d{${maxLengthAfterPoint}})\\d*`, "g"),
    "$1$2",
  );
}

export default filterCurrencyInput;
