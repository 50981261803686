import { useAppSelector } from "hooks/store";
import { useEffect } from "react";

const setHeight = (isTgWebApp: boolean) => {
  const height = window.innerHeight;
  document.documentElement.style.setProperty("--height", `${height}px`);

  if (isTgWebApp) {
    const contentWrapper = document.querySelector(
      "[class^=NavigationLayout_contentWrapper]",
    );
    const oldScrollHeight =
      document.documentElement.scrollTop +
      (contentWrapper ? contentWrapper.scrollTop : 0);

    window.scroll(0, 0);
    contentWrapper?.scrollTo({
      top: oldScrollHeight + 50,
      behavior: "smooth",
    });
  }
};

function HeightSetter() {
  const { isTgWebApp } = useAppSelector((state) => state.appState);

  useEffect(() => {
    setHeight(!!isTgWebApp);

    const resizeCallback = () => {
      setHeight(!!isTgWebApp);
    };

    window.addEventListener("resize", resizeCallback);

    return () => window.removeEventListener("resize", resizeCallback);
  }, [isTgWebApp]);

  return null;
}

export default HeightSetter;
