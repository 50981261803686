import React, { useState, useRef, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "hooks/store";
import { FiltersSliceType, ListingFilter } from "types/filters";
import Icon from "components/common/Icon";
import { TFuncKey, useTranslation } from "react-i18next";
import cn from "classnames";
import { RangeContent, SelectContent } from "../FilterContent";

import s from "./FilterElement.module.scss";

interface FilterElementProps {
  title: string;
  filter: ListingFilter;
  filtersSliceType: FiltersSliceType;
  isAllFiltersCollapsed: boolean;
  collapseAllFilters: (prevState: boolean) => void;
  isLoading?: boolean;
}

function FilterElement({
  title,
  filter,
  filtersSliceType,
  isAllFiltersCollapsed,
  collapseAllFilters,
  isLoading = false,
}: FilterElementProps) {
  const { t } = useTranslation();
  const translatedTitle = t(
    `item.attributes.${title.toLowerCase()}` as TFuncKey,
  ).toString();
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const { filters, appliedFilter } = useAppSelector(
    (state) => state.filters[filtersSliceType],
  );

  const filterFull = filters[filter.trait_type];
  const appliedFilters = useMemo(
    () => appliedFilter[filter.trait_type] ?? [],
    [appliedFilter, filter.trait_type],
  );

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
    }
    if (isOpen) {
      collapseAllFilters(false);
    }
  }, [isOpen, collapseAllFilters]);

  useEffect(() => {
    if (isAllFiltersCollapsed) {
      setIsOpen(false);
    }
  }, [isAllFiltersCollapsed]);

  const variants = {
    open: { opacity: 1, height },
    closed: { opacity: 0, height: 0 },
  };

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  if (isLoading) {
    return (
      <div className={s.filterElement}>
        <Skeleton height="100%" containerClassName="skeleton fill" />
      </div>
    );
  }

  const applied: string[] | number[] | null =
    appliedFilters && Array.isArray(appliedFilters) && appliedFilters.length
      ? appliedFilters
      : null;

  return (
    <div
      className={`${s.filterElement} ${filterFull?.isActive ? s.active : ""}`}
    >
      <button type="button" className={s.summery} onClick={toggleAccordion}>
        <div className={s.title}>
          <span>{translatedTitle}</span>
          {applied && filterFull?.type === "selector" && (
            <span className={s.applied}>{applied.length}</span>
          )}
          {applied && filterFull?.type === "range" && (
            <span className={s.appliedRange} />
          )}
        </div>
        {isOpen ? (
          <Icon variant="arrowDown" className={s.arrowUp} />
        ) : (
          <Icon variant="arrowDown" className={s.arrowDown} />
        )}
      </button>
      <motion.div
        className={s.contentWrapper}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div
          className={cn(s.content, {
            [s.contentSelector]: filterFull?.type === "selector",
          })}
          ref={contentRef}
        >
          {filterFull?.type === "range" && (
            <RangeContent
              filter={filterFull}
              appliedFilters={appliedFilters}
              filtersSliceType={filtersSliceType}
            />
          )}
          {filterFull?.type === "selector" && (
            <SelectContent
              filter={filterFull}
              appliedFilters={appliedFilters}
              filtersSliceType={filtersSliceType}
            />
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default FilterElement;
