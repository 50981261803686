import React from "react";
import noNft from "assets/images/noNFT.webp";
import { useTranslation } from "react-i18next";

import s from "./NoListing.module.scss";

function NoListing() {
  const { t } = useTranslation();

  return (
    <div>
      <div className={s.noListingTitle}>{t("market.noListingTitle")}</div>
      <img src={noNft} alt="No pass" className={s.noNft} />
    </div>
  );
}

export default NoListing;
