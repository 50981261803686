import React from "react";

import s from "./CharacteristicValue.module.scss";

enum ValueType {
  text = "text",
  percentage = "percentage",
  fraction = "fraction",
}

interface CharacteristicValueProps {
  value: number | string;
  max?: number;
  variant?: `${ValueType}`;
  secondLine?: string;
}

function CharacteristicValue({
  value,
  max = undefined,
  variant = ValueType.text,
  secondLine = "",
}: CharacteristicValueProps) {
  switch (variant) {
    case ValueType.text:
      return (
        <div className={`${s.valueWrapper} ${s[variant]}`}>
          <div className={s.value}>{value}</div>
          {secondLine && <div className={s.secondLine}>{secondLine}</div>}
        </div>
      );
    case ValueType.percentage:
      return (
        <div className={`${s.valueWrapper} ${s[variant]}`}>
          <div className={s.value}>{value}%</div>
          {secondLine && <div className={s.secondLine}>{secondLine}</div>}
        </div>
      );
    case ValueType.fraction:
      return (
        <div className={`${s.valueWrapper} ${s[variant]}`}>
          <div className={s.value}>
            <b>{value}</b>/{max}
          </div>
          {secondLine && <div className={s.secondLine}>{secondLine}</div>}
        </div>
      );

    default:
      return null;
  }
}

export default CharacteristicValue;
