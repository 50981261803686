/* eslint-disable import/prefer-default-export */

import ProjectPath from "constants/paths";
import {
  INavigationItem,
  INavigationMore,
  ISimpleNavigationElement,
} from "./NavigationElement/types";

export const navigationItems: INavigationItem[] = [
  {
    id: 0,
    title: "Home",
    link: `${ProjectPath.REAL_WORLD}`,
    icon: "home",
    isResourceLocal: false,
    mustBeAuthorized: false,
    isTargetBlank: true,
  },
  {
    id: 1,
    title: "Wallet",
    link: `/${ProjectPath.WALLET}`,
    icon: "wallet",
    isResourceLocal: true,
    mustBeAuthorized: true,
  },
  {
    id: 3,
    title: "Market",
    link: `/${ProjectPath.MARKET}`,
    icon: "market",
    isResourceLocal: true,
    mustBeAuthorized: false,
    subItems: [
      {
        id: 0,
        title: "NFT Car",
        link: `/${ProjectPath.MARKET}/car`,
        isResourceLocal: true,
        mustBeAuthorized: false,
      },
      {
        id: 1,
        title: "NFT Pass",
        link: `/${ProjectPath.MARKET}/pass`,
        isResourceLocal: true,
        mustBeAuthorized: false,
      },
      {
        id: 2,
        title: "NFT Cypet",
        link: `/${ProjectPath.MARKET}/pet`,
        isResourceLocal: true,
        mustBeAuthorized: false,
      },
      {
        id: 3,
        title: "NFT Parts",
        link: `/${ProjectPath.MARKET}/part`,
        isResourceLocal: true,
        mustBeAuthorized: false,
      },
      {
        id: 4,
        title: "NFT Towns",
        link: `/${ProjectPath.MARKET}/town`,
        isResourceLocal: true,
        mustBeAuthorized: false,
      },
    ],
  },
  {
    id: 4,
    title: "Game Shop",
    link: `/${ProjectPath.GAME_SHOP}`,
    icon: "game_shop",
    isResourceLocal: true,
    mustBeAuthorized: false,
    isNew: true,
  },
  {
    id: 5,
    title: "Invite To Earn",
    link: `/${ProjectPath.INVITE}`,
    icon: "personPlus",
    isResourceLocal: true,
    mustBeAuthorized: false,
  },
  {
    id: 6,
    title: "Tokens",
    link: `/${ProjectPath.TOKENS}`,
    icon: "tokens",
    isResourceLocal: true,
    mustBeAuthorized: false,
  },
];

export const navigationMore: INavigationMore = {
  title: "More",
  icon: "dots",
  subItems: [
    {
      id: 0,
      title: "Towns",
      link: `/${ProjectPath.TOWNS}`,
      isResourceLocal: false,
      mustBeAuthorized: false,
    },
    {
      id: 1,
      title: "Ads",
      link: `/${ProjectPath.ADVERTISEMENT}`,
      isResourceLocal: false,
      mustBeAuthorized: false,
    },
    {
      id: 2,
      title: "Whitepaper",
      link: `${ProjectPath.WHITEPAPER}`,
      isResourceLocal: false,
      mustBeAuthorized: false,
    },
    {
      id: 3,
      title: "EcoTrace",
      link: `${ProjectPath.ECO_TRACE}`,
      isResourceLocal: false,
      mustBeAuthorized: false,
    },
    {
      id: 4,
      title: "Update 1.5",
      link: `${ProjectPath.GAME}/${ProjectPath.UPDATE1_5}`,
      isResourceLocal: false,
      mustBeAuthorized: false,
    },
  ],
};

export const navigationSupport: INavigationItem = {
  id: 0,
  title: "Support Center",
  link: `https://t.me/MetaTrace_Help_Bot`,
  icon: "chats",
  isResourceLocal: false,
  mustBeAuthorized: false,
};

export const navigationSocials: ISimpleNavigationElement[] = [
  {
    id: 0,
    title: "Follow us",
    link: `https://twitter.com/trace_meta`,
    icon: "X",
    isResourceLocal: false,
    mustBeAuthorized: false,
  },
  {
    id: 1,
    title: "Join our community",
    link: `https://t.me/MetaTrace`,
    icon: "telegram",
    isResourceLocal: false,
    mustBeAuthorized: false,
  },
];
