/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable eqeqeq */
import convert, { UnitType } from "ethereum-unit-converter";
import { JustCoinType } from "types/coins";

export const convertDecimalTokenShort = (
  value: string,
  tokenType: `${JustCoinType}`,
) => {
  if (value === "0") return "0";

  // Определяем единицу измерения в зависимости от типа токена
  const unit: UnitType = (() => {
    switch (tokenType) {
      case "matic":
      case "weth":
        return "wei";
      case "usdt":
        return "szabo";
      case "wbtc":
        return "btc";
      case "trc":
      case "ace":
        return "trc/ace";

      default:
        throw new Error("Unsupported token type");
    }
  })();

  const convertedValue = convert(value, unit, "ether");

  // Устанавливаем количество знаков после запятой в зависимости от токена
  const decimalPlaces = (() => {
    if (tokenType === "wbtc" || tokenType === "weth") return 5;
    if (tokenType === "matic") return 4;
    return 2;
  })();
  const minValue = (() => {
    if (tokenType === "wbtc" || tokenType === "weth") return "0.00001";
    if (tokenType === "matic") return "0.0001";
    return "0.01";
  })();

  if (Number(convertedValue) < Number(minValue)) {
    return minValue;
  }

  // Разделяем число на целую и десятичную часть
  const [integerPart, decimalPart] = convertedValue.split(".");

  // Если десятичная часть существует и её длина больше допустимой, обрезаем
  const trimmedDecimalPart =
    decimalPart?.length > decimalPlaces
      ? decimalPart.substr(0, decimalPlaces)
      : decimalPart;

  // Собираем число обратно
  return integerPart + (trimmedDecimalPart ? `.${trimmedDecimalPart}` : "");
};

export const convertDecimalToken = (
  value: string,
  tokenType: `${JustCoinType}`,
) => {
  let result: string = value;
  switch (tokenType) {
    case JustCoinType.ace:
    case JustCoinType.trc:
      result = convert(value, "trc/ace", "ether");
      break;
    case JustCoinType.matic:
      result = convert(value, "wei", "ether");
      break;
    case JustCoinType.usdt:
      result = convert(value, "szabo", "ether");
      break;
    case JustCoinType.wbtc:
      result = convert(value, "btc", "ether");
      break;
    case JustCoinType.weth:
      result = convert(value, "wei", "ether");
      break;
    default:
      result = convert(value, "wei", "ether");
      break;
  }
  return result;
};

export const convertDecimalTokenBackward = (
  value: string,
  tokenType: `${JustCoinType}`,
) => {
  let result: string = value;
  switch (tokenType) {
    case JustCoinType.ace:
    case JustCoinType.trc:
      result = convert(value, "ether", "trc/ace");
      break;
    case JustCoinType.matic:
    case JustCoinType.weth:
      result = convert(value, "ether", "wei");
      break;
    case JustCoinType.usdt:
      result = convert(value, "ether", "szabo");
      break;
    case JustCoinType.wbtc:
      result = convert(value, "ether", "btc");
      break;
    default:
      result = convert(value, "ether", "wei");
      break;
  }
  return result;
};
